import { defineStore } from 'pinia';

import DemohubService from '@/services/Demohub';

import ErrorHandling from '@/mixins/ErrorHandling';

import dummyData from './_dummyData';

const useDemohubStore = defineStore({
  id: 'Demohub',
  state: () => ({
    // ## Demohub integration. -------------------------------------------------------------------------------
    demohubIntegrations: dummyData,
    demohubIntegrationsDummy: [],
    demohubIntegrationsDone: false,
    demohubIntegrationsPending: false,
    demohubIntegrationsPerPage: 15,
    demohubIntegrationsTotal: 0,

    // ## Demohub scanner details. ------------------------------------------------------------------------
    demohubScannerDetails: {
    },
    demohubScannerDetailsDone: false,
    demohubScannerDetailsPending: false,

    // ## Demohub scanner details. ------------------------------------------------------------------------
    sendDemohubRequestData: {},
    sendDemohubRequestDone: false,
    sendDemohubRequestPending: false,

    // ## Demohub Integrations ------------------------------------------------------------------------
    demohubScannerIntegrations: [],
    demohubScannerIntegrationsMenu: [],
    demohubTrainingIntegrations: [],
    demohubSingleSignOnIntegration: [],
    demohubAlmIntegration: [],
  }),
  actions: {
    // ## Get Demohub integrations. ---------------------------------------------------------------------------
    async getDemohubIntegrations(data) {
      this.demohubIntegrationsPending = true;
      this.demohubIntegrations = dummyData;

      try {
        const params = {
          limit: this.demohubIntegrationsPerPage,
          start: (data.page - 1) * this.demohubIntegrationsPerPage,
          category: data.type,
        };
        const res = await DemohubService.getDemohubIntegrations(params);
        this.demohubIntegrationsTotal = res.data.total;
        this.setGetDemohubIntegrations(res.data.data);
        this.demohubIntegrationsDone = !this.demohubIntegrationsDone;
        this.demohubIntegrationsPending = false;
      } catch (error) {
        this.demohubIntegrationsPending = false;
        this.demohubIntegrationsFail = !this.demohubIntegrationsFail;
        ErrorHandling(error);
      }
    },

    setGetDemohubIntegrations(payload) {
      this.demohubIntegrations = payload.map((s) => ({
        id: s.id,
        name: s.name,
        category: s.category,
        image: s.image,
        description: s.description,
        mode: s.mode,
        website: s.website,
        partnerGroupId: s.partner_group_id,
        type: s.type,
      }));
      this.demohubScannerIntegrations = this.demohubIntegrations.filter((s) => s.type === 'scanner');
      this.demohubScannerIntegrationsMenu = ['sast', ...new Set(this.demohubScannerIntegrations.map((s) => s.category).filter((s) => s !== 'sast'))];
      this.demohubTrainingIntegrations = this.demohubIntegrations.filter((s) => s.category === 'training');
      this.demohubSingleSignOnIntegration = this.demohubIntegrations.filter((s) => s.category === 'sso');
      this.demohubAlmIntegration = this.demohubIntegrations.filter((s) => s.category === 'alm');
    },

    async getDemohubScannerDetails(data) {
      this.demohubScannerDetailsPending = true;

      try {
        const res = await DemohubService.getDemohubScannerDetails(data);
        this.setGetDemohubScannerDetails(res.data.data);
        this.demohubScannerDetailsDone = !this.demohubScannerDetailsDone;
        this.demohubScannerDetailsPending = false;
      } catch (error) {
        this.demohubScannerDetailsPending = false;
        ErrorHandling(error);
      }
    },

    setGetDemohubScannerDetails(payload) {
      this.demohubScannerDetails = {
        partnerId: payload.partner_id,
        overview: payload.product_overview,
        highlights: payload.highlights,
        resources: payload.resources,
        media: payload.media_resources,
        tool: payload.tool,
      };
    },

    async sendDemohubRequest(data) {
      this.sendDemohubRequestPending = true;

      try {
        await DemohubService.sendDemohubRequest(data);
        this.sendDemohubRequestDone = !this.sendDemohubRequestDone;
        this.sendDemohubRequestPending = false;
      } catch (error) {
        this.sendDemohubRequestPending = false;
        ErrorHandling(error);
      }
    },
  },
});

export default useDemohubStore;
