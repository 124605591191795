import { createApp } from 'vue';
import { createMetaManager } from 'vue-meta';
import { createPinia } from 'pinia';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import App from '@/App.vue';
import Antd from 'ant-design-vue';
import 'remixicon/fonts/remixicon.css';

import ECharts from 'vue-echarts';
import { use } from 'echarts/core';

import { SVGRenderer } from 'echarts/renderers';
import {
  BarChart,
  PieChart,
  LineChart,
  ScatterChart,
  RadarChart,
  GaugeChart,
} from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  VisualMapComponent,
} from 'echarts/components';

import 'devicon/devicon.min.css';
import vClickOutside from 'click-outside-vue3';

import Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';
import More from 'highcharts/highcharts-more';

import router from '@/router/';
import i18n from '@/i18n';

import {
  faClipboardListCheck,
  faShieldXmark,
  faMessageExclamation,
  faAlarmExclamation,
  faFileCode,
  faFileExclamation,
  faHandshake,
  faHandshakeSlash,
} from '@fortawesome/pro-regular-svg-icons';

library.add(
  faClipboardListCheck,
  faShieldXmark,
  faMessageExclamation,
  faAlarmExclamation,
  faFileCode,
  faFileExclamation,
  faHandshake,
  faHandshakeSlash,
);

use([
  SVGRenderer,
  BarChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  VisualMapComponent,
  PieChart,
  LineChart,
  ScatterChart,
  RadarChart,
  GaugeChart,
]);

const pinia = createPinia();
const app = createApp(App);
app.use(pinia);
app.use(router);
app.use(i18n);
app.use(createMetaManager());
app.use(Antd);
app.use(HighchartsVue);
app.use(vClickOutside);
app.component('v-chart', ECharts);
app.component('font-awesome-icon', FontAwesomeIcon);
More(Highcharts);

app.config.errorHandler = (err) => {
  console.error(err);
};
app.config.warnHandler = (msg) => {
  console.warn(msg);
};
app.mount('#app');
