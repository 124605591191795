/* eslint-disable import/no-cycle */
import axios from 'axios';

import { API_URL, LOCAL_STORAGE } from '@/config/';

import useAuthStore from '@/store/auth';
import useDashboardFilterStore from '@/store/dashboard/Filter';

export default (params) => {
  const authStore = useAuthStore();
  const dashboardFilterStore = useDashboardFilterStore();
  const controller = new AbortController();

  const axiosInstance = axios.create({
    baseURL: API_URL.BASE,
    withCredentials: false,
  });

  // ## Global error handler.
  axiosInstance.interceptors.request.use((config) => {
    authStore.addPendingRequest(controller);
    return config;
  }, (error) => Promise.reject(error));

  axiosInstance.interceptors.response.use(
    (response) => (response),
    (error) => {
      const res = error.response;
      if (!res && !error.request) {
        authStore.authTimeout();
        dashboardFilterStore.removeGlobalDashboardFilter();
        return Promise.reject(error);
      }
      if (error.code === 'ECONNABORTED') {
        authStore.setRequestTimeout();
        dashboardFilterStore.removeGlobalDashboardFilter();
        return Promise.reject(error);
      }
      if (error.message === 'Network Error') {
        authStore.authTimeout();
        dashboardFilterStore.removeGlobalDashboardFilter();
      }
      if (res && res.status === 401) {
        authStore.authTimeout();
        dashboardFilterStore.removeGlobalDashboardFilter();
      }
      return Promise.reject(error);
    },
  );

  if (params) {
    if (params.responseType) {
      axiosInstance.defaults.responseType = params.responseType;
    } else {
      delete axiosInstance.defaults.responseType;
    }
  } else {
    delete axiosInstance.defaults.responseType;
  }
  axiosInstance.defaults.timeout = 5000000;
  axiosInstance.defaults.headers.common.authorization =
    `Bearer ${localStorage[LOCAL_STORAGE.TOKEN_NAME]}`;
  axiosInstance.defaults.signal = controller.signal;
  return axiosInstance;
};
