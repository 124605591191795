import { defineStore } from 'pinia';

import CommitterService from '@/services/Committer';

import ErrorHandling from '@/mixins/ErrorHandling';

import { COMMITTER } from '@/config/';

import i18n from '@/i18n';

import dummyData from './_dummyData';

const useCommitterStore = defineStore({
  id: 'Committer',
  state: () => ({
    // ## Committers. --------------------------------------------------------------------------------
    committers: [],
    committersPending: false,
    committersDone: false,

    // ## Committers insght list. --------------------------------------------------------------------
    committersInsightList: dummyData,
    committersInsightListPerpage: COMMITTER.PERPAGE,
    committersInsightListTotal: 0,
    committersInsightListPageSize: 0,
    committersInsightListPending: false,
    committersInsightListDone: false,
    committersInsightListFail: false,
    committersInsightListFailMsg: null,

    // ## Committer insight detail. ------------------------------------------------------------------
    committerInsightDetail: {},
    committerInsightDetailPending: false,
    committerInsightDetailDone: false,

    // ## Committer insight language. ----------------------------------------------------------------
    committerInsightLanguage: [],
    committerInsightLanguagePending: false,
    committerInsightLanguageDone: false,
  }),
  actions: {
    // ## Get committers. ----------------------------------------------------------------------------
    async getCommitters() {
      this.committersPending = true;

      try {
        const res = await CommitterService.getCommitters();
        this.committers = res.data.committers;
        this.committersDone = !this.committersDone;
        this.committersPending = false;
      } catch (error) {
        this.committersPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get committers insight list. ---------------------------------------------------------------
    async getCommittersInsightList(data) {
      this.committersInsightListPending = true;

      try {
        const params = {
          limit: this.committersInsightListPerpage,
          start: (data.page - 1) * this.committersInsightListPerpage,
          sort_by: data.sort,
          default_branch: data.defaultBranch,
          committers: data.committer,
          from: data.from,
          labels: data.label,
          projects: data.project,
          teams: data.team,
          to: data.to,
          severity: data.severity,
        };
        const res = await CommitterService.getCommittersInsightList(params);
        this.committersInsightListTotal = res.data.chart_data.total;
        this.setGetCommittersInsightList(res.data.chart_data);
        this.committersInsightListPageSize =
          Math.ceil(this.committersInsightListTotal / this.committersInsightListPerpage);
        this.committersInsightListDone = !this.committersInsightListDone;
        this.committersInsightListPending = false;
      } catch (error) {
        this.committersInsightListPending = false;
        this.committersInsightListFail = !this.committersInsightListFail;
        this.committersInsightListFailMsg = ErrorHandling(error);
      }
    },

    setGetCommittersInsightList(payload) {
      this.committersInsightList = [];
      if (!payload.committers) {
        return;
      }
      this.committersInsightList = payload.committers.map((s, i) => ({
        id: i,
        name: s.committer_name,
        user: {
          id: s.committer_user.id,
          username: s.committer_user.username,
          email: s.committer_user.email,
          role: s.committer_user.role,
        },
        summary: {
          critical: s.new.findings.critical,
          high: s.new.findings.high,
          medium: s.new.findings.medium,
          low: s.new.findings.low,
          score: s.new.score,
        },
      }));
    },

    // ## Get committer insight detail. --------------------------------------------------------------
    async getCommitterInsightDetail(data) {
      this.committerInsightDetailPending = true;

      try {
        const params = {
          committers: data.committers,
          default_branch: data.defaultBranch,
          from: data.from,
          labels: data.labels,
          projects: data.projects,
          teams: data.teams,
          to: data.to,
          owasp_standard: data.owaspStandard,
        };
        const res = await CommitterService.getCommitterInsightDetail(params);
        this.setGetCommitterInsightDetail(res.data);
        this.committerInsightDetailDone = !this.committerInsightDetailDone;
        this.committerInsightDetailPending = false;
      } catch (error) {
        this.committerInsightDetailPending = false;
        ErrorHandling(error);
      }
    },

    setGetCommitterInsightDetail(payload) {
      this.committerInsightDetail = {
        owaspChart: [],
        training: {},
      };
      if (
        payload.owasp_chart.chart_data.new.filter((s) => s.count).length < 1 &&
        payload.owasp_chart.chart_data.new_with_issue.filter((s) => s.count > 0).length < 1
      ) {
        this.committerInsightDetail.owaspChart = [];
      } else {
        const keys = ['new', 'newWithIssue'];
        keys.forEach((s) => {
          let data = [];
          if (s === 'new') {
            data = payload.owasp_chart.chart_data.new.map((a) => ({
              name: a.name,
              category: a.category,
              description: a.display_name,
              count: a.count,
            }));
          } else {
            data = payload.owasp_chart.chart_data.new_with_issue.map((a) => ({
              name: a.name,
              category: a.category,
              description: a.display_name,
              count: a.count,
            }));
          }
          this.committerInsightDetail.owaspChart.push({
            name: i18n.global.t(s),
            data,
          });
        });
      }
      this.committerInsightDetail.training = payload.training;
    },

    // ## Get committer insight language. ------------------------------------------------------------
    async getCommitterInsightLanguage(data) {
      this.committerInsightLanguagePending = false;

      try {
        const params = {
          committers: data.committers,
          default_branch: data.defaultBranch,
          from: data.from,
          labels: data.labels,
          projects: data.projects,
          teams: data.teams,
          to: data.to,
        };
        const res = await CommitterService.getCommitterInsightLanguage(params);
        this.setGetCommitterInsightLanguage(res.data);
        this.committerInsightLanguageDone = !this.committerInsightLanguageDone;
        this.committerInsightLanguagePending = false;
      } catch (error) {
        this.committerInsightLanguagePending = false;
        ErrorHandling(error);
      }
    },

    setGetCommitterInsightLanguage(payload) {
      this.committerInsightLanguage = [];
      this.committerInsightLanguage = payload.result.map((s, i) => ({
        id: i,
        language: {
          key: s.language,
          name: s.language_name,
        },
        count: s.count,
        child: s.classification.map((a) => ({
          cweId: a.cwe_id,
          cweName: a.cwe_name,
          severity: a.severity_list,
          count: a.count,
        })),
      }));
    },
  },
});

export default useCommitterStore;
