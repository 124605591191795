// ## Config Inc.
import { CLOUDASSETS } from '@/config/';

// eslint-disable-next-line no-unused-vars
const dummyData = [...Array(CLOUDASSETS.PERPAGE)].map((_, i) => ({
  key: i,
  id: i,
  name: '##########',
  platform: '##########',
  resourceType: '##########',
  nativeType: '##########',
  region: '##########',
  projects: ['##########', '##########'],
  tags: [{
    key: '##########',
    value: '##########',
  }],
}));

export default dummyData;
