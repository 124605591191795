const PreComponent = '<router-view/>';

export default {
  path: 'logs',
  component: { template: PreComponent },
  children: [
    {
      path: 'logs',
      name: 'logs',
      component: () => import('@/views/pages/member/Logs.vue'),
      meta: {
        title: 'Logs',
        activeMenuKeys: ['logs', 'system-log'],
        allowedRole: [5],
      },
    }, {
      path: 'audit',
      name: 'audit-log',
      component: () => import('@/views/pages/member/AuditLog.vue'),
      meta: {
        title: 'Audit Log',
        activeMenuKeys: ['logs', 'audit-log'],
        allowedRole: [5],
      },
      beforeEnter: (to) => {
        if (!to.query.page || !to.query.perPage) {
          return {
            name: 'audit-log',
            query: {
              page: 1,
              perPage: 15,
            },
          };
        }
      },
    },
  ],
};
