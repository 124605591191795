import { COMMITTER } from '@/config/';

const dummyData = [...Array(COMMITTER.PERPAGE)].map((_, i) => ({
  id: i,
  name: '########',
  summary: {
    critical: 0,
    high: 0,
    medium: 0,
    low: 0,
    total: 0,
  },
  user: {
    id: 0,
    email: '',
    name: '',
    role: 0,
  },
}));

export default dummyData;
