import MemberLayout from '@/views/layouts/MemberLayout.vue';

import DashboardChildren from './Routes/Dashboard';
import ProjectChildren from './Routes/Project';
import AssetsChildren from './Routes/Assets';
import ProductChildren from './Routes/Product';
import UserChildren from './Routes/User';
import TeamChildren from './Routes/Team';
import VulnerabilityChildren from './Routes/Vulnerability';
import CloudAssetsChildren from './Routes/CloudAssets';
import ScanChildren from './Routes/Scan';
import EndpointChildren from './Routes/Endpoint';
import IntegrationChildren from './Routes/Integration';
import DemohubChildren from './Routes/Demohub';
import WorkflowsChildren from './Routes/Workflows';
import SetupChildren from './Routes/Setup';
import ActionsRequestChildren from './Routes/ActionsRequests';
import LogsChildren from './Routes/Logs';
import SbomChildren from './Routes/Sbom';
import TeamLeadIntegrationChildren from './Routes/TeamLeadIntegration';

export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/visitor/Auth/Login.vue'),
    meta: {
      title: 'Login',
      requiresAuth: false,
    },
  }, {
    path: '/login/okta',
    name: 'OAuthLogin',
    component: () => import('@/views/pages/visitor/Auth/OAuthLogin.vue'),
    meta: {
      requiresAuth: false,
    },
  }, {
    path: '/login/azureactivedirectory',
    name: 'OAuthLoginAzure',
    component: () => import('@/views/pages/visitor/Auth/OAuthLogin.vue'),
    meta: {
      requiresAuth: false,
    },
  }, {
    path: '/login/googlesso',
    name: 'OAuthLoginGoogle',
    component: () => import('@/views/pages/visitor/Auth/OAuthLogin.vue'),
    meta: {
      requiresAuth: false,
    },
  }, {
    path: '/login/saml',
    name: 'OAuthLoginSaml',
    component: () => import('@/views/pages/visitor/Auth/OAuthLogin.vue'),
    meta: {
      requiresAuth: false,
    },
  }, {
    path: '',
    component: MemberLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      DashboardChildren,
      ProjectChildren,
      AssetsChildren,
      ProductChildren,
      UserChildren,
      TeamChildren,
      VulnerabilityChildren,
      CloudAssetsChildren,
      ScanChildren,
      EndpointChildren,
      IntegrationChildren,
      DemohubChildren,
      WorkflowsChildren,
      SetupChildren,
      ActionsRequestChildren,
      LogsChildren,
      SbomChildren,
      TeamLeadIntegrationChildren,
    ],
  },
];
