// -------------------------------------------------------------------------------------------------
// ## General
// -------------------------------------------------------------------------------------------------
const DEFAULT_LANGUAGE = 'en';

const configElement = document.getElementById('config');
const config = JSON.parse(configElement.innerHTML);

const UI_VERSION = process.env.VUE_APP_GIT_TAG;

// -------------------------------------------------------------------------------------------------
// ## Local storage config.
// -------------------------------------------------------------------------------------------------
const LOCAL_STORAGE = {};
LOCAL_STORAGE.TOKEN_NAME = 'kondukto-auth-token';
LOCAL_STORAGE.SEVERITY_CHART_VISIBLE_TEAMS = 'kondukto-severity-visible-teams';

// -------------------------------------------------------------------------------------------------
// ## Scans config.
// -------------------------------------------------------------------------------------------------
const SCANS = {};
SCANS.PERPAGE = 15; // Max 30
SCANS.REFRESH_DELAY = 10000; // Millisecond

// -------------------------------------------------------------------------------------------------
// ## Scans config.
// -------------------------------------------------------------------------------------------------
const VULN = {};
VULN.PERPAGE = 15; // Max 30

// -------------------------------------------------------------------------------------------------
// ## Committer config.
// -------------------------------------------------------------------------------------------------
const COMMITTER = {};
COMMITTER.PERPAGE = 15; // Max 30

// -------------------------------------------------------------------------------------------------
// ## Endpoint config.
// -------------------------------------------------------------------------------------------------
const ENDPOINTS = {};
ENDPOINTS.PERPAGE = 15;

// -------------------------------------------------------------------------------------------------
// ## Cloud assets config.
// -------------------------------------------------------------------------------------------------
const CLOUDASSETS = {};
CLOUDASSETS.PERPAGE = 15;

// -------------------------------------------------------------------------------------------------
// ## Project config.
// -------------------------------------------------------------------------------------------------
const PROJECT = {};

PROJECT.PERPAGE = 12;

PROJECT.WEBHOOK = [
  {
    name: 'azure',
    url: '/webhooks/v1/azure',
    table: [
      {
        event: 'pullRequestMergeAttempted',
        action: 'merged',
        code: 'pull-request',
      }, {
        event: 'codePushed',
        action: 'created',
        code: 'commit-push',
      },
    ],
  }, {
    name: 'github',
    url: '/webhooks/v1/github',
    table: [
      {
        event: 'pullRequest',
        action: 'merged',
        code: 'pull-request',
      }, {
        event: 'pushes',
        action: 'created',
        code: 'commit-push',
      },
    ],
  }, {
    name: 'gitlabonprem',
    url: '/webhooks/v1/gitlabonprem',
    table: [
      {
        event: 'mergeRequestEvents',
        action: 'merged',
        code: 'pull-request',
      }, {
        event: 'pushEvents',
        action: 'pushed',
        code: 'commit-push',
      },
    ],
  }, {
    name: 'gitlabcloud',
    url: '/webhooks/v1/gitlabcloud',
    table: [
      {
        event: 'mergeRequestEvents',
        action: 'merged',
        code: 'pull-request',
      }, {
        event: 'pushEvents',
        action: 'pushed',
        code: 'commit-push',
      },
    ],
  }, {
    name: 'bitbucket',
    url: '/webhooks/v1/bitbucket',
    table: [
      {
        event: 'pullRequest',
        action: 'merged',
        code: 'pull-request',
      }, {
        event: 'repositoryPush',
        action: 'created',
        code: 'commit-push',
      },
    ],
  },
];

PROJECT.SCANNER = [
  {
    type: 'sast',
    name: 'checkmarx',
    customType: 1105,
    imageIndex: 'checkmarx',
    isDrawer: true,
    isDocker: false,
    active: false,
    lang: 'Language agnostic',
    // eslint-disable-next-line max-len
    link: 'https://checkmarx.atlassian.net/wiki/spaces/KC/pages/103252040/8.4.1+Supported+Code+Languages+and+Frameworks',
    auth: [
      { label: 'basic', value: 0 },
    ],
  },
  {
    type: 'sast',
    name: 'checkmarxast',
    customType: 1375,
    imageIndex: 'checkmarxast',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'token', value: 1 },
    ],
  },
  {
    type: 'sast',
    name: 'cxsast',
    customType: 1115,
    imageIndex: 'checkmarx',
    isDrawer: true,
    isDocker: false,
    active: false,
    lang: 'Language agnostic',
    // eslint-disable-next-line max-len
    link: 'https://checkmarx.atlassian.net/wiki/spaces/KC/pages/103252040/8.4.1+Supported+Code+Languages+and+Frameworks',
    auth: [
      { label: 'basic', value: 0 },
    ],
  },
  {
    type: 'sast',
    name: 'coverity',
    customType: 1370,
    imageIndex: 'coverity',
    isDrawer: true,
    isDocker: false,
    active: false,
    lang: 'Language agnostic',
    link: 'https://scan.coverity.com/projects',
    auth: [
      { label: 'token', value: 1 },
    ],
  }, {
    type: 'sast',
    name: 'parasoft',
    customType: 1100,
    imageIndex: 'parasoft',
    isDrawer: false,
    isDocker: false,
    active: false,
    lang: 'Java',
    link: '',
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'sast',
    name: 'fortify',
    customType: 1110,
    imageIndex: 'fortify',
    isDrawer: false,
    isDocker: false,
    active: false,
    lang: 'Language agnostic',
    link: 'https://www.microfocus.com/en-us/fortify-languages',
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'sast',
    name: 'fortifyod',
    customType: 1395,
    imageIndex: 'fortifyod',
    isDrawer: true,
    isDocker: false,
    active: false,
    lang: 'Language agnostic',
    link: 'https://www.microfocus.com/en-us/fortify-languages',
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'sast',
    name: 'veracode',
    customType: 1140,
    imageIndex: 'veracode',
    isDrawer: true,
    isDocker: false,
    lang: 'Language agnostic',
    link: 'https://help.veracode.com/r/r_supported_table',
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'sast',
    name: 'sonarqube',
    customType: 1135,
    imageIndex: 'sonarqube',
    isDrawer: true,
    isDocker: false,
    active: false,
    lang: 'Language agnostic',
    link: 'https://www.sonarqube.org/features/multi-languages/',
    auth: [
      { label: 'basic', value: 0 },
      { label: 'token', value: 1 },
    ],
  }, {
    type: 'sast',
    name: 'sonarcloud',
    customType: 1365,
    imageIndex: 'sonarcloud',
    isDrawer: true,
    isDocker: false,
    lang: '5+ Languages Supported',
    link: 'https://sonarcloud.io/code-security',
    active: false,
    auth: [
      { label: 'token', value: 1 },
    ],
  }, {
    type: 'sast',
    name: 'semgrep',
    customType: 1130,
    imageIndex: 'semgrep',
    isDrawer: false,
    isDocker: true,
    lang: '10+ Languages Supported',
    link: 'https://semgrep.dev/docs/status/',
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'sast',
    name: 'gosec',
    customType: 1120,
    imageIndex: 'gosec',
    isDrawer: false,
    isDocker: true,
    lang: 'Golang',
    link: 'https://github.com/securego/gosec',
    active: false,
  }, {
    type: 'sast',
    name: 'brakeman',
    customType: 1125,
    imageIndex: 'brakeman',
    isDrawer: false,
    isDocker: true,
    lang: 'Ruby on Rails',
    link: 'https://brakemanscanner.org/',
    active: false,
  }, {
    type: 'sast',
    name: 'bandit',
    customType: 1305,
    imageIndex: 'bandit',
    isDrawer: false,
    isDocker: true,
    lang: 'Python',
    link: 'https://pypi.org/project/bandit/',
    active: false,
  }, {
    type: 'sast',
    name: 'findsecbugs',
    customType: 1310,
    imageIndex: 'findsecbugs',
    isDrawer: false,
    isDocker: true,
    lang: 'Java',
    link: 'https://find-sec-bugs.github.io/',
    active: false,
  }, {
    type: 'sast',
    name: 'securitycodescan',
    customType: 1315,
    imageIndex: 'securitycodescan',
    isDrawer: false,
    isDocker: true,
    lang: '.NET Core',
    link: 'https://security-code-scan.github.io/',
    active: false,
  }, {
    type: 'sast',
    name: 'gitleaks',
    customType: 1330,
    imageIndex: 'gitleaks',
    isDrawer: false,
    isDocker: true,
    lang: 'Language agnostic',
    link: 'https://github.com/zricethezav/gitleaks',
    active: false,
  }, {
    type: 'sast',
    name: 'eslint',
    customType: 1340,
    imageIndex: 'eslint',
    isDrawer: false,
    isDocker: true,
    lang: 'Javascript, TypeScript',
    link: 'https://github.com/eslint/eslint',
    active: false,
  }, {
    type: 'sast',
    name: 'nodejsscan',
    customType: 1350,
    imageIndex: 'nodejsscan',
    isDrawer: false,
    isDocker: true,
    lang: 'Node.js',
    link: 'https://github.com/ajinabraham/nodejsscan',
    active: false,
  }, {
    type: 'sast',
    name: 'psalm',
    customType: 1355,
    imageIndex: 'psalm',
    isDrawer: false,
    isDocker: true,
    lang: 'PHP',
    link: 'https://github.com/vimeo/psalm',
    active: false,
  }, {
    type: 'sast',
    name: 'codeql',
    customType: 1360,
    imageIndex: 'github',
    isDrawer: false,
    isDocker: false,
    lang: 'Language agnostic',
    link: 'https://codeql.github.com/',
    active: false,
  }, {
    type: 'sast',
    name: 'mobsfsast',
    customType: 1385,
    imageIndex: 'mobsfsast',
    isDrawer: true,
    isDocker: false,
    lang: 'Language agnostic',
    link: 'https://github.com/MobSF/Mobile-Security-Framework-MobSF',
    active: false,
    auth: [
      { label: 'token', value: 1 },
    ],
  }, {
    type: 'sast',
    name: 'snyksast',
    customType: 1380,
    imageIndex: 'snyksast',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'token', value: 1 },
    ],
  }, {
    type: 'sast',
    name: 'gitguardian',
    customType: 1390,
    imageIndex: 'gitguardian',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'token', value: 1 },
    ],
  }, {
    type: 'sast',
    name: 'codethreat',
    customType: 1435,
    imageIndex: 'codethreat',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'token', value: 1 },
    ],
  }, {
    type: 'sast',
    name: 'gitsecret',
    customType: 1445,
    imageIndex: 'gitsecret',
    isDrawer: false,
    isDocker: false,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'sast',
    name: 'trufflehogsecurity',
    customType: 1450,
    imageIndex: 'trufflehogsecurity',
    isDrawer: false,
    isDocker: true,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'mast',
    name: 'eshard',
    customType: 1440,
    imageIndex: 'eshard',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'token', value: 1 },
    ],
  }, {
    type: 'dast',
    name: 'hclappscan',
    customType: 1115,
    imageIndex: 'hclappscan',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'dast',
    name: 'burpsuiteenterprise',
    customType: 1240,
    imageIndex: 'burpsuite',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'token', value: 1 },
    ],
  }, {
    type: 'dast',
    name: 'burpsuite',
    customType: 1245,
    imageIndex: 'burpsuite',
    isDrawer: false,
    isDocker: false,
    active: false,
  }, {
    type: 'dast',
    name: 'owaspzap',
    customType: 1205,
    imageIndex: 'owaspzap',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'token', value: 1 },
    ],
  }, {
    type: 'dast',
    name: 'owaspzapheadless',
    customType: 1235,
    imageIndex: 'owaspzapheadless',
    isDrawer: false,
    isDocker: true,
    active: false,
  }, {
    type: 'dast',
    name: 'nuclei',
    customType: 1250,
    imageIndex: 'nuclei',
    isDrawer: false,
    isDocker: true,
    active: false,
  }, {
    type: 'dast',
    name: 'webinspect',
    customType: 1210,
    imageIndex: 'fortifyWebInspect',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
      { label: 'noAuth', value: -1 },
    ],
  }, {
    type: 'dast',
    name: 'netsparker',
    customType: 1215,
    imageIndex: 'netsparker',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'dast',
    name: 'appspider',
    customType: 1220,
    imageIndex: 'appspider',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
      { label: 'token', value: 1 },
    ],
  }, {
    type: 'dast',
    name: 'acunetix',
    customType: 1225,
    imageIndex: 'acunetix',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'token', value: 1 },
    ],
  }, {
    type: 'dast',
    name: 'acunetix360',
    customType: 1270,
    imageIndex: 'acunetix360',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'dast',
    name: 'veracodedast',
    customType: 1255,
    imageIndex: 'veracodedast',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'dast',
    name: 'qualyswas',
    customType: 1260,
    imageIndex: 'qualyswas',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'dast',
    name: 'tenableiowas',
    customType: 1265,
    imageIndex: 'tenableiowas',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'dast',
    name: 'fortifyoddast',
    customType: 1275,
    imageIndex: 'fortifyoddast',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'dast',
    name: 'rapid7',
    customType: 1280,
    imageIndex: 'rapid7',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'token', value: 1 },
    ],
  }, {
    type: 'sca',
    name: 'dependencycheck',
    customType: 1320,
    imageIndex: 'dependencycheck',
    isDrawer: false,
    isDocker: true,
    lang: 'Supported Languages',
    link: 'https://jeremylong.github.io/DependencyCheck/analyzers/index.html',
    active: false,
  }, {
    type: 'sca',
    name: 'nancy',
    customType: 1325,
    imageIndex: 'nancy',
    isDrawer: false,
    isDocker: true,
    lang: 'Golang',
    link: 'https://github.com/sonatype-nexus-community/nancy',
    active: false,
  }, {
    type: 'sca',
    name: 'checkmarxsca',
    customType: 1500,
    imageIndex: 'checkmarxsca',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'sca',
    name: 'checkmarxscacloud',
    customType: 1540,
    imageIndex: 'checkmarxscacloud',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'sca',
    name: 'checkmarxastsca',
    customType: 1535,
    imageIndex: 'checkmarxastsca',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'token', value: 1 },
    ],
  }, {
    type: 'sca',
    name: 'snyksca',
    customType: 1505,
    imageIndex: 'snyk',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'token', value: 1 },
    ],
  }, {
    type: 'sca',
    name: 'whitesource',
    customType: 1510,
    imageIndex: 'whitesource',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'sca',
    name: 'dependabot',
    customType: 1515,
    imageIndex: 'dependabot',
    isDrawer: false,
    isDocker: false,
    active: false,
  }, {
    type: 'sca',
    name: 'sonatypenl',
    customType: 1520,
    imageIndex: 'sonatype',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'sca',
    name: 'blackduck',
    customType: 1530,
    imageIndex: 'blackduck',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'token', value: 1 },
    ],
  }, {
    type: 'sca',
    name: 'osvscannersca',
    customType: 1550,
    imageIndex: 'osvscannersca',
    isDrawer: false,
    isDocker: true,
    active: false,
    auth: [
      { label: 'token', value: 1 },
    ],
  }, {
    type: 'sca',
    name: 'veracodesca',
    customType: 9999,
    imageIndex: 'veracodesca',
    isDrawer: false,
    isDocker: false,
    active: false,
  }, {
    type: 'sca',
    name: 'sbomradar',
    customType: 1555,
    imageIndex: 'sbomradar',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'noAuth', value: -1 },
    ],
  }, {
    type: 'sca',
    name: 'jfrogxraysca',
    customType: 1565,
    imageIndex: 'jfrogxraysca',
    isDrawer: false,
    isDocker: false,
    active: false,
  }, {
    type: 'sca',
    name: 'contrastsca',
    customType: 1570,
    imageIndex: 'contrastsca',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'token', value: 1 },
    ],
  }, {
    type: 'cs',
    name: 'trivy',
    customType: 1400,
    imageIndex: 'trivy',
    isDrawer: false,
    isDocker: true,
    active: false,
  }, {
    type: 'cs',
    name: 'qualyscs',
    customType: 1405,
    imageIndex: 'qualyswas',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'cs',
    name: 'prismacloud',
    customType: 1410,
    imageIndex: 'prismacloud',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'cs',
    name: 'laceworkcs',
    customType: 1415,
    imageIndex: 'laceworkcs',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'cs',
    name: 'grype',
    customType: 1420,
    imageIndex: 'grype',
    isDrawer: false,
    isDocker: true,
    active: false,
  }, {
    type: 'cs',
    name: 'awsinspectorcs',
    customType: 1425,
    imageIndex: 'awsinspectorcs',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'cs',
    name: 'snykcs',
    customType: 1430,
    imageIndex: 'snykcs',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'token', value: 1 },
    ],
  }, {
    type: 'cs',
    name: 'redhatcs',
    customType: 1455,
    imageIndex: 'redhatcs',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
      { label: 'token', value: 1 },
    ],
  }, {
    type: 'iac',
    name: 'semgrepconfig',
    customType: 1145,
    imageIndex: 'semgrep',
    isDrawer: false,
    isDocker: true,
    active: false,
  }, {
    type: 'iac',
    name: 'checkmarxkics',
    customType: 1150,
    imageIndex: 'checkmarxkics',
    isDrawer: false,
    isDocker: true,
    active: false,
  }, {
    type: 'iac',
    name: 'checkmarxastkics',
    customType: 1160,
    imageIndex: 'checkmarxastkics',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'token', value: 1 },
    ],
  }, {
    type: 'iac',
    name: 'checkov',
    customType: 1155,
    imageIndex: 'checkov',
    isDrawer: false,
    isDocker: true,
    active: false,
  }, {
    type: 'iac',
    name: 'tfsec',
    customType: 1165,
    imageIndex: 'tfsec',
    isDrawer: false,
    isDocker: true,
    active: false,
  }, {
    type: 'iac',
    name: 'snykiac',
    customType: 1170,
    imageIndex: 'snykiac',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'token', value: 1 },
    ],
  }, {
    type: 'iac',
    name: 'trivyiac',
    customType: 1175,
    imageIndex: 'trivyiac',
    isDrawer: false,
    isDocker: true,
    active: false,
  }, {
    type: 'infra',
    name: 'tenableiovm',
    customType: 1610,
    imageIndex: 'tenablesc',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'infra',
    name: 'tenablesc',
    customType: 1600,
    imageIndex: 'tenablesc',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'infra',
    name: 'nessuspro',
    customType: 1605,
    imageIndex: 'nessuspro',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'infra',
    name: 'laceworkinfra',
    customType: 1615,
    imageIndex: 'laceworkinfra',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'infra',
    name: 'qualysinfra',
    customType: 1620,
    imageIndex: 'qualysinfra',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'infra',
    name: 'rapid7infra',
    customType: 1625,
    imageIndex: 'rapid7infra',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'bugbounty',
    name: 'hackerone',
    customType: 1700,
    imageIndex: 'hackerone',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'utoken', value: 2 },
    ],
  }, {
    type: 'iast',
    name: 'seeker',
    customType: 1800,
    imageIndex: 'seeker',
    isDrawer: true,
    isDocker: false,
    active: false,
    lang: 'Language agnostic',
    link: 'https://scan.coverity.com/projects',
    auth: [
      { label: 'token', value: 1 },
    ],
  }, {
    type: 'iast',
    name: 'contrastiast',
    customType: 1570,
    imageIndex: 'contrastiast',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'token', value: 1 },
    ],
  }, {
    type: 'cspm',
    name: 'laceworkcspm',
    customType: 1900,
    imageIndex: 'laceworkcspm',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'cspm',
    name: 'awsinspectorcspm',
    customType: 1905,
    imageIndex: 'awsinspectorcspm',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'cspm',
    name: 'awssecurityhub',
    customType: 1909,
    imageIndex: 'awssecurityhub',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'cspm',
    name: 'prismacloudcspm',
    customType: 1907,
    imageIndex: 'prismacloudcspm',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'cspm',
    name: 'msdefendercloud',
    customType: 1911,
    imageIndex: 'msdefendercloud',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'cspm',
    name: 'wizcspm',
    customType: 1913,
    imageIndex: 'wizcspm',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'cnapp',
    name: 'wiz',
    customType: 2000,
    imageIndex: 'wiz',
    isDrawer: true,
    isDocker: false,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  },
];

PROJECT.TI = [
  {
    type: 'threat_intelligence',
    name: 'mandiant',
    customType: 9000,
    imageIndex: 'mandiant',
    isDrawer: true,
    active: false,
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'threat_intelligence',
    name: 'epss',
    customType: 9010,
    imageIndex: 'epss',
    isDrawer: true,
    active: false,
  }, {
    type: 'threat_intelligence',
    name: 'customsource',
    customType: 9020,
    imageIndex: 'customsource',
    isDrawer: true,
    active: false,
  },
];

PROJECT.ISSUE_MANAGER = [
  {
    type: 'issue',
    name: 'jira',
    customType: 4100,
  }, {
    type: 'issue',
    name: 'fourme',
    customType: 5100,
  }, {
    type: 'issue',
    name: 'ivanti',
    customType: 4100,
  }, {
    type: 'issue',
    name: 'github',
    customType: 4200,
  }, {
    type: 'issue',
    name: 'gitlabcloud',
    customType: 4300,
  }, {
    type: 'issue',
    name: 'gitlabonprem',
    customType: 4900,
  }, /* {
    type: 'issue',
    name: 'bitbucket',
    customType: 4400,
  }, */{
    type: 'issue',
    name: 'azurecloud',
    customType: 4500,
  }, {
    type: 'issue',
    name: 'azureserver',
    customType: 4600,
  }, {
    type: 'issue',
    name: 'servicenow',
    customType: 4700,
  }, {
    type: 'issue',
    name: 'webhook',
    customType: 4800,
  },
];

PROJECT.ALERTS = [
  {
    type: 'notification',
    name: 'slack',
  }, {
    type: 'notification',
    name: 'mail',
    auth: [
      { label: 'noAuth', value: 0 },
      { label: 'plain', value: 1 },
      { label: 'login', value: 2 },
      { label: 'crammd5', value: 3 },
    ],
  }, {
    type: 'notification',
    name: 'teams',
  }, {
    type: 'notification',
    name: 'mattermost',
  }, {
    type: 'notification',
    name: 'microsoftazuremail',
  },
];

PROJECT.ALM = [
  {
    type: 'repo',
    name: 'github',
    customType: 5200,
    imageIndex: 'github',
    auth: [
      { label: 'token', value: 1 },
    ],
  }, {
    type: 'repo',
    name: 'githubenterprise',
    customType: 3700,
    imageIndex: 'githubenterprise',
  }, {
    type: 'repo',
    name: 'gitlabcloud',
    customType: 3400,
    imageIndex: 'gitlabcloud',
    auth: [
      { label: 'token', value: 1 },
    ],
  }, {
    type: 'repo',
    name: 'gitlabonprem',
    customType: 3800,
    imageIndex: 'gitlabonprem',
    auth: [
      { label: 'token', value: 1 },
    ],
  }, {
    type: 'repo',
    name: 'bitbucket',
    customType: 5400,
    imageIndex: 'bitbucket',
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'repo',
    name: 'bitbucketserver',
    customType: 5400,
    imageIndex: 'bitbucket',
    auth: [
      { label: 'basic', value: 0 },
    ],
  }, {
    type: 'repo',
    name: 'azurecloud',
    customType: 3200,
    imageIndex: 'azure',
    auth: [
      { label: 'token', value: 1 },
    ],
  }, {
    type: 'repo',
    name: 'azureserver',
    customType: 3100,
    imageIndex: 'azure',
    auth: [
      { label: 'token', value: 1 },
    ],
  },
];

PROJECT.AUTHMAN = [
  {
    type: 'auth',
    name: 'ldap',
  }, {
    type: 'auth',
    name: 'okta',
  }, {
    type: 'auth',
    name: 'azureactivedirectory',
    customType: 8020,
    imageIndex: 'azureactivedirectory',
    auth: [
      { label: 'token', value: 1 },
    ],
  }, {
    type: 'auth',
    name: 'googlesso',
    customType: 8030,
  }, {
    type: 'auth',
    name: 'saml',
    customType: 8040,
  },
];

PROJECT.TRAINING = [
  {
    type: 'training',
    name: 'codebashing',
    customType: 8000,
    imageIndex: 'codebashing',
    auth: [
      { label: 'utoken', value: 2 },
    ],
  }, {
    type: 'training',
    name: 'avatao',
    customType: 10000,
    imageIndex: 'avatao',
    auth: [
      { label: 'token', value: 1 },
    ],
  }, {
    type: 'training',
    name: 'securecodewarrior',
    customType: 12000,
    imageIndex: 'securecodewarrior',
    auth: [
      { label: 'noAuth', value: -1 },
    ],
  }, {
    type: 'training',
    name: 'secureflag',
    customType: 12000,
    imageIndex: 'secureflag',
    auth: [
      { label: 'noAuth', value: -1 },
    ],
  },
];

PROJECT.RECOMMENDATION = [
  {
    type: 'recommendation',
    name: 'openai',
    customType: 8000,
    imageIndex: 'openai',
    auth: [
      { label: 'token', value: 1 },
    ],
  },
];

PROJECT.OWASP_CATEGORIES = [
  {
    id: 'All',
    label: 'Any of the below',
    value: 1100,
  },
  {
    id: 'A1',
    label: 'A1 Injection',
    value: 1027,
  }, {
    id: 'A2',
    label: 'A2 Broken Authentication',
    value: 1028,
  }, {
    id: 'A3',
    label: 'A3 Sensitive Data_Exposure',
    value: 1029,
  }, {
    id: 'A4',
    label: 'A4 XML External Entities(XXE)',
    value: 1030,
  }, {
    id: 'A5',
    label: 'A5 Broken Access Control',
    value: 1031,
  }, {
    id: 'A6',
    label: 'A6 Security Misconfiguration',
    value: 1032,
  }, {
    id: 'A7',
    label: 'A7 Cross-Site Scripting(XSS)',
    value: 1033,
  }, {
    id: 'A8',
    label: 'A8 Insecure Deserialization',
    value: 1034,
  }, {
    id: 'A9',
    label: 'A9 Using Components WithKnown Vulnerabilities',
    value: 1035,
  }, {
    id: 'A10',
    label: 'A10 Insufficient Logging And Monitoring',
    value: 1036,
  },
];

PROJECT.OWASP_CATEGORIES_2021 = [
  {
    id: 'All',
    label: 'Any of the below',
    value: 1000,
  },
  {
    id: 'A1',
    label: 'A1 Broken Access Control',
    value: 1201,
  }, {
    id: 'A2',
    label: 'A2 Cryptographic Failures',
    value: 1202,
  }, {
    id: 'A3',
    label: 'A3 Injection',
    value: 1203,
  }, {
    id: 'A4',
    label: 'A4 Insecure Design',
    value: 1204,
  }, {
    id: 'A5',
    label: 'A5 Security Misconfiguration',
    value: 1205,
  }, {
    id: 'A6',
    label: 'A6 Vulnerable and Outdated Components',
    value: 1206,
  }, {
    id: 'A7',
    label: 'A7 Identification and Authentication Failures',
    value: 1207,
  }, {
    id: 'A8',
    label: 'A8 Software and Data Integrity Failures',
    value: 1208,
  }, {
    id: 'A9',
    label: 'A9 Security Logging and Monitoring Failures',
    value: 1209,
  }, {
    id: 'A10',
    label: 'A10 Server-Side Request Forgery',
    value: 1210,
  },
];

PROJECT.PCI = [
  {
    id: 'All',
    label: 'Any of the below',
    value: 1000,
  },
  {
    id: '6.2',
    label: '6.2 - Apply vendor supplied security patches for known vulnerabilities',
    value: 1005,
  },
  {
    id: '6.5.1',
    label: '6.5.1 - Injection flaws',
    value: 1010,
  },
  {
    id: '6.5.2',
    label: '6.5.2 - Buffer overflows',
    value: 1015,
  },
  {
    id: '6.5.3',
    label: '6.5.3 - Insecure cryptographic storage',
    value: 1020,
  },
  {
    id: '6.5.4',
    label: '6.5.4 - Insecure communications',
    value: 1025,
  },
  {
    id: '6.5.5',
    label: '6.5.5 - Improper error handling',
    value: 1030,
  },
  {
    id: '6.5.7',
    label: '6.5.7 - Cross-site scripting (XSS)',
    value: 1035,
  },
  {
    id: '6.5.8',
    label: '6.5.8 - Improper access control',
    value: 1040,
  },
  {
    id: '6.5.9',
    label: '6.5.9 - Cross-site request forgery (CSRF)',
    value: 1045,
  },
  {
    id: '6.5.10',
    label: '6.5.10 - Broken authentication and session management',
    value: 1050,
  },
];

PROJECT.STRIDE = [
  {
    label: 'S - Spoofing',
    value: 'S',
  }, {
    label: 'T - Tampering',
    value: 'T',
  }, {
    label: 'R - Repudiation',
    value: 'R',
  }, {
    label: 'I - Information Disclosure',
    value: 'I',
  }, {
    label: 'D - Denial of Access',
    value: 'D',
  }, {
    label: 'E - Elevation of Privilege',
    value: 'E',
  },
];

PROJECT.ADVANCED_CRITERIA = [
  {
    label: 'Branch',
    value: 'branch',
  }, {
    label: 'Issue Status',
    value: 'issue_statuses',
  }, {
    label: 'Vulnerability Status',
    value: 'vuln_statuses',
  },
];

PROJECT.VULNSEVERITY = [
  {
    label: 'Low',
    value: 1,
  }, {
    label: 'Medium',
    value: 2,
  }, {
    label: 'High',
    value: 3,
  }, {
    label: 'Critical',
    value: 4,
  },
];

PROJECT.ISSUE_CRITERIA_VULNSEVERITY = [
  {
    label: 'Critical',
    value: 'critical',
  }, {
    label: 'High',
    value: 'high',
  }, {
    label: 'Medium',
    value: 'medium',
  }, {
    label: 'Low',
    value: 'low',
  },
];

PROJECT.VULNSEVERITY2 = [
  {
    label: 'Critical',
    value: 'critical',
  }, {
    label: 'High',
    value: 'high',
  }, {
    label: 'Medium',
    value: 'medium',
  },
];

PROJECT.MACROTYPE = [
  {
    key: 'mdb',
    value: 'mdb',
  },
  {
    key: 'wsdl',
    value: 'wsdl',
  },
  {
    key: 'traffic',
    value: 'traffic',
  },
  {
    key: 'macro',
    value: 'macro',
  },
  {
    key: 'loginmacro',
    value: 'loginmacro',
  },
  {
    key: 'update',
    value: 'update',
  },
  {
    key: 'bootstrap',
    value: 'bootstrap',
  },
];

const CRITERIA = {};
CRITERIA.PERPAGE = 2;

const REPORTS = {};
REPORTS.PERPAGE = 5;

// -------------------------------------------------------------------------------------------------
// ## Product config.
// -------------------------------------------------------------------------------------------------

const PRODUCT = {};
PRODUCT.PERPAGE = 15;

// -------------------------------------------------------------------------------------------------
// ## Team config.
// -------------------------------------------------------------------------------------------------

const TEAM = {};
TEAM.PERPAGE = 15;

// -------------------------------------------------------------------------------------------------
// ## User config.
// -------------------------------------------------------------------------------------------------

const USER = {};
USER.PERPAGE = 15;

// -------------------------------------------------------------------------------------------------
// ## Sbom config.
// -------------------------------------------------------------------------------------------------

const SBOM = {};
SBOM.PERPAGE = 15;

// -------------------------------------------------------------------------------------------------
// ## Dashboard config.
// -------------------------------------------------------------------------------------------------

const DASHBOARD = {};
DASHBOARD.MAX_SEVERITY_CHART_VISIBLE_TEAMS = 10;
DASHBOARD.SEVERITY_TREND_CHART_START_FROM = -30; // Days (Last 30 days)

// -------------------------------------------------------------------------------------------------
// ## Colors.
// -------------------------------------------------------------------------------------------------

const COLOR = {};
COLOR.SUCCESS = '#52C41A';
COLOR.WARNING = '#FAAD14';
COLOR.DANGER = '#F5222D';
COLOR.INFO = '#4397D2';

COLOR.NEW = '#5caee8';
COLOR.NEW_WITH_ISSUE = '#1890ff';
COLOR.FP = '#D9D9D9';
COLOR.MITIGATED = '#BFBFBF';
COLOR.WONTFIX = '#A6A6A6';
COLOR.CLOSED = '#808080';
COLOR.GREEN = '#2dc25a';

COLOR.CRITICAL = '#FF4758';
COLOR.HIGH = '#FF7E48';
COLOR.MEDIUM = '#FFE247';
COLOR.LOW = '#47AEFF';
COLOR.DUMMY = '#F8FAFC';

// -------------------------------------------------------------------------------------------------
// ## Global options.
// -------------------------------------------------------------------------------------------------
const GLOBAL = {};

GLOBAL.SCANNER_TYPES = [
  {
    label: 'sast',
    value: 'sast',
  }, {
    label: 'mast',
    value: 'mast',
  }, {
    label: 'dast',
    value: 'dast',
  }, {
    label: 'iast',
    value: 'iast',
  }, {
    label: 'sca',
    value: 'sca',
  }, {
    label: 'cspm',
    value: 'cspm',
  }, {
    label: 'cs',
    value: 'cs',
  }, {
    label: 'iac',
    value: 'iac',
  }, {
    label: 'bugbounty',
    value: 'bugbounty',
  }, {
    label: 'pentest',
    value: 'pentest',
  },
];

GLOBAL.DEMOHUB_SCANNER_TYPES = [
  {
    label: 'sast',
    value: 'sast',
  }, {
    label: 'dast',
    value: 'dast',
  }, {
    label: 'iast',
    value: 'iast',
  }, {
    label: 'sca',
    value: 'sca',
  }, {
    label: 'cspm',
    value: 'cspm',
  }, {
    label: 'cs',
    value: 'cs',
  }, {
    label: 'iac',
    value: 'iac',
  },
];

GLOBAL.SCAN_TYPE = [
  {
    label: 'scheduled',
    value: 'scheduled',
  }, {
    label: 'rescan',
    value: 'rescan',
  }, {
    label: 'import',
    value: 'import',
  }, {
    label: 'validation',
    value: 'validation',
  }, {
    label: 'kdt',
    value: 'kdt',
  }, {
    label: 'pr',
    value: 'pr',
  }, {
    label: 'webhook',
    value: 'webhook',
  },
];

GLOBAL.BUSINESS_CRITICALITY = [
  {
    label: 'all',
    value: 'all',
  }, {
    label: 'none',
    value: 0,
  }, {
    label: 'low',
    value: 1,
  }, {
    label: 'medium',
    value: 2,
  }, {
    label: 'high',
    value: 3,
  }, {
    label: 'critical',
    value: 4,
  },
];

GLOBAL.SCAN_STATUSES = [
  {
    label: 'Waiting',
    value: 'waiting',
  }, {
    label: 'Starting',
    value: 'starting',
  }, {
    label: 'Running',
    value: 'running',
  }, {
    label: 'Retrieving Results',
    value: 'retrieving-results',
  }, {
    label: 'Analyzing',
    value: 'analyzing',
  }, {
    label: 'Notifying',
    value: 'notifying',
  }, {
    label: 'Finished',
    value: 'finished',
  },
];

GLOBAL.SEVERITY_TYPES = [
  {
    label: 'critical',
    value: 4,
  }, {
    label: 'high',
    value: 3,
  }, {
    label: 'medium',
    value: 2,
  }, {
    label: 'low',
    value: 1,
  },
];

GLOBAL.LICENSE_RISK = [
  {
    label: 'critical',
    value: 4,
  }, {
    label: 'high',
    value: 3,
  }, {
    label: 'medium',
    value: 2,
  }, {
    label: 'low',
    value: 1,
  }, {
    label: 'licenseRiskunknown',
    value: 0,
  },
];

GLOBAL.VULN_STATUSES = [
  {
    label: 'New',
    value: 'new',
  }, {
    label: 'Closed',
    value: 'closed',
  }, {
    label: 'Recurrent',
    value: 'recurrent',
  },
];

GLOBAL.FALSE_POSITIVE = [
  {
    label: 'yes',
    value: 'yes',
  }, {
    label: 'no',
    value: 'no',
  },
];

GLOBAL.ISSUE_STATUSES = [
  {
    label: 'Unassigned',
    value: 'unassigned',
  }, {
    label: 'Pending',
    value: 'pending',
  }, {
    label: 'In Progress',
    value: 'inprogress',
  }, {
    label: 'Open',
    value: 'open',
  }, {
    label: 'Closed',
    value: 'closed',
  },
];

GLOBAL.NOTIFY_RULES = [
  {
    label: 'branch',
    value: 'branches',
  }, {
    label: 'issueStatus',
    value: 'issue_statuses',
  }, {
    label: 'lastScan',
    value: 'scan_frequency',
  }, {
    label: 'overdue',
    value: 'overdue',
  }, {
    label: 'owasp2017Top10',
    value: 'owasp_2017',
  }, {
    label: 'owasp2021Top10',
    value: 'owasp_2021',
  }, {
    label: 'pci',
    value: 'pci',
  }, {
    label: 'scanDuration',
    value: 'scan_duration',
  }, {
    label: 'scanRiskScore',
    value: 'risk_score',
  }, {
    label: 'severity',
    value: 'severities',
  }, {
    label: 'status',
    value: 'vuln_statuses',
  }, {
    label: 'vulnerabilityName',
    value: 'vulnerability_name',
  }, {
    label: 'woeInDays',
    value: 'woe',
  },
];

GLOBAL.NIST = [
  {
    id: '1000',
    label: 'Any of the below',
    value: 'All',
  },
  {
    id: 'AC-3',
    value: 'AC-3',
    label: 'AC-3 Access Enforcement',
  },
  {
    id: 'AC-6',
    value: 'AC-6',
    label: 'AC-6 Least Privilege: Privilege Levels for Code Execution',
  },
  {
    id: 'AC-7',
    value: 'AC-7',
    label: 'AC-7 Unsuccessful Logon Attempts',
  },
  {
    id: 'IA-5',
    value: 'IA-5',
    label: 'IA-5 Authenticator Management',
  },
  {
    id: 'IA-8',
    value: 'IA-8',
    label: 'IA-8 Identification and Authentication (Non-Organizational Users)',
  },
  {
    id: 'SA-11',
    value: 'SA-11',
    label: 'SA-11 Transmission Confidentiality and Integrity',
  },
  {
    id: 'SC-4',
    value: 'SC-4',
    label: 'SC-4 Information in Shared Resources',
  },
  {
    id: 'SC-8',
    value: 'SC-8',
    label: 'SC-8 Transmission Confidentiality and Integrity',
  },
  {
    id: 'SC-12',
    value: 'SC-12',
    label: 'SC-12 Cryptographic Key Establishment and Management',
  },
  {
    id: 'SC-13',
    value: 'SC-13',
    label: 'SC-13 Cryptographic Protection',
  },
  {
    id: 'SC-23',
    value: 'SC-23',
    label: 'SC-23 Session Authenticity',
  },
  {
    id: 'SC-28',
    value: 'SC-28',
    label: 'SC-28 Protection of Information at Rest',
  },
  {
    id: 'SI-10',
    value: 'SI-10',
    label: 'SI-10 Information Input Validation',
  },
  {
    id: 'SI-11',
    value: 'SI-11',
    label: 'SI-11 Error Handling',
  },
  {
    id: 'SI-16',
    value: 'SI-16',
    label: 'SI-16 Memory Protection',
  },
  {
    id: 'SR-6',
    value: 'SR-6',
    label: 'SR-6 Supplier Assessments and Reviews',
  },
  {
    id: '0',
    value: 'Unknown',
    label: 'Unknown Category',
  },
];

GLOBAL.SECURITY_CRITERIA = [
  {
    label: 'overdue',
    value: 'overdue',
  }, {
    label: 'owasp2017Top10',
    value: 'owasp_2017',
  }, {
    label: 'owasp2021Top10',
    value: 'owasp_2021',
  }, {
    label: 'pci',
    value: 'pci',
  }, {
    label: 'severity',
    value: 'severities',
  }, {
    label: 'stride',
    value: 'stride',
  }, {
    label: 'vulnerabilityName',
    value: 'vulnerability_name',
  },
];

GLOBAL.ISSUE_CRITERIA = [
  {
    label: 'owasp2017Top10',
    value: 'owasp_2017',
  }, {
    label: 'owasp2021Top10',
    value: 'owasp_2021',
  }, {
    label: 'pci',
    value: 'pci',
  }, {
    label: 'stride',
    value: 'stride',
  }, {
    label: 'cweId',
    value: 'cwe',
  }, {
    label: 'severity',
    value: 'severity',
  }, {
    label: 'vulnerabilityName',
    value: 'vulnerability_name',
  },
];

GLOBAL.OWASP_CATEGORIES_2017 = [
  {
    id: 1100,
    label: 'Any of the below',
    value: 'All',
  },
  {
    id: 1027,
    label: 'A1 Injection',
    value: 'A1',
  }, {
    id: 1028,
    label: 'A2 Broken Authentication',
    value: 'A2',
  }, {
    id: 1029,
    label: 'A3 Sensitive Data_Exposure',
    value: 'A3',
  }, {
    id: 1030,
    label: 'A4 XML External Entities(XXE)',
    value: 'A4',
  }, {
    id: 1031,
    label: 'A5 Broken Access Control',
    value: 'A5',
  }, {
    id: 1032,
    label: 'A6 Security Misconfiguration',
    value: 'A6',
  }, {
    id: 1033,
    label: 'A7 Cross-Site Scripting(XSS)',
    value: 'A7',
  }, {
    id: 1034,
    label: 'A8 Insecure Deserialization',
    value: 'A8',
  }, {
    id: 1035,
    label: 'A9 Using Components WithKnown Vulnerabilities',
    value: 'A9',
  }, {
    id: 1036,
    label: 'A10 Insufficient Logging And Monitoring',
    value: 'A10',
  },
];

GLOBAL.OWASP_CATEGORIES_2021 = [
  {
    id: 1000,
    label: 'Any of the below',
    value: 'All',
  },
  {
    id: 1201,
    label: 'A1 Broken Access Control',
    value: 'A1',
  }, {
    id: 1202,
    label: 'A2 Cryptographic Failures',
    value: 'A2',
  }, {
    id: 1203,
    label: 'A3 Injection',
    value: 'A3',
  }, {
    id: 1204,
    label: 'A4 Insecure Design',
    value: 'A4',
  }, {
    id: 1205,
    label: 'A5 Security Misconfiguration',
    value: 'A5',
  }, {
    id: 1206,
    label: 'A6 Vulnerable and Outdated Components',
    value: 'A6',
  }, {
    id: 1207,
    label: 'A7 Identification and Authentication Failures',
    value: 'A7',
  }, {
    id: 1208,
    label: 'A8 Software and Data Integrity Failures',
    value: 'A8',
  }, {
    id: 1209,
    label: 'A9 Security Logging and Monitoring Failures',
    value: 'A9',
  }, {
    id: 1210,
    label: 'A10 Server-Side Request Forgery',
    value: 'A10',
  },
];

GLOBAL.PCI = [
  {
    id: 1000,
    label: 'Any of the below',
    value: 'all',
  }, {
    id: 1005,
    label: '6.2 - Apply vendor supplied security patches for known vulnerabilities',
    value: '6.2',
  }, {
    id: 1010,
    label: '6.5.1 - Injection flaws',
    value: '6.5.1',
  }, {
    id: 1015,
    label: '6.5.2 - Buffer overflows',
    value: '6.5.2',
  }, {
    id: 1020,
    label: '6.5.3 - Insecure cryptographic storage',
    value: '6.5.3',
  }, {
    id: 1025,
    label: '6.5.4 - Insecure communications',
    value: '6.5.4',
  }, {
    id: 1030,
    label: '6.5.5 - Improper error handling',
    value: '6.5.5',
  }, {
    id: 1035,
    label: '6.5.7 - Cross-site scripting (XSS)',
    value: '6.5.7',
  }, {
    id: 1040,
    label: '6.5.8 - Improper access control',
    value: '6.5.8',
  }, {
    id: 1045,
    label: '6.5.9 - Cross-site request forgery (CSRF)',
    value: '6.5.9',
  }, {
    id: 1050,
    label: '6.5.10 - Broken authentication and session management',
    value: '6.5.10',
  },
];

GLOBAL.STRIDE = [
  {
    label: 'S - Spoofing',
    value: 'S',
  }, {
    label: 'T - Tampering',
    value: 'T',
  }, {
    label: 'R - Repudiation',
    value: 'R',
  }, {
    label: 'I - Information Disclosure',
    value: 'I',
  }, {
    label: 'D - Denial of Access',
    value: 'D',
  }, {
    label: 'E - Elevation of Privilege',
    value: 'E',
  },
];

GLOBAL.RISK_ACCEPTED = [
  {
    label: 'Won\'t Fix',
    value: 'wontfix',
  }, {
    label: 'Mitigated',
    value: 'mitigated',
  },
];

GLOBAL.RISK_RATING = [
  {
    label: '0',
    value: '0',
  }, {
    label: '1',
    value: '1',
  }, {
    label: '2',
    value: '2',
  }, {
    label: '3',
    value: '3',
  }, {
    label: '4',
    value: '4',
  },
];

GLOBAL.MICROSOFT_CLOUD_FILTER_DAYS = [
  {
    label: 'Last Day',
    value: 1,
  }, {
    label: 'Last 3 Days',
    value: 3,
  }, {
    label: 'Last 7 Days',
    value: 7,
  }, {
    label: 'Last Month',
    value: 30,
  }, {
    label: 'Last 3 Month',
    value: 90,
  },
];

GLOBAL.MICROSOFT_CLOUD_FILTER_MITRE_ATTACK = [
  {
    label: 'Reconnaissance',
    value: 'reconnaissance',
  }, {
    label: 'Resource Development',
    value: 'resourceDevelopment',
  }, {
    label: 'Initial Access',
    value: 'initialAccess',
  }, {
    label: 'Execution',
    value: 'execution',
  }, {
    label: 'Persistence',
    value: 'persistence',
  }, {
    label: 'Privilege Escalation',
    value: 'privilegeEscalation',
  }, {
    label: 'Defense Evasion',
    value: 'defenseEvasion',
  }, {
    label: 'Credential Access',
    value: 'credentialAccess',
  }, {
    label: 'Discovery',
    value: 'discovery',
  }, {
    label: 'Lateral Movement',
    value: 'lateralMovement',
  }, {
    label: 'Collection',
    value: 'collection',
  }, {
    label: 'Command and Control',
    value: 'commandAndControl',
  }, {
    label: 'Exfiltration',
    value: 'exfiltration',
  }, {
    label: 'Impact',
    value: 'impact',
  },
];

GLOBAL.MICROSOFT_CLOUD_FILTER_STATUS = [
  {
    label: 'Active',
    value: 'active',
  }, {
    label: 'Dismissed',
    value: 'dismissed',
  }, {
    label: 'In progress',
    value: 'inprogress',
  }, {
    label: 'Resolved',
    value: 'resolved',
  },
];

GLOBAL.ASSET_TYPES = [
  {
    label: 'Viritual Machine',
    value: 'VIRTUAL_MACHINE',
  }, {
    label: 'Container Image',
    value: 'CONTAINER_IMAGE',
  }, {
    label: 'Container',
    value: 'CONTAINER',
  }, {
    label: 'Serverles',
    value: 'SERVERLESS',
  },
];

GLOBAL.DASHBOARD_CHARTS = {
  'dashboard-appsec': {
    list1: [
      'ProjectMetrics',
      'SecurityKPITrendChart',
      'SummaryTable',
      'BubbleChart',
      'BurndownChart',
      'SeverityTrendChart',
      'OwaspTopTen',
    ],
    list2: [
      'KeyMetrics',
      'RiskScoreSnapshotChart',
      'WOE',
      'SeverityTrendByTeamChart',
      'IssueTrendChart',
      'RiskScoreTrendChart',
      'SuppressedVulns',
    ],
  },
  'dashboard-infra': {
    list1: [
      'ProjectMetrics',
      'SecurityKPITrendChart',
      'SummaryTable',
      'BubbleChart',
      'BurndownChart',
      'SeverityTrendChart',
      'SuppressedVulns',
    ],
    list2: [
      'KeyMetrics',
      'RiskScoreSnapshotChart',
      'WOE',
      // 'SeverityTrendByTeamChart',
      'IssueTrendChart',
      'RiskScoreTrendChart',
    ],
  },
  'dashboard-management': {
    list1: [
      'ProjectMetrics',
      'MaintainabilityMetrics',
      'ActionItemsTable',
    ],
    list2: [
      'KeyMetrics',
      'SecurityKPITrendChart',
    ],
  },
  'project-appsec-details': {
    list1: [
      'ProjectSecurityKPITrendChart',
      'ProjectSummaryTable',
      'ProjectBranchComparison',
      'ProjectBurndownChart',
      'ProjectSeverityTrend',
      'ProjectRadar',
      'ProjectSuppressedVulns',
      'ProjectEnvironmentBreakdown',
    ],
    list2: [
      'ProjectWOE',
      'ProjectOwaspTopTen',
      'ProjectToolsComparison',
      'ProjectIssueTrendChart',
      'ProjectRiskScoreTrend',
      'ProjectBubbleChart',
      'ProjectNewClosedTrend',
    ],
  },
  'project-infra-details': {
    list1: [
      'ProjectSecurityKPITrendChart',
      'ProjectSummaryTable',
      'ProjectBurndownChart',
      'ProjectSeverityTrend',
      'ProjectBubbleChart',
    ],
    list2: [
      'ProjectWOE',
      'ProjectToolsComparison',
      'ProjectIssueTrendChart',
      'ProjectRiskScoreTrend',
      'ProjectSuppressedVulns',
    ],
  },
  'product-appsec-details': {
    list1: [
      'ProductSummary',
      'ProductSecurityKPITrendChart',
      'ProductSummaryTable',
      'ProductBubbleChart',
      'ProductBurndownChart',
      'ProductSeverityTrend',
      'ProductSeverityTrendByTeamChart',
      'ProductNewClosedTrend',
    ],
    list2: [
      'ProductKeyMetrics',
      'ProductRiskScoreSnapshotChart',
      'ProductWOE',
      'ProductOwaspTopTen',
      'ProductIssueTrendChart',
      'ProductRiskScoreTrend',
      'ProductSuppressedVulns',
      'ProductEnvironmentBreakdown',
    ],
  },
  'product-infra-details': {
    list1: [
      'ProductSummary',
      'ProductSecurityKPITrendChart',
      'ProductSummaryTable',
      'ProductBurndownChart',
      'ProductSeverityTrend',
      // 'ProductSeverityTrendByTeamChart',
      'ProductSuppressedVulns',
    ],
    list2: [
      'ProductKeyMetrics',
      'ProductRiskScoreSnapshotChart',
      'ProductBubbleChart',
      'ProductIssueTrendChart',
      'ProductRiskScoreTrend',
      'ProductWOE',
    ],
  },
  'product-management-details': {
    list1: [
      'ProductSummary',
      'MaintainabilityMetrics',
    ],
    list2: [
      'ProductKeyMetrics',
      'ProductSecurityKPITrendChart',
    ],
  },
};

GLOBAL.PERPAGE = ['15', '30', '50', '100'];

GLOBAL.DEFAULT_COLUMNS = [
  'issueStatus',
  'id',
  'name',
  'project',
  'infraGroupNames',
  'branch',
  'status',
  'scanner',
  'severity',
  'cvss',
  'labels',
  'firstSeen',
];

GLOBAL.AVAILABLE_COLUMNS = [
  'branch',
  'committer',
  'cvss',
  'cweId',
  'cweName',
  'defaultBranch',
  'dockerImage',
  'dueDate',
  'environment',
  'epss',
  'falsePositive',
  'firstSeen',
  'fpMarkDate',
  'fqdn',
  'id',
  'infraGroupNames',
  'ip',
  'issueClosedDate',
  'issueCreatedDate',
  'issueLink',
  'issueStatus',
  'issueWIPDate',
  'knownExploit',
  'labels',
  'lastSeen',
  'manual',
  'metaData',
  'mitigated',
  'mscore',
  'name',
  'observedInTheWild',
  'overdue',
  'owasp2017Top10',
  'owasp2021Top10',
  'path',
  'pci',
  'project',
  'raMarkDate',
  'scanner',
  'scannerType',
  'severity',
  'status',
  'team',
  'truePositive',
  'woe',
  'wontfix',
];

GLOBAL.DEFAULT_CLOUD_CONTAINER_COLUMNS = [
  'name',
  'assetName',
  'platform',
  'projects',
  'currentVersion',
  'cveId',
  'imageName',
  'region',
  'severity',
];

GLOBAL.DEFAULT_CLOUD_CONTAINER_AVAILABLE_COLUMNS = [
  'name',
  'assetName',
  'platform',
  'projects',
  'currentVersion',
  'cveId',
  'fixedVersion',
  'imageId',
  'imageName',
  'region',
  'remediation',
  'severity',
];

GLOBAL.DEFAULT_CLOUD_VM_COLUMNS = [
  'name',
  'assetName',
  'projects',
  'os',
  'ipAddresses',
  'openToInternet',
  'platform',
  'region',
  'severity',
  'status',
];

GLOBAL.DEFAULT_CLOUD_VM_AVAILABLE_COLUMNS = [
  'name',
  'assetName',
  'projects',
  'os',
  'ipAddresses',
  'openToInternet',
  'platform',
  'region',
  'severity',
  'status',
];

const FILTER = {};
FILTER.OPERANDS = [
  {
    label: 'is',
    value: 'eq',
  }, {
    label: 'is not',
    value: 'ne',
  }, {
    label: 'is blank',
    value: 'bl',
  }, {
    label: 'is not blank',
    value: 'nbl',
  }, {
    label: '>',
    value: 'gt',
  }, {
    label: '>=',
    value: 'gte',
  }, {
    label: 'is older than',
    value: 'old',
  }, {
    label: '<',
    value: 'lt',
  }, {
    label: '<=',
    value: 'lte',
  }, {
    label: 'is one of',
    value: 'in',
  }, {
    label: 'is none of',
    value: 'nin',
  }, {
    label: 'contains',
    value: 'rgx',
  }, {
    label: 'doesn\'t contain',
    value: 'nrgx',
  },
];

GLOBAL.SBOM_SOURCE = [
  {
    label: 'Source Code',
    value: 'source_dir',
  }, {
    label: 'Docker Image',
    value: 'image',
  }, {
    label: 'Import',
    value: 'import',
  },
];

GLOBAL.REQUEST_TYPES = [
  {
    label: 'Mark',
    value: 'mark',
  }, {
    label: 'Unmark',
    value: 'unmark',
  },
];

FILTER.EQDEFAULT = [
  'branch', 'committer', 'cvssVector', 'cweId', 'cveId', 'cweName', 'cisaKevExploit', 'discoveredBy', 'closed',
  'defaultBranch', 'dnsName', 'dockerImage', 'fqdn', 'falsePositive', 'filePath', 'fixable', 'knownExploit', 'language',
  'license', 'licenses', 'manual', 'mitigated', 'netBiosName', 'ip', 'importedBy',
  'overdue', 'os', 'observedInTheWild', 'pluginName', 'riskRating', 'securityCriteria', 'service',
  'sbomSource', 'tags', 'vulnerabilityName', 'vulnerable', 'woe', 'wontfix', 'exploit', 'eventCategory', 'eventName',
  'count', 'openToInternet', 'ipAddresses', 'path', 'requestType', 'requestType', 'id', 'forked', 'rules',
  'tagName', 'truePositive', 'onboarded', 'obsolete', 'engagement', 'historicalStatus',
];

FILTER.GTDEFAULT = [
  'lastStatusUpdate', 'firstSeenFrom', 'fpMarkDate', 'cvss', 'issueCreatedDate', 'issueClosedDate',
  'issueWIPDate', 'lastSeen', 'raMarkDate', 'approvalDate', 'expirationDate', 'requestDate', 'dueDate',
];

FILTER.LTDEFAULT = [
  'firstSeenTo',
];

FILTER.INDEFAULT = [
  'businessCriticality', 'family', 'issueAssignee', 'issueId', 'issueStatus', 'infraGroupNames',
  'label', 'nist', 'owasp2017Top10', 'owasp2021Top10', 'pci', 'port', 'protocol', 'pluginId',
  'product', 'project', 'riskAccepted', 'scanStatus', 'scanner', 'scannerType', 'scanType', 'severity',
  'status', 'team', 'ipCidr', 'platform', 'resourceType', 'region', 'projects', 'type', 'requester', 'approver',
  'rejectedBy', 'alm', 'issueManager', 'notification', 'tagId', 'id', 'environment', 'licenseRisk',
];

FILTER.RGXDEFAULT = [
  'name', 'issueUrl', 'textSearch', 'packageUrl', 'username', 'imageName', 'metaData', 'bindTo', 'toolName',
  'profileName', 'nameSpace', 'fixedVersion',
];

FILTER.GTEDEFAULT = [
  'startDate', 'epssPercentile', 'epssPercentage',
];

FILTER.LTEDEFAULT = [
  'endDate',
];

FILTER.OLDDEFAULT = [
  'lastScan',
];

GLOBAL.RULES = [
  {
    key: 'branch',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'committer',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'cvss',
    operands: FILTER.OPERANDS.filter((s) => ['lt', 'gt'].find((a) => a === s.value)),
  }, {
    key: 'cvssVector',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    key: 'cweId',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is not',
      value: 'ne',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }],
  }, {
    key: 'cweName',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'defaultBranch',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    key: 'dockerImage',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    key: 'filePath',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'firstSeenFrom',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'firstSeenTo',
    operands: FILTER.OPERANDS.filter((s) => ['lt'].find((a) => a === s.value)),
  }, {
    key: 'fixable',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'issueAssignee',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'issueCreatedDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'issueClosedDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'issueWIPDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'issueId',
    operands: FILTER.OPERANDS.filter((s) => ['in'].find((a) => a === s.value)),
  }, {
    key: 'issueUrl',
    operands: FILTER.OPERANDS.filter((s) => ['rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'issueStatus',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'lastSeen',
    operands: FILTER.OPERANDS.filter((s) => ['gt', 'lt'].find((a) => a === s.value)),
  }, {
    key: 'lastStatusUpdate',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'metaData',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'manual',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'nist',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'overdue',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'owasp2017Top10',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'owasp2021Top10',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'pci',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'pluginId',
    operands: FILTER.OPERANDS.filter((s) => ['in'].find((a) => a === s.value)),
  }, {
    key: 'scanner',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'scannerType',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'severity',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'status',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'tags',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is one of',
      value: 'in',
    }],
  }, {
    key: 'textSearch',
    operands: FILTER.OPERANDS.filter((s) => ['rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'vulnerabilityName',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'woe',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'gt', 'gte', 'lt', 'lte']
      .find((a) => a === s.value)),
  },
];

GLOBAL.CLOUD_FILTER_OPTIONS = [
  {
    key: 'assetType',
    operands: FILTER.OPERANDS.filter((s) => ['in'].find((a) => a === s.value)),
  }, {
    key: 'image',
    operands: FILTER.OPERANDS.filter((s) => ['in'].find((a) => a === s.value)),
  }, {
    key: 'region',
    operands: FILTER.OPERANDS.filter((s) => ['in'].find((a) => a === s.value)),
  }, {
    key: 'platform',
    operands: FILTER.OPERANDS.filter((s) => ['in'].find((a) => a === s.value)),
  }, {
    key: 'os',
    operands: FILTER.OPERANDS.filter((s) => ['in'].find((a) => a === s.value)),
  }, {
    key: 'openToInternet',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    key: 'ipAddress',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    key: 'severity',
    operands: FILTER.OPERANDS.filter((s) => ['in'].find((a) => a === s.value)),
  },
];

GLOBAL.MICROSOFT_CLOUD_FILTER_OPTIONS = [
  {
    key: 'affectedResource',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    key: 'days',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    key: 'ipAddress',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    key: 'mitreAttackTactics',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    key: 'productComponentName',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    key: 'resourceType',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    key: 'severity',
    operands: FILTER.OPERANDS.filter((s) => ['in'].find((a) => a === s.value)),
  }, {
    key: 'status',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  },
];

GLOBAL.INFRA_RULES = [
  {
    key: 'dnsName',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is not',
      value: 'ne',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }, {
      label: 'contains',
      value: 'rgx',
    }],
  }, {
    key: 'family',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is not',
      value: 'ne',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }, {
      label: 'contains',
      value: 'rgx',
    }],
  }, {
    key: 'fqdn',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is not',
      value: 'ne',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }, {
      label: 'contains',
      value: 'rgx',
    }],
  }, {
    key: 'ipCidr',
    operands: [{
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }],
  }, {
    key: 'metaData',
    operands: [{
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }, {
      label: 'contains',
      value: 'rgx',
    }],
  }, {
    key: 'netBiosName',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is not',
      value: 'ne',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }, {
      label: 'contains',
      value: 'rgx',
    }],
  }, {
    key: 'os',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is not',
      value: 'ne',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }, {
      label: 'contains',
      value: 'rgx',
    }],
  }, {
    key: 'pluginName',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is not',
      value: 'ne',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }, {
      label: 'contains',
      value: 'rgx',
    }],
  }, {
    key: 'port',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is not',
      value: 'ne',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }],
  }, {
    key: 'protocol',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is not',
      value: 'ne',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }],
  }, {
    key: 'service',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is not',
      value: 'ne',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }],
  }, {
    key: 'vulnerabilityName',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx'].find((a) => a === s.value)),
  },
];

GLOBAL.AUTOMATION_RULES = [
  {
    type: ['vuln', 'sbom'],
    key: 'branch',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'committer',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin', 'rgx'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'cvss',
    operands: FILTER.OPERANDS.filter((s) => ['lt', 'gt'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'cvssVector',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'cweId',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is not',
      value: 'ne',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }],
  }, {
    type: ['vuln'],
    key: 'cweName',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'defaultBranch',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'dnsName',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'dockerImage',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'epssPercentage',
    operands: FILTER.OPERANDS.filter((s) => ['gte'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'epssPercentile',
    operands: FILTER.OPERANDS.filter((s) => ['gte'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'falsePositive',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'filePath',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'firstSeenFrom',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'firstSeenTo',
    operands: FILTER.OPERANDS.filter((s) => ['lt'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'fixable',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'historicalStatus',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    type: ['scan'],
    key: 'lastScan',
    operands: FILTER.OPERANDS.filter((s) => ['old'].find((a) => a === s.value)),
  }, {
    type: ['sbom'],
    key: 'license',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'issueAssignee',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'issueId',
    operands: FILTER.OPERANDS.filter((s) => ['in'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'issueUrl',
    operands: FILTER.OPERANDS.filter((s) => ['rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'issueClosedDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt', 'lt'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'issueCreatedDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt', 'lt'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'issueStatus',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'issueWIPDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt', 'lt'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'knownExploit',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'lastSeen',
    operands: FILTER.OPERANDS.filter((s) => ['gt', 'lt'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'manual',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'metaData',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'bl', 'nbl', 'in', 'rgx'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'mitigated',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    type: ['vuln', 'sbom'],
    key: 'name',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'nameSpace',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'in', 'nin', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'netBiosName',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'nist',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'observedInTheWild',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'os',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'overdue',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'owasp2017Top10',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'owasp2021Top10',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    type: ['sbom'],
    key: 'packageUrl',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'pci',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'pluginId',
    operands: FILTER.OPERANDS.filter((s) => ['in'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'riskAccepted',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'riskRating',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    type: ['vuln', 'scan'],
    key: 'scanner',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'scannerType',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    type: ['vuln', 'sbom'],
    key: 'severity',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    type: ['sbom'],
    key: 'sbomSource',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'status',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    type: ['sbom'],
    key: 'vulnerable',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'tags',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is one of',
      value: 'in',
    }],
  }, {
    type: ['vuln'],
    key: 'textSearch',
    operands: FILTER.OPERANDS.filter((s) => ['rgx'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'truePositive',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'woe',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'gt', 'gte', 'lt', 'lte']
      .find((a) => a === s.value)),
  }, {
    type: ['vuln'],
    key: 'wontfix',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  },
];

FILTER.APPSEC_VULNS_OPTIONS = [
  {
    key: 'branch',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'businessCriticality',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'committer',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'cvss',
    operands: FILTER.OPERANDS.filter((s) => ['lt', 'gt'].find((a) => a === s.value)),
  }, {
    key: 'cvssVector',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    key: 'cveId',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is not',
      value: 'ne',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }],
  }, {
    key: 'cweId',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is not',
      value: 'ne',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }],
  }, {
    key: 'cweName',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'defaultBranch',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    key: 'dockerImage',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'in', 'nin', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'dueDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt', 'lt'].find((a) => a === s.value)),
  }, {
    key: 'engagement',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'environment',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'epssPercentage',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'gte', 'lte'].find((a) => a === s.value)),
  }, {
    key: 'epssPercentile',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'gte', 'lte'].find((a) => a === s.value)),
  }, {
    key: 'falsePositive',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'filePath',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'firstSeenFrom',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'firstSeenTo',
    operands: FILTER.OPERANDS.filter((s) => ['lt'].find((a) => a === s.value)),
  }, {
    key: 'fixedVersion',
    operands: FILTER.OPERANDS.filter((s) => ['rgx'].find((a) => a === s.value)),
  }, {
    key: 'hasFix',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    key: 'hasInternetExposure',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'historicalStatus',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'fpMarkDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'id',
    operands: FILTER.OPERANDS.filter((s) => ['in'].find((a) => a === s.value)),
  }, {
    key: 'issueAssignee',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'issueCreatedDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'issueClosedDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'issueWIPDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'issueId',
    operands: FILTER.OPERANDS.filter((s) => ['in'].find((a) => a === s.value)),
  }, {
    key: 'issueUrl',
    operands: FILTER.OPERANDS.filter((s) => ['rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'issueStatus',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'knownExploit',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'label',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'lastSeen',
    operands: FILTER.OPERANDS.filter((s) => ['gt', 'lt'].find((a) => a === s.value)),
  }, {
    key: 'lastStatusUpdate',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'metaData',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'bl', 'nbl', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'manual',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'mitigated',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'nameSpace',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'in', 'nin', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'nist',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'observedInTheWild',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'overdue',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'owasp2017Top10',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'owasp2021Top10',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'platform',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'packageUrl',
    operands: FILTER.OPERANDS.filter((s) => ['rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'pci',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'pluginId',
    operands: FILTER.OPERANDS.filter((s) => ['in'].find((a) => a === s.value)),
  }, {
    key: 'product',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'project',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'region',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'riskAccepted',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'riskRating',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'raMarkDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'scanner',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'scannerType',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'severity',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'status',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'team',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'textSearch',
    operands: FILTER.OPERANDS.filter((s) => ['rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'truePositive',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'vulnerabilityName',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'woe',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'gt', 'gte', 'lt', 'lte']
      .find((a) => a === s.value)),
  }, {
    key: 'wontfix',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  },
];

FILTER.INFRA_VULNS_OPTIONS = [
  {
    key: 'cvss',
    operands: FILTER.OPERANDS.filter((s) => ['lt', 'gt'].find((a) => a === s.value)),
  }, {
    type: 'input',
    key: 'cvssVector',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    key: 'cveId',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is not',
      value: 'ne',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }],
  }, {
    type: 'input',
    key: 'cweId',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is not',
      value: 'ne',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }],
  }, {
    key: 'cweName',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'dnsName',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'dueDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt', 'lt'].find((a) => a === s.value)),
  }, {
    key: 'epssPercentage',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'gte', 'lte'].find((a) => a === s.value)),
  }, {
    key: 'epssPercentile',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'gte', 'lte'].find((a) => a === s.value)),
  }, {
    key: 'family',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is not',
      value: 'ne',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }, {
      label: 'contains',
      value: 'rgx',
    }, {
      label: 'doesn\'t contain',
      value: 'nrgx',
    }],
  }, {
    key: 'falsePositive',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'fpMarkDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'firstSeenFrom',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'firstSeenTo',
    operands: FILTER.OPERANDS.filter((s) => ['lt'].find((a) => a === s.value)),
  }, {
    key: 'fixable',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'fqdn',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is not',
      value: 'ne',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }, {
      label: 'contains',
      value: 'rgx',
    }, {
      label: 'doesn\'t contain',
      value: 'nrgx',
    }],
  }, {
    key: 'historicalStatus',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'id',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    key: 'issueAssignee',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'issueCreatedDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'issueClosedDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'issueWIPDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'issueId',
    operands: FILTER.OPERANDS.filter((s) => ['in'].find((a) => a === s.value)),
  }, {
    key: 'issueUrl',
    operands: FILTER.OPERANDS.filter((s) => ['rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'issueStatus',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'ip',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'infraGroupNames',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'knownExploit',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'lastSeen',
    operands: FILTER.OPERANDS.filter((s) => ['gt', 'lt'].find((a) => a === s.value)),
  }, {
    key: 'lastStatusUpdate',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'metaData',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'bl', 'nbl', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'manual',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'mitigated',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'netBiosName',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'observedInTheWild',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'overdue',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'os',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'pluginId',
    operands: FILTER.OPERANDS.filter((s) => ['in'].find((a) => a === s.value)),
  }, {
    key: 'pluginName',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is not',
      value: 'ne',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }, {
      label: 'contains',
      value: 'rgx',
    }, {
      label: 'doesn\'t contain',
      value: 'nrgx',
    }],
  }, {
    key: 'product',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'project',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'port',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is not',
      value: 'ne',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }],
  }, {
    key: 'protocol',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is not',
      value: 'ne',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }],
  }, {
    key: 'riskAccepted',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'riskRating',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'raMarkDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'scanner',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'severity',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'service',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is not',
      value: 'ne',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }],
  }, {
    key: 'status',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'team',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'textSearch',
    operands: FILTER.OPERANDS.filter((s) => ['rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'truePositive',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'vulnerabilityName',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'woe',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'gt', 'gte', 'lt', 'lte']
      .find((a) => a === s.value)),
  }, {
    key: 'wontfix',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  },
];

FILTER.SCAN_QUEUE_OPTIONS = [
  {
    key: 'branch',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'metaData',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'bl', 'nbl', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'project',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'profileName',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    key: 'scanner',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'scanStatus',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'team',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  },
];

FILTER.SCAN_COMPLETED_OPTIONS = [
  {
    key: 'branch',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'metaData',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'bl', 'nbl', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'project',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'profileName',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    key: 'scanner',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'scanType',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'team',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  },
];

FILTER.SCAN_FAILED_OPTIONS = [
  {
    key: 'branch',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'metaData',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'bl', 'nbl', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'project',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'profileName',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    key: 'scanner',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'team',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  },
];

FILTER.SCAN_IMPORTS_OPTIONS = [
  {
    key: 'branch',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'discoveredBy',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'engagement',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'metaData',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'bl', 'nbl', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'importedBy',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'project',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'profileName',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    key: 'scanner',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'team',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  },
];

FILTER.ENDPOINT_OPTIONS = [
  {
    key: 'endpoint',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'severity',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  },
];

FILTER.PROJECT_LIST_OPTIONS = [
  {
    key: 'businessCriticality',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'language',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'label',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'infraGroupNames',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'securityCriteria',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'product',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'project',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'team',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  },
];

FILTER.PROJECT_APPSEC_VULNS_OPTIONS = [
  {
    key: 'branch',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'committer',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'cvss',
    operands: FILTER.OPERANDS.filter((s) => ['lt', 'gt'].find((a) => a === s.value)),
  }, {
    type: 'input',
    key: 'cvssVector',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    type: 'input',
    key: 'cweId',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is not',
      value: 'ne',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }],
  }, {
    key: 'cweName',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'dockerImage',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'in', 'nin', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'dueDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt', 'lt'].find((a) => a === s.value)),
  }, {
    key: 'engagement',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'environment',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'epssPercentage',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'gte', 'lte'].find((a) => a === s.value)),
  }, {
    key: 'epssPercentile',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'gte', 'lte'].find((a) => a === s.value)),
  }, {
    key: 'falsePositive',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'fpMarkDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'filePath',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'firstSeenFrom',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'firstSeenTo',
    operands: FILTER.OPERANDS.filter((s) => ['lt'].find((a) => a === s.value)),
  }, {
    key: 'fixedVersion',
    operands: FILTER.OPERANDS.filter((s) => ['rgx'].find((a) => a === s.value)),
  }, {
    key: 'hasFix',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    key: 'hasInternetExposure',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'historicalStatus',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'id',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    key: 'issueAssignee',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'issueCreatedDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'issueClosedDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'issueWIPDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'issueId',
    operands: FILTER.OPERANDS.filter((s) => ['in'].find((a) => a === s.value)),
  }, {
    key: 'issueUrl',
    operands: FILTER.OPERANDS.filter((s) => ['rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'issueStatus',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'knownExploit',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'lastSeen',
    operands: FILTER.OPERANDS.filter((s) => ['gt', 'lt'].find((a) => a === s.value)),
  }, {
    key: 'lastStatusUpdate',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'metaData',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'bl', 'nbl', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'manual',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'mitigated',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'nameSpace',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'in', 'nin', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'nist',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'observedInTheWild',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'overdue',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'owasp2017Top10',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'owasp2021Top10',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'platform',
    operands: FILTER.OPERANDS.filter((s) => ['in'].find((a) => a === s.value)),
  }, {
    key: 'packageUrl',
    operands: FILTER.OPERANDS.filter((s) => ['rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'pci',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'pluginId',
    operands: FILTER.OPERANDS.filter((s) => ['in'].find((a) => a === s.value)),
  }, {
    key: 'region',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'riskAccepted',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'riskRating',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'raMarkDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'scanner',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'scannerType',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'severity',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'status',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'textSearch',
    operands: FILTER.OPERANDS.filter((s) => ['rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'truePositive',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'vulnerabilityName',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'woe',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'gt', 'gte', 'lt', 'lte']
      .find((a) => a === s.value)),
  }, {
    key: 'wontfix',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  },
];

FILTER.PROJECT_INFRA_VULNS_OPTIONS = [
  {
    key: 'cvss',
    operands: FILTER.OPERANDS.filter((s) => ['lt', 'gt'].find((a) => a === s.value)),
  }, {
    key: 'cvssVector',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    key: 'cweId',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is not',
      value: 'ne',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }],
  }, {
    key: 'cweName',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'dnsName',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'dueDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt', 'lt'].find((a) => a === s.value)),
  }, {
    key: 'epssPercentage',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'gte', 'lte'].find((a) => a === s.value)),
  }, {
    key: 'epssPercentile',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'gte', 'lte'].find((a) => a === s.value)),
  }, {
    key: 'family',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is not',
      value: 'ne',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }, {
      label: 'contains',
      value: 'rgx',
    }, {
      label: 'doesn\'t contain',
      value: 'nrgx',
    }],
  }, {
    key: 'falsePositive',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'fpMarkDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'firstSeenFrom',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'firstSeenTo',
    operands: FILTER.OPERANDS.filter((s) => ['lt'].find((a) => a === s.value)),
  }, {
    key: 'fixable',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'fqdn',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is not',
      value: 'ne',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }, {
      label: 'contains',
      value: 'rgx',
    }, {
      label: 'doesn\'t contain',
      value: 'nrgx',
    }],
  }, {
    key: 'historicalStatus',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'id',
    operands: FILTER.OPERANDS.filter((s) => ['in'].find((a) => a === s.value)),
  }, {
    key: 'infraGroupNames',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'issueAssignee',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'issueCreatedDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'issueClosedDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'issueWIPDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'issueId',
    operands: FILTER.OPERANDS.filter((s) => ['in'].find((a) => a === s.value)),
  }, {
    key: 'issueUrl',
    operands: FILTER.OPERANDS.filter((s) => ['rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'issueStatus',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'ip',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'knownExploit',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'lastStatusUpdate',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'pluginId',
    operands: FILTER.OPERANDS.filter((s) => ['in'].find((a) => a === s.value)),
  }, {
    key: 'pluginName',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is not',
      value: 'ne',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }, {
      label: 'contains',
      value: 'rgx',
    }, {
      label: 'doesn\'t contain',
      value: 'nrgx',
    }],
  }, {
    key: 'port',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is not',
      value: 'ne',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }],
  }, {
    key: 'protocol',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is not',
      value: 'ne',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }],
  }, {
    key: 'riskAccepted',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'raMarkDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'riskRating',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'lastSeen',
    operands: FILTER.OPERANDS.filter((s) => ['gt', 'lt'].find((a) => a === s.value)),
  }, {
    key: 'metaData',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'bl', 'nbl', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'manual',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'mitigated',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'netBiosName',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'os',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'observedInTheWild',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'overdue',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'scanner',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'service',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is not',
      value: 'ne',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }],
  }, {
    key: 'severity',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'status',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'textSearch',
    operands: FILTER.OPERANDS.filter((s) => ['rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'truePositive',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'vulnerabilityName',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'woe',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'gt', 'gte', 'lt', 'lte']
      .find((a) => a === s.value)),
  }, {
    key: 'wontfix',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  },
];

FILTER.PROJECT_SCAN_OPTIONS = [
  {
    key: 'branch',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'metaData',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'bl', 'nbl', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'scanner',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'scanType',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  },
];

FILTER.PROJECT_IMPORTS_OPTIONS = [
  {
    key: 'branch',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'discoveredBy',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'engagement',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'importedBy',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'metaData',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'bl', 'nbl', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'scanner',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  },
];

FILTER.PROJECT_FAILED_OPTIONS = [
  {
    key: 'branch',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'metaData',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'bl', 'nbl', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'scanner',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'team',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  },
];

FILTER.PROJECT_MANAGEMENT_OPTIONS = [
  {
    key: 'businessCriticality',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'label',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'language',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'project',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'product',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'team',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  },
];

FILTER.PRODUCT_SCAN_OPTIONS = [
  {
    key: 'branch',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'project',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'metaData',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'bl', 'nbl', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'scanner',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'scanType',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  },
];

FILTER.PRODUCT_IMPORTS_OPTIONS = [
  {
    key: 'branch',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'discoveredBy',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'engagement',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'importedBy',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'project',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'metaData',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'bl', 'nbl', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'scanner',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  },
];

FILTER.PF_PENDING_OPTIONS = [
  {
    key: 'requester',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'expirationDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt', 'lt'].find((a) => a === s.value)),
  }, {
    key: 'requestDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt', 'lt'].find((a) => a === s.value)),
  }, {
    key: 'requestType',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    key: 'severity',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'type',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'vulnerabilityName',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  },
];

FILTER.PF_APPROVED_OPTIONS = [
  {
    key: 'approver',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'approvalDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt', 'lt'].find((a) => a === s.value)),
  }, {
    key: 'businessCriticality',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'expirationDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt', 'lt'].find((a) => a === s.value)),
  }, {
    key: 'project',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'product',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'requester',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'requestDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt', 'lt'].find((a) => a === s.value)),
  }, {
    key: 'requestType',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    key: 'scannerType',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'severity',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'type',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'vulnerabilityName',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  },
];

FILTER.PF_REJECTED_OPTIONS = [
  {
    key: 'rejectedBy',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'rejectionDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt', 'lt'].find((a) => a === s.value)),
  }, {
    key: 'requester',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'requestDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt', 'lt'].find((a) => a === s.value)),
  }, {
    key: 'requestType',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    key: 'severity',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'vulnerabilityName',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'type',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  },
];

FILTER.PF_EXPIRED_OPTIONS = [
  {
    key: 'approver',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'requester',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'expirationDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'requestDate',
    operands: FILTER.OPERANDS.filter((s) => ['gt'].find((a) => a === s.value)),
  }, {
    key: 'severity',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'vulnerabilityName',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'type',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  },
];

FILTER.COMMITTERS_OPTIONS = [
  {
    key: 'committer',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'defaultBranch',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    key: 'label',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'project',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'severity',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'team',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  },
];

FILTER.SBOM_OPTIONS = [
  {
    key: 'branch',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'license',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'projects',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'packageUrl',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'sbomSource',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'vulnerable',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  },
];

FILTER.SBOM_COMPONENTS_OPTIONS = [
  {
    key: 'branch',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'license',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'packageUrl',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'projects',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'licenseRisk',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'severity',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'name',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'vulnerable',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  },
];

FILTER.PROJECT_SBOM_OPTIONS = [
  {
    key: 'branch',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'license',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'packageUrl',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'sbomSource',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'vulnerable',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  },
];

FILTER.PROJECT_SBOM_COMPONENTS_OPTIONS = [
  {
    key: 'branch',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'licenses',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'packageUrl',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'licenseRisk',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'sbomSource',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'severity',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'name',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  },
];

FILTER.REMEDIATION_OPTIONS = [
  {
    key: 'count',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    key: 'cweId',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is not',
      value: 'ne',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }],
  }, {
    key: 'cweName',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx'].find((a) => a === s.value)),
  },
];

FILTER.AUDIT_LOGS_OPTIONS = [
  {
    key: 'endDate',
    operands: FILTER.OPERANDS.filter((s) => ['lte'].find((a) => a === s.value)),
  }, {
    key: 'eventCategory',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'eventName',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'startDate',
    operands: FILTER.OPERANDS.filter((s) => ['gte'].find((a) => a === s.value)),
  }, {
    key: 'username',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx'].find((a) => a === s.value)),
  },
];

FILTER.CLOUD_ASSETS_OPTIONS = [
  {
    key: 'name',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }, {
      label: 'contains',
      value: 'rgx',
    }],
  }, {
    key: 'platform',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }, {
      label: 'contains',
      value: 'rgx',
    }],
  }, {
    key: 'resourceType',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }, {
      label: 'contains',
      value: 'rgx',
    }],
  }, {
    key: 'projects',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }, {
      label: 'contains',
      value: 'rgx',
    }],
  }, {
    key: 'region',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }, {
      label: 'contains',
      value: 'rgx',
    }],
  },
];

FILTER.CLOUD_CONTAINER_VULNS_OPTIONS = [
  {
    key: 'cisaKevExploit',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    key: 'closed',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'exploit',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  }, {
    key: 'imageName',
    operands: FILTER.OPERANDS.filter((s) => ['rgx'].find((a) => a === s.value)),
  }, {
    key: 'name',
    operands: FILTER.OPERANDS.filter((s) => ['rgx'].find((a) => a === s.value)),
  }, {
    key: 'platform',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'project',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'region',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'severity',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  },
];

FILTER.CLOUD_VM_VULNS_OPTIONS = [
  {
    key: 'ipAddresses',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'name',
    operands: FILTER.OPERANDS.filter((s) => ['rgx'].find((a) => a === s.value)),
  }, {
    key: 'os',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'openToInternet',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'platform',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'project',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'region',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'severity',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  },
];

FILTER.ASSETS_MGMT_OPTIONS = [
  {
    key: 'alm',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'businessCriticality',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'issueManager',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'label',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'language',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'notification',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'product',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'project',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'scanner',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'team',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  },
];

FILTER.CODE_MGMT_OPTIONS = [
  {
    key: 'alm',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  }, {
    key: 'forked',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'name',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'rgx'].find((a) => a === s.value)),
  }, {
    key: 'onboarded',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne'].find((a) => a === s.value)),
  }, {
    key: 'obsolete',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  },
];

FILTER.EVENT_REPORTS = [
  {
    key: 'status',
    operands: [{
      label: 'is',
      value: 'eq',
    }, {
      label: 'is not',
      value: 'ne',
    }, {
      label: 'is one of',
      value: 'in',
    }, {
      label: 'is none of',
      value: 'nin',
    }],
  }, {
    key: 'profileId',
    operands: FILTER.OPERANDS.filter((s) => ['eq'].find((a) => a === s.value)),
  },
];

FILTER.EVENT_STATUS_OPTIONS = [
  {
    label: 'Waiting',
    value: 'waiting',
  },
  {
    label: 'Working',
    value: 'working',
  },
  {
    label: 'Finished',
    value: 'finished',
  },
  {
    label: 'Failed',
    value: 'failed',
  },
];

FILTER.SBOM_STATUS_OPTIONS = [
  {
    label: 'Waiting',
    value: 'waiting',
  }, {
    label: 'Done',
    value: 'done',
  },
];

FILTER.PLAYBOOK_OPTIONS = [
  {
    label: 'Failed',
    value: 'fail',
  }, {
    label: 'Success',
    value: 'success',
  }, {
    label: 'Not Entered',
    value: 'undefined',
  },
];

FILTER.QUICK_FILTER_OPTIONS = [
  {
    label: 'Untriaged Vulnerabilities',
    value: 'untriagedVulnerabilities',
  }, {
    label: 'Discovered in Last 30 Days',
    value: 'discoveredInLast30Days',
  }, {
    label: 'OWASP (2021) Top-10',
    value: 'owasp2021Top10',
  }, {
    label: 'Critical & High Severity',
    value: 'criticalHighSeverity',
  }, {
    label: 'Highlighted Vulnerabilities',
    value: 'highlightedVulnerabilities',
  }, {
    label: 'Has Fix',
    value: 'hasFix',
  },
];

FILTER.INFRA_PROFİLES_OPTIONS = [
  {
    key: 'bindTo',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'metaData',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'in', 'nin', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'profileName',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'in', 'nin', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'scanner',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'ne', 'in', 'nin', 'rgx', 'nrgx'].find((a) => a === s.value)),
  },
];

FILTER.INFRA_GROUPS_OPTIONS = [
  {
    key: 'rules',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'nin', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'tagName',
    operands: FILTER.OPERANDS.filter((s) => ['eq', 'rgx', 'nrgx'].find((a) => a === s.value)),
  }, {
    key: 'tagId',
    operands: FILTER.OPERANDS.filter((s) => ['in', 'nin'].find((a) => a === s.value)),
  },
];

// -------------------------------------------------------------------------------------------------
// ## Api config.
// -------------------------------------------------------------------------------------------------

const API_URL = {};
const tablePrefix = 'dashboard/table';
const chartPrefix = 'dashboard/charts/';

API_URL.PREFIX = 'core/';
API_URL.BASE = config.baseUrl + API_URL.PREFIX;

API_URL.ROLES = 'roles';

API_URL.LOGIN = 'login';
API_URL.LOGOUT = 'logout';

API_URL.USERS = 'users';
API_URL.USERS_VALIDATE = 'users/validate';
API_URL.NOTIFICATIONS = 'notifications';
API_URL.USER_PASSWORD = 'users/password';

API_URL.START = 'start';
API_URL.EXPORT = 'export';

API_URL.CWE = 'cwe';
API_URL.SOURCES = 'sources';
API_URL.SETTINGS = 'settings';
API_URL.SETTINGS_API = `${API_URL.SETTINGS}/api`;
API_URL.SETTINGS_SCANNER = 'scanner';
API_URL.SETTINGS_SCANNER_PROJECTS = 'projects';
API_URL.SETTINGS_SLACK = `${API_URL.SETTINGS}/notification/slack`;
API_URL.SETTINGS_SLACK_CHANNELS = `${API_URL.SETTINGS_SLACK}/channels`;
API_URL.SETTINGS_SLACK_TEST = `${API_URL.SETTINGS_SLACK}/test_connection`;
API_URL.SETTINGS_MATTERMOST = `${API_URL.SETTINGS}/notification/mattermost`;
API_URL.SETTINGS_MATTERMOST_CHANNELS = `${API_URL.SETTINGS_MATTERMOST}/channels`;
API_URL.SETTINGS_MATTERMOST_TEST = `${API_URL.SETTINGS_MATTERMOST}/test_connection`;
API_URL.SETTINGS_TEST_CONNECTION = `${API_URL.SETTINGS}/test`;
API_URL.SETTINGS_JIRA_ISSUE_TYPES = `${API_URL.SETTINGS}/jira/issue/types`;
API_URL.SETTINGS_JIRA_ISSUE_STATUSES = `${API_URL.SETTINGS}/jira/statuses`;
API_URL.SETTINGS_JIRA_PROJECTS = `${API_URL.SETTINGS}/jira/projects`;
API_URL.SETTINGS_JIRA = `${API_URL.SETTINGS}/jira`;
API_URL.SETTINGS_DOCKER = 'docker';
API_URL.SETTINGS_LABELS = `${API_URL.SETTINGS}/labels`;

API_URL.REMEDIATIONS = 'remediations';
API_URL.ENDPOINTS = 'endpoints';

API_URL.PROJECTS = 'projects';
API_URL.PROJECTS_VALIDATE = 'projects/validate';

API_URL.PROJECT = 'project';
API_URL.PROJECT_VULNS_WITH_ISSUE = 'card/vulns_with_issue';
API_URL.PROJECT_OVERALL_RISK_SCORE = 'card/overall_risk_score';
API_URL.PROJECT_DETAIL_TABLE = 'project_detail_table';
API_URL.PROJECT_VULNS_IDENTIFIED_YESTERDAY = 'charts/recent_findings';
API_URL.PROJECT_TOOLS_COMPARISON = 'charts/project_tools_comparison';
API_URL.PROJECT_SEVERITY_TREND = 'charts/project_severity_trend';
API_URL.PROJECT_RISK_SCORE_TREND = 'charts/project_risk_score_trend';
API_URL.PROJECT_CWE_SCHEMA = 'charts/project_cwe_schema';
API_URL.PROJECT_BUBBLE_CHART = 'charts/project_bubble_chart';
API_URL.PROJECT_OWASP_CATEGORIES = 'charts/project_owasp_categories';

API_URL.PRODUCTS = 'products';
API_URL.PRODUCTS_VALIDATE = 'products/validate';

API_URL.SCANNERS = 'scanners';
API_URL.SCANS = 'scans';
API_URL.SCANS_QUEUE = 'queue';
API_URL.SCANS_COMPLETED = 'completed';
API_URL.SCANS_FAILED = 'failed';
API_URL.SCANS_CALENDAR = 'calendar';
API_URL.SCAN_PARAMS = 'scanparams';

API_URL.VULNS = 'vulns';

API_URL.COMMITTERS = 'committers';

API_URL.TEAMS = 'teams';
API_URL.TEAMS_VALIDATE = 'teams/validate';

API_URL.WEB_HOOKS = 'webhooks';
API_URL.WEB_HOOKS_GENERATE_KEY = `${API_URL.WEB_HOOKS}/generatekey`;

API_URL.EVENTS = 'events';
API_URL.SBOM = 'sbom';

API_URL.DASHBOARD_TABLE = `${tablePrefix}`;
API_URL.DASHBOARD_CARD_PROJECTS_COUNT = 'dashboard/card/projects_count';
API_URL.DASHBOARD_CARD_PROJECTS_FAIL = 'dashboard/card/projects_fail';
API_URL.DASHBOARD_AGING_BY_SEVERITY = 'dashboard/charts/stacked_bar_chart';
API_URL.DASHBOARD_WOE_TOP_TEN = 'dashboard/charts/top10_woe';
API_URL.DASHBOARD_VULNS_IDENTIFIED_YESTERDAY = 'dashboard/charts/recent_findings';
API_URL.SY = `${chartPrefix}severity_trend`;
API_URL.RS_TREND = `${chartPrefix}risk_score_trend`;
API_URL.RS_SNAPSHOT = `${chartPrefix}risk_score_snapshot`;
API_URL.RS_TRENDY_BY_TEAM = `${chartPrefix}risk_score_trend_by_team`;
API_URL.SY_SNAPSHOT_BY_TEAM = `${chartPrefix}severity_snapshot_by_team`;
API_URL.RS_SNAPSHOT_BY_TEAM = `${chartPrefix}risk_score_snapshot_by_team`;
API_URL.RV_COUNT = `${chartPrefix}recurrent_vuln_count`;
API_URL.OWASP_CATEGORIES = `${chartPrefix}owasp_categories`;
API_URL.RV_COUNT_BY_STANDARDS = `${chartPrefix}recurrent_vuln_count_by_standards`;

API_URL.DASHBOARD = 'dashboard';
API_URL.DASHBOARD_CHART = 'dashboard/charts';

API_URL.LICENSE = 'license';

API_URL.FILTERS_SCANNER = '/filters/scanners';

API_URL.PERSONEL_ACCESS_TOKEN = '/settings/api';

API_URL.CLOUD_ASSETS = 'cloud';

const HIGHCHARTOPTIONS = {
  chart: {
    type: 'packedbubble',
    height: 320,
  },
  legend: {
    enabled: false,
  },
  title: {
    text: null,
  },
  tooltip: {
    useHTML: true,
    borderWidth: 0,
    headerFormat: null,
    pointFormatter() {
      const point = this.options;
      const loopFor = () => {
        let str = '';
        if (point.countByTool) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < point.countByTool.length; i++) {
            str += `
              <div class="mg-b-4 clearfix">` +
                `<span class="text-uppercase">${point.countByTool[i].tool_type}</span>` +
                '<span class="pd-l-8 float-right">' +
                ` ${point.countByTool[i].count}` +
                '</span>' +
            '</div>';
          }
        }
        return str;
      };
      const idTitle = () => {
        let str = '';
        str += `<div class="mg-b-4 clearfix${(point.idTitle ? null : ' display-none')}">` +
          `<b>${point.idTitle}</b>` +
          '<span class="pd-l-8 float-right">' +
          ` <b>${point.id}</b>` +
          '</span>' +
        '</div>';
        return str;
      };
      const formatStr = '<div class="highcharts-tooltip-container">' +
          '<div class="mg-b-4 clearfix">' +
              '<a href="#" >' +
              `<b>${point.name}</b>` +
              '</a>' +
          '</div>' +
          `${point.idTitle ? idTitle() : ''}` +
          `${loopFor()}` +
          '<div class="mg-b-4 clearfix">' +
              ' <b>TOTAL</b>' +
              '<span class="pd-l-8 float-right">' +
              ` <b>${point.value}</b>` +
              '</span>' +
          '</div>' +
      '</div>';
      return formatStr;
    },
  },
  credits: false,
};

export {
  COLOR,
  DASHBOARD,
  PRODUCT,
  PROJECT,
  TEAM,
  USER,
  SCANS,
  VULN,
  SBOM,
  COMMITTER,
  DEFAULT_LANGUAGE,
  LOCAL_STORAGE,
  API_URL,
  HIGHCHARTOPTIONS,
  CRITERIA,
  REPORTS,
  ENDPOINTS,
  GLOBAL,
  FILTER,
  UI_VERSION,
  CLOUDASSETS,
};
