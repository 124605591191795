const PreComponent = '<router-view/>';

export default {
  path: 'setup',
  name: 'setup',
  component: () => import('@/views/layouts/SetupLayouts.vue'),
  redirect: {
    name: 'labels',
    query: {
      page: 1,
    },
  },
  children: [
    {
      path: 'labels',
      name: 'labels',
      component: () => import('@/views/pages/member/Setup/Labels.vue'),
      meta: {
        title: 'Setup: Labels',
        activeMenuKeys: ['setup', 'labels'],
        allowedRole: [5],
      },
      beforeEnter: (to) => {
        if (!to.query.page) {
          return {
            name: 'labels',
            query: { page: 1 },
          };
        }
      },
    }, {
      path: 'bc-thresholds',
      name: 'bc-thresholds',
      component: () => import('@/views/pages/member/Setup/BCThresholds.vue'),
      meta: {
        title: 'Setup: BC Thresholds',
        activeMenuKeys: ['setup', 'bc-thresholds'],
        allowedRole: [5],
      },
    }, {
      path: 'business-units',
      name: 'business-units',
      component: () => import('@/views/pages/member/Setup/BusinessUnits.vue'),
      meta: {
        title: 'Setup: Business Units',
        activeMenuKeys: ['setup', 'business-units'],
        allowedRole: [5],
      },
      beforeEnter: (to) => {
        if (!to.query.page) {
          return {
            name: 'business-units',
            query: { page: 1 },
          };
        }
      },
    }, {
      path: 'sla',
      name: 'sla-rules',
      component: { template: PreComponent },
      redirect: {
        name: 'sla-list',
        query: {
          page: 1,
        },
      },
      children: [
        {
          path: 'list',
          name: 'sla-list',
          component: () => import('@/views/pages/member/Setup/SLA/List.vue'),
          meta: {
            title: 'Setup: SLA Rules',
            activeMenuKeys: ['setup', 'sla-rules'],
            allowedRole: [5],
          },
          beforeEnter: (to) => {
            if (!to.query.page) {
              return {
                name: 'sla-list',
                query: { page: 1 },
              };
            }
          },
        },
        {
          path: 'add',
          name: 'sla-add',
          component: () => import('@/views/pages/member/Setup/SLA/Add.vue'),
          meta: {
            title: 'Setup: Add SLA Rule',
            activeMenuKeys: ['setup', 'sla-rules'],
            allowedRole: [5],
          },
        },
        {
          path: 'edit/:id',
          name: 'sla-edit',
          component: () => import('@/views/pages/member/Setup/SLA/Edit.vue'),
          meta: {
            title: 'Setup: Edit SLA Rule',
            activeMenuKeys: ['setup', 'sla-rules'],
            allowedRole: [5],
          },
        },
      ],
    }, {
      path: 'infra',
      name: 'setup-infra',
      component: () => import('@/views/layouts/SetupInfraLayout.vue'),
      redirect: {
        name: 'infra-profiles',
        query: {
          page: 1,
        },
      },
      children: [
        {
          path: 'profiles',
          name: 'infra-profiles',
          component: () => import('@/views/pages/member/Setup/Infra/Profiles.vue'),
          meta: {
            title: 'Setup: Infra Profiles',
            activeMenuKeys: ['setup', 'infra', 'profiles'],
            allowedRole: [5],
          },
          beforeEnter: (to) => {
            if (!to.query.page) {
              return {
                name: 'infra-profiles',
                query: { page: 1 },
              };
            }
          },
        }, {
          path: 'groups',
          name: 'infra-groups',
          component: () => import('@/views/pages/member/Setup/Infra/Groups.vue'),
          meta: {
            title: 'Setup: Infra Groups',
            activeMenuKeys: ['setup', 'infra', 'groups'],
            allowedRole: [5],
          },
          beforeEnter: (to) => {
            if (!to.query.page || !to.query.perPage) {
              return {
                name: 'infra-groups',
                query: { page: 1, perPage: 15 },
              };
            }
          },
        },
      ],
    }, {
      path: 'severity-score',
      name: 'severity-score',
      component: () => import('@/views/pages/member/Setup/SeverityScore.vue'),
      meta: {
        title: 'Setup: Severity Score',
        activeMenuKeys: ['setup', 'severity-score'],
        allowedRole: [5],
      },
    }, {
      path: 'highlighted-vulns',
      name: 'highlighted-vulns',
      component: { template: PreComponent },
      redirect: {
        name: 'highlighted-vulns-list',
        query: {
          page: 1,
        },
      },
      children: [
        {
          path: 'list',
          name: 'highlighted-vulns-list',
          component: () => import('@/views/pages/member/Setup/HighlightedVulns/List.vue'),
          meta: {
            title: 'Setup: Highlighted Vulnerabilities',
            activeMenuKeys: ['setup', 'highlighted-vulns'],
            allowedRole: [5],
          },
          beforeEnter: (to) => {
            if (!to.query.page) {
              return {
                name: 'highlighted-vulns-list',
                query: { page: 1 },
              };
            }
          },
        }, {
          path: 'add',
          name: 'highlighted-vulns-add',
          component: () => import('@/views/pages/member/Setup/HighlightedVulns/Add.vue'),
          meta: {
            title: 'Setup: Add Highlighted Vulnerabilities',
            activeMenuKeys: ['setup', 'highlighted-vulns'],
            allowedRole: [5],
          },
        }, {
          path: 'edit/:id',
          name: 'highlighted-vulns-edit',
          component: () => import('@/views/pages/member/Setup/HighlightedVulns/Edit.vue'),
          meta: {
            title: 'Setup: Edit Highlighted Vulnerabilities',
            activeMenuKeys: ['setup', 'highlighted-vulns'],
            allowedRole: [5],
          },
        },
      ],
    }, {
      path: 'global-settings',
      name: 'global-settings',
      component: () => import('@/views/pages/member/Setup/GlobalSettings.vue'),
      meta: {
        title: 'Global Settings',
        activeMenuKeys: ['setup', 'global-settings'],
        allowedRole: [5],
      },
    },
  ],
};
