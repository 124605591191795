import { defineStore } from 'pinia';

import TeamService from '@/services/Team';

import ErrorHandling from '@/mixins/ErrorHandling';

import { TEAM } from '@/config/';

import dummyData from './_dummyData';

const useTeamStore = defineStore({
  id: 'Team',
  state: () => ({
    // ## All teams. ---------------------------------------------------------------------------------
    allTeams: [],
    allTeamsPending: false,
    allTeamsDone: false,

    // ## All teams list. ---------------------------------------------------------------------------
    allTeamsList: dummyData,
    allTeamsListTotal: 0,
    allTeamsListPending: false,
    allTeamsListDone: false,
    allTeamsListFail: false,
    allTeamsListFailMsg: null,

    // ## Get team. ----------------------------------------------------------------------------------
    teamDetails: null,
    teamDetailsPending: false,
    teamDetailsDone: false,

    // ## Validate team name. ------------------------------------------------------------------------
    validateTeamName: false,
    validateTeamNamePending: false,
    validateTeamNameDone: false,

    // ## Update team. -------------------------------------------------------------------------------
    updateTeamPending: false,
    updateTeamDone: false,

    // ## Create team. -------------------------------------------------------------------------------
    createTeamData: null,
    createTeamPending: false,
    createTeamDone: false,

    // ## Remove team. -------------------------------------------------------------------------------
    removeTeamPending: false,
    removeTeamDone: false,

    // ## Delegate team. -----------------------------------------------------------------------------
    delegateTeamPending: false,
    delegateTeamDone: false,

    // ## Tem members. -------------------------------------------------------------------------------
    teamMembers: null,
    teamMembersPerPage: TEAM.PERPAGE,
    teamMembersTotal: 0,
    teamMembersPending: false,
    teamMembersDone: false,

    // ## Update members on team. --------------------------------------------------------------------
    updateMembersOnTeamPending: false,
    updateMembersOnTeamDone: false,
    updateMembersOnTeamFail: false,
    updateMembersOnTeamFailMsg: null,
  }),
  actions: {
    // ## Get all teams. -----------------------------------------------------------------------------
    async getAllTeams(data) {
      this.allTeamsPending = true;

      try {
        const res = await TeamService.get(data);
        this.setGetAllTeams(res.data.data);
        this.allTeamsDone = !this.allTeamsDone;
        this.allTeamsPending = false;
      } catch (error) {
        this.allTeamsPending = false;
        ErrorHandling(error);
      }
    },

    setGetAllTeams(payload) {
      this.allTeams = [];
      this.allTeams = payload.map((s) => ({
        id: s.id,
        name: s.name,
        members: s.members,
        issueResponsible: {
          name: (s.issue_responsible ? s.issue_responsible.username : null),
          email: (s.issue_responsible ? s.issue_responsible.email : null),
        },
      }));
    },

    // ## Get all teams list. ------------------------------------------------------------------------
    async getAllTeamsList(data) {
      this.allTeamsListPending = true;

      try {
        const params = {
          limit: data.perPage,
          start: (data.page - 1) * data.perPage,
          sort_by: data.sort,
          q: data.teamname,
        };
        const res = await TeamService.get(params);
        this.allTeamsListTotal = res.data.total;
        this.setGetAllTeamsList(res.data);
        this.allTeamsListDone = !this.allTeamsListDone;
        this.allTeamsListPending = false;
      } catch (error) {
        this.allTeamsList = dummyData;
        this.allTeamsListTotal = 0;
        this.allTeamsListPending = false;
        this.allTeamsListFail = !this.allTeamsListFail;
        this.allTeamsListFailMsg = ErrorHandling(error);
      }
    },

    setGetAllTeamsList(payload) {
      this.allTeamsList = [];
      if (payload.data.length < 1) {
        return;
      }
      this.allTeamsList = payload.data.map((s) => ({
        id: s.id,
        name: s.name,
        membersCount: s.members_count,
        ...(s.issue_responsible && {
          issueResponsible: {
            name: (s.issue_responsible ? s.issue_responsible.username : null),
            email: (s.issue_responsible ? s.issue_responsible.email : null),
          },
        }),
      }));
    },

    // ## Get team details. --------------------------------------------------------------------------
    async getTeamDetails(data) {
      this.teamDetailsPending = false;

      try {
        const res = await TeamService.view(data);
        this.teamDetails = res.data.data;
        this.teamDetailsDone = !this.teamDetailsDone;
        this.teamDetailsPending = false;
      } catch (error) {
        this.teamDetailsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Validate team name. ------------------------------------------------------------------------
    async getValidateTeamName(data) {
      this.validateTeamNamePending = true;

      try {
        const res = await TeamService.validate(data);
        this.validateTeamName = res.data.result;
        this.validateTeamNameDone = !this.validateTeamNameDone;
        this.validateTeamNamePending = false;
      } catch (error) {
        this.validateTeamNamePending = false;
        ErrorHandling(error);
      }
    },

    // ## Update team. -------------------------------------------------------------------------------
    async updateTeam(data) {
      this.updateTeamPending = true;

      try {
        const params = {
          id: data.id,
          query: {
            name: data.name,
            issue_responsible: data.issue_responsible,
          },
        };
        await TeamService.update(params);
        this.updateTeamDone = !this.updateTeamDone;
        this.updateTeamPending = false;
      } catch (error) {
        this.updateTeamPending = false;
        ErrorHandling(error);
      }
    },

    // ## Create team. -------------------------------------------------------------------------------
    async createTeam(data) {
      this.createTeamPending = true;

      try {
        const res = await TeamService.post(data);
        this.createTeamData = res.data.data;
        this.createTeamDone = !this.createTeamDone;
        this.createTeamPending = false;
      } catch (error) {
        this.createTeamPending = false;
        ErrorHandling(error);
      }
    },

    // ## Add team. ----------------------------------------------------------------------------------
    async removeTeam(data) {
      this.removeTeamPending = true;

      try {
        await TeamService.remove(data);
        this.removeTeamDone = !this.removeTeamDone;
        this.removeTeamPending = false;
      } catch (error) {
        this.removeTeamPending = false;
        ErrorHandling(error);
      }
    },

    // ## Delegate team. -----------------------------------------------------------------------------
    async delegateTeam(data) {
      this.delegateTeamPending = true;

      try {
        await TeamService.delegate(data);
        this.delegateTeamDone = !this.delegateTeamDone;
        this.delegateTeamPending = false;
      } catch (error) {
        this.delegateTeamPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get team members. --------------------------------------------------------------------------
    async getTeamMembers(data) {
      this.teamMembersPending = true;

      try {
        const params = {
          id: data.id,
          query: {
            limit: data.perPage,
            start: (data.page - 1) * data.perPage,
            q: data.name,
          },
        };
        const res = await TeamService.getTeamMembers(params);
        this.teamMembersTotal = res.data.total;
        this.setGetTeamMembers(res.data);
        this.teamMembersDone = !this.teamMembersDone;
        this.teamMembersPending = false;
      } catch (error) {
        this.teamMembersPending = false;
        ErrorHandling(error);
      }
    },

    setGetTeamMembers(payload) {
      this.teamMembers = [];
      if (!payload) {
        return;
      }
      this.teamMembers = payload.data.map((s) => ({
        key: s.id,
        id: s.id,
        status: s.status,
        username: s.username,
        email: s.email,
        role: s.role.name,
        shadowUser: s.shadow_user ? s.shadow_user.email : '-',
      }));
    },

    // ## Update members on team. --------------------------------------------------------------------
    async updateMembersOnTeam(data) {
      this.updateMembersOnTeamPending = true;

      try {
        await TeamService.updateMembersOnTeam(data);
        this.updateMembersOnTeamDone = !this.updateMembersOnTeamDone;
        this.updateMembersOnTeamPending = false;
      } catch (error) {
        this.updateMembersOnTeamPending = false;
        this.updateMembersOnTeamFail = !this.updateMembersOnTeamFail;
        this.updateMembersOnTeamFailMsg = ErrorHandling(error);
      }
    },
  },
});

export default useTeamStore;
