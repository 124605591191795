import Api from '@/services/Api';
import { API_URL } from '@/config/';

export default {
  get(params) {
    return Api().get(
      `${API_URL.PROJECTS}/${params.projectId}/${API_URL.SCAN_PARAMS}?imported=true`,
      params,
    );
  },
  getScanDetail(params) {
    if (params.startType === 'global') {
      return Api().get(`infra_management/scan_params/${params.scanId}`, params);
    }
    return Api().get(
      `${API_URL.PROJECTS}/${params.projectId}/${API_URL.SCAN_PARAMS}/${params.scanId}`,
      params,
    );
  },
  getScanParams(params) {
    const { id, imported } = params;
    return Api().get(`${API_URL.PROJECTS}/${id}/${API_URL.SCAN_PARAMS}?imported=${imported}`);
  },
  getAll(params) {
    return Api().get(`${API_URL.SCANS}/${API_URL.SCANS_COMPLETED}`, { params });
  },
  getAllQueue(params) {
    return Api().get(`${API_URL.SCANS}/${API_URL.SCANS_QUEUE}`, { params });
  },
  getAllFailed(params) {
    return Api().get(`${API_URL.SCANS}/${API_URL.SCANS_FAILED}`, { params });
  },
  startScanParams(params) {
    if (params.date) {
      return Api().get(`${API_URL.SCANS}/start?scanparam_id=` +
        `${params.id}&start_date=${params.date}`);
    }
    return Api().get(`${API_URL.SCANS}/start?scanparam_id=${params.id}`);
  },
  getCalendar(params) {
    return Api().get(`${API_URL.SCANS}/${API_URL.SCANS_CALENDAR}`, { params });
  },
  post(params) {
    if (params.type === 'management') {
      delete params.type;
      return Api().patch(`${API_URL.PROJECTS}/management`, params);
    }
    if (params.type === 'global') {
      delete params.type;
      return Api().post('infra_management/scan_params?project_mode=edit', params);
    }
    return Api().post(
      `${API_URL.PROJECTS}/${params.project.id}/${API_URL.SCAN_PARAMS}?project_mode=edit`,
      params,
    );
  },
  update(params) {
    if (params.startType === 'global') {
      delete params.startType;
      return Api().patch(`infra_management/scan_params/${params.id}?project_mode=edit`, params);
    }
    return Api().patch(
      `${API_URL.PROJECTS}/${params.project.id}/${API_URL.SCAN_PARAMS}/` +
        `${params.scanParamId}?project_mode=edit`,
      params,
    );
  },
  remove(params) {
    return Api().delete(
      `${API_URL.PROJECTS}/${params.projectId}/${API_URL.SCAN_PARAMS}/${params.id}`,
      params,
    );
  },
  reScan(params) {
    if (params.type) {
      return Api().get(`${API_URL.SCANS}/${params.type}/${params.id}/rescan`);
    }
    return Api().get(`${API_URL.SCANS}/${params.id}/rescan`);
  },
  cancelScan(data) {
    const params = data.query;
    return Api().post(`${API_URL.SCANS}/${data.id}/cancel`, params);
  },
  getImportableTools() {
    return Api().get(`${API_URL.SETTINGS}/scanners/importable`);
  },
  createNessusProScan(params) {
    return Api().post(`${API_URL.SETTINGS}/scanners/nessuspro/scans`, params);
  },
  createQualysWasScan(params) {
    return Api().post(`${API_URL.SETTINGS}/scanners/qualyswas/projects`, params);
  },
  createSonatypenlScan(params) {
    return Api().post(`${API_URL.SETTINGS}/scanners/sonatypenl/projects`, params);
  },
};
