const PreComponent = '<router-view/>';

export default {
  path: 'cloud-assets',
  name: 'cloud-assets',
  component: { template: PreComponent },
  redirect: {
    name: 'cloud-assets',
    query: {
      page: 1,
    },
  },
  children: [
    {
      path: 'list',
      name: 'cloud-assets-list',
      component: () => import('@/views/pages/member/CloudAssets/List.vue'),
      meta: {
        title: 'Cloud Assets',
        activeMenuKeys: ['cloud-assets', 'cloud-assets-list'],
        allowedRole: [-1],
      },
      beforeEnter: (to) => {
        if (!to.query.page) {
          return {
            name: 'cloud-assets-list',
            query: { page: 1 },
          };
        }
      },
    },
  ],
};
