import Api from '@/services/Api';
import { API_URL } from '@/config/';

export default {
  get(data) {
    const params = data.query;
    return Api().get(`${API_URL.SETTINGS}/${data.type}`, { params });
  },
  getTeamLeadIntegrationSettings(params) {
    return Api().get(`${API_URL.USERS}/me/integrations/${params}`);
  },
  getTeamLeadIntegrationDetails(params) {
    return Api().get(`${API_URL.USERS}/me/integrations/${params.type}/${params.id}`);
  },
  addTeamLeadIntegrationInstance(data) {
    const params = data.query;
    return Api().post(`${API_URL.USERS}/me/integrations/${data.type}/${data.scannerName}/instances`, params);
  },
  updateTeamLeadIntegrationInstance(data) {
    const params = data.query;
    return Api().patch(`${API_URL.USERS}/me/integrations/${data.type}` +
      `/${data.scannerName}/instances/${data.instanceId}`, params);
  },
  deleteTeamLeadIntegrationInstance(data) {
    return Api().delete(`${API_URL.USERS}/me/integrations/${data.type}` +
      `/${data.scannerName}/instances/${data.instanceId}`);
  },
  teamLeadIntegrationTestConnection(data) {
    const params = data.query;
    return Api().post(`${API_URL.USERS}/me/integrations/${data.type}/${data.scannerName}/instances/test`, params);
  },
  retestTeamLeadIntegrationConnection(params) {
    return Api().get(`${API_URL.USERS}/me/integrations/${params.type}` +
      `/${params.scannerName}/instances/${params.instance}/retest`);
  },
  updateToolAccessSettings(data) {
    const params = data.query;
    return Api().patch(`${API_URL.SETTINGS}/${data.type}/${data.id}/access`, params);
  },
  post(params) {
    return Api().post(`${API_URL.SETTINGS}/${params.type}`, params.data);
  },
  update(params) {
    return Api().patch(`${API_URL.SETTINGS}/${params.type}/${params.id}`, params.data);
  },
  delete(params) {
    return Api().delete(`${API_URL.SETTINGS}/${params.type}/${params.id}`);
  },
  getDetail(params) {
    return Api().get(`${API_URL.SETTINGS}/${params.type}/${params.id}`);
  },
  test(params) {
    return Api().post(`${API_URL.SETTINGS}/${params.type}/test`, params.data);
  },
  retest(params) {
    if (params.instance) {
      return Api().get(`${API_URL.SETTINGS}/${params.id}/retest?instance=${params.instance}`);
    }
    return Api().get(`${API_URL.SETTINGS}/${params.id}/retest`);
  },
  addInstance(params) {
    return Api().post(`${API_URL.SETTINGS}/${params.id}/instances`, params.data);
  },
  updateInstance(params) {
    return Api().patch(
      `${API_URL.SETTINGS}/${params.id}` +
      `/instances/${params.instance_id}`,
      params.data,
    );
  },
  deleteInstance(params) {
    return Api().delete(`${API_URL.SETTINGS}/${params.id}` +
      `/instances/${params.instance_id}`);
  },
  createScannersProjects(params) {
    return Api().post(`${API_URL.SETTINGS}/scanners/${params.type}/projects`, params.data);
  },
  getScannersProjects(data) {
    const params = data.query;
    return Api().get(`${API_URL.SETTINGS}/scanners/${data.tool}/projects?limit=30`, { params });
  },
  getScannersEngines(data) {
    const params = data.query;
    return Api().get(`${API_URL.SETTINGS}/scanners/${data.tool}/engines/${data.siteId}`, { params });
  },
  getScannersProjectsDetail(params) {
    return Api().get(`${API_URL.SETTINGS}/scanners/${params.type}/projects/${params.id}`);
  },
  getScannersConfigurations(data) {
    const params = data.query;
    return Api().get(`${API_URL.SETTINGS}/scanners/${data.tool}/configurations`, { params });
  },
  uploadScannersPresets(params) {
    return Api().post(`${API_URL.SETTINGS}/scanners/${params.type}/presets`, params.data);
  },
  getScannersMacros(params) {
    return Api().get(`${API_URL.SETTINGS}/scanners/${params.type}/macros`);
  },
  uploadScannersMacro(params) {
    return Api().post(`${API_URL.SETTINGS}/scanners/${params.type}/recording`, params.data);
  },
  getScannersProfiles(params) {
    if (params.instanceId) {
      return Api().get(`${API_URL.SETTINGS}/scanners/${params.type}/profiles?` +
      `q=${params.q}&limit=30&instance_id=${params.instanceId}`);
    }
    if (params.programId) {
      return Api().get(`${API_URL.SETTINGS}/scanners/${params.type}/profiles?` +
      `q=${params.q}&limit=30&program_id=${params.programId}`);
    }
    if (params.project_name) {
      return Api().get(`${API_URL.SETTINGS}/scanners/${params.type}/profiles?` +
      `q=${params.q}&limit=30&project_name=${params.project_name}`);
    }
    if (params.projectId) {
      return Api().get(`${API_URL.SETTINGS}/scanners/${params.type}/profiles?` +
      `q=${params.q}&limit=30&project_id=${params.projectId}`);
    }
    if (params.accountName) {
      return Api().get(`${API_URL.SETTINGS}/scanners/${params.type}/profiles?` +
      `q=${params.q}&limit=30&account_name=${params.accountName}`);
    }
    if (params.application) {
      return Api().get(`${API_URL.SETTINGS}/scanners/${params.type}/profiles?` +
      `application=${params.application}&q=${params.q}&limit=30`);
    }
    return Api().get(`${API_URL.SETTINGS}/scanners/${params.type}/profiles?` +
      `q=${params.q}&limit=30`);
  },
  getReposProjects(params) {
    if (params.type === 'azureserver' || params.type === 'azurecloud') {
      return Api().post(`${API_URL.SETTINGS}/repos/${params.type}/projects` +
        `?limit=${params.limit}&q=${params.q}&instance=${params.instance}`, params.data);
    }
    if (params.type === 'githubenterprise') {
      return Api().get(`${API_URL.SETTINGS}/repos/${params.type}/projects` +
      `?limit=${params.limit}&q=${params.q}&organisation=${params.organisation}`);
    }
    return Api().get(`${API_URL.SETTINGS}/repos/${params.type}/projects` +
      `?limit=${params.limit}&q=${params.q}`);
  },
  getReposCollections(params) {
    if (params.instance) {
      return Api().get(`${API_URL.SETTINGS}/repos/${params.type}/collections` +
        `?instance=${params.instance}`);
    }
    return Api().get(`${API_URL.SETTINGS}/repos/${params.type}/collections`);
  },
  getReposApps(params) {
    return Api().get(`${API_URL.SETTINGS}/repos/${params.type}/apps` +
      `?limit=${params.limit}&q=${params.q}`);
  },
  getReposBranches(params) {
    if (params.type === 'azurecloud' || params.type === 'azureserver') {
      return Api().post(`${API_URL.SETTINGS}/repos/${params.type}/` +
      `branches?q=${params.q}&instance=${params.instance}`, params.data);
    }
    return Api().post(`${API_URL.SETTINGS}/repos/${params.type}/` +
      `branches?q=${params.q}`, params.data);
  },
  getReposTags(params) {
    return Api().post(`${API_URL.SETTINGS}/repos/${params.toolName}/` +
      `tags?q=${params.q}`, params.data);
  },
  getIssuesProjects(params) {
    if (params.type === 'azurecloud') {
      return Api().post(`${API_URL.SETTINGS}/issues/${params.type}/` +
        `projects?q=${params.q}&instance=${params.instance}`, params.data);
    }
    if (params.type === 'azureserver') {
      return Api().post(`${API_URL.SETTINGS}/issues/${params.type}/` +
        `projects?q=${params.q}&instance=${params.instance}`, params.data);
    }
    if (params.type === 'jira') {
      return Api().get(`${API_URL.SETTINGS}/issues/${params.type}/projects?` +
        `limit=${params.limit}&q=${params.q}&instance=${params.instance}`);
    }
    return Api().get(`${API_URL.SETTINGS}/issues/${params.type}/projects` +
      `?limit=${params.limit}&q=${params.q}`);
  },
  getIssuesComponents(params) {
    if (params.type === 'jira') {
      return Api().get(`${API_URL.SETTINGS}/issues/` +
        `${params.type}/components?project=${params.key}&instance=${params.instance}`);
    }
    return Api().get(`${API_URL.SETTINGS}/issues/${params.type}/projects`);
  },
  getIssuesTypes(params) {
    if (params.type === 'jira') {
      return Api().get(`${API_URL.SETTINGS}/issues/${params.type}/types` +
        `?project=${params.project}&instance=${params.instance}`);
    }
    if (params.type === 'azurecloud' || params.type === 'azureserver') {
      return Api().post(`${API_URL.SETTINGS}/issues/${params.type}/types` +
        `?&instance=${params.instance}`, params.data);
    }
    return Api().post(`${API_URL.SETTINGS}/issues/${params.type}/types`, params.data);
  },
  getIssuesStatuses(params) {
    if (params.type === 'azurecloud' || params.type === 'azureserver') {
      return Api().post(`${API_URL.SETTINGS}/issues/${params.type}/statuses` +
        `?instance=${params.instance}`, params.data);
    }
    if (params.type === 'jira') {
      return Api().get(`${API_URL.SETTINGS}/issues/${params.type}/statuses` +
        `?instance=${params.instance}`);
    }
    return Api().get(`${API_URL.SETTINGS}/issues/${params.type}/statuses`);
  },
  getIssuesWorkItems(data) {
    const params = data.query;
    return Api().get(`${API_URL.SETTINGS}/issues/${data.type}/items`, { params });
  },
  updateIssuesStatuses(data) {
    const params = data.query;
    if (data.instance) {
      return Api().patch(`${API_URL.SETTINGS}/issues/${data.type}/statuses` +
      `?instance=${data.instance}`, params);
    }
    return Api().patch(`${API_URL.SETTINGS}/issues/${data.type}/statuses`, params);
  },
  getIssuesCollections(params) {
    return Api().get(`${API_URL.SETTINGS}/issues/${params.type}/collections` +
      `?instance=${params.instance}`);
  },
  getIssuesOverrideFields(params) {
    return Api().get(`${API_URL.SETTINGS}/issues/${params.type}/instance/` +
      `${params.instance}/project/${params.projectKey}/type/${params.issueType}/override`);
  },
  getIssueFields(params) {
    return Api().get(`${API_URL.SETTINGS}/issues/${params.type}/fields`);
  },
  getIvantiOwnerTeams(data) {
    const params = data.query;
    return Api().get(`${API_URL.SETTINGS}/issues/${data.type}/owner_teams`, { params });
  },
  getIvantiOwners(data) {
    const params = data.query;
    return Api().get(`${API_URL.SETTINGS}/issues/${data.type}/owners`, { params });
  },
  getSlackChannels() {
    return Api().get(API_URL.SETTINGS_SLACK_CHANNELS);
  },
  getMattermostChannels() {
    return Api().get(API_URL.SETTINGS_MATTERMOST_CHANNELS);
  },
  getTokens(params) {
    return Api().get(`${API_URL.PERSONEL_ACCESS_TOKEN}`, { params });
  },
  createToken(params) {
    return Api().post(`${API_URL.PERSONEL_ACCESS_TOKEN}`, params.data);
  },
  deleteToken(params) {
    return Api().delete(`${API_URL.PERSONEL_ACCESS_TOKEN}/${params.id}`);
  },
  updateToken(params) {
    return Api().patch(`${API_URL.PERSONEL_ACCESS_TOKEN}/${params.id}`, params.data);
  },
  getRepository(params) {
    return Api().get(`${API_URL.SETTINGS}/${params}/repos`);
  },
  getIssueProjects(params) {
    return Api().get(`${API_URL.SETTINGS}/${params}/issue/projects`);
  },
  addCheckmarxScan(params) {
    return Api().post(`${API_URL.SETTINGS}/scanner/checkmarx/projects`, params);
  },
  getAcunetixProfiles() {
    return Api().get(`${API_URL.SETTINGS}/scanner/acunetix/profiles`);
  },
  getReportProfiles(params) {
    return Api().get(`${API_URL.SETTINGS}/reports/profiles`, { params });
  },
  getReportProfilesList(params) {
    return Api().get(`${API_URL.SETTINGS}/reports/profiles`, { params });
  },
  addReportProfile(params) {
    return Api().post(`${API_URL.SETTINGS}/reports/profiles`, params);
  },
  getReportProfileDetails(params) {
    return Api().get(`${API_URL.SETTINGS}/reports/profiles/${params}`);
  },
  deleteReportProfile(params) {
    return Api().delete(`${API_URL.SETTINGS}/reports/profiles/${params}`);
  },
  updateReportProfile(params) {
    return Api().patch(`${API_URL.SETTINGS}/reports/profiles/${params.id}`, params);
  },
  activateReportProfile(params) {
    return Api().patch(`${API_URL.SETTINGS}/reports/profiles/${params}/activate`);
  },
  deactivateReportProfile(params) {
    return Api().patch(`${API_URL.SETTINGS}/reports/profiles/${params}/deactivate`);
  },
  downloadReportHistory(params) {
    return Api({
      responseType: 'blob',
    }).get(`${API_URL.SETTINGS}/reports/events/${params.id}/download`);
  },
  getReportHistory(params) {
    return Api().get(`${API_URL.SETTINGS}/reports/events`, { params });
  },
  deleteReportHistory(params) {
    return Api().delete(`${API_URL.SETTINGS}/reports/events/${params.id}`);
  },
  getSourceStatus(params) {
    if (params.key) {
      return Api().get(`${API_URL.SOURCES}/${params.tool}/status?key=${params.key}`);
    }
    return Api().get(`${API_URL.SOURCES}/${params.tool}/status`);
  },
  startAlmSourceSync(params) {
    return Api().get('assets_management/alm_source/assets/sync', { params });
  },
  getAlmSourceSyncStatus(params) {
    return Api().get('assets_management/alm_source/assets/status', { params });
  },
  getDockerTags(params) {
    if (params.image) {
      return Api().get(`${API_URL.SETTINGS_DOCKER}/${params.tool}?image=${params.image}`);
    }
    return Api().get(`${API_URL.SETTINGS_DOCKER}/${params.tool}`);
  },
  pullDockerTags(params) {
    return Api().get(`${API_URL.SETTINGS_DOCKER}/${params.tool}/pull?tags=${params.tags}`);
  },
  startSourceSync(data) {
    const params = data.query;
    return Api().get(`${API_URL.SOURCES}/${data.tool}/sync`, { params });
  },
  getSourceProjects(data) {
    const params = data.query;
    return Api().get(`${API_URL.SOURCES}?tool=${data.tool}`, { params });
  },
  createSourceProject(params) {
    return Api().post(`${API_URL.SOURCES}/projects`, params);
  },
  getLogs() {
    return Api().get(`${API_URL.SETTINGS}/logs?limit=50`);
  },
  getAuditLog(params) {
    return Api().get(`${API_URL.SETTINGS}/audit-logs`, { params });
  },
  getExport(params) {
    return Api().get(`${API_URL.SETTINGS}/audit-logs/${API_URL.EXPORT}`, { params });
  },
  getAllScanner(params) {
    return Api().get(`${API_URL.SETTINGS}/scanners/mv`, { params });
  },
  getIssuesUserGroupTypes(data) {
    const params = data.query;
    return Api().get(`${API_URL.SETTINGS}/issues/${data.type}/user_group_types`, { params });
  },
  getIssuesUserGroups(data) {
    const params = data.query;
    return Api().get(`${API_URL.SETTINGS}/issues/${data.type}/user_group?`, { params });
  },
  getIssueCategories(data) {
    const params = data.query;
    return Api().get(`${API_URL.SETTINGS}/issues/${data.type}/categories`, { params });
  },
  getIssueSubCategories(data) {
    const params = data.query;
    return Api().get(`${API_URL.SETTINGS}/issues/${data.type}/subcategories`, { params });
  },
  getIssueServices(data) {
    const params = data.query;
    return Api().get(`${API_URL.SETTINGS}/issues/${data.type}/services/${data.id}`, { params });
  },
  getIssueServiceInstances(data) {
    const params = data.query;
    return Api().get(`${API_URL.SETTINGS}/issues/${data.type}/service_instances/${data.id}`, { params });
  },
  getIssueTemplates(data) {
    const params = data.query;
    return Api().get(`${API_URL.SETTINGS}/issues/${data.type}/request_templates/${data.id}`, { params });
  },
  getIssueTeams(data) {
    const params = data.query;
    return Api().get(`${API_URL.SETTINGS}/issues/${data.type}/teams`, { params });
  },
  getIssueMembers(data) {
    const params = data.query;
    return Api().get(`${API_URL.SETTINGS}/issues/${data.type}/members/${data.id}`, { params });
  },
  getIssueLabels(data) {
    const params = data.query;
    return Api().get(`${API_URL.SETTINGS}/issues/${data.type}/labels`, { params });
  },
  getLabels(data) {
    const params = data.query;
    return Api().get(`${API_URL.SETTINGS}/labels`, { params });
  },
  getLabelsList(params) {
    return Api().get(`${API_URL.SETTINGS}/labels`, { params });
  },
  addLabel(params) {
    return Api().post(`${API_URL.SETTINGS}/labels`, params);
  },
  deleteLabel(params) {
    return Api().delete(`${API_URL.SETTINGS}/labels/${params}`);
  },
  updateLabel(data) {
    const params = data.query;
    return Api().patch(`${API_URL.SETTINGS}/labels/${data.id}`, params);
  },
  validateLabelName(params) {
    return Api().get(`${API_URL.SETTINGS}/labels/validate?name=${params}`);
  },
  getBusinessUnitsTable(params) {
    return Api().get(`${API_URL.SETTINGS}/business_unit_tags`, { params });
  },
  getBusinessUnitsList(params) {
    return Api().get(`${API_URL.SETTINGS}/business_unit_tags`, { params });
  },
  addBusinessUnits(params) {
    return Api().post(`${API_URL.SETTINGS}/business_unit_tags`, params);
  },
  updateBusinessUnits(data) {
    const params = data.query;
    return Api().patch(`${API_URL.SETTINGS}/business_unit_tags/${data.id}`, params);
  },
  deleteBusinessUnits(params) {
    return Api().delete(`${API_URL.SETTINGS}/business_unit_tags/${params}`);
  },
  validateBusinessUnitsName(params) {
    return Api().get(`${API_URL.SETTINGS}/business_unit_tags/validate?name=${params}`);
  },
  getAuthmanPeople(data) {
    const params = data.query;
    return Api().get(`authman/${data.type}/people`, { params });
  },
  createAuthmanPeople(data) {
    const params = data.data;
    return Api().post(`authman/${data.type}/people/import`, params);
  },
  getSLARulesTable(params) {
    return Api().get(`${API_URL.SETTINGS}/global/sla`, { params });
  },
  addSLARules(params) {
    return Api().post(`${API_URL.SETTINGS}/global/sla`, params);
  },
  getSLARuleDetails(params) {
    return Api().get(`${API_URL.SETTINGS}/global/sla/${params.id}`);
  },
  updateSLARules(data) {
    const params = data.query;
    return Api().patch(`${API_URL.SETTINGS}/global/sla/${data.id}`, params);
  },
  deleteSLARule(params) {
    return Api().delete(`${API_URL.SETTINGS}/global/sla/${params}`);
  },
  getAllBranches(params) {
    return Api().get('/branches', { params });
  },
  validationPolicyName(params) {
    if (params.type && params.type === 'hv') {
      return Api().get(`${API_URL.SETTINGS}/highlighted_vulnerabilities_rules/validate?name=${params.name}`);
    }
    return Api().get(`${API_URL.SETTINGS}/policies/validate?name=${params.name}`);
  },
  validationAgentLabel(params) {
    return Api().get(`${API_URL.SETTINGS}/agents/validate?label=${params}`);
  },
  trainingToolInviteUser(params) {
    return Api().post(`${API_URL.SETTINGS}/training/invite/${params.tool}`, params);
  },
  getTrainingUserInfo(params) {
    return Api().get(`${API_URL.SETTINGS}/training/${params.tool}/` +
      `users?user=${params.username}`);
  },
  getTrainingCourses(params) {
    return Api().get(`${API_URL.SETTINGS}/training/${params}/course`);
  },
  getTrainingLessons(params) {
    return Api().get(`${API_URL.SETTINGS}/training/${params.tool}/course/${params.lang}`);
  },
  assignTrainingToUser(data) {
    const params = data.query;
    return Api().post(`${API_URL.SETTINGS}/training/${data.tool}/course`, params);
  },
  getAddableScanners(params) {
    return Api().get(`${API_URL.SETTINGS}/scanners/bulk_addable?q=${params}`);
  },
  getAgentLanguage(params) {
    return Api().get(`/agent/${params.agentId}/languages`);
  },
  getOrganizations(data) {
    const params = data.query;
    return Api().get(`${API_URL.SETTINGS}/scanners/${data.tool}/organizations`, { params });
  },
  getAllSbomTable(params) {
    return Api().get(`${API_URL.SBOM}/scans`, { params });
  },
  getAllSbomComponentTable(params) {
    return Api().get(`${API_URL.SBOM}/components`, { params });
  },
  exportSbom(params) {
    return Api().get(`${API_URL.SBOM}/export`, { params });
  },
  getSeverityScore() {
    return Api().get('/severity_multipliers');
  },
  updateSeverityScore(params) {
    return Api().patch('/severity_multipliers', params);
  },
  restoreSeverityScore() {
    return Api().get('/severity_multipliers/restore');
  },
  getSandboxes(data) {
    const params = data.query;
    return Api().get(`${API_URL.SETTINGS}/scanners/${data.tool}/sandboxes`, { params });
  },
  getRiskProfile() {
    return Api().get('/risk_profile');
  },
  updateRiskProfile(params) {
    return Api().patch('/risk_profile', params);
  },
  getGlobalSettings(params) {
    return Api().get(`${API_URL.SETTINGS}/${params.type}/global_config?tool=${params.tool}`);
  },
  updateGlobalSettings(data) {
    const params = data.query;
    return Api().patch(`${API_URL.SETTINGS}/${data.type}/global_config/${data.tool}`, params);
  },
  updateInstanceSettings(data) {
    const params = data.query;
    return Api().patch(`${API_URL.SETTINGS}/${data.id}/instances/` +
      `${data.instance}/global_config`, params);
  },
  getGlobalConfigurations() {
    return Api().get(`${API_URL.SETTINGS}/global/configuration`);
  },
  saveGlobalConfigurations(data) {
    const params = data.query;
    return Api().patch(`${API_URL.SETTINGS}/global/configuration/${data.id}`, params);
  },
  resetGlobalConfigurations(params) {
    return Api().delete(`${API_URL.SETTINGS}/global/configuration/${params}/reset`);
  },
  uploadGlobalConfigurationsFiles(data) {
    const params = data.query;
    return Api().post(`${API_URL.SETTINGS}/global/configuration` +
      `/${data.id}/${data.prefix}`, params);
  },
  deleteGlobalConfigurationsFiles(data) {
    return Api().delete(`${API_URL.SETTINGS}/global/configuration` +
      `/${data.id}/${data.prefix}?file_type=${data.type}`);
  },
  getInfraProfiles(params) {
    return Api().get('infra_management/scan_params', { params });
  },
  deleteInfraProfiles(params) {
    return Api().delete(`infra_management/scan_params/${params}`);
  },
  getInfraGroups(params) {
    return Api().get('infra_management/groups', { params });
  },
  getToolProfiles(data) {
    const params = data.query;
    return Api().get(`${API_URL.SETTINGS}/scanners/${data.tool}/${data.type}_profiles`, { params });
  },
  createToolProjects(data) {
    const params = data.query;
    return Api().post(`${API_URL.SETTINGS}/scanners/${data.tool}/` +
      `${data.type}_profiles/create_bulk_scan?create_project=${data.prefix}`, params);
  },
  getToolProjectsCount(params) {
    return Api().get(`${API_URL.SETTINGS}/scanners/${params.tool}/` +
      `${params.type}_profiles/bulk_scan_count?create_project=${params.prefix}`);
  },
  createInfraToolProfiles(params) {
    return Api().post('/infra_management/scan_params/bulk', params);
  },
  validateInfraGroupName(params) {
    return Api().get(`infra_management/groups/validate?name=${params}`);
  },
  createInfraGroups(params) {
    return Api().post('infra_management/groups', params);
  },
  deleteInfraGroups(params) {
    return Api().delete(`infra_management/groups/${params}`);
  },
  updateInfraGroups(data) {
    const params = data.query;
    return Api().patch(`infra_management/groups/${data.id}`, params);
  },
  updateScannerStatus(data) {
    const params = data.query;
    return Api().patch(`${API_URL.SETTINGS}/scanners/${data.id}/status`, params);
  },
  getAutomationRules(data) {
    const params = data.query;
    return Api().get(`${API_URL.SETTINGS}/policies`, { params });
  },
  getProjectAutomationRules(data) {
    const params = data.query;
    return Api().get(`${API_URL.PROJECTS}/${data.id}/policies`, { params });
  },
  createAutomationRules(data) {
    const params = data.query;
    return Api().post(`${API_URL.SETTINGS}/policies`, params);
  },
  getAutomationRulesDetails(params) {
    return Api().get(`${API_URL.SETTINGS}/policies/${params}`);
  },
  deleteAutomationRulesTable(oarams) {
    return Api().delete(`${API_URL.SETTINGS}/policies/${oarams}`);
  },
  updateAutomationRules(data) {
    const params = data.query;
    return Api().patch(`${API_URL.SETTINGS}/policies/${data.id}`, params);
  },
  getHighlightedVulns(data) {
    const params = data.query;
    return Api().get(`${API_URL.SETTINGS}/highlighted_vulnerabilities_rules`, { params });
  },
  createHighlightedVulns(data) {
    const params = data.query;
    return Api().post(`${API_URL.SETTINGS}/highlighted_vulnerabilities_rules`, params);
  },
  updateHighlightedVulns(data) {
    const params = data.query;
    return Api().patch(`${API_URL.SETTINGS}/highlighted_vulnerabilities_rules/${data.id}`, params);
  },
  deleteHighlightedVulns(oarams) {
    return Api().delete(`${API_URL.SETTINGS}/highlighted_vulnerabilities_rules/${oarams}`);
  },
  getHighlightedVulnsDetails(params) {
    return Api().get(`${API_URL.SETTINGS}/highlighted_vulnerabilities_rules/${params}`);
  },
  getSbomGeneratorTools(params) {
    return Api().get(`${API_URL.SETTINGS}/scanners/${params}`);
  },
  getFeatureEnvironmentSettings() {
    return Api().get(`${API_URL.SETTINGS}/global/app_environments`);
  },
  getScannerStatus(params) {
    return Api().get(`${API_URL.SETTINGS}/scanners/${params.tool}/scanner_status`);
  },
  getScannerScopeCount(data) {
    const params = data.query;
    return Api().post(`${API_URL.SETTINGS}/scanners/${data.tool}/scope_count`, params);
  },
  getCustomToolFields(params) {
    return Api().get(`${API_URL.SETTINGS}/scanners/custom/template`, { params });
  },
  uploadCustomToolFields(params) {
    return Api().post(`${API_URL.SETTINGS}/scanners/custom/path_list`, params);
  },
  getUploadedCustomToolFields(params) {
    return Api().get(`${API_URL.SETTINGS}/scanners/${params.id}/custom/path_list`, params);
  },
  validationCustomToolName(params) {
    return Api().get(`${API_URL.SETTINGS}/scanners/custom/validate?name=${params.name}`);
  },
  getCustomIntegrationLogos() {
    return Api().get(`${API_URL.SETTINGS}/scanners/custom/logos`);
  },
};
