export const dummyKeyMetrics = [
  {
    name: 'issues',
    critical: 0,
    high: 0,
    low: 0,
    medium: 0,
    total: 0,
  }, {
    name: 'sla',
    critical: 0,
    high: 0,
    low: 0,
    medium: 0,
    total: 0,
  }, {
    name: 'woe',
    critical: 0,
    high: 0,
    low: 0,
    medium: 0,
    total: 0,
  }, {
    name: 'mtf',
    critical: 0,
    high: 0,
    low: 0,
    medium: 0,
    total: 0,
  },
];

export const dummySummary = {
  overall_risk_score: {
    overall_score: 0,
    project: {
      findings: {
        critical: 0,
        high: 0,
        low: 0,
        medium: 0,
      },
      risk_score: 0,
    },
  },
  ti_known_exploit_metric: {
    findings: {
      critical: 0,
      high: 0,
      low: 0,
      medium: 0,
    },
    total: 0,
  },
  ti_observed_in_the_wild_metric: {
    findings: {
      critical: 0,
      high: 0,
      low: 0,
      medium: 0,
    },
    total: 0,
  },
  tool_summary: {
    secret: {
      highest_severity: 'dummy',
      tools: [],
    },
    code: {
      highest_severity: 'dummy',
      tools: [],
    },
    artifact: {
      highest_severity: 'dummy',
      tools: [],
    },
    test: {
      highest_severity: 'dummy',
      tools: [],
    },
    cloud: {
      highest_severity: 'dummy',
      tools: [],
    },
    linux: {
      highest_severity: 'dummy',
    },
    windows: {
      highest_severity: 'dummy',
    },
    others: {
      highest_severity: 'dummy',
    },
  },
};

const dummyArray = [
  {
    name: 'new',
    critical: 0,
    high: 0,
    low: 0,
    medium: 0,
    total: 0,
  }, {
    name: 'recurrent',
    critical: 0,
    high: 0,
    low: 0,
    medium: 0,
    total: 0,
  }, {
    name: 'closed',
    critical: 0,
    high: 0,
    low: 0,
    medium: 0,
    total: 0,
  }, {
    name: 'total',
    critical: 0,
    high: 0,
    low: 0,
    medium: 0,
    total: 0,
  }, {
    name: 'wontfix',
    critical: 0,
    high: 0,
    low: 0,
    medium: 0,
    total: 0,
  }, {
    name: 'fp',
    critical: 0,
    high: 0,
    low: 0,
    medium: 0,
    total: 0,
  },
];

const vulnExpiringTableDummy = [...Array(7)].map((_, i) => ({
  id: i,
  name: '#########',
  severity: 1,
  expirationDate: '#########',
}));

export {
  dummyArray,
  vulnExpiringTableDummy,
};
