import { defineStore } from 'pinia';

import RemediationService from '@/services/Remediation';

import ErrorHandling from '@/mixins/ErrorHandling';

import dayjs from 'dayjs';

import dummyData from './_dummyData';

const useRemediationStore = defineStore({
  id: 'Remediation',
  state: () => ({
    // ## Remediation add. ---------------------------------------------------------------------------
    addRemediationPending: false,
    addRemediationDone: false,
    addRemediationFail: false,
    addRemediationFailMsg: null,

    // ## Remediations DB table. --------------------------------------------------------------------------
    allRemediationTable: dummyData,
    allRemediationTablePending: false,
    allRemediationTableTotal: 0,
    allRemediationTablePerPage: 15,
    allRemediationTableDone: false,
    allRemediationTableFail: false,
    allRemediationTableFailMsg: null,

    // ## Single Remediation. ------------------------------------------------------------------------
    remediationDetails: [],
    remediationDetailsPending: false,
    remediationDetailsDone: false,
    remediationDetailsFail: false,

    // ## Delete Remediation. ------------------------------------------------------------------------
    deleteRemediationPending: false,
    deleteRemediationDone: false,
    deleteRemediationFail: false,

    // ## Update Remediation. ------------------------------------------------------------------------
    updateRemediationPending: false,
    updateRemediationDone: false,
    updateRemediationFail: false,
  }),
  actions: {
    // ## Add remediation. ---------------------------------------------------------------------------
    async addRemediation(data) {
      this.addRemediationPending = true;

      try {
        await RemediationService.addRemediation(data);
        this.addRemediationDone = !this.addRemediationDone;
        this.addRemediationPending = false;
      } catch (error) {
        this.addRemediationPending = false;
        this.addRemediationFail = !this.addRemediationFail;
        this.addRemediationFailMsg = ErrorHandling(error);
      }
    },

    // ## Get all remediation table. ----------------------------------------------------------------------
    async getAllRemediationTable(data) {
      this.allRemediationTablePending = true;
      this.allRemediationTable = dummyData;

      try {
        const params = {
          limit: this.allRemediationTablePerPage,
          start: (data.page - 1) * this.allRemediationTablePerPage,
          sort_by: data.sort,
          cwe_no: data.cweId,
          cwe_name: data.cweName,
          count: data.count,
        };
        const res = await RemediationService.getAllRemediationTable(params);
        this.setGetAllRemediationTable(res.data.data);
        this.allRemediationTableTotal = res.data.total;
        this.allRemediationTableDone = !this.allRemediationTableDone;
        this.allRemediationTablePending = false;
      } catch (error) {
        this.allRemediationTable = dummyData;
        this.allRemediationTablePending = false;
        this.allRemediationTableFail = !this.allRemediationTableFail;
        this.allRemediationTableFailMsg = ErrorHandling(error);
      }
    },

    setGetAllRemediationTable(payload) {
      this.allRemediationTable = [];

      if (!payload) {
        return;
      }
      this.allRemediationTable = payload.map((s, i) => ({
        key: i,
        id: s.cwe_id,
        cweId: s.cwe_id,
        cweName: s.cwe_name,
        labels: s.labels,
        createdDate: dayjs(s.created_at).format('DD MMM YYYY'),
        updatedDate: dayjs(s.updated_at).format('DD MMM YYYY'),
      }));
    },

    // ## Get remediation details. -------------------------------------------------------------------
    async getRemediationDetails(data) {
      this.remediationDetailsPending = true;

      try {
        const res = await RemediationService.getRemediationDetails(data);
        this.setGetRemediationDetails(res.data.data);
        this.remediationDetailsDone = !this.remediationDetailsDone;
        this.remediationDetailsPending = false;
      } catch (error) {
        this.remediationDetailsPending = false;
        this.remediationDetailsFail = !this.remediationDetailsFail;
        ErrorHandling(error);
      }
    },

    setGetRemediationDetails(payload) {
      this.remediationDetails = [];

      if (payload.length < 1) {
        return;
      }
      this.remediationDetails = payload.map((s) => ({
        id: s.id,
        createdAt: dayjs(s.created_at).format('DD MMM YYYY'),
        updatedAt: dayjs(s.updated_at).format('DD MMM YYYY'),
        cweId: s.cwe_id,
        author: {
          name: s.author.name,
          email: s.author.email,
        },
        source: s.source,
        entry: s.entry,
        ...(s.label && { label: s.label }),
      }));
    },

    resetRemediationDetails() {
      this.remediationDetails = [];
    },

    // ## Delete remediation. ------------------------------------------------------------------------
    async deleteRemediation(data) {
      this.deleteRemediationPending = true;

      try {
        await RemediationService.deleteRemediation(data);
        this.deleteRemediationDone = !this.deleteRemediationDone;
        this.deleteRemediationPending = false;
      } catch (error) {
        this.deleteRemediationPending = false;
        this.deleteRemediationFail = !this.deleteRemediationFail;
        ErrorHandling(error);
      }
    },

    // ## Update remediation. ------------------------------------------------------------------------
    async updateRemediation(data) {
      this.updateRemediationPending = true;

      try {
        await RemediationService.updateRemediation(data);
        this.updateRemediationDone = !this.updateRemediationDone;
        this.updateRemediationPending = false;
      } catch (error) {
        this.updateRemediationPending = false;
        this.updateRemediationFail = !this.updateRemediationFail;
        ErrorHandling(error);
      }
    },
  },
});

export default useRemediationStore;
