import Api from '@/services/Api';
import { API_URL } from '@/config/';

export default {
  getFalsePositiveDetail(params) {
    return Api().get(`${API_URL.VULNS}/${params}/fp_request`);
  },
  getFalsePositiveList(params) {
    return Api().get(`/actions_requests/notifications?start=${params.start}&limit=${params.limit}`);
  },
  getARPendingTable(data) {
    const params = data.query;
    return Api().get(`/${data.type}_requests/pending`, { params });
  },
  getARApprovedTable(data) {
    const params = data.query;
    return Api().get(`/${data.prefix}_requests/approved`, { params });
  },
  getARRejectedTable(data) {
    const params = data.query;
    return Api().get(`/${data.type}_requests/rejected`, { params });
  },
  getARExpiredTable(data) {
    const params = data.query;
    return Api().get(`/${data.type}_requests/expired`, { params });
  },
  approveFalsePositive(params) {
    return Api().patch(`/fp_requests/${params.id}/approve`, params);
  },
  rejectFalsePositive(params) {
    return Api().patch(`/fp_requests/${params.id}/reject`, params);
  },
  getTruePositiveDetail(params) {
    return Api().get(`${API_URL.VULNS}/${params}/tp_request`);
  },
};
