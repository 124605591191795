import { defineStore } from 'pinia';

import FalsePositiveService from '@/services/FalsePositive';

import ErrorHandling from '@/mixins/ErrorHandling';

import dayjs from 'dayjs';

import dummyData from './_dummyData';

const useFalsePositiveStore = defineStore({
  id: 'FalsePositive',
  state: () => ({
    // ## Get False positive detail. -----------------------------------------------------------------
    falsePositiveDetail: null,
    falsePositiveDetailPending: false,
    falsePositiveDetailDone: false,

    // ## Get False positive. ------------------------------------------------------------------------
    falsePositiveList: [],
    falsePositivePendingCount: 0,
    falsePositiveTotalCount: 0,
    falsePositiveListPage: 1,
    falsePositiveListPerpage: 5,
    falsePositiveListPending: false,
    falsePositiveListDone: false,

    // ## Actions requests pending table. ------------------------------------------------------------
    aRPendingTable: dummyData,
    aRPendingTableTotal: 0,
    aRPendingTablePage: 1,
    aRPendingTablePerpage: 15,
    aRPendingTablePending: false,
    aRPendingTableDone: false,
    aRPendingTableFail: false,
    aRPendingTableFailMsg: null,

    // ## Actions requests approved table. -----------------------------------------------------------
    aRApprovedTable: dummyData,
    aRApprovedTableTotal: 0,
    aRApprovedTablePage: 1,
    aRApprovedTablePerpage: 15,
    aRApprovedTablePending: false,
    aRApprovedTableDone: false,
    aRApprovedTableFail: false,
    aRApprovedTableFailMsg: null,

    // ## Actions requests rejected table. -----------------------------------------------------------
    aRRejectedTable: dummyData,
    aRRejectedTableTotal: 0,
    aRRejectedTablePage: 1,
    aRRejectedTablePerpage: 15,
    aRRejectedTablePending: false,
    aRRejectedTableDone: false,
    aRRejectedTableFail: false,
    aRRejectedTableFailMsg: null,

    // ## Actions requests expired table. ------------------------------------------------------------
    aRExpiredTable: dummyData,
    aRExpiredTableTotal: 0,
    aRExpiredTablePage: 1,
    aRExpiredTablePerpage: 15,
    aRExpiredTablePending: false,
    aRExpiredTableDone: false,
    aRExpiredTableFail: false,
    aRExpiredTableFailMsg: null,

    // ## Approve False positive. --------------------------------------------------------------------
    approveFalsePositiveData: null,
    approveFalsePositivePending: false,
    approveFalsePositiveDone: false,

    // ## Reject False positive. ---------------------------------------------------------------------
    rejectFalsePositiveData: null,
    rejectFalsePositivePending: false,
    rejectFalsePositiveDone: false,

    // ## Get true positive detail. -----------------------------------------------------------------
    truePositiveDetail: null,
    truePositiveDetailPending: false,
    truePositiveDetailDone: false,
  }),
  actions: {
    // ## Vulns false possitive. ---------------------------------------------------------------------
    async getFalsePositiveDetail(data) {
      this.falsePositiveDetailPending = true;

      try {
        const res = await FalsePositiveService.getFalsePositiveDetail(data);
        this.setGetFalsePositiveDetail(res.data);
      } catch (error) {
        this.falsePositiveDetailPending = false;
        ErrorHandling(error);
      }
    },

    setGetFalsePositiveDetail(payload) {
      this.falsePositiveDetail = {
        id: payload.data.id,
        expired: {
          date: dayjs(payload.data.expiry_date).format('DD MMM YYYY HH:mm'),
          status: payload.data.no_expiration,
        },
        requested: {
          name: payload.data.owner.user.username,
          privilege: payload.data.owner.user.role.privilege,
          status: payload.data.request_status,
          date: dayjs(payload.data.requested_at).format('DD MMM YYYY HH:mm'),
          text: payload.data.request_text,
        },
        replied: {
          name: payload.data.replied_by.username,
          text: (payload.data.reply_text !== '' ? payload.data.reply_text : null),
          date: dayjs(payload.data.replied_at).format('DD MMM YYYY HH:mm'),
        },
        type: payload.data.request_type,
      };
      this.falsePositiveDetailPending = false;
      this.falsePositiveDetailDone = !this.falsePositiveDetailDone;
    },

    resetFalsePositiveDetail() {
      this.falsePositiveDetail = null;
    },

    // ## Vulns false possitive. ---------------------------------------------------------------------
    async getFalsePositiveList() {
      this.falsePositiveListPending = true;

      try {
        const params = {
          start: (this.falsePositiveListPage - 1) * this.falsePositiveListPerpage,
          limit: this.falsePositiveListPerpage,
        };
        const res = await FalsePositiveService.getFalsePositiveList(params);
        this.setGetFalsePositiveList(res.data);
      } catch (error) {
        this.falsePositiveListPending = false;
        ErrorHandling(error);
      }
    },

    setGetFalsePositiveList(payload) {
      this.falsePositivePendingCount = 0;
      this.falsePositiveList = payload.data;
      this.falsePositiveTotalCount = payload.total;
      this.falsePositivePendingCount = payload.pending_total;
      this.falsePositiveListPending = false;
      this.falsePositiveListDone = !this.falsePositiveListDone;
    },

    // ## Get actions requests pending table. --------------------------------------------------------
    async getARPendingTable(data) {
      this.aRPendingTablePending = true;
      this.aRPendingTable = dummyData;

      try {
        const params = {
          type: data.type,
          query: {
            limit: this.aRPendingTablePerpage,
            start: (data.query.page - 1) * this.aRPendingTablePerpage,
            sort_by: data.query.sort,
            expiry_date: data.query.expirationDate,
            severity: data.query.severity,
            requested_at: data.query.requestDate,
            request_type: data.query.requestType,
            owner_names: data.query.requester,
            vuln_name: data.query.vulnerabilityName,
            type: data.query.type,
          },
        };
        const res = await FalsePositiveService.getARPendingTable(params);
        this.aRPendingTableTotal = res.data.total;
        this.setGetARPendingTable(res.data);
        this.aRPendingTableDone = !this.aRPendingTableDone;
        this.aRPendingTablePending = false;
      } catch (error) {
        this.aRPendingTable = dummyData;
        this.aRPendingTableTotal = 0;
        this.aRPendingTablePending = false;
        this.aRPendingTableFail = !this.aRPendingTableFail;
        this.aRPendingTableFailMsg = ErrorHandling(error);
      }
    },

    setGetARPendingTable(payload) {
      if (!payload.data) {
        return;
      }
      this.aRPendingTable = payload.data.map((s) => ({
        id: s.id,
        vulnId: s.vuln_detail.id,
        wontfix: s.vuln_detail.change_wf,
        mitigated: s.vuln_detail.change_mitigated,
        falsePositive: s.vuln_detail.change_fp,
        expirationDate: dayjs(s.expiry_date).format('DD MMM YYYY HH:mm'),
        noExpiration: s.no_expiration,
        vulnerabilityName: s.vuln_detail.name,
        severity: s.vuln_detail.severity,
        requestedDate: dayjs(s.requested_at).format('DD MMM YYYY HH:mm'),
        requester: s.owner.user.email,
        request: s.request_text,
        requestType: s.request_type,
      }));
    },

    // ## Get actions requests approved table. -------------------------------------------------------
    async getARApprovedTable(data) {
      this.aRApprovedTablePending = true;
      this.aRApprovedTable = dummyData;

      try {
        const params = {
          prefix: data.prefix,
          query: {
            limit: this.aRApprovedTablePerpage,
            start: (data.page - 1) * this.aRApprovedTablePerpage,
            sort_by: data.sort,
            asvs_level: data.businessCriticality,
            business_unit_tag_ids: data.businessUnits,
            default_branch: data.defaultBranch,
            repl_names: data.approver,
            replied_at: data.approvalDate,
            expiry_date: data.expirationDate,
            expiry_date_from: data.expirationDateFrom,
            expiry_date_to: data.expirationDateTo,
            owner_names: data.requester,
            label_ids: data.labelIds,
            projects: data.project,
            project_ids: data.projectIds || data.projects,
            products: data.product,
            requested_at: data.requestDate,
            request_type: data.requestType,
            severity: data.severity,
            is_wf: data.isWf,
            is_mit: data.isMit,
            team_ids: data.teamIds,
            tool_type: data.scannerType,
            vuln_name: data.vulnerabilityName,
            type: data.type,
          },
        };
        const res = await FalsePositiveService.getARApprovedTable(params);
        this.aRApprovedTableTotal = res.data.total;
        this.setGetARApprovedTable(res.data);
        this.aRApprovedTableDone = !this.aRApprovedTableDone;
        this.aRApprovedTablePending = false;
      } catch (error) {
        this.aRApprovedTable = dummyData;
        this.aRApprovedTableTotal = 0;
        this.aRApprovedTablePending = false;
        this.aRApprovedTableFail = !this.aRApprovedTableFail;
        this.aRApprovedTableFailMsg = ErrorHandling(error);
      }
    },

    setGetARApprovedTable(payload) {
      if (!payload.data) {
        return;
      }
      this.aRApprovedTable = payload.data.map((s) => ({
        id: s.id,
        vulnId: s.vuln_detail.id,
        wontfix: s.vuln_detail.change_wf,
        mitigated: s.vuln_detail.change_mitigated,
        falsePositive: s.vuln_detail.change_fp,
        vulnerabilityName: s.vuln_detail.name,
        severity: s.vuln_detail.severity,
        expirationDate: dayjs(s.expiry_date).format('DD MMM YYYY HH:mm'),
        noExpiration: s.no_expiration,
        requestedDate: dayjs(s.requested_at).format('DD MMM YYYY HH:mm'),
        approvalDate: dayjs(s.replied_at).format('DD MMM YYYY HH:mm'),
        requester: s.owner.user.email,
        approver: s.replied_by.email,
        requestType: s.request_type,
      }));
    },

    // ## Get actions requests rejected table. -------------------------------------------------------
    async getARRejectedTable(data) {
      this.aRRejectedTablePending = true;
      this.aRRejectedTable = dummyData;

      try {
        const params = {
          type: data.type,
          query: {
            limit: this.aRRejectedTablePerpage,
            start: (data.query.page - 1) * this.aRRejectedTablePerpage,
            sort_by: data.query.sort,
            repl_names: data.query.rejectedBy,
            replied_at: data.query.rejectionDate,
            owner_names: data.query.requester,
            requested_at: data.query.requestDate,
            severity: data.query.severity,
            vuln_name: data.query.vulnerabilityName,
            type: data.query.type,
          },
        };
        const res = await FalsePositiveService.getARRejectedTable(params);
        this.aRRejectedTableTotal = res.data.total;
        this.setGetARRejectedTable(res.data);
        this.aRRejectedTableDone = !this.aRRejectedTableDone;
        this.aRRejectedTablePending = false;
      } catch (error) {
        this.aRRejectedTable = dummyData;
        this.aRRejectedTableTotal = 0;
        this.aRRejectedTablePending = false;
        this.aRRejectedTableFail = !this.aRRejectedTableFail;
        this.aRRejectedTableFailMsg = ErrorHandling(error);
      }
    },

    setGetARRejectedTable(payload) {
      if (!payload.data) {
        return;
      }
      this.aRRejectedTable = payload.data.map((s) => ({
        id: s.id,
        vulnId: s.vuln_detail.id,
        wontfix: s.vuln_detail.change_wf,
        mitigated: s.vuln_detail.change_mitigated,
        falsePositive: s.vuln_detail.change_fp,
        vulnerabilityName: s.vuln_detail.name,
        severity: s.vuln_detail.severity,
        requestedDate: dayjs(s.requested_at).format('DD MMM YYYY HH:mm'),
        rejectionDate: dayjs(s.replied_at).format('DD MMM YYYY HH:mm'),
        requester: s.owner.user.email,
        rejectedBy: s.replied_by.email,
        requestType: s.request_type,
      }));
    },

    // ## Get actions requests expired table. -------------------------------------------------------
    async getARExpiredTable(data) {
      this.aRExpiredTablePending = true;
      this.aRExpiredTable = dummyData;

      try {
        const params = {
          type: data.type,
          query: {
            limit: this.aRExpiredTablePerpage,
            start: (data.query.page - 1) * this.aRExpiredTablePerpage,
            sort_by: data.query.sort,
            repl_names: data.query.approver,
            owner_names: data.query.requester,
            expiry_date: data.query.expirationDate,
            requested_at: data.query.requestDate,
            severity: data.query.severity,
            vuln_name: data.query.vulnerabilityName,
            ra_mark_date: data.query.raMarkDate,
            request_type: data.query.requestType,
            type: data.query.type,
          },
        };
        const res = await FalsePositiveService.getARExpiredTable(params);
        this.aRExpiredTableTotal = res.data.total;
        this.setGetARExpiredTable(res.data);
        this.aRExpiredTableDone = !this.aRExpiredTableDone;
        this.aRExpiredTablePending = false;
      } catch (error) {
        this.aRExpiredTable = dummyData;
        this.aRExpiredTableTotal = 0;
        this.aRExpiredTablePending = false;
        this.aRExpiredTableFail = !this.aRExpiredTableFail;
        this.aRExpiredTableFailMsg = ErrorHandling(error);
      }
    },

    setGetARExpiredTable(payload) {
      if (!payload.data) {
        return;
      }
      this.aRExpiredTable = payload.data.map((s) => ({
        id: s.id,
        vulnId: s.vuln_detail.id,
        wontfix: s.vuln_detail.is_wf,
        mitigated: s.vuln_detail.is_mitigated,
        falsePositive: s.vuln_detail.is_fp,
        vulnerabilityName: s.vuln_detail.name,
        severity: s.vuln_detail.severity,
        expirationDate: dayjs(s.expiry_date).format('DD MMM YYYY HH:mm'),
        requestDate: dayjs(s.requested_at).format('DD MMM YYYY HH:mm'),
        approvalDate: dayjs(s.replied_at).format('DD MMM YYYY HH:mm'),
        requester: s.owner.user.email,
        approver: s.replied_by.email,
        requestType: s.request_type,
      }));
    },

    // ## Approve False possitive. -------------------------------------------------------------------
    async approveFalsePositive(data) {
      this.approveFalsePositivePending = true;

      try {
        const res = await FalsePositiveService.approveFalsePositive(data);
        this.setApproveFalsePositive(res.data);
      } catch (error) {
        this.approveFalsePositivePending = false;
        ErrorHandling(error);
      }
    },

    setApproveFalsePositive(payload) {
      this.approveFalsePositiveData = payload.data;
      this.approveFalsePositivePending = false;
      this.approveFalsePositiveDone = !this.approveFalsePositiveDone;
    },

    // ## Reject False possitive. --------------------------------------------------------------------
    async rejectFalsePositive(data) {
      this.rejectFalsePositivePending = true;

      try {
        const res = await FalsePositiveService.rejectFalsePositive(data);
        this.setRejectFalsePositive(res.data);
      } catch (error) {
        this.rejectFalsePositivePending = false;
        ErrorHandling(error);
      }
    },

    setRejectFalsePositive(payload) {
      this.rejectFalsePositiveData = payload.data;
      this.rejectFalsePositivePending = false;
      this.rejectFalsePositiveDone = !this.rejectFalsePositiveDone;
    },

    // ## Vulns true possitive. ---------------------------------------------------------------------
    async getTruePositiveDetail(data) {
      this.truePositiveDetailPending = true;

      try {
        const res = await FalsePositiveService.getTruePositiveDetail(data);
        this.setGetTruePositiveDetail(res.data);
      } catch (error) {
        this.truePositiveDetailPending = false;
        ErrorHandling(error);
      }
    },

    setGetTruePositiveDetail(payload) {
      this.truePositiveDetail = {
        id: payload.data.id,
        requested: {
          name: payload.data.owner.user.username,
          privilege: payload.data.owner.user.role.privilege,
          date: dayjs(payload.data.requested_at).format('DD MMM YYYY HH:mm'),
          text: payload.data.request_text,
        },
        type: payload.data.request_type,
      };
      this.truePositiveDetailPending = false;
      this.truePositiveDetailDone = !this.truePositiveDetailDone;
    },

    resetTruePositiveDetail() {
      this.truePositiveDetail = null;
    },
  },
});

export default useFalsePositiveStore;
