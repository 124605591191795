export default {
  aborted: 'Aborted',
  aboveAvgRiskScore: 'Above Avg. Risk Score',
  accountId: 'Account ID',
  accountIdNull: 'Account ID cannot be empty.',
  action: 'Action',
  actions: 'Actions',
  actionItems: 'Action Items',
  actionItemsInfo: 'Displays the breakdown of all vulnerabilities' +
    ' based on their status and severity category.',
  activate: 'Activate',
  activityDate: 'Activity Date',
  acunetix: 'Acunetix Premium',
  acunetixScan: 'Acunetix Premium Scan',
  acunetixScanDescription: 'Description for Acunetix scan.',
  acunetix360: 'Acunetix 360',
  acunetix360Scan: 'Acunetix 360 Scan',
  accessKey: 'Access Key',
  accessKeyNull: 'Access Key cannot be empty.',
  accessKeyInvalid: 'Invalid Access Key',
  accessKeyId: 'Access Key ID',
  accessKeyIdNull: 'Access Key ID cannot be empty.',
  accessKeyIdInvalid: 'Invalid Access Key ID',
  accessType: 'Access Type',
  accessibleFromVpn: 'Accessible from VPN',
  accessibleFromOtherVnet: 'Accessible from other Vnet',
  accessibleFromOtherSubscription: 'Accessible from other subscription',
  actionType: 'Action Type',
  actionTypeNull: 'Action type cannot be empty.',
  active: 'Active',
  availableProjectS: 'Available Project(s)',
  admin: 'Admin',
  add: 'Add',
  Agents: 'Agents',
  addAgent: 'Add Agent',
  addBusinessUnits: 'Add Business Units',
  addUpToTenInstances: '(Add up to 10 instances)',
  agentInfo: 'Create Kondukto agents to orchestrate your scanners that do not have an API.',
  addComment: 'Add Comment',
  addNote: 'Add Note',
  addCs: 'Add CS',
  addCustomAlert: 'Add Custom Alert',
  addCustomCriteria: 'Add Custom Criteria',
  addVulnerabilities: 'Add Vulnerabilities',
  addToAnExistingIssue: 'Add To An Existing Issue',
  addDast: 'Add DAST',
  added: 'Added',
  addedBefore: 'Added Before',
  addedOnJira: 'Added on JIRA',
  addedOnAzure: 'Added on Azure',
  addedOnGithub: 'Added on Github',
  addedOnGitlab: 'Added on Gitlab',
  addEndpoint: 'Add Endpoint',
  addPreset: 'Add Preset',
  addAlm: 'Add ALM',
  addAlerts: 'Add Alerts',
  addInfra: 'Add Infrastructure',
  addIac: 'Add IAC',
  addRemediationWhenOpeningIssues: 'Add training video and links when opening issues',
  addRemediationWhenOpeningIssues2: 'Add SecureFlag vulnerability description when opening issues',
  addSecurityCriteria: 'Add Security Criteria',
  addSla: 'Add SLA',
  addSLARule: 'Add SLA Rule',
  addMembers: 'Add Members',
  addNewCriteria: 'Add New Criteria',
  addNewEndpoint: 'Add New Endpoint',
  addNewInstance: 'Add New Instance',
  addNewSastCriteria: 'Add SAST Criteria',
  addNewDastCriteria: 'Add DAST Criteria',
  addNewCsCriteria: 'Add CS Criteria',
  addNewMacro: 'Add New Macro',
  addNewScaCriteria: 'Add SCA Criteria',
  addNewFile: 'Add New File',
  addNewLabel: 'Add New Label',
  addNewProduct: 'Add New Product',
  addNewProject: 'Add New Project',
  addNewProductDescription: 'Enter a name for the new product and select projects.',
  addNewPreset: 'Add New Preset',
  addNewProfile: 'Add New Profile',
  addNewReport: 'Add New Report',
  addNewRule: 'Add New Rule',
  addNewGroup: 'Add New Group',
  addNewScan: 'Add New Scan',
  addNewTeam: 'Add New Team',
  addNewTeamDescription: 'Enter a name for the new team and select members.',
  addNewUser: 'Add New User',
  addNewUserDescription: 'Enter new user information.',
  addOrRemoveProducts: 'Add or Remove Products',
  addOrRemoveLabels: 'Add or Remove Labels',
  addIssueManager: 'Add Issue Manager',
  addIssueAssignment: 'Add Issue Assignment',
  addIssueCriteria: 'Add issue criteria',
  addLabels: 'Add Labels',
  addNotification: 'Add Notification',
  addRemediation: 'Add Remediation',
  addSast: 'Add SAST',
  addSca: 'Add SCA',
  addScreenshot: 'Add Screenshot',
  addSbom: 'Add SBOM',
  addAsIssueManager: 'Add as Issue Manager',
  addInfrastructureVulnerabilities: 'Add Infrastructure Vulnerabilities',
  addFileToThisProjectOnly: 'Add file to this project only',
  addFileToMultipleProjects: 'Add file to multiple projects',
  addToAllProjectsUnderProducts: 'Add to all projects under product(s)',
  addASingleVulnerability: 'Add a single vulnerability',
  advancedSettings: 'Advanced Settings',
  advancedFilter: 'Advanced Filter',
  alreadyAssigned: 'The selected vulnerabilities are already assigned',
  importVulnerabilitiesWithACSVFile: 'Import vulnerabilities with a CSV file or a tool template',
  affectedResource: 'Affected Resource',
  after: 'After',
  aging: 'Aging',
  agentNull: 'Agent cannot be empty.',
  agent: 'Agent',
  agents: 'Agents',
  agentId: 'Agent Id',
  agentIdNull: 'Agent Id cannot be empty.',
  agingInDays: 'Window of Exposure',
  agingBySeverity: 'WOE by Severity',
  agingBySeverityInfo: 'Displays the window of exposure of new and recurrent vulnerabilities.',
  allowedWorkItemTags: 'Allowed labels for action items',
  alert: 'Alert',
  alerts: 'Alerts',
  alertWhenHiggerThanAvarageRiskScore: 'Alert when higher than average risk score.',
  alertRules: 'Alert Rules',
  alertRulesInfo: 'New alert rules become effective in 10 minutes. After the first' +
    ' alert, alerts will be sent on a weekly basis as long as the rule is satisfied.',
  alertRulesInfoTwo: 'There is a default alert rule entered at a' +
    ' global level.',
  alertsEnabledDescOne: 'Enable to add additional alerts to be applied alongside' +
    ' the global criteria. By default, alerts are always sent about completed and' +
    ' failed scans when enabled.',
  alertsEnabledDescTwo: 'Enable to add alerts. By default, alerts are always' +
    ' sent about completed and failed scans when enabled.',
  alertRuleAssociatedWithLabels: 'Alert rule associated' +
    ' with the following label(s) are applied: ',
  alm: 'ALM',
  almFileLink: 'ALM File Link',
  almLink: 'ALM Link',
  allBranches: 'All Branches',
  apps: 'Apps',
  appsNull: 'Apps cannot be empty.',
  applicationLifecycleManagement: 'Application Lifecycle Management',
  applicationLifecycleManagementNull: 'Please select a application lifecycle management.',
  applicationEnvironments: 'Application Environments',
  appPassword: 'App Password',
  appPasswordNull: 'App Password cannot be empty.',
  appPasswordContainsIllegalCharacters: 'App Password contains illegal characters.',
  appPasswordInstructions: 'How to get an app password',
  assetName: 'Asset Name',
  assetsManagement: 'Assets Mgmt.',
  assetType: 'Asset Type',
  all: 'All',
  All: 'All',
  allowIDPInitiated: 'Allow IDP Initiated SSO',
  allUpperCase: 'ALL',
  allScanners: 'All scanners',
  allSeverities: 'All severities',
  allStatus: 'All status',
  allTeamMembers: 'All Team Members',
  allVulnsDescription: 'If merging vulnerabilities is enabled in the config file,' +
    ' this view displays a merged view of vulnerabilities with duplicate vulnerabilities' +
    ' excluded. Otherwise, all vulnerabilities are displayed.',
  allVulnsDescription2: 'The status of the vulnerabilities presented here reflect the status' +
    ' applicable at the time of the scan. Subsequent changes' +
    ' on the status can be tracked within vulnerability details.',
  vulnScore: 'Vuln. Score',
  awsinspectorcs: 'Amazon Inspector CS',
  awsinspectorcsScan: 'Amazon Inspector CS Scan',
  awsinspectorcspm: 'Amazon Inspector CSPM',
  awsinspectorcspmScan: 'Amazon Inspector CSPM Scan',
  awssecurityhub: 'Amazon Security Hub CSPM',
  awssecurityhubScan: 'Amazon Security Hub CSPM Scan',
  analyzing: 'Analyzing',
  analysisNameLengthInvalid: 'Analysis name must be minimum of 6 and maximum of 190 characters',
  analyzed: 'Analyzed',
  appID: 'App ID',
  appIDNull: 'App ID cannot be empty.',
  apiKey: 'Api Key',
  apiKeyNull: 'Api Key cannot be empty.',
  apiKeyInstructions: 'How to get an Api Key',
  apiKeyInvalid: 'Invalid api key',
  apiToken: 'Api Token',
  apiTokenNull: 'Api Token cannot be empty.',
  apiTokenInvalid: 'Invalid api token',
  apiUrl: 'Api URL',
  apiUrlNull: 'Api URL cannot be empty.',
  application: 'Application',
  applicationNull: 'Application can not be empty.',
  applicationLogin: 'Application Login',
  appSecVulnerabilities: 'AppSec Vulnerabilities',
  appspider: 'App Spider Pro',
  appspiderScan: 'App Spider Pro Scan',
  appspiderScanDescription: 'Description for App Spider scan.',
  appsec: 'AppSec',
  appsecAll: 'AppSec All',
  appsecDuplicates: 'AppSec Duplicates',
  approve: 'Approve',
  approved: 'Approved',
  approveFalsePositiveRequest: 'Approve False positive Request',
  apply: 'Apply',
  approver: 'Approver',
  approvalDate: 'Approval Date',
  areYouSureToClose: 'Are you sure to close?',
  areYouSureToDeactivate: 'Are you sure to deactivate?',
  areYouSureToDelete: 'Are you sure to delete?',
  areYouSureToRestoreSettings: 'Are you sure to restore to default settings?',
  areYouSureToCancel: 'Are you sure to cancel?',
  areYouSureToReScan: 'Are you sure to rescan?',
  areYouSureToReset: 'Are you sure to reset?',
  areYouSureToRemoveAccess: 'Are you sure to remove access?',
  areYouSureToApprove: 'Are you sure to approve?',
  asset: 'Asset',
  assetNull: 'Asset cannot be empty',
  assign: 'Assign',
  assignToNewProject: 'Assign To New Project',
  assignToNewProduct: 'Assign To New Product',
  assignToNewTeam: 'Assign To New Team',
  assignAUser: 'Assign a User',
  assignToCommitter: 'Assign to committer',
  assignToCommitterInfo: 'The committer is not a Kondukto user.',
  assignToKonduktoUser: 'Assign to Kondukto user',
  assignCustomIssueStateMapping: 'Assign custom issue state mapping',
  assignee: 'Assignee',
  assignIssue: 'Assign Issue',
  assignIssues: 'Assign Issue(s)',
  assignIssueManager: 'Assign Issue Manager',
  assignEndpoint: 'Assign Endpoint',
  assignEndpoints: 'Assign Endpoint(s)',
  assignTo: 'Assign To',
  assignedTo: 'Assigned To',
  assignTraining: 'Assign Training',
  assignedSuccessfully: 'Assigned Successfully',
  assignNewTeam: 'Assign New Team',
  assignTeamDesc: 'There are projects assigned to this team.' +
    '  You must assign those projects to another team.',
  assignWontFix: 'Assign Won\'t Fix',
  assignSeparateIssuesForSelectedVulns: 'Assign separate issues for selected vulns',
  assignOneIssueForSelectedVulns: 'Assign one issue for selected vulns',
  assignOneIssueForSelectedVulnsTooltip: 'Sync and automation features' +
    ' can not be used in this option.',
  atSpecificTime: 'Wait until specific time of day to check if one or more issues have been closed',
  atSpecificTime2: 'Check if any issues have been closed in the last 24 hours',
  assignToNotSelectedAnyone: 'Please select one of the options.',
  asvs: 'ASVS',
  asvsCompliance: 'ASVS Compliance',
  asvsLevel: 'ASVS Level',
  audience: 'Audience',
  authentication: 'Authentication',
  authenticationType: 'Authentication Type',
  authenticationTypeNull: 'Please select an authentication type.',
  author: 'Author',
  authors: 'Author(s)',
  authUnauthorizedErrorTimeout: 'Your session has expired. Please log in again.',
  authUnauthorizedErrorTitle: 'Unauthorized',
  autoLabels: 'Auto Labels',
  automation: 'Automation',
  automations: 'Automations',
  automationRules: 'Automation Rules',
  automationRulesNull: 'Automation Rules cannot be empty.',
  avatao: 'Avatao',
  average: 'Average',
  avgRiskScore: 'Avg. Risk Score',
  avgTimeToCloseInDays: 'Mean time to fix',
  awsAccountName: 'AWS Account Name',
  awsAccountNameNull: 'AWS Account Name cannot be empty.',
  auditLog: 'Audit Log',
  azure: 'Azure DevOps',
  azureserver: 'Azure DevOps Server',
  azureAD: 'Azure AD',
  azureactivedirectory: 'Azure Active Directory',
  azureCredentialsToUseOnScanner: 'Azure credentials to use on scanner',
  azurecloud: 'Azure DevOps Services',
  away: 'Away',
  backToDemoHub: 'Back to Demo Hub',
  bandit: 'Bandit',
  banditScan: 'Bandit Scan',
  banditScanDescription: 'Description for Bandit Scan',
  basic: 'Basic',
  baseURL: 'Base URL',
  baseURLNull: 'Base URL cannot be empty',
  baseDn: 'Base Dn',
  baseDnNull: 'Base Dn cannot be empty',
  basedOn: 'Based On',
  basedOnNull: 'Based On cannot be empty.',
  bcThresholds: 'BC Thresholds',
  businessCriticalityThresholds: 'Business Criticality Thresholds',
  businessCriticalityThresholdsInfo: 'Define business criticality thresholds for Kondukto to' +
    '  calculate automatically the business criticality of your' +
    '  projects based on labels and their associated risk values.',
  belowAvgRiskScore: 'Below Avg. Risk Score',
  bindProject: 'Bind project',
  bindProjects: 'Bind projects',
  bindProjectTooltip: 'Map this project to a project that already exists on the scanner.',
  bindProjectsNull: 'Bind projects cannot be empty.',
  bindUsername: 'Bind Username',
  bindUsernameNull: 'Bind Username cannot be empty.',
  bindUsernameInvalid: 'Invalid Bind Username.',
  bindPassword: 'Bind Password',
  bindPasswordNull: 'Bind Password cannot be empty.',
  bindPasswordContainsIllegalCharacters: 'Bind Password contains illegal characters.',
  bind: 'Bind',
  bindTo: 'Bind to',
  bitbucket: 'Bitbucket',
  bitbucketserver: 'Bitbucket Server',
  blackduck: 'Black Duck',
  blackduckScan: 'Black Duck Scan',
  brakeman: 'Brakeman',
  brakemanScan: 'Brakeman Scan',
  brakemanScanDescription: 'Description for Brakeman Scan',
  branch: 'Branch',
  branchProject: 'Branch Project',
  branchInvalid: 'Invalid branch name.',
  branchNull: 'Branch cannot be empty.',
  bridgecrewAccess: 'To get accurate vulnerability description and severity in reports,' +
    ' make sure that your server can access https://docs.bridgecrew.io/docs.',
  bool: 'Boolean',
  both: 'BOTH',
  bounty: 'Bounty',
  bugbounty: 'Bug Bounty',
  bulkAction: 'Bulk Action',
  burpsuiteenterprise: 'Burp Suite Enterprise',
  burpsuite: 'Burp Suite',
  burpsuiteenterpriseScan: 'Burp Suite Enterprise Scan',
  burpsuiteenterpriseScanDescription: 'Description for Burp Suite Enterprise',
  businessCriticality: 'Business Criticality',
  businessUnit: 'Business Unit',
  businessUnits: 'Business Units',
  businessunits: 'Business Units',
  businessunit: 'Business Unit',
  businessUnitsNull: 'Business Units cannot be empty.',
  businessUnitsSelectedNull: 'At least one required business unit should be selected.',
  businessUnitsTooltip: 'Managers have view-only permissions and they need to be associated with' +
    ' business units to have access to clusters of products.',
  businessUnitsDescription: 'Associate business units with your products for advanced filtering' +
    '  options in the dashboard and to create a custom hierarchy for manager-level users.',
  burndownChart: 'Burndown Chart',
  burndownChartInfo: 'Displays the evolution of total and open vulnerabilities over time.',
  byBusinessUnit: 'By Business Unit',
  byProduct: 'By Product',
  byProject: 'By Project',
  byTeam: 'By Team',
  bySeverity: 'By Severity',
  calendar: 'Calendar',
  calendarView: 'Calendar View',
  listView: 'List View',
  caCrt: 'Ca CRT',
  caCrtNull: 'Ca CRT cannot be empty.',
  calculateAutomatically: 'Calculate Automatically',
  calculateAutomaticallyInfo: 'Choose this option if you\'d like Kondukto to calculate' +
    '  the business criticality based on the labels entered in the project and' +
    '  their associated risk values.',
  cancel: 'Cancel',
  campaign: 'Campaign',
  campaignNull: 'Campaign cannot be empty.',
  canceled: 'Canceled',
  cancelScan: 'Cancel Scan',
  category: 'Category',
  cdxgen: 'Cdxgen',
  cert: 'Cert',
  changed: 'changed',
  changePassword: 'Change Password',
  changeScanparamsDescription: 'Changing a field may result in the closure of vulnerabilities discovered in' +
    ' previous scans with this configuration.',
  changeStatus: 'Change Status',
  changeTeams: 'Change Teams',
  changeLogs: 'Change Logs',
  channel: 'Channel',
  channelInvalid: 'Invalid channel name.',
  channelNull: 'Channel cannot be empty.',
  changeFPStatus: 'Change FP status',
  changeTPStatus: 'Change TP status',
  charactersRemaining: 'Characters Remaining',
  certificate: 'Certificate',
  certificateNull: 'Certificate cannot be empty.',
  checkmarx: 'Checkmarx',
  checkmarxast: 'Checkmarx One SAST',
  checkmarxastScan: 'Checkmarx One SAST Scan',
  checkmarxastkics: 'Checkmarx One IAC/KICS ',
  checkmarxastkicsScan: 'Checkmarx One IAC/KICS Scan',
  checkmarxkics: 'Checkmarx KICS',
  checkmarxkicsScan: 'Checkmarx KICS Scan',
  checkmarxkicsScanDescription: 'Description for Checkmarx KICS scan.',
  checkmarxsca: 'Checkmarx SCA',
  checkmarxscaScan: 'Checkmarx SCA Scan',
  checkmarxscacloud: 'Checkmarx SCA Cloud',
  checkmarxscacloudScan: 'Checkmarx SCA Scan',
  checkmarxastsca: 'Checkmarx One SCA',
  checkmarxastscaScan: 'Checkmarx One SCA Scan',
  checkmarxscaScanDescription: 'Description for Checkmarx SCA scan.',
  checkmarxScan: 'Checkmarx Scan',
  checkmarxScanDescription: 'Description for Checkmarx scan.',
  checkmarxTeamInfo: 'Teams are used to control access to specific projects in Checkmarx CxSAST.' +
    ' All projects created by Kondukto will be mapped to this team.',
  checkov: 'Checkov',
  checkovScan: 'Checkov Scan',
  clear: 'Clear',
  clearFilter: 'Clear Filter',
  cloud: 'Cloud',
  cloudAssets: 'Cloud Assets',
  cloudScope: 'Cloud Scope',
  cloudFilter: 'Cloud Filter',
  cloudPlatform: 'Cloud Platform',
  currentVersion: 'Current Version',
  close: 'Close',
  closedVulnerabilities: 'Closed Vulnerabilities',
  closedVulnerabilitiesInfo: 'Displays the number of vulnerabilities with a closed on date in the selected period.',
  closeVulnS: 'Close Vuln(s)',
  closeVulnInfo: 'Only imported vulnerabilities can be closed manually.',
  closeManuallyOnKondukto: 'Close manually on Kondukto',
  closeWhenClosedOnTheIssueManager: 'Close when closed on the issue manager',
  closeIssues: 'Close Issue(s)',
  closed: 'Closed',
  closedNoOfVulnerabilities: 'Closed no. of vulnerabilities',
  closedNoOfIssues: 'Closed no of. issues',
  closedFindings: 'Closed Findings',
  closedFindingsWithIssue: 'Closed Findings with Issue',
  clientId: 'Client ID',
  clientIdNull: 'Client ID cannot be empty.',
  clientIdInvalid: 'Invalid Client Id.',
  clientKey: 'Client Key',
  clientKeyNull: 'Client Key cannot be empty.',
  clientSecret: 'Client Secret',
  clientSecretNull: 'Client Secret cannot be empty.',
  clientSecretContainsIllegalCharacters: 'Client Secret contains illegal characters.',
  cicd: 'CI/CD',
  cicdsecurityCriteria: 'CI/CD Security Criteria',
  cisa: 'CISA',
  cisaKevExploit: 'CISA Kev Exploit',
  chooseALabel: 'Choose a Label',
  chooseAProduct: 'Choose a Product',
  chooseAScanner: 'Choose a Scanner',
  chooseATeam: 'Choose a Team',
  crammd5: 'CRAM MD5',
  create: 'Create',
  created: 'Created',
  createdDate: 'Created Date',
  createdAt: 'Created At',
  createdBy: 'Created By',
  createdOn: 'Created On',
  createNewIssue: 'Create New Issue',
  createProduct: 'Create Product',
  createProject: 'Create Project',
  createProjects: 'Create Projects',
  createProfiles: 'Create Profiles',
  createNewProjects: 'Create New Projects',
  createNewTeam: 'Create New Team',
  createNewProduct: 'Create New Product',
  createNewLabel: 'Create New Label',
  createNewBusinessUnit: 'Create New Business Unit',
  createScan: 'Create Scan',
  createNewScan: 'Create New Scan',
  createProjectTooltip: 'Create a new project both on Kondukto and on the scanner.',
  createProjectTooltip2: 'Create a new project both on Kondukto and on the scanner.' +
    ' Please enter source control info to activate this feature.',
  createVulnerabilities: 'Create Vulnerabilities',
  createUsers: 'Create Users',
  createNewUsers: 'Create New Users',
  createTeam: 'Create Team',
  criteria: 'Criteria',
  criteriaNull: 'Criteria cannot be empty.',
  criticality: 'Criticality',
  cspm: 'CSPM',
  cnapp: 'CNAPP',
  codebashing: 'Codebashing',
  code: 'Code',
  codeAssets: 'Code Assets',
  codeOwner: 'Code Owner',
  codeOwnerTooltip: 'Is the person who wrote the vulnerable line of code.',
  codeLine: 'Code Line',
  codeLocation: 'Code Location',
  codeInvalid: 'Invalid code.',
  codeNull: 'Code cannot be empty.',
  codePushed: 'Code Pushed',
  codePushedEvent: 'Code Push Event',
  codeFlow: 'Code Flow',
  codeql: 'CodeQL',
  codeqlScan: 'CodeQL Scan',
  choose: 'Choose',
  collection: 'Collection',
  collectionNull: 'Collection cannot be empty.',
  collections: 'Collections',
  collectionsNull: 'Collections cannot be empty.',
  color: 'Color',
  colorNull: 'Color cannot be empty.',
  collaboration: 'Collaboration',
  configPath: 'Config Path',
  confirmNewPassword: 'Confirm New Password',
  confirmNewPasswordNull: 'Confirm new password cannot be empty',
  confirmNewPasswordLength: 'Confirm new password should contain at least 8 or most 30 characters.',
  consolidated: 'Consolidated',
  condition: 'Condition',
  completed: 'Completed',
  confidence: 'Confidence',
  components: 'Components',
  componentName: 'Component Name',
  componentVersion: 'Component Version',
  componentType: 'Component Type',
  comment: 'Comment',
  commentNull: 'Comment cannot be empty.',
  commentTooLong: 'Remediation Details can be max length 1500.',
  committer: 'Committer',
  committers: 'Committers',
  committerBenchmark: 'Committer Benchmark',
  committerBenchmarkDetails: 'Committer Benchmark Details',
  committerBenchmarkInfo: 'Track the number of SAST and IAC vulnerabilities' +
    ' created by your developers within the selected period.',
  committerIsKnowText: 'Committer when committer is known',
  committerIsUnKnowText: 'Used when committer is unknown. If the team in this project has' +
    ' an issue responsible, specific user selected here will supersede.',
  committerNull: 'Specific User cannot be empty',
  committerNot: 'Please enter a valid username',
  compare: 'Compare',
  comparison: 'Comparison',
  concurrency: 'Concurrency',
  connectionFailed: 'Connection failed',
  connectionSuccessful: 'Connection successful',
  container: 'Container',
  contrastsca: 'Contrast SCA',
  contrastscaScan: 'Contrast SCA Scan',
  contrastiast: 'Contrast IAST',
  contrastiastScan: 'Contrast IAST Scan',
  copied: 'Copied',
  copyToClipboard: 'Copy to clipboard',
  codethreat: 'Code Threat',
  codethreatScan: 'Code Threat Scan',
  coverity: 'Coverity',
  coverityBranch: 'Coverity Branch',
  coverityBranchNull: 'Coverity branch cannot be empty.',
  coverityScan: 'Coverity Scan',
  credentials: 'Credentials',
  critical: 'Critical',
  cs: 'CS',
  csPriorityList: 'CS Priority List',
  csPriorityListNull: 'CS Priority List cannot be empty.',
  creator: 'Creator',
  creatorNull: 'Creator cannot be empty.',
  customDate: 'Custom Date',
  currentPassword: 'Current Password',
  currentPasswordNull: 'Current password cannot be empty',
  currentPasswordMinLength: 'Current password cannot be empty',
  customIssueCriteria: 'Custom Issue Criteria',
  customTemplateS: 'Custom Template(s)',
  customsource: 'Custom TI DB',
  customsourceInfo: 'If the toggle below is not enabled the associated' +
    ' risk rating will be displayed on the interface without having an impact on the severity of vulnerabilities.',
  customsourceInfo2: 'Integrate Kondukto with custom threat intelligence databases you might' +
    '  be using in the organization.',
  cwe: 'CWE',
  cweDescription: 'CWE Description',
  cweLink: 'CWE Link',
  cweCount: 'Cwe Count',
  cweId: 'CWE ID',
  cweIdOrName: 'CWE ID or Name',
  cweIdNull: 'CWE ID cannot be empty.',
  cweLevelNull: 'CWE cannot be empty.',
  cweName: 'CWE Name',
  cve: 'CVE',
  cveId: 'CVE ID',
  cveIdInValid: 'CVE ID not valid.',
  cveLink: 'CVE Link',
  cveName: 'CVE Name',
  cveDescription: 'CVE Description',
  cvss: 'CVSS',
  cvssMapping: 'CVSS Mapping',
  cvssVector: 'CVSS Vector',
  cvssV3: 'CVSS V3',
  cyclonedx: 'CycloneDX',
  cxsast: 'CxSAST',
  cxsastScan: 'CxSAST Scan',
  count: 'Count',
  countNull: 'Count cannot be empty.',
  customImageRegistry: 'Custom Image Registry',
  daily: 'Daily',
  dashboard: 'Dashboard',
  dashboardFilters: 'Dashboard Filters',
  dashboardCharts: 'Dashboard Charts',
  dast: 'DAST',
  dastsca: 'DAST+SCA',
  date: 'Date',
  dateAdded: 'Date added',
  dateCreated: 'Date Created',
  dateDiscovered: 'Date Discovered',
  dateDiscoveredNull: 'Date Discovered cannot be empty.',
  dateImported: 'Date Imported',
  dateRange: 'Date Range',
  dayS: 'Day(s)',
  days: 'Days',
  daySNull: 'Day(s) cannot be empty.',
  daysNull: 'Days cannot be empty.',
  deactivate: 'Deactivate',
  defineBusinessCriticality: 'Define Business Criticality',
  defineNewRule: 'Define New Rule',
  defineRuleNull: 'Define Rule cannot be empty.',
  definedSourceBranch: 'Defined Source Branch',
  description: 'Description',
  descriptionNull: 'Description cannot be empty.',
  destination: 'Destination',
  detailedExport: 'Detailed Export',
  detailedExportInfo: 'Exports all vulnerability properties including the ones not listed as columns.' +
    '  This export type will take longer to complete.',
  details: 'Details',
  delete: 'Delete',
  deleteComment: 'Delete Comment',
  deleteEndpoint: 'Delete Endpoint',
  deleteInstance: 'Delete Instance',
  deleteProjects: 'Delete Projects',
  deleteVulns: 'Delete Vuln(s)',
  deleteAllVulnsWarning: 'All vulnerabilities related to this scan parameter' +
    ' will also be deleted. Are you sure to proceed?',
  deActiveScannerWarning: 'All vulnerabilities identified by this scanner will be deleted.' +
    ' Are you sure to proceed ?',
  deActiveScannerAndInstancesWarning: 'All vulnerabilities identified by this scanner,' +
  ' associated scanner instances and their vulnerabilities will be deleted.' +
  ' Are you sure to proceed ?',
  deletedSuccessfully: 'Deleted Successfully',
  demo: 'Demo',
  demohub: 'Demo Hub',
  denialOfService: 'Denial of Service',
  dependencycheck: 'Dependency Check',
  dependencycheckScan: 'Dependency Check Scan',
  dependencycheckScanDescription: 'Description for Dependency Check scan.',
  dependencyFile: 'Dependency File',
  dependencyName: 'Dependency Name',
  dependabot: 'Dependabot',
  dependabotScan: 'Dependabot Scan',
  deployment: 'Deployment',
  default: 'Default',
  defaultBranch: 'Default Branch',
  defaultBranchInfo: 'When source control is enabled, default branch is automatically fetched from the ALM.',
  defaultBranches: 'Default Branches',
  defaultBranchNull: 'Default Branch cannot be empty.',
  defaultBranchPattern: 'Default Branch Pattern',
  defaultBranchPatternNull: 'Invalid default branch pattern.',
  defaultBranchPatternInfo: 'The dashboard metrics are calculated by considering the' +
    ' default branches. Enter a pattern here to update your default branch in each scan.',
  developer: 'Developer',
  deactivated: 'Deactivated',
  defaultPreset: 'Default Preset',
  defaultPresetNull: 'Default Preset cannot be empty.',
  defaultTeam: 'Default Team',
  defaultTeamInfo: 'Select the team that will automatically be assigned to newly created' +
    ' projects (through CLI or ALM import) under this product.',
  development: 'Development',
  dismissed: 'Dismissed',
  dnsName: 'DNS Name',
  domain: 'Domain',
  domainNull: 'Domain cannot be empty.',
  dockerImage: 'Docker Image',
  dockerRegistryPath: 'Docker registry path',
  dockerRegistryPathExampleHelp: 'Must be of the form path:tag',
  dockerRegistryPathAlreadyExists: 'Docker registry path already exists.',
  dockerRegistryPathPlaceholder: 'i.e registry.company.com/ubuntu:latest, alpine/httpie:2.2.0',
  dockerRegistryPathNull: 'Docker registry path can not be empty',
  documentation: 'Documentation',
  download: 'Download',
  downloadSample: 'Download Sample',
  downloadAsPdf: 'Download as PDF',
  downloadAsPng: 'Download as PNG',
  domainIp: 'Domain / Ip',
  done: 'Done',
  duration: 'Duration',
  durationOutsideLimits: 'Duration should be between 1 and 600',
  scanDuration: 'Scan duration',
  scanDurationInHours: 'Duration in hours',
  durationNull: 'Duration can not be empty.',
  duplicates: 'Duplicates',
  deduplication: 'Deduplication',
  duplicatesForEachScan: 'Duplicates for each scan',
  dueDate: 'Due Date',
  direct: 'Direct',
  disable: 'Disable',
  disabled: 'Disabled',
  discoveredBy: 'Discovered By',
  discoveredByNull: 'Discovered By cannot be empty.',
  directlyImportFiles: 'Directly import files generated by your commercial scanners.',
  directLink: 'Direct Link',
  edit: 'Edit',
  editComment: 'Edit Comment',
  editPreset: 'Edit Preset',
  editEndpoint: 'Edit Endpoint',
  editTeam: 'Edit Team',
  editTeamDescription: 'Edit selected team.',
  editUser: 'Edit User',
  editUserDescription: 'Edit selected user.',
  editProduct: 'Edit Product',
  editProductDescription: 'Edit selected product.',
  editSLARule: 'Edit SLA Rule',
  email: 'E-mail',
  emailAlreadyExists: 'Email already exists.',
  emailNull: 'E-mail cannot be empty',
  emailInvalid: 'Invalid email.',
  emailRecipients: 'Additional Email Recipients',
  emailRecipientsInvalid: 'Invalid email in recipients.',
  emailRecipientsNull: 'Recipients cannot be empty.',
  emailIntegrationShouldBeActivated: 'E-mail integration should' +
    ' be activated for the report to be sent properly.',
  empty: 'Empty',
  end: 'End',
  entity: 'Entity',
  entityNull: 'Entity cannot be empty.',
  entityId: 'Entity ID',
  entityIdNull: 'Entity ID cannot be empty.',
  enable: 'Enable',
  enableForSast: 'Enable for SAST',
  enableForIaC: 'Enable for IaC',
  enabled: 'Enabled',
  endNull: 'End cannot be empty',
  endDate: 'End Date',
  endDateNull: 'End Date cannot be empty.',
  endpoint: 'Endpoint',
  endpoints: 'Endpoints',
  endpointsNull: 'Endpoints cannot be empty.',
  endpointInputInfo: 'Endpoints are application\'s data entry points where dynamic application' +
    ' security tools send their payload such as https://target.com/api/v1/auth.',
  enterPathInfoExample: 'Enter path info as comma separated values, e.g: ',
  enterTemplateInfoExample: 'Enter template directory/file paths' +
    '(as comma separated values) relative' +
    ' to the nuclei-templates folder(nuclei-templates folder' +
    ' is downloaded from projectdiscovery/nuclei repo), e.g: ',
  enterTemplateInfoExampleContinued: 'Default value "All" creates a scan using all templates',
  enterUrlInfoExample: 'Enter list of URLs as comma separated values, e.g: ',
  entryType: 'Entry Type',
  entryTypeNull: 'Entry Type cannot be empty.',
  enableEmailToInviteUser: '(Enable mail to invite user)',
  enableToAssignIssuesOnYourIssueManager: 'Enable to assign issues on your issue manager.',
  enableToCreateProjectsOnCheckmarxViaKondukto: 'Enable for certain situations where' +
    ' credentials might be needed such as cloning projects and creating projects' +
    ' on some commercial scanners via Kondukto.',
  engagement: 'Engagement',
  engagementNull: 'Engagement cannot be empty.',
  engines: 'Engines',
  enginesNull: 'Engines cannot be empty.',
  equalTo: 'Equal to',
  epss: 'EPSS',
  epssInfo: 'If the toggle below is not enabled, the EPSS probability, percentile and the associated' +
    ' risk rating will be displayed on the interface without having an impact on the severity of vulnerabilities.',
  epssPercentile: 'EPSS Percentile',
  epssPercentage: 'EPSS %',
  epssProceedInfo: 'This is an irreversible action and severities of vulnerabilities will not be reverted to' +
    ' their original state even if you disable this feature. Are you sure to proceed?',
  event: 'Event',
  eventName: 'Event Name',
  eventCategory: 'Event Category',
  every: 'every',
  everyMonth: 'Every month',
  elevationOfPrivilege: 'Elevation of Privilege',
  eshard: 'eShard esChecker',
  eshardScan: 'eShard esChecker Scan',
  eslint: 'Eslint',
  eslintScan: 'Eslint Scan',
  exclude: 'Exclude',
  excludeClosed: 'Exclude closed',
  excludePaths: 'Exclude paths',
  excludePathsInvalid: 'Invalid path(s)',
  excludedmanually: 'Manually removed',
  excludeQuality: 'Exclude quality findings',
  export: 'Export',
  exportType: 'Export Type',
  exportVulnMessage: 'There is an ongoing export that needs to be completed before exporting a new file.',
  exportVulnMessage2: 'Your download will start as soon as the export is ready even if you close this window.',
  expirationDate: 'Expiration Date',
  expirationDateFrom: 'From',
  expirationDateTo: 'To',
  expired: 'Expired',
  expiryDate: 'Expiry Date',
  expiredDateNull: 'Expiry Date cannot be empty',
  expiresOn: 'Expires On',
  exploit: 'Exploit',
  exploitable: 'Exploitable',
  environment: 'Environment',
  environments: 'Environments',
  environmentBreakdown: 'Environment Breakdown',
  environmentBreakdownInfo: 'Displays the distribution of open vulnerabilities across different environments.',
  error: 'Error',
  extend: 'Extend',
  extraInspectorTool: 'Extra Inspector Tool',
  family: 'Family',
  fail: 'Fail',
  failed: 'Failed',
  failedOn: 'Failed On',
  failedDate: 'Failed Date',
  failedScans: 'Failed Scans',
  failedSaveAdvancedSettings: 'Failed to save advanced settings',
  false: 'False',
  falsePositive: 'False Positive',
  falsepositive: 'False Positive',
  falsepositiveNull: 'False Positive cannot be empty.',
  falsePositiveStatus: 'False Positive Status',
  fpMarkDate: 'FP Mark Date',
  fetchProjects: 'Fetch Projects',
  fetchInfraProfiles: 'Fetch Infra Profiles',
  fetchUsers: 'Fetch Users',
  feature: 'Feature',
  featuredevelopment: 'Feature + Development',
  featureBranchMgmt: 'Feature Branch Mgmt.',
  featureEnvironment: 'Feature Environment',
  fbBulkActionSummary: 'FP Bulk Action Summary',
  float64: 'Float64',
  fp: 'FP',
  tp: 'TP',
  fpStatus: 'FP Status',
  fieldsInKondukto: 'Fields in Kondukto',
  fieldsInFile: 'Fields In File',
  fixable: 'Fixable',
  finished: 'Finished',
  filter: 'Filter',
  filterNull: 'Filter cannot be empty',
  file: 'File',
  fileNull: 'File cannot be empty',
  files: 'Files',
  filename: 'File Name',
  filenames: 'File Names',
  filesnamesInfo: 'Enter file names Kondukto should check for in vulnerabilities alongside paths',
  fileName: 'File/Path',
  filePath: 'File/Path/Resource',
  fileNameNull: 'File name cannot be empty',
  fileShouldBeUploaded: 'File should be uploaded before saving',
  findings: 'Findings',
  findsecbugs: 'Find Security Bugs',
  findsecbugsScan: 'Find Security Bugs Scan',
  findsecbugsScanDescription: 'Description for Find Security Bugs Scan',
  firstSeen: 'First Seen',
  firstSeenFrom: 'First Seen (From)',
  firstSeenTo: 'First Seen (To)',
  fixedVersion: 'Fixed Version',
  fields: 'Fields',
  fullName: 'Full Name',
  footerText: 'Kondukto.io © 2024',
  fourme: '4me',
  forked: 'Forked',
  forceScan: 'Force Scan',
  fortify: 'Fortify',
  fortifyScan: 'Fortify Scan',
  fortifyod: 'Fortify On Demand SAST',
  fortifyodScan: 'Fortify On Demand SAST Scan',
  fortifyoddast: 'Fortify On Demand DAST',
  fortifyoddastScan: 'Fortify On Demand DAST Scan',
  fortifyScanDescription: 'Description for Fortify Scan',
  forkScan: 'Fork Scan',
  forkScanInfo: 'The vulnerabilities discovered in the branch specified above will' +
    ' be compared against the vulnerabilities in the default branch to remove those ' +
    ' that have been previously suppressed.',
  forkDefaultBranch: 'Fork Default Branch',
  forkSourceBranch: 'Fork Source Branch',
  foundYesterday: 'Found Yesterday (New)',
  friday: 'Friday',
  from: 'From',
  fromTo: 'From - To',
  fqdn: 'FQDN',
  generate: 'Generate',
  generatedAt: 'Generated At',
  generatedAtNull: 'Generated At cannot be empty.',
  generatedDate: 'Generated Date',
  generateToken: 'Generate Token',
  generateNewToken: 'Generate New Token',
  generateReportInformation: 'Last report date option is' +
    ' unavailable as there have been no reports generated so far.',
  generator: 'Generator',
  generatorTool: 'Generator Tool',
  generatorToolNull: 'Generator Tool cannot be empty.',
  git: 'Git',
  gitMatcher: 'Git Matcher',
  github: 'GitHub',
  githubenterprise: 'GitHub Enterprise',
  gitsecret: 'Github Secret Scanner',
  gitsecretScan: 'Github Secret Scanner Scan',
  githubLabels: 'Github Labels',
  gitguardian: 'GitGuardian',
  gitguardianScan: 'GitGuardian Scan',
  gitlab: 'GitLab',
  gitlabcloud: 'GitLab Cloud',
  gitlabonprem: 'GitLab On-prem',
  gitlabLabels: 'GitLab Labels',
  gitleaks: 'Gitleaks',
  gitleaksScan: 'Gitleaks Scan',
  gitleaksScanDescription: 'Description for Gitleaks scan.',
  gosec: 'Gosec',
  gosecScan: 'Gosec Scan',
  gosecScanDescription: 'Description for Gosec Scan',
  goToProjectList: 'Go to Project List',
  goToAssetsMgmt: 'Go to Assets Mgmt.',
  googlesso: 'Google',
  globalConfigurations: 'Global Configurations',
  globalPresets: 'Global Presets',
  globalRules: 'Global Rules',
  globalSettings: 'Global Settings',
  goToUserList: 'Go to User List',
  goToScanQueue: 'Go to Scan Queue',
  goToLogin: 'Go to Login',
  greaterThan: 'Greater than',
  groups: 'Groups',
  groupName: 'Group Name',
  groupingIssues: 'Grouping Issues',
  groupingIssuesText: 'Group SCA findings with the same component into one issue.',
  groupingIssuesInfo: 'By default, Kondukto creates separate issues for each SCA finding.' +
    ' Enable this checkbox if you\'d like to reduce the number of issues on your issue tracker.',
  grype: 'Grype',
  grypeScan: 'Grype Scan',
  hackerone: 'HackerOne',
  hackeroneScan: 'HackerOne Scan',
  hasFix: 'Has Fix',
  hash: 'Hash',
  hasInternetExposure: 'Has Internet Exposure',
  hasInternetExposureStatus: 'Has Internet Exposure Status',
  header: 'Header',
  help: 'Help',
  high: 'High',
  highAccuracyForSimilarity: 'High accuracy for similarity',
  highlightedVulns: 'Highlighted Vulnerabilities',
  highlightedVulnerabilities: 'Highlighted Vulnerabilities',
  historicalStatus: 'Historical Status',
  hourly: 'Hourly',
  hostUrl: 'Host (URL)',
  hostUrlNull: 'Host (URL) cannot be empty',
  hostUrlInvalid: 'Invalid host URL.',
  hostFqdn: 'Host Fqdn',
  hourS: 'Hour(s)',
  hours: 'Hours',
  hclappscan: 'HCL AppScan(Enterprise)',
  hclappscanScanDescription: 'Description for HCL AppScan',
  httpRequest: 'HTTP Request',
  httpResponse: 'HTTP Response',
  httpHeaders: 'HTTP Headers',
  iac: 'IaC',
  iast: 'IAST',
  ibmappscan: 'IBM AppScan',
  ibmappscansource: 'IBM AppScan Source',
  id: 'ID',
  idNull: 'ID cannot be empty.',
  idInvalid: 'Invalid ID.',
  image: 'Image',
  imageNull: 'Image cannot be empty.',
  imageDetails: 'Image Details',
  imageDetailsError: 'Failed to get image details. Image might not exist on the server',
  images: 'Images',
  impersonateUserEmail: 'Impersonate User Email',
  impersonateUserEmailNull: 'Impersonate User Email cannot be empty.',
  impersonateUserEmailInvalid: 'Invalid Impersonate User email.',
  instance: 'Instance',
  instanceNull: 'Instance cannot be empty.',
  instanceName: 'Instance Name',
  instanceNameNull: 'Instance name tag cannot be empty.',
  includeEmptyScope: 'Include Empty Scope',
  includeEmptyScopeInfo: 'Enable to include SAST, SCA and IAC vulnerabilities with no path in this project.',
  includeFalsePositiveStatusAndComment: 'Include false positive status and comment',
  includeTruePositiveStatusAndComment: 'Include true positive status and comment',
  includeRiskAcceptedStatusAndComment: 'Include risk accepted status and comment',
  includeGeneralCommentsOnVulnerabilities: 'Include general comments on vulnerabilities',
  info: 'Info',
  informationDisclosure: 'Information Disclosure',
  infraProfiles: 'Infra Profiles',
  infraProfilesInfo: 'Create scan profiles to trigger scans or fetch vulnerabilities from an infra scanner.',
  infraGroups: 'Infra Groups',
  infraGroupsInfo: 'Create groups of vulnerabilities that can be associated with projects to place' +
    ' infra vulnerabilities under relevant projects.',
  infraGroupName: 'Infra Group Name',
  infrastructure: 'Infrastructure',
  infrastructureVulnerabilities: 'Infrastructure Vulnerabilities',
  infrastructureVulnerabilitiesDescription: 'Infrastructure Vulnerabilities Description',
  infraScannerManagement: 'Infra Scanner Management ',
  importType: 'Import Type',
  importTypeNull: 'Import Type cannot be empty.',
  importInfraVulnsSuccessfully: 'Import Infrastructure Vulnerabilities Successfully',
  importedBy: 'Imported By',
  integration: 'Integration',
  integrations: 'Integrations',
  integrationOpenSourceDesc: 'Make sure application lifecycle management' +
    ' integration is activated for this scanner to work properly.',
  integrateWithOnPremisesVersion: 'Integrate with on-premises version.',
  insecure: 'Insecure',
  insecureInfo: 'Check the box to skip verification of the certificate used.',
  inspector: 'Inspector',
  inspectorTool: 'Inspector Tool',
  includedmanually: 'Manually added',
  int: 'Int',
  incremental: 'Incremental',
  isIncremental: 'Is Incremental',
  inScopeOnly: 'In scope only',
  ip: 'IP',
  ipAddress: 'IP Address',
  ipAddresses: 'IP Address',
  ipNull: 'IP cannot be empty.',
  ipInvalid: 'Invalid IP.',
  ipCidr: 'IP CIDR',
  ipHostName: 'IP/Hostname',
  ipHostNameNull: 'IP/Hostname cannot be empty.',
  ipHostNameInvalid: 'Invalid IP/Hostname.',
  issue: 'Issue',
  issues: 'Issues',
  issuer: 'Issuer',
  issueAssignee: 'Issue Assignee',
  issueAssignment: 'Issue Assignment',
  issueAssignmentEnabledDescription: 'Enable to assign issues on your issue manager.',
  issueAssignModalTitle: 'will be assigned an issue.',
  issueSourceProject: 'Issue Source Project',
  issuerNull: 'Issuer cannot be empty.',
  issuesAssigned: 'Vulns. with an open issue',
  issueCriteriaInfo: 'You can use issue criteria to automate the' +
    ' issue assignment process for certain types of vulnerabilities.',
  issueCriteriaEnabledDescOne: 'There is a default issue criteria entered at a' +
    ' global level.',
  issueCriteriaAssociatedWithLabels: 'Issue criteria associated' +
    ' with the following label(s) are applied: ',
  issueClosedDate: 'Issue Closed Date',
  issueClosureDate: 'Issue Closure Date',
  issueCreatedDate: 'Issue Created Date',
  issueCreationDate: 'Issue Creation Date',
  issuesEvent: 'Issues Event',
  issuesEvents: 'Issues Events',
  issueCategories: 'Issue Categories',
  issueCategoriesNull: 'Issue Categories cannot be empty.',
  issueSubCategories: 'Issue Subcategories',
  issueSubCategoriesNull: 'Issue Subcategories cannot be empty.',
  issueServices: 'Issue Services',
  issueServicesNull: 'Issue Services cannot be empty.',
  issueServiceInstances: 'Issue Service Instances',
  issueServiceInstancesNull: 'Issue Service Instances cannot be empty.',
  issueTemplates: 'Issue Templates',
  issueTemplatesNull: 'Issue Templates cannot be empty.',
  issueCanNotBeAssignedToInfoLevel: "Can not be assigned an issue if severity is 'Info'",
  issueCriteria: 'Issue Criteria',
  issueCriteriaNull: 'Issue Criteria cannot be empty.',
  issueCriteriaDescription: 'The next two steps are required for automatically opening issues for' +
    ' vulnerabilities. You can skip them if you prefer to open issues manually.',
  issueUpdate: 'Issue Update',
  issueUpdateEvent: 'Issue Update Event',
  issueManager: 'Issue Manager',
  issueManagerNotConnected: 'The project does not have an active issue manager.',
  issueManagerNull: 'Issue manager cannot be empty.',
  issueManagers: 'Issue Managers',
  issuePath: 'Issue Path',
  issuePathNull: 'Issue path cannot be empty.',
  issuePathInvalid: 'Invalid Issue path.',
  issueTitlePlaceholder: 'Issue title in the issue manager (optional)',
  issueTracker: 'Issue Tracker',
  issueTrend: 'Issue Trend',
  issueTrendInfo: 'Displays the evolution of the status (open, in progress, closed)' +
    ' of issues created over the last 30 days.',
  issueType: 'Issue Type',
  issueId: 'Issue ID',
  issueUrl: 'Issue URL',
  issueStatus: 'Issue Status',
  issueStatusOfTheVulnerabilityIsOpen: 'Issue status of the vulnerability is open',
  issueStatusOfTheVulnerabilityIsPending: 'Issue status of the vulnerability is pending',
  issueStatusOfTheVulnerabilityIsClosed: 'Issue status of the vulnerability is closed',
  issueStatusOfTheVulnerabilityIsInProgress: 'Issue status of the vulnerability is in progress',
  issueResponsible: 'Issue Responsible',
  issueResponsibleDescription: 'The team in this project already has an issue responsible.',
  issueResponsibleNull: 'Issue Responsible cannot be empty.',
  issueTypesNull: 'Issue types cannot be empty.',
  issueWIPDate: 'Issue WIP Date',
  issueLabel: 'Issue Label',
  imageId: 'Image ID',
  imageName: 'Image Name',
  infra: 'Infra',
  infraGroupNames: 'Infra Group Names',
  infraDuplicates: 'Infra Duplicates',
  infiniteRetention: 'Infinite Retention',
  include: 'Include',
  includePublicChannels: 'Include public channels',
  includeForkedProjects: 'Include forked projects',
  industryStandards: 'Industry Standards',
  industryStandardsInfo: 'Displays the distribution of vulnerabilities' +
  ' by industry standards such as OWASP Top-10, NIST, PCI etc.',
  inviteUser: 'Invite user?',
  inviteUserToTrainingTool: 'Invite user to training tool',
  inviteUserSuccessful: 'User invited successfully',
  import: 'Import',
  imports: 'Imports',
  importGlobalCriteria: 'Import Global Criteria',
  importGlobalPreset: 'Import Global Preset',
  importGlobalRule: 'Import Global Rule',
  importScanSuccessfully: 'Import Scan Successfully',
  importScan: 'Import Scan',
  importVulnerabilities: 'Import Vulnerabilities',
  inprogress: 'In Progress',
  installedVersion: 'Installed version',
  internal: 'Internal',
  internalIP: 'Internal IP',
  ivanti: 'Ivanti',
  jira: 'Jira',
  jiraStepInfo: 'Make sure the states chosen for opening, in progress and' +
    ' closing are linked to each other on Jira.',
  jiraIssueStateMapping: 'Jira Issue State Mapping',
  jiraOpenIssueOn: 'Issue status when opening issues:',
  jiraInProgressIssueOn: 'Issue status when in progress issues:',
  jiraCloseIssueOn: 'Issue status when closing issues:',
  jiraOpenIssueNull: 'Open issue can not be empty',
  jiraInProgressIssueNull: 'In Progress issue can not be empty',
  jiraCloseIssueNull: 'Close issue can not be empty',
  jiraUnassignedIssueNull: 'Unassigned issue can not be empty',
  jiraPendingIssueNull: 'Pending issue can not be empty',
  jiraCustomIssueLabel: 'Kondukto fields to use as a label on Jira',
  jfrogxraysca: 'JFrog Xray',
  jfrogxrayscaScan: 'JFrog Xray Scan',
  jotSomethingDown: 'Go on, jot something down that will help your devs',
  jumpto: 'Jump to',
  jumptoProject: 'Jump to project',
  jumptoProduct: 'Jump to product',
  jumptoUser: 'Jump to user',
  closedOn: 'Closed On',
  kondukto: 'Kondukto',
  konduktoProjects: 'Kondukto Projects',
  key: 'Key',
  keyId: 'Key ID',
  keyIdNull: 'Key ID cannot be empty.',
  kdt: 'KDT',
  knownExploit: 'Known Exploit',
  laceworkcspm: 'Lacework CSPM',
  laceworkcspmScan: 'Lacework CSPM Scan',
  laceworkinfra: 'Lacework Infra',
  laceworkinfraScan: 'Lacework Infra Scan',
  laceworkcs: 'Lacework CS',
  laceworkcsScan: 'Lacework CS Scan',
  language: 'Language',
  languages: 'Languages',
  languageNull: 'Language cannot be empty.',
  languageEnvironment: 'Language Environment',
  languageEnvironmentNull: 'Language Environment cannot be empty.',
  languageStats: 'Language stats',
  ldapKey: 'Ldap Key',
  ldapKeyNull: 'Ldap Key cannot be empty.',
  ldapCrt: 'Ldap CRT',
  ldapCrtNull: 'Ldap CRT cannot be empty.',
  label: 'Label',
  labels: 'Labels',
  labelNull: 'Label cannot be empty.',
  labelsNull: 'Labels cannot be empty.',
  labelAlreadyExists: 'Label already exists.',
  labelsDescription: 'Associate labels with your projects for advanced filtering' +
    ' options and to create global rules for multiple projects quickly.',
  labelsAvailableOnJira: 'Labels available on Jira',
  labelsAndBusinessUnits: 'Labels & Business Units',
  lastScanDate: 'Last Scan Date',
  lastScan: 'Last Scan',
  lastScanned: 'Last Scanned',
  lastScannedBy: 'Last Scanned By',
  lastScanIsNull: 'Last scan days cannot be empty',
  lastScanInvalid: 'Last scan days not valid',
  lastRecurringDate: 'Last recurring date',
  lastSeen: 'Last Seen',
  lastStatusUpdate: 'Last Status Update',
  lastUpdated: 'Last updated',
  lastUpdatedDate: 'Last Updated Date',
  lastUpdateOn: 'Last Update On',
  lastestRiskScore: 'Latest Risk Score',
  lastCheckedOn: 'Last Checked On',
  lastPushOwner: 'Last Push Owner',
  lastPushOwnerTooltip: 'Is the person who pushed a commit to the file' +
    '  where the vulnerability is located.',
  latestFindings: 'Latest Findings',
  ldap: 'LDAP',
  ldapLogin: 'LDAP Login',
  ldapUsernameInfo: 'LDAP user who has at least read-only administrator privilege',
  ldapObjectClassInfo: 'LDAP ObjectClass property name identifying employees or users.',
  learnMore: 'Learn More',
  lessThan: 'Less than',
  library: 'Library',
  line: 'Line',
  lineNumber: 'Line Number',
  lineNumberNull: 'Line Number cannot be empty.',
  link: 'Link',
  linkToAnExistingIssue: 'Link To An Existing Issue',
  linkNull: 'Link cannot be empty.',
  login: 'Log in',
  loginWithOkta: 'Login with Okta',
  loginWithAzure: 'Login with Azure Active Directory',
  loginWithGoogle: 'Login with Google',
  loginWithAzureAD: 'Login with Azure AD',
  loginWithSaml: 'Login with SAML',
  loginErrorNotCorrect: 'Username or password not correct. Please try again.',
  loginErrorTitle: 'Login Error',
  loginMethod: 'Login Method',
  logs: 'Logs',
  low: 'Low',
  license: 'License',
  licenseNull: 'License cannot be empty.',
  licenses: 'Licenses',
  licensedAdmins: 'Licensed Admins',
  licensedProjects: 'Licensed Projects',
  licensedUsers: 'Licensed Users',
  mandiant: 'Mandiant',
  management: 'Management',
  maintainabilityMetrics: 'Maintainability Metrics',
  manager: 'Manager',
  mark: 'Mark',
  markAsFalsePositive: 'Mark as False Positive',
  markAsTruePositive: 'Mark as True Positive',
  markAsWontfix: 'Mark as Won\'t Fix',
  markAsMitigated: 'Mark as Mitigated',
  mail: 'Email',
  manual: 'Manual',
  manuallyImported: 'Manually Imported',
  manuallyAddedVulnerabilities: 'Manually Added Vulnerabilities',
  macro: 'Macro',
  macroNull: 'Macro can not be empty',
  macroName: 'Macro Name',
  macroNameNull: 'Macro Name can not be empty',
  macroType: 'Macro Type',
  macroTypeNull: 'Macro type can not be empty',
  mast: 'MAST',
  matchingRule: 'Matching Rule',
  mattermost: 'Mattermost',
  maximum: 'Maximum',
  maxValue: 'Max. Value',
  meanTimeToFix: 'Mean time to remediate in days',
  meanTimeToFixDesc: 'Average time to close the closed vulnerabilities.',
  median: 'Median',
  medianRiskScore: 'Median Risk Score',
  medium: 'Medium',
  members: 'Members',
  membersNull: 'Members cannot be empty.',
  merged: 'Merged',
  mergeTotal: 'Merge Total',
  mergeRequestEvents: 'Merge Request Events',
  message: 'Message',
  metaData: 'Meta Data',
  metaDataAlreadyExists: 'Meta data already exists.',
  metaDataInfo: '* Use a new metadata to add new vulnerabilities to a project.',
  metaDataInfo2: '* Use an existing metadata to compare discovered vulnerabilities' +
    ' against vulnerabilities discovered in previous scans.',
  metaDataNull: 'Meta data cannot be empty.',
  metaDataInvalid: 'Meta data invalid.',
  method: 'Method',
  methodNull: 'Method cannot be empty',
  mscore: 'M-Score',
  microsoftazuremail: 'Microsoft Azure Mail',
  microsoftTeams: 'Microsoft Teams',
  msdefendercloud: 'Microsoft Defender for Cloud',
  msdefendercloudScan: 'Microsoft Defender for Cloud Scan',
  minutes: 'Minutes',
  mitigated: 'Mitigated',
  mitigatedNull: 'Mitigated cannot be empty.',
  mitreAttackTactics: 'Mitre Attack Tactics',
  minValue: 'Min. Value',
  mobsfsast: 'MobSF SAST',
  mobsfsastScan: 'MobSF SAST Scan',
  monday: 'Monday',
  monthly: 'Monthly',
  monthlyByDay: 'Monthly by day',
  monthS: 'month(s)',
  mode: 'Mode',
  mtf: 'MTF',
  multiple: 'MULTIPLE',
  multipleORGAccess: 'Multiple ORG. Access',
  myTimeZone: 'My Timezone',
  myTimeZoneNull: 'My Timezone cannot be empty.',
  name: 'Name',
  nameSpace: 'Name Space',
  nameNull: 'Name cannot be empty.',
  nameInvalid: 'Name not valid.',
  nameLengthInvalid: 'Name must be minimum of 3 and maximum of 20 characters.',
  nameLengthInvalid2: 'Name must be minimum of 1 and maximum of 50 characters.',
  nameAlreadyExists: 'Name already exists.',
  nativeType: 'Native Type',
  nancy: 'Nancy',
  nancyScan: 'Nancy Scan',
  nancyScanDescription: 'Description for Nancy scan.',
  nessus: 'Nessus',
  nessuspro: 'Nessus Pro',
  nessusproScan: 'Nessus Pro Scan',
  nessusproScanDescription: 'Description for Nessus Pro scan.',
  netsparker: 'Invicti',
  netsparkerScan: 'Invicti Scan',
  never: 'Never',
  newVulnerabilities: 'New Vulnerabilities',
  newVulnerabilitiesInfo: 'Displays the number of vulnerabilities with a first seen date in the selected period.',
  netNewVulnerabilities: 'Net New Vulnerabilities',
  netNewVulnerabilitiesInfo: 'Displays the difference between the number of new and' +
    '  closed vulnerabilities in the selected period.',
  no: 'No',
  noAuth: 'No Auth',
  noProjectsIncluded: 'No projects included',
  none: 'None',
  noRetention: 'No Retention',
  notes: 'Notes',
  noteNull: 'Note cannot be empty.',
  noteTooLong: 'Remediation Details can be max length 1500.',
  notFound: 'Not found',
  notscanned: 'Not Scanned',
  notScannedSoFar: 'Not scanned so far',
  notSelected: 'Not Selected',
  notifying: 'Notifying',
  notification: 'Notification',
  notifications: 'Notifications',
  notificationCriteria: 'Notification Criteria',
  notificationChannel: 'Notification channel',
  notificationChannelNull: 'Notification channel cannot be empty.',
  notificationsForCompletedScans: 'Receive notifications for all completed scans',
  notificationsForFailedScans: 'Receive notifications for all failed scans',
  notificationTool: 'Notification Tool',
  notificationTools: 'Notification Tools',
  notValid: 'Not Valid',
  notApplicable: 'Not Applicable',
  noOfConcurrentImports: 'No. of concurrent imports',
  noOfConcurrentImportsNull: 'No. of concurrent imports cannot be empty.',
  noOfConcurrentScans: 'No. of concurrent scans',
  noOfConcurrentScansNull: 'No. of concurrent scans cannot be empty.',
  noOfFindings: 'No of Findings',
  noOfProjects: 'No. of Projects',
  noSecurityCriteriaEntered: 'No security criteria entered',
  now: 'Now',
  netBiosName: 'Net Bios Name',
  new: 'New',
  newClosedTrend: 'New vs. Closed Trend',
  newClosedTrendInfo: 'Displays the number of new and closed vulnerabilities in each week of the selected period.',
  newWithIssue: 'New With Issue',
  newReccurent: 'New + Reccurent',
  newFindings: 'New Findings',
  newFindingsWithIssue: 'New Findings with Issue',
  newPassword: 'New Password',
  newPasswordNull: 'New password cannot be empty',
  newPasswordLength: 'Confirm new password should contain at least 8 or most 30 characters.',
  newPasswordRepeat: 'Retype new password',
  newProject: 'New Project',
  newTeam: 'New Team',
  next: 'Next',
  nist: 'NIST SP 800-53',
  nuclei: 'Nuclei',
  nucleiScan: 'Nuclei Scan',
  nucleiScanDescription: 'Description for Nuclei scan.',
  numberOfComponents: 'Number of Components',
  numberOfLastCommits: 'Number of Last Commits',
  numberOfLastCommitsNull: 'Number of commits cannot be empty',
  numberOfLastCommitsInfo: 'Defines the number of commits to check retrospectively for' +
    ' finding the committers of SAST vulnerabilities.' +
    'Every 500 commits approximately leads to 1 minute longer analysis time.',
  numberOfMembers: 'Number of Members',
  numberOfProjects: 'Number of Projects',
  numberNull: 'Number cannot be empty.',
  numberNotCorrect: 'Number not valid.',
  noData: 'NO DATA',
  nodejsscan: 'nodejsscan',
  nodejsscanScan: 'nodejsscan Scan',
  noExpiration: 'No Expiration',
  notOnboarded: 'Not onboarded',
  obsolete: 'Obsolete',
  onboarded: 'Onboarded',
  observedInTheWild: 'Observed In The Wild',
  objectClass: 'Object Class',
  objectClassNull: 'Object Class cannot be empty.',
  onDate: 'On date',
  onDayS: 'On day(s)',
  onDaySNull: 'On day(s) cannot be empty.',
  onlyTeamLead: 'Only Team Lead',
  occurenceS: 'Occurence(s)',
  operand: 'Operand',
  operatingSystem: 'Operating System',
  open: 'Open',
  openSource: 'Open Source',
  optional: ' (optional)',
  organization: 'Organization',
  organizationNull: 'Organization cannot be empty.',
  organizationName: 'Organization Name',
  organizationNameNull: 'Organization Name cannot be empty.',
  organizations: 'Organizations',
  organizationsNull: 'Organizations cannot be empty.',
  organizationRiskScore: 'Organization Risk Score',
  organizationVulnScore: 'Organization Vuln. Score',
  others: 'Others',
  overdue: 'Overdue',
  openai: 'Open AI',
  openaiInfo: 'Allow Kondukto to share code snippets with OpenAI',
  optionProfiles: 'Option Profiles',
  optionProfilesNull: 'Option Profiles cannot be empty.',
  os: 'OS',
  osvscannersca: 'OSV',
  osvscannerscaScan: 'OSV Scan',
  orgUrl: 'Org. URL',
  overrideDescription: 'Override Description',
  overrideDescriptionNull: 'Override Description cannot be empty.',
  overrideLabels: 'Override Labels',
  overrideLabelsNull: 'Override Labels cannot be empty.',
  overrideSummary: 'Override Summary',
  overrideSummaryNull: 'Override Summary cannot be empty.',
  overridePriority: 'Override Priority',
  overridePriorityNull: 'Override Priority cannot be empty.',
  osv: 'OSV',
  openToInternet: 'Open To Internet',
  owaspCategory: 'Owasp Category',
  owaspCategories: 'Owasp Categories',
  owasp2017Top10: 'OWASP (2017) Top-10',
  owasp2017Top10Null: 'OWASP (20217) Top-10 cannot be empty.',
  owasp2021Top10: 'OWASP (2021) Top-10',
  owasp2021Top10Null: 'OWASP (2021) Top-10 cannot be empty.',
  owaspCategoriesNull: 'Owasp category cannot be empty.',
  owaspTopTen: 'Owasp Top Ten',
  owaspzap: 'OWASP ZAP',
  owaspzapScan: 'OWASP ZAP Scan',
  owaspzapScanDescription: 'Description for OWASP ZAP scan.',
  owaspzapheadless: 'OWASP ZAP Headless',
  owaspzapheadlessScan: 'OWASP ZAP Headless',
  owaspzapheadlessScanDescription: 'Description for OWASP ZAP Headless scan.',
  owner: 'Owner',
  ownerNull: 'Owner cannot be empty.',
  ownerTeam: 'Owner Team',
  ownerTeamNull: 'Owner Team cannot be empty.',
  ok: 'OK',
  okta: 'Okta',
  oktaTokeInfo: 'API token is required to import users from Okta.',
  qualyscs: 'Qualys CS',
  qualyscsScan: 'Qualys CS Scan',
  qualyswas: 'Qualys Was',
  qualyswasScan: 'Qualys Was Scan',
  qualysinfra: 'Qualys VMDR',
  qualysinfraScan: 'Qualys VMDR Scan',
  quickFilters: 'Quick Filters',
  parasoft: 'Parasoft',
  parentOnly: 'Parent Only',
  parentChild: 'Parent - Child',
  parentWorkItemType: 'Parent Work Item Type',
  parentWorkItemName: 'Parent Work Item Name',
  parentWorkItemNameNull: 'Parent Work Item Name cannot be empty.',
  param: 'Param',
  packageS: 'Package(s)',
  package: 'Package',
  packages: 'Packages',
  packagesNull: 'Packages cannot be empty.',
  packageUrl: 'Package URL',
  packageName: 'Package Name',
  packageVersion: 'Package Version',
  packageFixedVersion: 'Package Fixed Version',
  page: 'Page',
  perPage: 'Per Page',
  password: 'Password',
  passwordInstructions: 'How to get an password',
  passwordContainsInvalidCharacters: 'Password contains invalid characters.',
  passwordNull: 'Password cannot be empty.',
  passwordsNotSame: 'Passwords are not the same.',
  passwordLength: 'Password should contain at least 8 and most 30 characters.',
  passwordRepeat: 'Retype password',
  passwordRepeatNull: 'Password repeat cannot be empty',
  passwordOrToken: 'Password or Token',
  passwordMustMatch: 'Passwords must match',
  path: 'Path',
  pending: 'Pending',
  permission: 'Permission',
  permissions: 'Permissions',
  personalAccessTokens: 'Personal Access Tokens',
  personalAccessTokenInformation: 'Make sure to copy your new personal' +
  ' access token now. You won’t be able to see it again!',
  personalAccessTokenInformation2: 'Tokens generated here can be used to access the Kondukto API.',
  pentest: 'Pen Test',
  pentester: 'Pentester',
  pci: 'PCI',
  pciNull: 'PCI cannot be empty.',
  platform: 'Platform',
  platformNull: 'Platform cannot be empty',
  playbook: 'Playbook',
  plain: 'Plain',
  pleaseActiveProjectsIssueAssignmentSettings: 'Please activate' +
   " project's “Issue Assignment” settings",
  plugin: 'Plugin',
  pluginName: 'Plugin Name',
  pluginId: 'Plugin ID',
  port: 'Port',
  portNull: 'Port cannot be empty',
  portInValid: 'Port not valid',
  policies: 'Policies',
  policiesNull: 'Policies cannot be empty.',
  pr: 'PR',
  previous: 'Previous',
  preset: 'Preset',
  presetName: 'Preset Name',
  presetNameNull: 'Preset Name cannot be empty.',
  presetNull: 'Preset cannot be empty.',
  priority: 'Priority',
  priorityMapping: 'Priority mapping',
  profile: 'Profile',
  profileName: 'Profile Name',
  profileNameNull: 'Profile Name cannot be empty.',
  profileId: 'Profile',
  profiles: 'Profiles',
  profilesNull: 'Profiles cannot be empty.',
  product: 'Product',
  productComponentName: 'Product Component Name',
  product_owner: 'Product Owner',
  productOwnerNull: 'Product Owner cannot be empty.',
  productNull: 'Product cannot be empty.',
  productInfo: 'Product Info',
  productName: 'Product Name',
  productNameAlreadyExists: 'Product name already exists.',
  productNameLower: 'Product name',
  productNameInvalid: 'Product name invalid.',
  productNameNull: 'Product name cannot be empty.',
  productNameTooShort: 'Product name too short.',
  productNotSelected: 'Please select a product.',
  productDetails: 'Product Details',
  products: 'Products',
  productsNull: 'Products cannot be empty.',
  productSelect: 'Please select a product.',
  productVulnScore: 'Product Vuln. Score',
  productRiskScore: 'Product Risk Score',
  production: 'Production',
  project: 'Project',
  projectId: 'Project ID',
  projectProfile: 'Project / Profile',
  projectCode: 'Project Code',
  projectCodeNotCorrect: 'Project code not valid.',
  projectCodeNull: 'Project code cannot be empty.',
  projectDetails: 'Project Details',
  projectWebhookNull: 'Project webhook cannot be empty.',
  projectComponents: 'Project Components',
  projectCounts: 'Project Counts',
  projectKey: 'Project Key',
  projectKeyNull: 'Project Key cannot be empty.',
  projectMgmt: 'Project Mgmt.',
  projectInfo: 'Project Info',
  projectInfoDescription: 'Please enter your project\'s info.',
  projectInfoSuccess: 'Updated Successfully',
  projectInfoError: 'Oops! Something went wrong!',
  projectName: 'Project Name',
  projectNameAlreadyExists: 'Project name already exists.',
  projectNameLower: 'Project name',
  projectNameNotCorrect: 'Project name not valid.',
  projectNameNull: 'Project name cannot be empty.',
  projectNameTooShort: 'Project name too short.',
  projectNull: 'Project cannot be empty.',
  projectRiskScore: 'Project Risk Score',
  projectVulnScore: 'Project Vuln. Score',
  projectType: 'Project Type',
  projects: 'Projects',
  projectsAndProducts: 'Projects & Products',
  projectsFailingASVS: 'Failing ASVS',
  projectsFailingSC: 'Failing CI/CD SC',
  projectsNull: 'Projects cannot be empty.',
  projectsScanned: 'Projects Scanned',
  projectsScan: 'Projects Scan',
  projectVersion: 'Project Version',
  projectVersionNull: 'Project Version cannot be empty.',
  program: 'Program',
  programNull: 'Program cannot be empty.',
  protocol: 'Protocol',
  protocolNull: 'Protocol cannot be empty.',
  proof: 'Proof',
  processing: 'Processing',
  privateRegistry: 'Private Registry',
  privateKey: 'Private Key',
  privateKeyNull: 'Private Key cannot be empty.',
  privateKeyId: 'Private Key ID',
  privateKeyIdNull: 'Private Key ID cannot be empty.',
  prismacloud: 'Prisma Cloud Compute',
  prismacloudScan: 'Prisma Cloud Compute Scan',
  prismacloudcspm: 'Prisma Cloud CSPM',
  prismacloudcspmScan: 'Prisma Cloud CSPM Scan',
  psalm: 'Psalm',
  psalmScan: 'Psalm Scan',
  pullRequest: 'Pull Request',
  pullRequestEvent: 'Push Event',
  pullRequestMergedEvent: 'Pull Request Merged Event',
  pullRequestMergeAttempted: 'Pull Request Merge Attempted',
  publicKey: 'Public Key',
  pullSelected: 'Pull selected',
  push: 'Push',
  pushes: 'Pushes',
  pushed: 'Pushed',
  pushEvent: 'Push Event',
  pushEvents: 'Push Events',
  publishDate: 'Publish Date',
  policy: 'Policy',
  raMarkDate: 'RA Mark Date',
  rapid7: 'Rapid7 InsightAppSec',
  rapid7Scan: 'Rapid7 InsightAppSec Scan',
  rapid7infra: 'Rapid7 Insight VM/Nexpose',
  rapid7infraScan: 'Rapid7 Insight VM/Nexpose Scan',
  recurrence: 'Recurrence',
  recurse: 'Recurse',
  refresh: 'Refresh',
  relatedEndpoints: 'Related Endpoints',
  recurrent: 'Recurrent',
  recurrentVulns: 'Recurrent Vulns.',
  recurrentVulnsInfo: 'Displays the number of vulnerabilities that recurred in the selected period.',
  redhatcs: 'Red Hat Advanced Cluster Security',
  redhatcsScan: 'Red Hat Advanced Cluster Security Scan',
  references: 'References',
  release: 'Release',
  releaseCriteria: 'Release Criteria',
  releasePrivacy: 'Release Criteria',
  releasePrivacySuccess: 'Updated Successfully',
  releasePrivacyError: 'Oops! Something went wrong!',
  readMore: 'Read more',
  repeat: 'Repeat',
  repeatNull: 'Repeat cannot be empty.',
  relCli: 'Rel.Cli.',
  reset: 'Reset',
  resetToDefaultSettings: 'Reset to Default Settings',
  remove: 'Remove',
  removeAccess: 'Remove Access',
  removeEndpoint: 'Remove Endpoint',
  removeEndpoints: 'Remove Endpoint(s)',
  totalRemediationCount: 'Total Remediation Count',
  reason: 'Reason',
  reasonForTheDemo: 'Reason for the Demo',
  reasonForTheDemoNull: 'Reason for the Demo cannot be empty.',
  relatedProjects: 'Related Projects',
  remediation: 'Remediation',
  remediationDatabase: 'Remediation Database',
  remediationDetails: 'Remediation Details',
  remediationDetailsNull: 'Remediation Details cannot be empty.',
  remediationDetailsToLong: 'Remediation Details can be max length 1000.',
  remediationDetailsFor: 'Remediation Details For CWE ID',
  remediationDelete: 'Remediation Delete',
  remediationInformations: 'Remediation advice for each CWE ID appears on the issue' +
    ' of the vulnerability opened by Kondukto.' +
    'Kondukto can also capture comments starting with "kondukto:" on the issue' +
    ' manager, and automatically add those comments to the remediation database.',
  requestTimeoutTitle: 'Request timeout.',
  requestTimeoutDescription: 'Your request timeout. Please try again.',
  requestType: 'Request Type',
  requestResponse: 'Request / Response',
  reporter: 'Reporter',
  reject: 'Reject',
  rejected: 'Rejected',
  rejectFalsePositiveRequest: 'Reject False Positive Request',
  riskAccepted: 'Risk Accepted',
  riskAcceptedNull: 'Risk Accepted cannot be empty.',
  riskScore: 'Risk Score',
  riskRating: 'Risk Rating',
  riskScoreTrend: 'Risk Score Trend',
  riskScoreSnapShot: 'Risk Score Snapshot',
  riskScoreSnapShotChartInfo: 'Displays the distribution of products/projects/teams' +
    ' around the average vuln./risk score.',
  riskScoreTrendChartInfo: 'Displays the evolution of risk score over time.',
  riskProfile: 'Risk Profile',
  riskValue: 'Risk Value',
  licenseRisk: 'License Risk',
  licenseRiskunknown: '-',
  recommendation: 'Recommendation',
  aiRecommendation: 'AI Recommendation',
  repoCommitStatus: 'Repo Commit Status',
  repoEmpty: 'Repo empty',
  repositoryPush: 'Repository Push',
  repositoryUrl: 'Repository URL',
  repository: 'Repository',
  repositoryError: 'Oops! Something went wrong',
  repositoryUrlNull: 'Repository URL cannot be empty.',
  repositoryUrlInvalid: 'Invalid repository URL.',
  repositoryName: 'Respository Name',
  repositoryNameNull: 'Repository name cannot be empty.',
  reports: 'Reports',
  reportsDescription: 'Create periodic reports to be sent automatically by Kondukto.',
  reportHistory: 'Report History',
  reportHistoryDescription: 'Only reports that have been generated in the last 365' +
    ' days are listed in this view.',
  reportType: 'Report Type',
  reportTypeNull: 'Report type cannot be empty.',
  reportTitle: 'Report Title',
  reportDeactivateInformation: 'You are trying to edit a report that is' +
    ' currently deactivated. The report will be re-activated if you click on the "Save" button.',
  reportGeneratedSuccessfully: 'Report generated successfully.',
  reportProfiles: 'Report Profiles',
  region: 'Region',
  regionNull: 'Region cannot be empty.',
  registryPath: 'Registry Path',
  registryPathPlaceholder: 'i.e alpine',
  registryPathNull: 'Registry Path cannot be empty',
  reasons: 'Reasons',
  resolved: 'Resolved',
  resource: 'Resource',
  resourceGroup: 'Resource Group',
  resourceGroupNull: 'Resource Group cannot be empty.',
  resourceType: 'Resource Type',
  rejectionDate: 'Rejection Date',
  rejectedBy: 'Rejected By',
  repliedBy: 'Replied By',
  repliedDate: 'Replied Date',
  repliedText: 'Replied Text',
  requester: 'Requester',
  request: 'Request',
  requestNull: 'Request cannot be empty.',
  requestBy: 'Request By',
  requestedBy: 'Request By',
  requestDate: 'Request Date',
  requestedDate: 'Request Date',
  requestedText: 'Request Text',
  requestUrl: 'Request URL',
  requestDemo: 'Request Demo',
  retrievingResults: 'Retrieving Results',
  rescan: 'Rescan',
  rescanError: 'Rescan Error',
  rescanErrorMessage: 'Oops! Something went wrong',
  rescanSuccess: 'Rescan Success',
  rescanSuccessMessage: 'Rescan started',
  response: 'Response',
  responseNull: 'Response cannot be empty.',
  resetSort: 'Reset Sort',
  restore: 'Restore',
  restoreToDefault: 'Restore to Default',
  repudiation: 'Repudiation',
  reportAnIssue: 'Report an Issue',
  retest: 'Retest',
  retestConnection: 'Retest Connection',
  retentionPeriod: 'Retention Period',
  running: 'Running',
  runtime: 'Runtime',
  role: 'Role',
  roleNull: 'Role cannot be empty.',
  runIssueManagerOnLocal: 'Run issue manager on a local network.',
  ruleID: 'Rule ID',
  rule: 'Rule',
  ruleName: 'Rule Name',
  rules: 'Rules',
  rulesNull: 'Rules cannot be empty.',
  ruleset: 'Ruleset',
  rulesetNull: 'Ruleset cannot be empty.',
  rulesetURL: 'Ruleset URL',
  rulesetURLNull: 'Ruleset URL cannot be empty.',
  rulesetPath: 'Ruleset Path',
  rulesetPathNull: 'Ruleset Path cannot be empty.',
  rulesetType: 'Ruleset Type',
  rulesetTypeNull: 'Ruleset Type cannot be empty.',
  staging: 'Staging',
  sandbox: 'Sandbox',
  sandboxNull: 'Sandbox cannot be empty.',
  saml: 'SAML',
  sampleFile: 'Sample File',
  sampleFileNull: 'Sample File cannot be empty.',
  sast: 'SAST',
  sastPriorityList: 'SAST Priority List',
  sastPriorityListNull: 'SAST Priority List cannot be empty.',
  sastdast: 'SAST+DAST',
  saturday: 'Saturday',
  save: 'Save',
  saveAdvancedSettings: 'Save Advanced Settings',
  sbom: 'SBOM',
  sbomCompomentsInfo: 'The status of the vulnerabilities presented here reflect the status applicable' +
    ' at the time of the scan. Subsequent changes on the status can be tracked within vulnerability details.',
  sbomScans: 'SBOM Scans',
  sbomSource: 'SBOM Source',
  sbomradar: 'SBOM Radar',
  sbomDescription: 'Search for components across projects' +
    ' and track the number of components used in each project.',
  sbomFormat: 'SBOM Format',
  sbomType: 'SBOM Type',
  sbomTypeNull: 'SBOM Type cannot be empty.',
  securecodewarrior: 'Secure Code Warrior',
  secureCodeWarriorConnectionReminder: 'In order to display Secure Code Warrior' +
    ' training videos' +
    ' and links,' +
    ' make sure that your server can reach' +
    ' https://integration-api.securecodewarrior.com/api/v1/language-keys',
  secureflag: 'SecureFlag',
  secureflagConnectionReminder: 'To display SecureFlag training links,' +
    '  ensure that your server can access' +
    ' https://knowledge-base.secureflag.com',
  security: 'Security',
  securityCriteria: 'Security Criteria',
  securityCriteriaAssociatedWithLabels: 'Security criteria associated' +
    ' with the following label(s) are applied: ',
  securityCriteriaIsMet: 'Security Criteria is met',
  securityCriteriaIsNotMet: 'Security Criteria is not met',
  securityCriteriaDescription: 'Add security criteria to impose on branches of the project.',
  securityCriteriaEnabledDescOne: 'There is a default security criteria entered at a' +
    ' global level.',
  securityCriteriaEnabledDescTwo: 'Enable to add additional security criteria' +
    ' to be applied alongside the global criteria.',
  securityCriteriaEnabledDescThree: 'Enable to activate security criteria.',
  securityCriteriaInfo: 'Security criteria are used to decide if the project' +
    ' can advance in the pipeline. Use KDT to ask Kondukto if the project meets the security' +
    ' criteria and to automatically break builds in your CI/CD tool. ',
  securityCriteriaNull: 'Security criteria cannot be empty.',
  secret: 'Secret',
  secretNull: 'Secret cannot be empty.',
  secretToken: 'Secret Token',
  secretAccessKey: 'Secret Access Key',
  secretAccessKeyNull: 'Secret access key cannot be empty.',
  secretAccessKeyContainsIllegalCharacters: 'Secret access key contains illegal characters.',
  seeker: 'Seeker',
  seekerScan: 'Seeker Scan',
  seekerScanDescription: 'Description for Seeker Scan',
  sessionDropped: 'Session dropped',
  sessionDroppedDescription: 'Session dropped due to inactivity (15 minutes).',
  semgrep: 'Semgrep',
  semgrepScan: 'Semgrep Scan',
  semgrepScanDescription: 'Description for Semgrep Scan',
  semgrepconfig: 'Semgrep Config',
  semgrepconfigScan: 'Semgrep Config Scan',
  semgrepconfigScanDescription: 'Description for Semgrep Config Scan',
  setAsIssueManager: 'Set as Issue Manager',
  setSlaLevels: 'Set SLA levels to determine acceptable time' +
    ' frame to fix vulnerabilities from each category.',
  sca: 'SCA',
  scaPriorityList: 'SCA Priority List',
  scaPriorityListNull: 'SCA Priority List cannot be empty.',
  scan: 'Scan',
  scanPeriodHours: 'Scan Period (Hours)',
  scanPeriodNull: 'Scan Period cannot be empty.',
  scanPeriodLengthInvalid: 'Name must be minimum of 1 and maximum of 24.',
  scanStatus: 'Scan Status',
  scanRiskScore: 'Scan Risk Score',
  scanConfigurations: 'Scan configurations',
  scanInformation: 'Assign scanners that have been activated under Integrations to your project.',
  scanCancelledSuccessfully: 'Scan cancelled successfully',
  scanConfigurationsNull: 'Scan configurations can not be empty.',
  scanDate: 'Scan Date',
  scanDescription: 'Add scanner and schedule scans for your project.',
  scanDurationIsNull: 'Scan duration cannot be empty',
  scanDurationInvalid: 'Scan duration not valid',
  scanName: 'Scan Name',
  scanNameNull: 'Scan name can not be empty.',
  scannerImage: 'Scanner Image',
  scanner: 'Scanner',
  scannerPriorityList: 'Scanner priority list',
  scannerName: 'Scanner Name',
  scannerNameNull: 'Scanner name cannot be empty.',
  scannerNameInValid: 'Scanner name invalid.',
  scannerNull: 'Scanner cannot be empty.',
  scanners: 'Scanners',
  scannersInfo: 'Benchmark the results of your scanners.',
  scanTitle: 'Scan Title',
  scanTitleNull: 'Scan Title cannot be empty.',
  scanType: 'Scan Type',
  scannerType: 'Scanner Type',
  scannerTypeNull: 'Scanner Type cannot be empty.',
  scanS: 'scan(s)',
  scans: 'Scans',
  scanViaKonda: 'Scan via Konda',
  scanStartedSuccessfully: 'Scan started successfully.',
  scanScheduledSuccessfully: 'Scan scheduled successfully.',
  schedule: 'Schedule',
  scheduled: 'Scheduled',
  scheduleScan: 'Schedule Scan',
  scheduleAScanFor: 'Schedule a Scan for',
  scheduledTo: 'Scheduled to',
  scheduledWebHook: 'Scheduled Webhook',
  scheduledWebHookDescription: 'Start a daily scan if any webhook ' +
    'event has been triggered by the time determined below.',
  scheduler: 'Scheduler',
  schedulerNull: 'Scheduler cannot be empty.',
  score: 'Score',
  scoreSnapShot: 'Score Snapshot',
  scope: 'Scope',
  scopeInfo: 'Define the paths within your mono-repo so that Kondukto' +
    '  can decide on the SAST, SCA and IAC vulnerabilities to include in this project.',
  screenshots: 'Screenshots',
  sendFalsePositiveRequest: 'Send False Positive Request',
  sendNotificationsVia: 'Send Notifications Via',
  search: 'Search',
  searchTeam: 'Search team',
  secretKey: 'Secret Key',
  secretKeyInstructions: 'How to get a Secret Key',
  secretAccessKeyInstructions: 'How to get a Secret Access Key',
  secretKeyNull: 'Please generate a secret key.',
  secretKeyNull2: 'Secret key cannot be empty.',
  secretKeyContainsIllegalCharacters: 'Secret key contains illegal characters.',
  securitycodescan: 'Security Code Scan',
  securitycodescanScan: 'Security Code Scan',
  securitycodescanScanDescription: 'Description for Security scan.',
  setup: 'Setup',
  SCNotEntered: 'Security criteria not entered/not available.',
  SCNotMet: 'Security criteria not met.',
  SCMet: 'Security criteria met.',
  seeAvailableTags: 'See available tags',
  seeMoreProjects: 'See more projects',
  selectAssignee: 'Please select an assignee',
  selectCustomDate: 'Select custom date',
  seeDetails: 'Click to see details',
  selected: 'Selected',
  selection: 'Selection',
  selectionNull: 'Selection cannot be empty.',
  selectedProjects: 'Selected Projects',
  selectedProjectsNull: 'Selected Projects cannot be empty.',
  selectProjectsManually: 'Select Projects Manually',
  selectedTeams: 'Selected Teams',
  selectedLabels: 'Selected Labels',
  selectedInfraGroups: 'Selected Infra Groups',
  selectLabel: 'Select label',
  selectIssueState: 'Issue state should be selected',
  selectFile: 'Select File',
  selectUser: 'Select User',
  selectUserNull: 'Select User cannot be empty.',
  shareCodeSnippets: 'Share code snippets:',
  shouldAddUser: 'One or more users should be selected.',
  shouldAddProfiles: 'One or more profiles should be selected.',
  shouldAddProjects: 'One or more projects should be selected.',
  steps: 'Steps',
  dockerTagNull: 'Tag can not be empty.',
  dockerImages: 'Docker Images',
  dockerImageS: 'Docker Image(s)',
  selectTeams: 'Select Teams',
  selectTimePeriod: 'Select time period',
  settings: 'Settings',
  settingsName: 'Settings Name',
  severity: 'Severity',
  severities: 'Severities',
  severityPlus: 'Severity Plus',
  severityPlusInfraTooltip: 'Use this label in projects where you\'d like Kondukto to automatically' +
    ' upgrade severity categories of Infra vulnerabilities by 1 category.',
  severityPlusTooltip: 'Use this label in projects where you\'d like Kondukto to automatically' +
    ' upgrade severity categories of AppSec vulnerabilities by 1 category.',
  severityMinus: 'Severity Minus',
  severityMinusInfraTooltip: 'Use this label in projects where you\'d like Kondukto to automatically' +
    ' downgrade severity categories of Infra vulnerabilities by 1 category.',
  severityMinusTooltip: 'Use this label in projects where you\'d like Kondukto to automatically' +
    ' downgrade severity categories of AppSec vulnerabilities by 1 category.',
  severityTrend: 'Severity Trend',
  severityTrendChartInfo: 'Displays the evolution of new and recurrent' +
    ' findings under each severity class over time.',
  securityKPITrend: 'Security KPI Trend',
  securityKPITrendChartInfo: 'Displays the trend of security KPI metrics over the last 90 days.',
  severitySnapshotByTeam: 'Severity Snapshot by Team',
  severitySnapshotByTeamInfo: 'Displays the distribution of open' +
    ' (new+recurrent) vulnerabilities among teams.',
  severityTrendByTeam: 'Severity Trend by Team',
  severityLevel: 'Severity Level',
  severityLevelNull: 'Severity level cannot be empty.',
  severityNull: 'Severity cannot be empty.',
  severityScore: 'Severity Score',
  severityScoreInfo: 'The scores assigned to severity categories here are used to calculate the' +
    ' risk score of projects.',
  service: 'Service',
  servicenow: 'Service Now',
  setAsDefault: 'Set as Default',
  ssh: 'SSH',
  showAll: 'Show All',
  showAllPendingFPRequests: 'Show All Pending FP Requests',
  showMore: 'Show More',
  shadowUser: 'Shadow User',
  signInUrl: 'Sign in URL',
  signInUrlNull: 'Sign in URL cannot be empty.',
  similarIssues: 'Similar Issues',
  similarIssueInfo: 'This vulnerability will be added as a comment' +
  ' in the issue details of the vulnerability selected below.',
  similarIssueEmpty: 'There are no SCA vulnerabilities with the same package name to associate' +
  ' this vulnerability with.',
  since: 'Since',
  sinceCustomDate: 'Since custom date',
  singleSignOnTools: 'Single Sign-On Tools',
  sso: 'SSO',
  ssoType: 'SSO Type',
  ssoTypeNull: 'SSO Type cannot be empty.',
  site: 'Site',
  siteNull: 'Site can not be empty.',
  skipped: 'Skipped',
  sla: 'SLA',
  slaCounter: 'Start SLA countdown after ticket creation',
  slack: 'Slack',
  smtp: 'Smtp',
  smtpNull: 'Smtp cannot be empty.',
  smtpInvalid: 'Invalid smtp.',
  snippet: 'Snippet',
  snyksca: 'Snyk SCA',
  snykscaScan: 'Snyk SCA Scan',
  snykscaScanDescription: 'Description for Snyk Scan',
  snyksast: 'Snyk SAST',
  snyksastScan: 'Snyk SAST Scan',
  snykiac: 'Snyk IaC',
  snykiacScan: 'Snyk IaC Scan',
  snykcs: 'Snyk CS',
  snykcsScan: 'Snyk CS Scan',
  sonarcloud: 'SonarCloud',
  sonarcloudScan: 'SonarCloud Scan',
  sonarqube: 'SonarQube',
  sonarqubeScan: 'SonarQube Scan',
  sonarqubeScanDescription: 'Description for SonarQube scan.',
  sonatypenl: 'Sonatype Nexus Lifecycle',
  sonatypenlScan: 'Sonatype Nexus Lifecycle Scan',
  somethingWentWrong: 'Oops! Something went wrong!',
  source: 'Source',
  sourceBranch: 'Source Branch',
  sourceType: 'Source Type',
  sourceControl: 'Source Control',
  sourceControlSuccess: 'Updated Successfully',
  sourceControlError: 'Oops! Something went wrong!',
  sourceControlEnabledDescription: 'Enable to use SAST, SCA and IaC tools' +
    ' and to find committers of SAST vulnerabilities.',
  sourceControlIsNull: 'Source control section needs to be' +
    ' completed first for the open source scanner to work properly.',
  sourcePath: 'Source Path',
  sourceDir: 'Source Dir',
  sourceDirInputInfo: 'Kondukto compares file/path of SAST and SCA findings to source dir' +
    ' entered here and automatically matches the finding with the related endpoint.' +
    'Use regex or wildcard when entering a source dir such as; /src/api/v1/login*',
  sort: 'Sort',
  spdx: 'SPDX',
  specificUser: 'Specific User',
  specificUserPlaceHolder: 'Use username on your issue manager',
  spoofing: 'Spoofing',
  stackTrace: 'Stack Trace',
  standards: 'Standards',
  stashMode: 'Stash Mode',
  startDate: 'Start Date',
  startDateNull: 'Start date cannot be empty.',
  startDatePast: 'Start date can not be in the past, check date and time',
  startScan: 'Start Scan',
  startScanInfo: 'By default, Kondukto will retrieve the last scan results from the scanner.' +
    'If you want Kondukto to start a scan, click this checkbox.',
  startInstantScan: 'Start Instant Scan',
  startUpload: 'Start Upload',
  status: 'Status',
  statusChanges: 'Status Changes',
  statusNull: 'Status cannot be empty',
  statusTooltipContent: 'Could be new, recurrent or closed depending on the scan results.',
  statusDateNull: 'Status date cannot be empty',
  stride: 'Stride',
  STRIDE: 'STRIDE params',
  strideNull: 'Stride cannot be empty.',
  success: 'Success',
  successAdvancedSettings: 'Advanced settings saved',
  successful: 'Successful',
  summary: 'Summary',
  sunday: 'Sunday',
  subVersion: 'Sub Version',
  subVersionNull: 'Sub Version cannot be empty.',
  submitter: 'Submitter',
  submittedAt: 'Submitted At',
  supportedApiVersion: 'Supported Api Version',
  suppressionRequests: 'Suppression Requests',
  suppressedVulns: 'Suppressed Vulns',
  suppressedVulnsText: 'Suppressed Vulns. Expiring In A Week',
  suppressedVulnsTextInfo: 'Displays the type, number and severity of suppressed' +
    ' vulnerabilities that are set to expire in a week.',
  suppressedVulnerabilities: 'Suppressed Vulnerabilities',
  suppressedVulnerabilitiesInfo: 'Displays the number of vulnerabilities that have' +
    '  been suppressed in the selected period.',
  suppression: 'Suppression',
  suppressionRules: 'Suppression Rules',
  suppressionCriteriaInfo: 'Automatically suppress vulnerabilities meeting specific criteria.',
  suppressionDetailsAndComments: 'Suppression Details & Comments',
  submitRequest: 'Submit Request',
  subscriptionId: 'Subscription ID',
  subscriptionIdNull: 'Subscription ID cannot be empty.',
  string: 'String',
  state: 'State',
  syft: 'Syft',
  sync: 'Sync',
  syncProjects: 'Sync Projects',
  syncBranches: 'Sync Branches',
  syncEndpoints: 'Sync Endpoints',
  syncing: 'Syncing',
  syncNow: 'Sync Now',
  systemEdition: 'System Edition',
  systemEditionNull: 'System Edition cannot be empty.',
  systemLog: 'System Log',
  queue: 'Queue',
  queueDescription: 'Only scans that are scheduled for the next 24' +
    ' hours and the ones completed in the last 5 minutes are listed on this page.',
  queueDate: 'Queue Date',
  query: 'Query',
  queryKey: 'Query Key',
  queryParams: 'Query Params',
  tableExport: 'Table Export',
  tableExportInfo: 'Exports all columns included on the table.',
  tag: 'Tag',
  tagId: 'Tag ID',
  tagOrBranch: 'Tag / Branch',
  tagOrBranchNull: 'Tag / Branch can not be empty.',
  tagName: 'Tag Name',
  tagNull: 'Tag can not be empty.',
  tags: 'Tags',
  target: 'Target',
  targets: 'Target(s)',
  targetImage: 'Target image',
  targetNull: 'Target cannot be empty',
  targetURL: 'Target URL',
  targetURLNull: 'Target URL cannot be empty',
  targetUrlInvalid: 'Target url invalid',
  targetScore: 'Target score',
  targetScoreIsNull: 'Target score cannot be empty',
  targetScoreInvalid: 'Target score not valid',
  targetProjects: 'Target Projects',
  targetProjectsNull: 'Target projects cannot be empty.',
  tampering: 'Tampering',
  template: 'Template',
  templateNull: 'Templates cannot be null.',
  templateOrCustomTemplateNull: 'Template(s) or Custom Template(s) cannot be empty',
  templates: 'Templates',
  templatesNull: 'Templates cannot be empty.',
  templateS: 'Template(s)',
  templatesInvalid: 'Invalid templates path.',
  tenantId: 'Tenant ID',
  tenantIdNull: 'Tenant ID cannot be null.',
  team: 'Team',
  teamNull: 'Team cannot be empty.',
  teamlead: 'Team Lead',
  teamLeadMissing: 'Team Lead Missing!',
  teamLeadMissingDesc: 'You cannot create a team without a team lead.',
  teamLeadsCreateUsersManually: 'Allow team leads to create users manually',
  teamname: 'Team Name',
  teamName: 'Team Name',
  teamNameLengthInvalid: 'Team name must be minimum of 3 and maximum of 40 characters.',
  teamNameAlreadyExists: 'Team name already exists.',
  teamNameLower: 'Team name',
  teamNameNull: 'Team name cannot be empty.',
  teamNameInvalid: 'Invalid team name.',
  teamNameTooShort: 'Team name too short.',
  teamNotSelected: 'Please select a team.',
  teamProvisioning: 'Team Provisioning',
  teams: 'Teams',
  teamsNull: 'Teams cannot be empty.',
  teamsTooltipInfo: 'Define teams whose team leads can view this product.',
  test: 'Test',
  testConnection: 'Test Connection',
  testMailConnection: 'Test Mail Connection',
  testProduction: 'Test/Production',
  textSearch: 'Text Search',
  tenantName: 'Tenant Name',
  tenantNameNull: 'Tenant Name cannot be empty.',
  tenableiovm: 'Tenable.io VM',
  tenableiovmInfo: 'Kondukto gets the last launched scans information from Tenable.io side.' +
    ' Any change is only reflected on Kondukto side if the related scan is launched.',
  tenableiovmScan: 'Tenable.io VM Scan',
  tenableiowas: 'Tenable.io Was',
  tenableiowasScan: 'Tenable.io Was Scan',
  tenablesc: 'Tenable.sc',
  tenablescScan: 'Tenable.sc Scan',
  tenablescScanDescription: 'Description for Tenable.sc scan.',
  thursday: 'Thursday',
  threatIntelligence: 'Threat Intelligence',
  thereIsNoRepoUrl: 'There is no repo URL for this project' +
    ' on the scanner which will cause the scan to fail.',
  thereIsNoGlobalCriteria: 'There is no Global Criteria',
  tickets: 'Tickets',
  time: 'Time',
  timeNull: 'Time cannot be empty.',
  title: 'Title',
  titleLengthInvalid: 'Title must be maximum of 255 characters.',
  titleNull: 'Title cannot be empty.',
  titleInValid: 'Title invalid.',
  to: 'To',
  toolName: 'Tool Name',
  toolLogo: 'Tool Logo',
  toolLogoNull: 'Tool Logo cannot be empty.',
  toolNameLower: 'Tool name',
  total: 'Total',
  totalNew: 'Total New',
  totalClosed: 'Total Closed',
  totalComponents: 'Total Components',
  totalApplicable: 'Total Applicable',
  totalFindings: 'Total Findings',
  totalProjects: 'Total Projects',
  totalCount: 'Total Count',
  totalSelected: 'Total Selected',
  token: 'Token',
  tokenUrl: 'Token URL',
  tokenUrlNull: 'Token URL cannot be empty.',
  tokenInstructions: 'How to get a token',
  tokenNull: 'Token cannot be empty.',
  tokenInvalid: 'Invalid token.',
  tool: 'Tool',
  toolNull: 'Tool cannot be empty.',
  tools: 'Tools',
  training: 'Training',
  trend: 'Trend',
  trendDescription: 'The risk score of the latest scan will be compared against the scan with' +
    '  the same scanner and branch that has the lowest risk score.',
  threshold: 'Threshold',
  trigger: 'Trigger',
  triggerScanDescription: 'Trigger scans via actions taken on your' +
    ' application lifecycle management tool.',
  triggerValidationScan: 'Trigger a validation scan',
  trivy: 'Trivy',
  trivyScan: 'Trivy Scan',
  trivyScanDescription: 'Description for Trivy scan.',
  trivyiac: 'Trivy IaC',
  trivyiacScan: 'Trivy IaC Scan',
  true: 'True',
  trufflehogsecurity: 'TruffleHog',
  trufflehogsecurityScan: 'TruffleHog Scan',
  truePositive: 'True Positive',
  tryNow: 'Try Now',
  tuesday: 'Tuesday',
  type: 'Type',
  typeNull: 'Type cannot be empty.',
  tls: 'TLS',
  tfsec: 'tfSec',
  tfsecScan: 'tfSec Scan',
  uncategorized: 'Uncategorized',
  updatePassword: 'Update Password',
  updateSeverity: 'Update Severity',
  url: 'URL',
  urlNull: 'URL cannot be null.',
  urlsToBeScanned: 'URL\'s to be scanned',
  urlToBeScanned: 'URL to be scanned',
  urlWithoutPath: 'URL should be without path. i.e: http://127.0.0.1:8088',
  urlInvalid: 'Invalid URL.',
  unassigned: 'Unassigned',
  unknown: 'Unknown',
  unMark: 'Unmark',
  unmark: 'Unmark',
  unMarkFalsePositive: 'Unmark False Positive',
  unMarkTruePositive: 'Unmark True Positive',
  unMarkWontfix: 'Unmark Won\'t Fix',
  unMarkMitigated: 'Unmark Mitigated',
  unSendFalsePositive: 'Unsend False Positive',
  useTls: 'Use TLS',
  useAssigneeField: 'Use assignee field',
  useExtraInspectorTool: 'Use extra inspector tool',
  useReporterField: 'Use reporter field',
  useCustomLabelsOnJira: 'Use custom labels on Jira',
  useCustomPriorityMapping: 'Use custom priority mapping',
  useCustomWebhook: 'Use custom webhook',
  useDefaultIssueStateMapping: 'Use default issue state mapping',
  useAuthMacro: 'Use Auth Macro',
  useCache: 'Use Cache',
  useMachineCredentials: 'Use host instance profile',
  useMachineCredentialsInfo: 'The integration session will be retrieved from the Kondukto host\'s "instance profile."' +
    ' For this approach to work, the EC2 instance should have the appropriate IAM role and permissions.',
  user: 'User',
  userEmailAttribute: 'User Email Attribute',
  userEmailAttributeNull: 'Username Attribute cannot be empty.',
  userInfo: 'User Info',
  userMgmt: 'User Mgmt.',
  userManagement: 'User Management',
  usersAndTeams: 'Users & Teams',
  userKey: 'User Key',
  userKeyNull: 'User Key cannot be empty.',
  userObjectFilter: 'User Object Filter',
  userObjectFilterNull: 'User Object Filter cannot be empty.',
  userVulnStatus: 'User Vuln Status',
  userGroupTypes: 'User Group Types',
  userGroupTypesNull: 'User Group Types cannot be empty',
  userGroups: 'User Groups',
  userGroupsNull: 'User Groups cannot be empty',
  username: 'Username',
  usernameAlreadyExists: 'Username already exists.',
  usernameAttribute: 'Username Attribute',
  usernameAttributeNull: 'Username Attribute cannot be empty.',
  usernameNull: 'Username cannot be empty.',
  usernameOrEmail: 'Username or Email',
  usernameOrEmailNull: 'Username or Email cannot be empty.',
  usernameOrEmailInvalid: 'Invalid Username or Email.',
  usernameInvalid: 'Invalid username.',
  usernameTooShort: 'Username too short.',
  users: 'Users',
  userNotOnTrainingTool: 'Committer is not a training tool\'s user.',
  userNull: 'User cannot be empty.',
  useSsl: 'User SSL',
  userTheJsonFormat: 'Use the json format provided by Kondukto' +
    ' to import vulnerabilities from any source.',
  useURLOfTheProjectAsIssuePath: 'Use URL of the project as issue path.',
  unMerged: 'Unmerged',
  uniqueId: 'Unique ID',
  verifySsl: 'Verify SSL',
  uiImport: 'UI-Import',
  utoken: 'U-Token',
  update: 'Update',
  updateAgent: 'Update Agent',
  updateDate: 'Update Date',
  updatedOn: 'Updated On',
  updatedCount: 'Updated Count',
  upload: 'Upload',
  uploadConfig: 'Upload Config',
  uploadedDate: 'Uploaded Date',
  updateEndpoint: 'Update Endpoint',
  uploadMacro: 'Upload Macro',
  uploadPreset: 'Upload Preset',
  uploadPresetErrorMessage: 'Error Message',
  uploading: 'Uploading',
  uploadFileNull: 'Upload file cannot be empty.',
  updateRemediation: 'Update remediation',
  updateToken: 'Update Token',
  updatedSuccessfully: 'Updated successfully',
  uploadedSuccessfully: 'Uploaded Successfully',
  value: 'Value',
  valid: 'Valid',
  validation: 'Validation',
  validationScan: 'Validation Scan',
  validationScanNull: 'Validation Scan cannot be empty.',
  validationScanDescription: 'Validation scans are to make sure that a known' +
    ' vulnerability does not exist anymore by starting a' +
    ' scan each time an issue gets closed on the issue manager.',
  validationScanDescription2: 'Alternatively, instead of starting a' +
    ' scan each time an issue gets closed, Kondukto can check whether one or more' +
    ' issues have been closed in the last 24 hours at a specific time each day and start' +
    ' a scan accordingly.',
  vcs: 'Vcs',
  viewOnScanner: 'View on scanner',
  viewProjects: 'View Projects',
  verified: 'Verified',
  veracode: 'Veracode',
  veracodedast: 'Veracode DAST',
  veracodeScan: 'Veracode Scan',
  veracodedastScan: 'Veracode DAST Scan',
  veracodesca: 'Veracode SCA',
  veracodeScanDescription: 'Description for Veracode scan.',
  veracodedastScanDescription: 'Description for Veracode DAST scan.',
  veracodeStartScanDesc: 'Kondukto can trigger scans on Veracode if the programming' +
    ' language used in the project does not require compilation.When the box is left unchecked,' +
    ' Kondukto will only pull findings from Veracode at scheduled times.',
  versionControlSystem: 'Version Control System',
  versionControlSystemNull: 'Please select a version control system.',
  version: 'Version',
  versionNull: 'Version cannot be empty.',
  vsts: 'Azure DevOps',
  vstsCloudExample: 'cloud i.e : https://fabrikam.visualstudio.com/',
  vstsLocalExample: 'local i.e: http://172.16.0.1',
  vulnerableComponents: 'Vulnerable Components',
  vulnerabilityAssignmentSuccessNotification: 'Total number of issues assigned to selected ' +
    'vulnerabilities:',
  vulnerabilityAssignmentErrorNotification: 'An error occurred while assigning ' +
    'issues to the following vulnerabilities:',
  vulnerabilityCount: 'Vulnerability Count',
  vulnerabilityDb: 'Vulnerability DB',
  vulnsExceedingSla: 'Vulns. exceeding SLA',
  vulnerabilityName: 'Vulnerability Name',
  vulnerabilityNameNull: 'Vulnerability Name  cannot be empty',
  vulnerabilityNameLower: 'Vulnerability name',
  vulnerable: 'Vulnerable',
  vulnerability: 'Vulnerability',
  vulnerabilities: 'Vulnerabilities',
  vulnerabilityDensity: 'Vulnerability Density',
  vulnerabilityDensityInfo: 'Displays the top 25 most discovered vulnerabilities' +
    '  based on CWE ID and file/path/resource.',
  vulnerabilitySummary: 'Vulnerability Summary',
  vulnerabilitySummaryChartInfo: 'Displays the breakdown of all vulnerabilities' +
    ' based on their status and severity category.',
  vulnerabilityToBeAssignedAnIssue: "Still hasn't been assigned an" +
    ' issue(select vulnerabilities to assign to user)',
  vulnerabilityChangeToFalsePositive: 'Vulnerability change to False Positive',
  vulnerabilitiesChangeToFalsePositive: 'Vulnerabilities change to False Positive',
  vulnerabilityPriorityRating: 'Vulnerability Priority Rating',
  vulnsStatus: 'Vulns Status',
  vulnsWOENull: 'Vulns WOE cannot be empty.',
  vulnsWaitingForRemediation: 'Vulnerabilities waiting for remediation',
  vulnsTotalCounInfo: 'There might be a discrepancy between this number and' +
    ' the numbers shown in the scan summary above due to duplicate' +
    ' vulnerabilities eliminated in subsequent scans.',
  via: 'Via',
  viaNull: 'Via cannot be empty.',
  videos: 'Videos',
  violations: 'Violations',
  webinspect: 'Fortify WebInspect',
  webinspectScan: 'Fortify WebInspect Scan',
  webinspectScanDescription: 'Description for Fortify WebInspect scan.',
  waiting: 'Waiting',
  webhook: 'Webhook',
  webhookNull: 'Webhook cannot be empty.',
  wednesday: 'Wednesday',
  weekly: 'Weekly',
  weekS: 'week(s)',
  whenRelatedIssueGetsClosed: 'Each time an issue gets closed',
  whenClosing: 'When Closing',
  whenClosingTooltip: 'This is the state Kondukto will use to close issues' +
    ' and to decide when the issue has been closed on your issue manager.',
  whenClosingNull: 'When Closing cannot be empty.',
  whenInProgress: 'When In Progress',
  whenInProgressTooltip: 'This is the state Kondukto will use to decide when' +
    ' the issue has been moved from the opening state to in progress.',
  whenInProgressNull: 'When In Progress cannot be empty.',
  whenOpening: 'When Opening',
  whenOpeningTooltip: 'This is the state Kondukto will choose on' +
    ' your issue manager when creating an issue.',
  whenOpeningNull: 'When Opening cannot be empty.',
  whenUnassigned: 'When Unassigned',
  whenUnassignedTooltip: 'This is the state Kondukto will choose on' +
    ' your issue manager when creating an issue.',
  whenUnassignedNull: 'When Opening cannot be empty.',
  whenPending: 'When Pending',
  whenPendingTooltip: 'This is the state Kondukto will choose on' +
    ' your issue manager when creating an issue.',
  whenPendingNull: 'When Opening cannot be empty.',
  whitesource: 'Mend',
  whitesourceScan: 'Mend Scan',
  whitesourceScanDescription: 'Description for Mend scan.',
  windowsOfExposure: 'Window of Exposure in days',
  windowsOfExposureDesc: 'Average time passed since discovery of' +
    ' new and recurrent vulnerabilities.',
  wiz: 'Wiz',
  wizcspm: 'Wiz CSPM',
  wizcspmScan: 'Wiz CSPM Scan',
  wizcontainer: 'Container vulns',
  wizvm: 'VM vulns',
  woe: 'WOE',
  woeInDays: 'WOE in Days',
  wontfix: "Won't Fix",
  wontfixNull: "Won't Fix cannot be empty.",
  woeBySeverity: 'WOE by Severity',
  worksInAContainer: 'Works in a Docker container',
  woeTopTen: 'WOE Top Ten',
  woeIsNull: 'WOE in days cannot be empty',
  woeInvalid: 'WOE in days not valid',
  woeMtf: 'WOE/MTTR',
  workflows: 'Workflows',
  working: 'Working',
  workItemUpdated: 'WorkItem Updated',
  workItemUpdatedEvent: 'WorkItem Updated Event',
  yes: 'Yes',
  yourName: 'Your Name',
  yourNameNull: 'Your Name cannot be empty.',
  yourNameInvalidLength: 'Your Name should contain at least 2 or most 200 characters.',
  yourEmailAddress: 'Your Email Address',
  yourEmailAddressNull: 'Your Email Address cannot be empty.',
  yourPhone: 'Your Phone',
  yourPhoneNull: 'Your Phone cannot be empty.',
  yourPhoneInvalid: 'Invalid phone number.',
  messageNull: 'Message cannot be empty.',
  messageInvalidLength: 'Message should contain at most 400 characters.',
};
