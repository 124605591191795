import { SBOM } from '@/config/';

const sbomDummy = [...Array(SBOM.PERPAGE)].map((_, i) => ({
  key: i,
  createdAt: '########',
  sbomFormat: 'cyclonedx',
  branch: '########',
  scanType: '########',
  inspectorTool: 'osv',
  vulnerableComponents: '########',
  totalComponents: '########',
  projectId: '########',
}));

const sbomDetailDummy = [...Array(SBOM.PERPAGE)].map((_, i) => ({
  key: i,
  id: i,
  componentName: '###########',
  componentVersion: '###########',
  componentType: '###########',
  projects: [],
  branch: [],
  licenses: [],
  packageUrl: '###########',
  vulnerabilities: '###########',
  licenseRisk: 1,
  critical: 0,
  high: 0,
  medium: 0,
  low: 0,
  info: 0,
}));

export {
  sbomDummy,
  sbomDetailDummy,
};
