const teamLeadScannerIntegrationSettingsDummy = [
  {
    id: 0,
    name: 'cspm',
    data: [
      {
        id: 0,
        name: 'msdefendercloud',
      },
    ],
  },
];

const teamLeadIntegrationSettingsDummy = [
  {
    id: 0,
    name: 'msdefendercloud',
  }, {
    id: 1,
    name: 'msdefendercloud',
  },
];

export {
  teamLeadScannerIntegrationSettingsDummy,
  teamLeadIntegrationSettingsDummy,
};
