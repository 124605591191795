export default {
  path: 'actions-requests',
  name: 'actions-requests',
  component: () => import('@/views/layouts/ActionsRequests.vue'),
  children: [
    {
      path: ':id/pending',
      name: 'actions-requests-pending',
      component: () => import('@/views/pages/member/ActionsRequests/Pending.vue'),
      meta: {
        title: 'Pending',
        activeMenuKeys: ['actions-requests-pending'],
        allowedRole: [0, 1, 5],
      },
      beforeEnter: (to) => {
        if (!to.query.page) {
          return {
            name: 'actions-requests-pending',
            params: { id: to.params.id },
            query: { page: 1 },
          };
        }
      },
    }, {
      path: ':id/approved',
      name: 'actions-requests-approved',
      component: () => import('@/views/pages/member/ActionsRequests/Approved.vue'),
      meta: {
        title: 'Approved',
        activeMenuKeys: ['actions-requests-approved'],
        allowedRole: [0, 1, 5],
      },
      beforeEnter: (to) => {
        if (!to.query.page) {
          return {
            name: 'actions-requests-approved',
            params: { id: to.params.id },
            query: { page: 1 },
          };
        }
      },
    }, {
      path: ':id/rejected',
      name: 'actions-requests-rejected',
      component: () => import('@/views/pages/member/ActionsRequests/Rejected.vue'),
      meta: {
        title: 'Rejected',
        activeMenuKeys: ['actions-requests-rejected'],
        allowedRole: [0, 1, 5],
      },
      beforeEnter: (to) => {
        if (!to.query.page) {
          return {
            name: 'actions-requests-rejected',
            params: { id: to.params.id },
            query: { page: 1 },
          };
        }
      },
    }, {
      path: ':id/expired',
      name: 'actions-requests-expired',
      component: () => import('@/views/pages/member/ActionsRequests/Expired.vue'),
      meta: {
        title: 'Expired',
        activeMenuKeys: ['actions-requests-expired'],
        allowedRole: [0, 1, 5],
      },
      beforeEnter: (to) => {
        if (!to.query.page) {
          return {
            name: 'actions-requests-expired',
            params: { id: to.params.id },
            query: { page: 1 },
          };
        }
      },
    },
  ],
};
