import { defineStore } from 'pinia';

// eslint-disable-next-line import/no-cycle
import DashboardService from '@/services/Dashboard';

import ErrorHandling from '@/mixins/ErrorHandling';

import { GLOBAL } from '@/config/';

const useDashboardFilterStore = defineStore({
  id: 'DashboardFilter',
  state: () => ({
    // ## Dashboard selected filter. -----------------------------------------------------------------
    dashboardCharts: GLOBAL.DASHBOARD_CHARTS,
    globalDashboardFilter: {
      global: {
        filter: {
          type: 'appsec',
          scannerType: 'consolidated',
          branch: 'all-branches',
          businessCriticality: 'all',
        },
      },
    },

    // ## Dashboard products filter. -----------------------------------------------------------------
    dashboardProductsFilter: null,
    dashboardProductsFilterPending: null,
    dashboardProductsFilterDone: null,

    // ## Dashboard teams filter. --------------------------------------------------------------------
    dashboardTeamsFilter: null,
    dashboardTeamsFilterPending: null,
    dashboardTeamsFilterDone: null,

    // ## Dashboard labels filter. -------------------------------------------------------------------
    dashboardLabelsFilter: null,
    dashboardLabelsFilterPending: null,
    dashboardLabelsFilterDone: null,
  }),
  actions: {
    // ## Get dashboard product filter. --------------------------------------------------------------
    async getDashboardProductsFilter(data) {
      this.dashboardProductsFilterPending = true;

      try {
        const res = await DashboardService.getProductsFilter(data);
        this.setGetDashboardProductsFilter(res.data);
        this.dashboardProductsFilterDone = !this.dashboardProductsFilterDone;
        this.dashboardProductsFilterPending = false;
      } catch (error) {
        this.dashboardProductsFilterPending = false;
        ErrorHandling(error);
      }
    },

    setGetDashboardProductsFilter(payload) {
      this.dashboardProductsFilter = [];
      this.dashboardProductsFilter = payload.data.map((s) => ({
        title: s.name,
        value: s.id,
        children: s.projects.map((a) => ({
          title: a.name,
          value: a.id,
        })),
      }));
    },

    // ## Get dashboard teams filter. ----------------------------------------------------------------
    async getDashboardTeamsFilter(data) {
      this.dashboardTeamsFilterPending = true;

      try {
        const res = await DashboardService.getTeamsFilter(data);
        this.setGetDashboardTeamsFilter(res.data);
        this.dashboardTeamsFilterDone = !this.dashboardTeamsFilterDone;
        this.dashboardTeamsFilterPending = false;
      } catch (error) {
        this.dashboardTeamsFilterPending = false;
        ErrorHandling(error);
      }
    },

    setGetDashboardTeamsFilter(payload) {
      this.dashboardTeamsFilter = [];
      this.dashboardTeamsFilter = payload.data.map((s) => ({
        title: s.name,
        value: s.id,
      }));
    },

    // ## Get dashboard labels filter. ---------------------------------------------------------------
    async getDashboardLabelsFilter(data) {
      this.dashboardLabelsFilterPending = true;

      try {
        const res = await DashboardService.getLabelsFilter(data);
        this.setGetDashboardLabelsFilter(res.data);
        this.dashboardLabelsFilterDone = !this.dashboardLabelsFilterDone;
        this.dashboardLabelsFilterPending = false;
      } catch (error) {
        this.dashboardLabelsFilterPending = false;
        ErrorHandling(error);
      }
    },

    setGetDashboardLabelsFilter(payload) {
      this.dashboardLabelsFilter = [];
      this.dashboardLabelsFilter = payload.data.map((s) => ({
        title: s.name,
        value: s.id,
      }));
    },

    getDashboardCharts(payload) {
      const storage = localStorage['kondukto-chart-list'];
      if (storage && JSON.parse(storage) && JSON.parse(storage)[payload]) {
        const storageList = JSON.parse(storage)[payload];
        const storageListLength = [...storageList.list1, ...storageList.list2].length;
        const globalList = GLOBAL.DASHBOARD_CHARTS[payload];
        const globalListLength = [...globalList.list1, ...globalList.list2].length;
        const newList = {};
        if (storageListLength > globalListLength) {
          ['list1', 'list2'].forEach((key) => {
            newList[key] = storageList[key]
              .filter((item) => [...globalList.list1, ...globalList.list2].includes(item));
          });
          this.dashboardCharts[payload] = newList;
          this.saveDashboardCharts(payload);
        } else if (storageListLength < globalListLength) {
          ['list1', 'list2'].forEach((key) => {
            globalList[key].forEach((s, i) => {
              if ([...storageList.list1, ...storageList.list2].indexOf(s) < 0) {
                storageList[key].splice(i, 0, s);
              }
            });
          });
          this.dashboardCharts[payload] = storageList;
          this.saveDashboardCharts(payload);
        } else {
          this.dashboardCharts[payload] = storageList;
        }
      } else {
        this.dashboardCharts[payload] = GLOBAL.DASHBOARD_CHARTS[payload];
      }
    },

    saveDashboardCharts(payload) {
      const storage = localStorage['kondukto-chart-list'];
      let data = {};
      if (storage) {
        data = {
          ...JSON.parse(storage),
          [payload]: this.dashboardCharts[payload],
        };
      } else {
        data = {
          [payload]: this.dashboardCharts[payload],
        };
      }
      localStorage.setItem('kondukto-chart-list', JSON.stringify(data));
    },

    // ## Get global dashboard filter. ---------------------------------------------------------------
    getGlobalDashboardFilter() {
      const storage = localStorage['kondukto-global-dashboard'];
      if (storage && JSON.parse(storage)) {
        if (JSON.parse(storage) && !JSON.parse(storage).global) {
          this.globalDashboardFilter = {
            ...this.globalDashboardFilter,
            ...JSON.parse(storage),
          };
        } else {
          this.globalDashboardFilter = JSON.parse(storage);
        }
      }
    },

    // ## Save global dashboard filter. --------------------------------------------------------------
    saveGlobalDashboardFilter(payload) {
      const storage = localStorage['kondukto-global-dashboard'];
      let data = {};
      if (storage) {
        data = JSON.parse(storage);
        if (!data[payload.type]) {
          data[payload.type] = {};
        }
        data[payload.type][payload.prefix] = payload.data;
      } else {
        data = {
          [payload.type]: {
            [payload.prefix]: payload.data,
          },
        };
      }
      localStorage.setItem('kondukto-global-dashboard', JSON.stringify(data));
    },

    // ## Remove global dashboard filter. ------------------------------------------------------------
    removeGlobalDashboardFilter() {
      const storage = localStorage['kondukto-global-dashboard'];
      if (!storage) {
        return;
      }
      const data = JSON.parse(storage);
      Object.keys(data).forEach((s) => {
        if (s !== 'global') {
          delete data[s];
        }
      });
      localStorage.setItem('kondukto-global-dashboard', JSON.stringify(data));
    },

    removeUnusedStorage() {
      localStorage.removeItem('kondukto-dSettings');
    },
  },
});

export default useDashboardFilterStore;
