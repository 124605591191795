const dummyData = [...Array(15)].map((_, i) => ({
  key: i,
  id: i,
  cweId: 0,
  cweName: '#######',
  labels: [],
  createdDate: '#######',
  updatedDate: '#######',
}));

export default dummyData;
