const infraProfileDummy = [...Array(15)].map((_, i) => ({
  key: i,
  tool: 'gosec',
  metaData: '########',
  bindTo: '########',
  schedule: '########',
  profileName: '########',
}));

const infraGroupsDummy = [...Array(15)].map((_, i) => ({
  key: i,
  tool: 'gosec',
  metaData: '########',
  bindTo: '########',
  schedule: '########',
}));

const toolProfilesDummy = [...Array(10)].map((s, i) => ({
  id: i,
  name: '###############',
  orgUrl: '###############',
  status: 'empty',
}));

// eslint-disable-next-line import/prefer-default-export
export { infraProfileDummy, infraGroupsDummy, toolProfilesDummy };
