import Api from '@/services/Api';
import { API_URL } from '@/config/';

export default {
  get() {
    return Api().get(API_URL.LICENSE);
  },
  getAppVersion() {
    return Api().get('/version');
  },
};
