import Api from '@/services/Api';
import { API_URL } from '@/config/';

export default {
  get(params) {
    return Api().get(`${API_URL.SCAN_PARAMS}/${params}/webhooks`);
  },
  getKey() {
    return Api().get(`${API_URL.WEB_HOOKS_GENERATE_KEY}`);
  },
  post(params) {
    return Api().post(`${API_URL.SCAN_PARAMS}/` +
      `${params.data.scanparams.id}/webhooks`, params.data);
  },
  update(params) {
    return Api().patch(`${API_URL.SCAN_PARAMS}/` +
      `${params.data.scanparams.id}/webhooks/${params.id}`, params.data);
  },
  remove(params) {
    return Api().delete(`${API_URL.SCAN_PARAMS}/` +
      `${params.data.scanparams.id}/webhooks/${params.id}`);
  },
};
