const PreComponent = '<router-view/>';

export default {
  path: 'assets',
  name: 'assets',
  component: { template: PreComponent },
  children: [
    {
      path: 'kondukto',
      name: 'kondukto-assets-mgmt',
      component: () => import('@/views/pages/member/Project/KonduktoMgmt.vue'),
      meta: {
        title: 'Assets Management',
        activeMenuKeys: ['management'],
        allowedRole: [5, 1],
      },
      beforeEnter: (to) => {
        if (!to.query.page || !to.query.perPage) {
          return {
            name: 'kondukto-assets-mgmt',
            query: { page: 1, perPage: 15 },
          };
        }
      },
    }, {
      path: 'code',
      name: 'code-assets-mgmt',
      component: () => import('@/views/pages/member/Project/CodeMgmt.vue'),
      meta: {
        title: 'Assets Management',
        activeMenuKeys: ['management'],
        allowedRole: [5],
      },
      beforeEnter: (to) => {
        if (!to.query.page || !to.query.perPage) {
          return {
            name: 'code-assets-mgmt',
            query: { page: 1, perPage: 15 },
          };
        }
      },
    },
  ],
};
