const PreComponent = '<router-view/>';

export default {
  path: 'workflows',
  name: 'workflows',
  component: () => import('@/views/layouts/WorkflowsLayouts.vue'),
  redirect: {
    name: 'automation-rules-list',
    query: {
      page: 1,
    },
  },
  children: [
    {
      path: 'automation-rules',
      name: 'automation-rules',
      component: { template: PreComponent },
      redirect: {
        name: 'automation-rules-list',
        query: {
          page: 1,
        },
      },
      children: [
        {
          path: 'list',
          name: 'automation-rules-list',
          component: () => import('@/views/pages/member/Workflows/AutomationRules/List.vue'),
          meta: {
            title: 'Workflows: Automation Rules',
            activeMenuKeys: ['workflows', 'automation-rules'],
            allowedRole: [5],
          },
          beforeEnter: (to) => {
            if (!to.query.page) {
              return {
                name: 'automation-rules-list',
                query: { page: 1 },
              };
            }
          },
        }, {
          path: 'add',
          name: 'automation-rules-add',
          component: () => import('@/views/pages/member/Workflows/AutomationRules/Add.vue'),
          meta: {
            title: 'Workflows: Add Automation Rules',
            activeMenuKeys: ['workflows', 'automation-rules'],
            allowedRole: [5],
          },
        }, {
          path: 'edit/:id',
          name: 'automation-rules-edit',
          component: () => import('@/views/pages/member/Workflows/AutomationRules/Edit.vue'),
          meta: {
            title: 'Workflows: Edit Automation Rules',
            activeMenuKeys: ['workflows', 'automation-rules'],
            allowedRole: [5],
          },
        },
      ],
    }, {
      path: 'remediation',
      name: 'remediation-database',
      component: () => import('@/views/pages/member/Workflows/Remediations.vue'),
      meta: {
        title: 'Workflows: Remediation',
        activeMenuKeys: ['workflows', 'remediation'],
        allowedRole: [5],
      },
      beforeEnter: (to) => {
        if (!to.query.page) {
          return {
            name: 'remediation-database',
            query: { page: 1 },
          };
        }
      },
    }, {
      path: 'reports',
      name: 'settings-reports',
      component: () => import('@/views/layouts/ReportsLayout.vue'),
      redirect: {
        name: 'report-profiles',
        query: {
          page: 1,
        },
      },
      children: [
        {
          path: 'profiles',
          name: 'report-profiles',
          component: () => import('@/views/pages/member/Workflows/Reports/Profiles.vue'),
          meta: {
            title: 'Workflows: Profiles',
            activeMenuKeys: ['workflows', 'report-profiles', 'profiles'],
            allowedRole: [5],
          },
          beforeEnter: (to) => {
            if (!to.query.page) {
              return {
                name: 'report-profiles',
                query: { page: 1 },
              };
            }
          },
        }, {
          path: 'history',
          name: 'report-history',
          component: () => import('@/views/pages/member/Workflows/Reports/History.vue'),
          meta: {
            title: 'Workflows: History',
            activeMenuKeys: ['workflows', 'report-profiles', 'history'],
            allowedRole: [5],
          },
          beforeEnter: (to) => {
            if (!to.query.page) {
              return {
                name: 'report-history',
                query: { page: 1 },
              };
            }
          },
        },
      ],
    },
  ],
};
