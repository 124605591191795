import Api from '@/services/Api';

export default {
  getDemohubIntegrations(params) {
    return Api().get('demo-hub/partners', { params });
  },
  getDemohubScannerDetails(params) {
    return Api().get(`demo-hub/partner-groups/${params.partnerGroupId}/partners/` +
      `${params.id}/detail`);
  },
  sendDemohubRequest(params) {
    return Api().post('demo-hub', params);
  },
};
