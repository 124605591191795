import Api from '@/services/Api';
import { API_URL } from '@/config/';

export default {
  getCommitters() {
    return Api().get(`${API_URL.COMMITTERS}`);
  },
  getCommittersInsightList(params) {
    return Api().get(`${API_URL.COMMITTERS}/insight`, { params });
  },
  getCommitterInsightDetail(params) {
    return Api().get(`${API_URL.COMMITTERS}/insight/detail`, { params });
  },
  getCommitterInsightLanguage(params) {
    return Api().get(`${API_URL.COMMITTERS}/insight/language`, { params });
  },
};
