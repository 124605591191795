export default {
  path: 'teams',
  name: 'teams',
  component: () => import('@/views/layouts/TeamLayouts.vue'),
  meta: {
    breadcrumbName: 'Teams',
  },
  redirect: {
    name: 'team-list',
  },
  children: [
    {
      path: 'list',
      name: 'team-list',
      component: () => import('@/views/pages/member/Team/List.vue'),
      meta: {
        title: 'Teams',
        activeMenuKeys: ['teams'],
        allowedRole: [1, 5],
      },
      beforeEnter: (to) => {
        if (!to.query.page) {
          return {
            name: 'team-list',
            query: { page: 1, perPage: 15 },
          };
        }
      },
    }, {
      path: ':id/details',
      name: 'team-details',
      component: () => import('@/views/pages/member/Team/Details.vue'),
      meta: {
        title: 'Teams',
        activeMenuKeys: ['teams'],
        allowedRole: [1, 5],
      },
    },
  ],
};
