import { defineStore } from 'pinia';

import UserService from '@/services/User';

import ErrorHandling from '@/mixins/ErrorHandling';

import {
  dummyData,
  dummyTeamsData,
  dummyProjectsData,
  dummyProductData,
} from './_dummyData';

const useUserStore = defineStore({
  id: 'User',
  state: () => ({
    // ## All users table. ---------------------------------------------------------------------------------------------
    allUsers: [],
    allUsersPending: false,
    allUsersDone: false,

    // ## All users table. ---------------------------------------------------------------------------------------------
    allUsersTable: dummyData,
    allUsersTableTotal: 0,
    allUsersTablePending: false,
    allUsersTableDone: false,
    allUsersTableFail: false,
    allUsersTableFailMsg: null,
    allUsersTableCurrentUser: {},

    // ## Roles. -------------------------------------------------------------------------------------------------------
    userRoles: [],
    userRolesPending: false,
    userRolesDone: false,

    // ## Validate username. -------------------------------------------------------------------------------------------
    validateUsername: true,
    validateUsernamePending: false,
    validateUsernameDone: false,

    // ## Validate email. ----------------------------------------------------------------------------------------------
    validateEmail: true,
    validateEmailPending: false,
    validateEmailDone: false,

    // ## User details. ------------------------------------------------------------------------------------------------
    userDetails: null,
    userDetailsPending: false,
    userDetailsDone: false,

    // ## Update user. -------------------------------------------------------------------------------------------------
    updateUser: null,
    updateUserPending: false,
    updateUserDone: false,

    // ## Update password. ---------------------------------------------------------------------------------------------
    updateUserPasswordData: null,
    updateUserPasswordPending: false,
    updateUserPasswordDone: false,
    updateUserPasswordFail: false,

    // ## Add user. ----------------------------------------------------------------------------------------------------
    addUserData: null,
    addUserPending: false,
    addUserDone: false,

    // ## Remove user. -------------------------------------------------------------------------------------------------
    removeUserData: null,
    removeUserPending: false,
    removeUserDone: false,

    // ## Update status. -----------------------------------------------------------------------------------------------
    updateUserStatusData: null,
    updateUserStatusPending: false,
    updateUserStatusDone: false,
    updateUserStatusDoneMsg: null,
    updateUserStatusFail: false,
    updateUserStatusFailMsg: null,

    // ## Update user login type. --------------------------------------------------------------------------------------
    updateUserLoginTypeData: null,
    updateUserLoginTypePending: false,
    updateUserLoginTypeDone: false,
    updateUserLoginTypeFail: false,
    updateUserLoginTypeFailMsg: null,

    // ## User teams. --------------------------------------------------------------------------------------
    userTeams: dummyTeamsData,
    userTeamsPending: false,
    userTeamsTotal: 0,
    userTeamsPerPage: 15,
    userTeamsDone: false,
    userTeamsFail: false,
    userTeamsFailMsg: null,

    // ## User projects. --------------------------------------------------------------------------------------
    userProjects: dummyProjectsData,
    userProjectsPending: false,
    userProjectsTotal: 0,
    userProjectsPerPage: 15,
    userProjectsDone: false,
    userProjectsFail: false,
    userProjectsFailMsg: null,

    // ## User products. --------------------------------------------------------------------------------------
    userProducts: dummyProductData,
    userProductsPending: false,
    userProductsTotal: 0,
    userProductsPerPage: 15,
    userProductsDone: false,
    userProductsFail: false,
    userProductsFailMsg: null,

    // ## User products. --------------------------------------------------------------------------------------
    updateUserScopePending: false,
    updateUserScopeDone: false,
    updateUserScopeFail: false,
    updateUserScopeFailMsg: null,
  }),
  actions: {
    // ## Get all users. -----------------------------------------------------------------------------------------------
    async getAllUsers(data) {
      this.allUsersPending = true;

      try {
        const res = await UserService.getAllUsers(data);
        this.allUsers = res.data.data;
        this.allUsersDone = !this.allUsersDone;
        this.allUsersPending = false;
      } catch (error) {
        this.allUsersPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get all users table. -----------------------------------------------------------------------------------------
    async getAllUsersTable(data) {
      this.allUsersTablePending = true;
      this.allUsersTable = dummyData;

      try {
        const params = {
          limit: data.perPage,
          start: (data.page - 1) * data.perPage,
          sort_by: data.sort,
          q: data.username,
        };
        const res = await UserService.get(params);
        this.allUsersTableTotal = res.data.total;
        this.setGetAllUsersTable(res.data);
        this.allUsersTableDone = !this.allUsersTableDone;
        this.allUsersTablePending = false;
      } catch (error) {
        this.allUsersTable = dummyData;
        this.allUsersTableTotal = 0;
        this.allUsersTablePending = false;
        this.allUsersTableFail = !this.allUsersTableFail;
        this.allUsersTableFailMsg = ErrorHandling(error);
      }
    },

    setGetAllUsersTable(payload) {
      this.allUsersTableCurrentUser = payload.current_user;
      this.allUsersTable = payload.data.map((s, i) => ({
        key: i,
        username: s.username,
        email: s.email,
        userId: s.id,
        role: s.role.name,
        status: s.status,
        shadowUser: (s.shadow_user ? s.shadow_user.username : ''),
        type: s.auth_man.ok ? s.auth_man.tool : 'internal',
        teamIds: s.teams,
      }));
    },

    // ## Get user roles. ----------------------------------------------------------------------------------------------
    async getUserRoles() {
      this.userRolesPending = true;

      try {
        const res = await UserService.getRoles();
        this.userRoles = res.data.data;
        this.userRolesDone = !this.userRolesDone;
        this.userRolesPending = false;
      } catch (error) {
        this.userRolesPending = false;
        ErrorHandling(error);
      }
    },

    // ## Validate username. -------------------------------------------------------------------------------------------
    async getValidateUsername(data) {
      this.validateUsernamePending = true;

      try {
        const res = await UserService.validate(data);
        this.validateUsername = res.data.result;
        this.validateUsernamePending = false;
        this.validateUsernameDone = !this.validateUsernameDone;
      } catch (error) {
        this.validateUsernamePending = false;
        ErrorHandling(error);
      }
    },

    // ## Validate email. ----------------------------------------------------------------------------------------------
    async getValidateEmail(data) {
      this.validateEmailPending = true;

      try {
        const res = await UserService.validate(data);
        this.validateEmail = res.data.result;
        this.validateEmailPending = false;
        this.validateEmailDone = !this.validateEmailDone;
      } catch (error) {
        this.validateEmailPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get user detail. ---------------------------------------------------------------------------------------------
    async getUserDetails(data) {
      this.userDetailsPending = true;

      try {
        const res = await UserService.getUserDetails(data);
        this.userDetails = res.data.data;
        this.userDetailsPending = false;
        this.userDetailsDone = !this.userDetailsDone;
      } catch (error) {
        this.userDetailsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Update user. -------------------------------------------------------------------------------------------------
    async updateUser(data) {
      this.updateUserPending = true;

      try {
        await UserService.update(data);
        this.updateUserDone = !this.updateUserDone;
        this.updateUserPending = false;
      } catch (error) {
        this.updateUserPending = false;
        ErrorHandling(error);
      }
    },

    // ## Update user password. ----------------------------------------------------------------------------------------
    async updateUserPassword(data) {
      this.updateUserPasswordPending = true;

      try {
        const res = await UserService.updatePassword(data);
        this.updateUserPasswordData = res.data.data;
        this.updateUserPasswordPending = false;
        this.updateUserPasswordDone = !this.updateUserPasswordDone;
      } catch (error) {
        this.updateUserPasswordPending = false;
        this.updateUserPasswordFail = !this.updateUserPasswordFail;
        ErrorHandling(error);
      }
    },

    // ## Add user. ----------------------------------------------------------------------------------------------------
    async addUser(data) {
      this.addUserPending = true;

      try {
        const res = await UserService.post(data);
        this.addUserData = res.data.data;
        this.addUserPending = false;
        this.addUserDone = !this.addUserDone;
      } catch (error) {
        this.addUserPending = false;
        ErrorHandling(error);
      }
    },

    // ## Remove user. -------------------------------------------------------------------------------------------------
    async removeUser(data) {
      this.removeUserPending = true;

      try {
        const res = await UserService.remove(data);
        this.removeUserData = res.data.data;
        this.removeUserPending = false;
        this.removeUserDone = !this.removeUserDone;
      } catch (error) {
        this.removeUserPending = false;
        ErrorHandling(error);
      }
    },

    // ## Update user status. ------------------------------------------------------------------------------------------
    async updateUserStatus(data) {
      this.updateUserStatusPending = true;

      try {
        const res = await UserService.updateStatus(data);
        this.updateUserStatusData = res.data.data;
        this.updateUserStatusPending = false;
        this.updateUserStatusDone = !this.updateUserStatusDone;
        this.updateUserStatusDoneMsg = res.data.message;
      } catch (error) {
        this.updateUserStatusPending = false;
        this.updateUserStatusFail = !this.updateUserStatusFail;
        this.updateUserStatusFailMsg = ErrorHandling(error);
      }
    },

    // ## Update user login type. --------------------------------------------------------------------------------------
    async updateUserLoginType(data) {
      this.updateUserLoginTypePending = true;

      try {
        const res = await UserService.updateUserLoginType(data);
        this.updateUserLoginTypeData = res.data.data;
        this.updateUserLoginTypePending = false;
        this.updateUserLoginTypeDone = !this.updateUserLoginTypeDone;
      } catch (error) {
        this.updateUserLoginTypePending = false;
        this.updateUserLoginTypeFail = !this.updateUserLoginTypeFail;
        this.updateUserLoginTypeFailMsg = ErrorHandling(error);
      }
    },

    // ## Get user teams. --------------------------------------------------------------------------------------
    async getUserTeams(data) {
      this.userTeamsPending = true;

      try {
        const params = {
          id: data.id,
          query: {
            limit: this.userTeamsPerPage,
            start: (data.page - 1) * this.userTeamsPerPage,
            sort_by: data.sort,
            q: data.q,
          },
        };
        const res = await UserService.getUserTeams(params);
        this.setGetUserTeams(res.data.data);
        this.userTeamsTotal = res.data.total;
        this.userTeamsPending = false;
        this.userTeamsDone = !this.userTeamsDone;
      } catch (error) {
        this.userTeams = dummyTeamsData;
        this.userTeamsPending = false;
        this.userTeamsFail = !this.userTeamsFail;
        this.userTeamsFailMsg = ErrorHandling(error);
      }
    },

    setGetUserTeams(payload) {
      if (!payload) {
        return;
      }
      this.userTeams = payload.map((s) => ({
        id: s.id,
        teamName: s.name,
        issueResponsible: s.issue_responsible ? s.issue_responsible.email : '-',
      }));
    },

    // ## Get user projects. --------------------------------------------------------------------------------------
    async getUserProjects(data) {
      this.userProjectsPending = true;

      try {
        const params = {
          id: data.id,
          query: {
            limit: this.userProjectsPerPage,
            start: (data.page - 1) * this.userProjectsPerPage,
            sort_by: data.sort,
            name: data.name,
          },
        };
        const res = await UserService.getUserProjects(params);
        this.setGetUserProjects(res.data.data);
        this.userProjectsTotal = res.data.total;
        this.userProjectsPending = false;
        this.userProjectsDone = !this.userProjectsDone;
      } catch (error) {
        this.userProjects = dummyProjectsData;
        this.userProjectsPending = false;
        this.userProjectsFail = !this.userProjectsFail;
        this.userProjectsFailMsg = ErrorHandling(error);
      }
    },

    setGetUserProjects(payload) {
      if (!payload) {
        return;
      }
      this.userProjects = payload.map((s) => ({
        id: s.id,
        projectName: s.name,
        accessType: s.access_type.replaceAll('_', ''),
        excluded: s.excluded,
        excludedByProduct: s.excluded_by_product,
      }));
    },

    // ## Get user products. --------------------------------------------------------------------------------------
    async getUserProducts(data) {
      this.userProductsPending = true;

      try {
        const params = {
          id: data.id,
          query: {
            limit: this.userProductsPerPage,
            start: (data.page - 1) * this.userProductsPerPage,
            sort_by: data.sort,
            q: data.q,
          },
        };
        const res = await UserService.getUserProducts(params);
        this.setGetUserProducts(res.data.data);
        this.userProductsTotal = res.data.total;
        this.userProductsPending = false;
        this.userProductsDone = !this.userProductsDone;
      } catch (error) {
        this.userProducts = dummyProductData;
        this.userProductsPending = false;
        this.userProductsFail = !this.userProductsFail;
        this.userProductsFailMsg = ErrorHandling(error);
      }
    },

    setGetUserProducts(payload) {
      if (!payload) {
        return;
      }
      this.userProducts = payload.map((s) => ({
        id: s.id,
        productName: s.name,
        accessType: s.access_type.replaceAll('_', ''),
        excluded: s.excluded,
      }));
    },

    // ## Update user scope. --------------------------------------------------------------------------------------
    async updateUserScope(data) {
      this.updateUserScopePending = true;

      try {
        await UserService.updateUserScope(data);
        this.updateUserScopePending = false;
        this.updateUserScopeDone = !this.updateUserScopeDone;
      } catch (error) {
        this.updateUserScopePending = false;
        this.userProductsFail = !this.userProductsFail;
        this.userProductsFailMsg = ErrorHandling(error);
      }
    },
  },
});

export default useUserStore;
