/* eslint-disable import/no-cycle */
import Api from '@/services/Api';
import { API_URL } from '@/config/';

export default {
  login(params) {
    return Api().post(API_URL.LOGIN, params);
  },
  logout() {
    return Api().get(API_URL.LOGOUT);
  },
  getOAuthLoginParams(params) {
    return Api().get(`/authman/${params}/login`);
  },
  oAuthLoginCallback(params) {
    return Api().get(`/authman/${params.type}/callback?` +
      `code=${params.query.code}&state=${params.query.state}`);
  },
};
