export default {
  path: 'users',
  name: 'users',
  component: () => import('@/views/layouts/UserLayouts.vue'),
  meta: {
    breadcrumbName: 'Users',
  },
  redirect: {
    name: 'user-list',
  },
  children: [
    {
      path: 'list',
      name: 'user-list',
      component: () => import('@/views/pages/member/User/List.vue'),
      meta: {
        title: 'Users',
        activeMenuKeys: ['users'],
        allowedRole: [1, 5],
      },
      beforeEnter: (to) => {
        if (!to.query.page) {
          return {
            name: 'user-list',
            query: { page: 1, perPage: 15 },
          };
        }
      },
    }, {
      path: 'add',
      name: 'user-add',
      component: () => import('@/views/pages/member/User/Add.vue'),
      meta: {
        title: 'Add New User',
        activeMenuKeys: ['users'],
        allowedRole: [1, 5],
      },
    }, {
      path: ':id',
      name: 'user-edit',
      component: () => import('@/views/layouts/UserEditLayouts.vue'),
      children: [
        {
          path: 'info',
          name: 'user-info',
          component: () => import('@/views/pages/member/User/Edit/Info.vue'),
          meta: {
            title: 'Edit User',
            activeMenuKeys: ['users', 'user-info'],
            allowedRole: [1, 5],
          },
        }, {
          path: 'teams',
          name: 'user-teams',
          component: () => import('@/views/pages/member/User/Edit/Teams.vue'),
          meta: {
            title: 'Edit User',
            activeMenuKeys: ['users', 'user-teams'],
            allowedRole: [1, 5],
          },
          beforeEnter: (to) => {
            if (!to.query.page) {
              return {
                name: 'user-teams',
                params: {
                  id: to.params.id,
                },
                query: { page: 1 },
              };
            }
          },
        }, {
          path: 'projects',
          name: 'user-projects',
          component: () => import('@/views/pages/member/User/Edit/Projects.vue'),
          meta: {
            title: 'Edit User',
            activeMenuKeys: ['users', 'user-projects'],
            allowedRole: [1, 5],
          },
          beforeEnter: (to) => {
            if (!to.query.page) {
              return {
                name: 'user-projects',
                params: {
                  id: to.params.id,
                },
                query: { page: 1 },
              };
            }
          },
        }, {
          path: 'products',
          name: 'user-products',
          component: () => import('@/views/pages/member/User/Edit/Products.vue'),
          meta: {
            title: 'Edit User',
            activeMenuKeys: ['users', 'user-products'],
            allowedRole: [1, 5],
          },
          beforeEnter: (to) => {
            if (!to.query.page) {
              return {
                name: 'user-products',
                params: {
                  id: to.params.id,
                },
                query: { page: 1 },
              };
            }
          },
        },
      ],
    },
  ],
};
