import Api from '@/services/Api';
import { API_URL } from '@/config/';

export default {
  get(params) {
    return Api().get(API_URL.TEAMS, { params });
  },
  view(params) {
    return Api().get(`${API_URL.TEAMS}/${params}`);
  },
  validate(params) {
    return Api().get(`${API_URL.TEAMS_VALIDATE}?name=${params.name}`);
  },
  post(params) {
    return Api().post(API_URL.TEAMS, params);
  },
  update(data) {
    const params = data.query;
    return Api().patch(`${API_URL.TEAMS}/${data.id}`, params);
  },
  remove(params) {
    return Api().delete(`${API_URL.TEAMS}/${params.id}`);
  },
  delegate(params) {
    return Api().patch(`${API_URL.TEAMS}/delegate`, params);
  },
  getTeamMembers(data) {
    const params = data.query;
    return Api().get(`${API_URL.TEAMS}/${data.id}/members`, { params });
  },
  updateMembersOnTeam(data) {
    const params = data.query;
    return Api().patch(`${API_URL.TEAMS}/${data.id}/members`, params);
  },
};
