import { defineStore } from 'pinia';

const useMessageBoxStore = defineStore({
  id: 'Messagebox',
  state: () => ({
    // Messagebox. -----------------------------------------------------------------------------------
    messageBoxKey: null,
    messageBoxType: 'open',
    messageBoxDescription: null,
    messageBoxMessage: null,
    messageBoxOpenChange: false,
  }),
  actions: {
    // ## Open. --------------------------------------------------------------------------------------
    messageBoxOpen(payload) {
      this.messageBoxType = payload.type ? payload.type : 'open';
      this.messageBoxKey = payload.key ? payload.key : null;
      this.messageBoxMessage = payload.message;
      this.messageBoxDescription = payload.description;

      this.messageBoxOpenChange = !this.messageBoxOpenChange;
    },
  },
});

export default useMessageBoxStore;
