export default {
  path: 'dashboard',
  name: 'dashboard',
  component: () => import('@/views/pages/member/Dashboard/Dashboard.vue'),
  meta: {
    title: 'Dashboard',
    activeMenuKeys: ['dashboard'],
    allowedRole: [0, 1, 2, 3, 5],
  },
};
