export default {
  path: 'demohub',
  name: 'demohub',
  component: () => import('@/views/layouts/DemohubLayout.vue'),
  redirect: {
    name: 'demohub-scanners',
    query: {
      page: 1,
      toolType: 'sast',
    },
  },
  children: [
    {
      path: 'scanners',
      name: 'demohub-scanners',
      component: () => import('@/views/pages/member/Demohub/Scanners.vue'),
      meta: {
        title: 'Demo Hub: Scanners',
        activeMenuKeys: ['demohub', 'scanners'],
        allowedRole: [5],
      },
      beforeEnter: (to) => {
        if (!to.query.page || !to.query.toolType) {
          return {
            name: 'demohub-scanners',
            query: {
              page: 1,
              toolType: 'sast',
            },
          };
        }
      },
    }, {
      path: 'application-lifecycle-management',
      name: 'demohub-application-lifecycle-management',
      component: () => import('@/views/pages/member/Demohub/ApplicationLifecycleManagement.vue'),
      meta: {
        title: 'Demo Hub: Application Lifecycle Management',
        activeMenuKeys: ['demohub', 'alm'],
        allowedRole: [5],
      },
      beforeEnter: (to) => {
        if (!to.query.page) {
          return {
            name: 'demohub-application-lifecycle-management',
            query: {
              page: 1,
            },
          };
        }
      },
    }, {
      path: 'single-sign-on-tools',
      name: 'demohub-single-sign-on-tools',
      component: () => import('@/views/pages/member/Demohub/SingleSignOnTools.vue'),
      meta: {
        title: 'Demo Hub: Single Sign-On Tools',
        activeMenuKeys: ['demohub', 'sso'],
        allowedRole: [5],
      },
      beforeEnter: (to) => {
        if (!to.query.page) {
          return {
            name: 'demohub-single-sign-on-tools',
            query: {
              page: 1,
            },
          };
        }
      },
    }, {
      path: 'training',
      name: 'demohub-training',
      component: () => import('@/views/pages/member/Demohub/Training.vue'),
      meta: {
        title: 'Demo Hub: Training',
        activeMenuKeys: ['demohub', 'training'],
        allowedRole: [5],
      },
      beforeEnter: (to) => {
        if (!to.query.page) {
          return {
            name: 'demohub-training',
            query: {
              page: 1,
            },
          };
        }
      },
    },
  ],
};
