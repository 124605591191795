// ## Config Inc.
import { ENDPOINTS } from '@/config/';

// eslint-disable-next-line no-unused-vars
const dummyData = [...Array(ENDPOINTS.PERPAGE)].map((_, i) => ({
  id: i,
  name: '########',
  summary: {
    critical: 0,
    high: 0,
    medium: 0,
    low: 0,
    total: 0,
  },
  projects: [
    {
      key: 1,
      sast: 0,
      dast: 0,
      sca: 0,
    },
  ],
  children: [
    {
      name: 'sast',
      summary: {
        critical: 0,
        high: 0,
        low: 0,
        medium: 0,
        total: 0,
      },
    }, {
      name: 'dast',
      summary: {
        critical: 0,
        high: 0,
        medium: 0,
        low: 0,
        total: 0,
      },
    }, {
      name: 'sca',
      summary: {
        critical: 0,
        high: 0,
        medium: 0,
        low: 0,
        total: 0,
      },
    },
  ],
}));

export default dummyData;
