const automationRulesDummy = [...Array(5)].map((s, i) => ({
  id: i,
  name: '#####',
  issue: {
    ok: false,
  },
  notification: {
    ok: false,
  },
  release: {
    ok: false,
  },
  suppression: {
    ok: false,
  },
}));

const compiledAutomationRulesDummy = [...Array(3)].map((s, i) => ({
  id: i,
  type: 'vulnerability',
  rule: [{
    id: i,
    field: 'severity',
    operand: 'in',
    value: '####',
  }],
}));

export {
  automationRulesDummy,
  compiledAutomationRulesDummy,
};
