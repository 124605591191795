import { defineStore } from 'pinia';

import LicenseService from '@/services/License';

import ErrorHandling from '@/mixins/ErrorHandling';

import dayjs from 'dayjs';

import dummyData from './_dummyData';

const useLicenseStore = defineStore({
  id: 'License',
  state: () => ({
    // ## Get license. -------------------------------------------------------------------------------------------------
    license: dummyData,
    licensePending: false,
    licenseDone: false,

    // ## Get app version. ---------------------------------------------------------------------------------------------
    appVersion: null,
    appVersionPending: false,
    appVersionDone: false,
  }),
  actions: {
    // ## Get license. -------------------------------------------------------------------------------------------------
    async getLicense() {
      this.licensePending = true;

      try {
        const res = await LicenseService.get();
        this.setGetLicense(res.data);
      } catch (error) {
        this.licensePending = false;
        ErrorHandling(error);
      }
    },

    setGetLicense(payload) {
      this.license = {
        project: {
          status: payload.used_projects >= payload.max_projects,
          max: payload.max_projects,
          used: payload.used_projects,
        },
        user: {
          status: payload.used_users >= payload.max_users,
          max: payload.max_users,
          used: payload.used_users,
        },
        status: payload.status,
        expireDate: dayjs(new Date(payload.expire_date.split('+')[0]
          .split(' ')[0])).format('DD MMM YYYY'),
      };
      this.licensePending = false;
      this.licenseDone = !this.licenseDone;
    },

    // ## Get version. -------------------------------------------------------------------------------------------------
    async getAppVersion() {
      this.appVersionPending = true;

      try {
        const res = await LicenseService.getAppVersion();
        this.setGetAppVersion(res.data);
      } catch (error) {
        this.appVersionPending = false;
        ErrorHandling(error);
      }
    },

    setGetAppVersion(payload) {
      [this.appVersion] = payload.version.split('-');
      this.appVersionPending = false;
      this.appVersionDone = !this.appVersionDone;
    },
  },
});

export default useLicenseStore;
