// ## Config Inc.
import { SCANS } from '@/config/';

// eslint-disable-next-line no-unused-vars
const dummyData = [...Array(SCANS.PERPAGE)].map((_, i) => ({
  key: i,
  id: i,
  scanDate: '##########',
  queueDate: '##########',
  scanner: 'checkmarx',
  project: '########',
  projectId: 0,
  team: '########',
  status: '######',
  message: '#########################',
  recurrence: '######',
  critical: '##',
  high: '##',
  medium: '##',
  low: '##',
  info: '##',
  metaData: '###',
  branch: '###',
  duration: 0,
  scanParams: {
    pr: {
      ok: false,
    },
    tool: {
      name: 'gosec',
    },
  },
  action: 0,
}));

export default dummyData;
