import Api from '@/services/Api';
import { API_URL } from '@/config/';

export default {
  addRemediation(params) {
    return Api().post(`${API_URL.REMEDIATIONS}`, params);
  },
  getAllRemediationTable(params) {
    return Api().get(`${API_URL.REMEDIATIONS}/list`, { params });
  },
  getRemediationDetails(params) {
    return Api().get(`${API_URL.REMEDIATIONS}`, { params });
  },
  deleteRemediation(params) {
    return Api().delete(`${API_URL.REMEDIATIONS}/${params}`);
  },
  updateRemediation(data) {
    const params = data.query;
    return Api().patch(`${API_URL.REMEDIATIONS}/${data.id}`, params);
  },
};
