const PreComponent = '<router-view/>';

export default {
  path: 'projects',
  name: 'project',
  component: { template: PreComponent },
  children: [
    {
      path: 'list',
      name: 'project-list',
      component: () => import('@/views/pages/member/Project/List.vue'),
      meta: {
        title: 'Projects',
        activeMenuKeys: ['projects'],
        allowedRole: [0, 1, 3, 5, 10],
      },
      beforeEnter: (to) => {
        if (!to.query.page) {
          return {
            name: 'project-list',
            query: {
              page: 1,
            },
          };
        }
      },
    }, {
      path: ':id',
      name: 'project-details',
      component: () => import('@/views/layouts/Projects/ProjectViewLayout.vue'),
      children: [
        {
          path: 'details',
          name: 'project-details',
          component: () => import('@/views/pages/member/Project/Details.vue'),
          children: [
            {
              path: 'appsec',
              name: 'project-appsec-details',
              component: () => import('@/views/pages/member/Project/Details.vue'),
              meta: {
                title: 'Project: Details',
                activeMenuKeys: ['projects', 'project-details'],
                allowedRole: [0, 1, 2, 3, 5],
              },
            }, {
              path: 'infra',
              name: 'project-infra-details',
              component: () => import('@/views/pages/member/Project/Details.vue'),
              meta: {
                title: 'Project: Details',
                activeMenuKeys: ['projects', 'project-details'],
                allowedRole: [0, 1, 2, 3, 5],
              },
            },
          ],
        }, {
          path: 'scans',
          name: 'project-scans',
          component: () => import('@/views/layouts/Projects/ProjectScansViewLayout.vue'),
          redirect: {
            name: 'project-scans-completed',
          },
          children: [
            {
              path: 'completed',
              name: 'project-scans-completed',
              component: () => import('@/views/pages/member/Project/Scans/Completed.vue'),
              meta: {
                title: 'Project: Scans',
                activeMenuKeys: ['projects', 'project-scans-completed'],
                allowedRole: [0, 1, 3, 5],
              },
              beforeEnter: (to) => {
                if (!to.query.page) {
                  return {
                    name: 'project-scans-completed',
                    params: { id: to.params.id },
                    query: { page: 1 },
                  };
                }
              },
            }, {
              path: 'failed',
              name: 'project-scans-failed',
              component: () => import('@/views/pages/member/Project/Scans/Failed.vue'),
              meta: {
                title: 'Project: Failed Scans',
                activeMenuKeys: ['projects', 'project-scans-completed'],
                allowedRole: [0, 1, 3, 5],
              },
              beforeEnter: (to) => {
                if (!to.query.page) {
                  return {
                    name: 'project-scans-failed',
                    params: { id: to.params.id },
                    query: { page: 1 },
                  };
                }
              },
            },
          ],
        }, {
          path: 'imports',
          name: 'project-scans-imports',
          component: () => import('@/views/pages/member/Project/Scans/Imports.vue'),
          meta: {
            title: 'Project: Imports',
            activeMenuKeys: ['projects', 'project-scans-imports'],
            allowedRole: [0, 1, 3, 5, 10],
          },
          beforeEnter: (to) => {
            if (!to.query.page) {
              return {
                name: 'project-scans-imports',
                params: { id: to.params.id },
                query: { page: 1 },
              };
            }
          },
        }, {
          path: 'vulns',
          name: 'project-vulns',
          component: () => import('@/views/layouts/Projects/ProjectVulnsViewLayout.vue'),
          children: [
            {
              path: 'appsec',
              name: 'project-appsec-vulns',
              component: () => import('@/views/pages/member/Project/Vulns/AppSec.vue'),
              meta: {
                title: 'AppSec - All',
                activeMenuKeys: ['projects', 'project-vulns', 'appsec'],
                allowedRole: [0, 1, 2, 3, 5, 10],
              },
              beforeEnter: (to) => {
                if (!to.query.page || !to.query.perPage) {
                  return {
                    name: 'project-appsec-vulns',
                    params: { id: to.params.id },
                    query: { page: 1, perPage: 15 },
                  };
                }
              },
            }, {
              path: 'appsec/filePath',
              name: 'project-appsec-file-path-vulns',
              component: () => import('@/views/pages/member/Project/Vulns/AppSecFilePath.vue'),
              meta: {
                title: 'AppSec - All',
                activeMenuKeys: ['projects', 'project-vulns', 'appsec'],
                allowedRole: [0, 1, 2, 3, 5, 10],
              },
              beforeEnter: (to) => {
                if (!to.query.page || !to.query.perPage) {
                  return {
                    name: 'project-appsec-file-path-vulns',
                    params: { id: to.params.id },
                    query: { page: 1, perPage: 15 },
                  };
                }
              },
            }, {
              path: 'appsec/cweId',
              name: 'project-appsec-cwe-id-vulns',
              component: () => import('@/views/pages/member/Project/Vulns/AppSecCweId.vue'),
              meta: {
                title: 'AppSec - All',
                activeMenuKeys: ['projects', 'project-vulns', 'appsec'],
                allowedRole: [0, 1, 2, 3, 5, 10],
              },
              beforeEnter: (to) => {
                if (!to.query.page || !to.query.perPage) {
                  return {
                    name: 'project-appsec-cwe-id-vulns',
                    params: { id: to.params.id },
                    query: { page: 1, perPage: 15 },
                  };
                }
              },
            }, {
              path: 'clould/:type',
              name: 'project-cloud-vulns',
              component: () => import('@/views/pages/member/Project/Vulns/Cloud.vue'),
              meta: {
                title: 'Cloud - All',
                activeMenuKeys: ['projects', 'project-vulns', 'cloud'],
                allowedRole: [0, 1, 2, 3, 5, 10],
              },
              beforeEnter: (to) => {
                if (!to.query.page || !to.query.perPage) {
                  return {
                    name: 'project-cloud-vulns',
                    params: { id: to.params.id },
                    query: { page: 1, perPage: 15 },
                  };
                }
              },
            }, {
              path: 'infra',
              name: 'project-infra-vulns',
              component: () => import('@/views/pages/member/Project/Vulns/Infra.vue'),
              meta: {
                title: 'Infrastructure - All',
                activeMenuKeys: ['projects', 'project-vulns', 'infra'],
                allowedRole: [0, 1, 2, 3, 5, 10],
              },
              beforeEnter: (to) => {
                if (!to.query.page || !to.query.perPage) {
                  return {
                    name: 'project-infra-vulns',
                    params: { id: to.params.id },
                    query: { page: 1, perPage: 15 },
                  };
                }
              },
            }, {
              path: 'infra/ip',
              name: 'project-infra-ip-vulns',
              component: () => import('@/views/pages/member/Project/Vulns/InfraIp.vue'),
              meta: {
                title: 'Infrastructure - All',
                activeMenuKeys: ['projects', 'project-vulns', 'infra'],
                allowedRole: [0, 1, 2, 3, 5, 10],
              },
              beforeEnter: (to) => {
                if (!to.query.page || !to.query.perPage) {
                  return {
                    name: 'project-infra-ip-vulns',
                    params: { id: to.params.id },
                    query: { page: 1, perPage: 15 },
                  };
                }
              },
            }, {
              path: 'appsec-duplicates',
              name: 'project-appsec-duplicate-vulns',
              component: () => import('@/views/pages/member/Project/Vulns/Duplicates.vue'),
              meta: {
                title: 'AppSec - Duplicates',
                activeMenuKeys: ['projects', 'project-vulns', 'appsec-duplicate'],
                allowedRole: [1, 3, 5],
              },
              beforeEnter: (to) => {
                if (!to.query.page) {
                  return {
                    name: 'project-appsec-duplicate-vulns',
                    params: { id: to.params.id },
                    query: { page: 1 },
                  };
                }
              },
            }, {
              path: 'infra-duplicates',
              name: 'project-infra-duplicate-vulns',
              component: () => import('@/views/pages/member/Project/Vulns/Duplicates.vue'),
              meta: {
                title: 'Infra - Duplicates',
                activeMenuKeys: ['projects', 'project-vulns', 'infra-duplicate'],
                allowedRole: [1, 3, 5],
              },
              beforeEnter: (to) => {
                if (!to.query.page) {
                  return {
                    name: 'project-infra-duplicate-vulns',
                    params: { id: to.params.id },
                    query: { page: 1 },
                  };
                }
              },
            }, {
              path: 'appsec/add',
              name: 'add-project-appsec-vulns',
              component: () => import('@/views/pages/member/Project/Vulns/Add.vue'),
              meta: {
                title: 'Add AppSec Vulns',
                activeMenuKeys: ['projects', 'project-vulns', 'appsec'],
                allowedRole: [1, 5, 10],
              },
            }, {
              path: 'infra/add',
              name: 'add-project-infra-vulns',
              component: () => import('@/views/pages/member/Project/Vulns/Add.vue'),
              meta: {
                title: 'Add Infrastructure Vulns',
                activeMenuKeys: ['projects', 'project-vulns', 'infra'],
                allowedRole: [1, 5, 10],
              },
            }, {
              path: 'appsec/import',
              name: 'import-project-appsec-vulns',
              component: () => import('@/views/pages/member/Project/Vulns/Import.vue'),
              meta: {
                title: 'Import AppSec Vulns',
                activeMenuKeys: ['projects', 'project-vulns', ''],
                allowedRole: [1, 5, 10],
              },
            }, {
              path: 'infra/import',
              name: 'import-project-infra-vulns',
              component: () => import('@/views/pages/member/Project/Vulns/Import.vue'),
              meta: {
                title: 'Import Infrastructure Vulns',
                activeMenuKeys: ['projects', 'project-vulns', 'infra'],
                allowedRole: [1, 5, 10],
              },
            },
          ],
        }, {
          path: 'files',
          name: 'project-files',
          component: () => import('@/views/pages/member/Project/Files.vue'),
          meta: {
            title: 'Project: Files',
            activeMenuKeys: ['projects', 'project-files'],
            allowedRole: [1, 3, 5],
          },
          beforeEnter: (to) => {
            if (!to.query.page) {
              return {
                name: 'project-files',
                params: { id: to.params.id },
                query: { page: 1 },
              };
            }
          },
        }, {
          path: 'images',
          name: 'project-images',
          component: () => import('@/views/pages/member/Project/Images.vue'),
          meta: {
            title: 'Project: Images',
            activeMenuKeys: ['projects', 'project-images'],
            allowedRole: [0, 1, 3, 5],
          },
          beforeEnter: (to) => {
            if (!to.query.page) {
              return {
                name: 'project-Images',
                params: { id: to.params.id },
                query: { page: 1 },
              };
            }
          },
        }, {
          path: 'sbom',
          name: 'project-sbom',
          component: () => import('@/views/layouts/Projects/ProjectSbomViewLayout.vue'),
          redirect: {
            name: 'project-sbom-scans',
          },
          children: [
            {
              path: 'scans',
              name: 'project-sbom-scans',
              component: () => import('@/views/pages/member/Project/Sbom/Scans.vue'),
              meta: {
                title: 'SBOM',
                activeMenuKeys: ['projects', 'project-sbom'],
                allowedRole: [0, 1, 2, 3, 5],
              },
              beforeEnter: (to) => {
                if (!to.query.page) {
                  return {
                    name: 'project-sbom',
                    params: { id: to.params.id },
                    query: { page: 1 },
                  };
                }
              },
            }, {
              path: 'components',
              name: 'project-sbom-components',
              component: () => import('@/views/pages/member/Project/Sbom/Components.vue'),
              meta: {
                title: 'SBOM',
                activeMenuKeys: ['projects', 'project-sbom'],
                allowedRole: [0, 1, 2, 3, 5],
              },
              beforeEnter: (to) => {
                if (!to.query.page) {
                  return {
                    name: 'project-sbom',
                    params: { id: to.params.id },
                    query: { page: 1 },
                  };
                }
              },
            },
          ],
        }, {
          path: 'asvs',
          name: 'project-asvs',
          component: () => import('@/views/pages/member/Project/Asvs.vue'),
          meta: {
            title: 'Project: ASVS',
            activeMenuKeys: ['projects', 'project-asvs'],
            allowedRole: [0, 1, 3, 5],
          },
        }, {
          path: 'endpoints',
          name: 'project-endpoints',
          component: () => import('@/views/pages/member/Project/Endpoint/List.vue'),
          meta: {
            title: 'Endpoints',
            activeMenuKeys: ['projects', 'project-endpoints'],
            allowedRole: [0, 1, 3, 5],
          },
        }, {
          path: 'endpoints/:endpoint/detail',
          name: 'project-endpoints-detail',
          component: () => import('@/views/pages/member/Project/Endpoint/Details.vue'),
          meta: {
            title: 'Endpoints Detail',
            activeMenuKeys: ['projects', 'project-endpoints'],
            allowedRole: [0, 1, 3, 5],
          },
        }, {
          path: 'settings',
          name: 'project-settings',
          component: () => import('@/views/layouts/Projects/ProjectSettingsLayout.vue'),
          redirect: {
            name: 'project-settings-source-control',
          },
          children: [
            {
              path: 'source-control',
              name: 'project-settings-source-control',
              component: () => import('@/views/pages/member/Project/Settings/SourceControl.vue'),
              meta: {
                title: 'Project Settings',
                activeMenuKeys: ['projects', 'project-settings', 'source-control'],
                allowedRole: [1, 5],
              },
            }, {
              path: 'scanners',
              name: 'project-settings-scanners',
              component: () => import('@/views/pages/member/Project/Settings/Scanners.vue'),
              meta: {
                title: 'Project Settings',
                activeMenuKeys: ['projects', 'project-settings', 'scanners'],
                allowedRole: [1, 5],
              },
            }, {
              path: 'endpoints',
              name: 'project-settings-endpoints',
              component: () => import('@/views/pages/member/Project/Settings/Endpoints.vue'),
              meta: {
                title: 'Project Settings',
                activeMenuKeys: ['projects', 'project-settings', 'endpoints'],
                allowedRole: [1, 5],
              },
            }, {
              path: 'issue-assignment',
              name: 'project-settings-issue-assignment',
              component: () => import('@/views/pages/member/Project/Settings/IssueAssignment.vue'),
              meta: {
                title: 'Project Settings',
                activeMenuKeys: ['projects', 'project-settings', 'issue-assignment'],
                allowedRole: [1, 5],
              },
            }, {
              path: 'security-criteria',
              name: 'project-settings-security-criteria',
              component: () => import('@/views/pages/member/Project/Settings/SecurityCriteria.vue'),
              meta: {
                title: 'Project Settings',
                activeMenuKeys: ['projects', 'project-settings', 'security-criteria'],
                allowedRole: [1, 5],
              },
            }, {
              path: 'alerts',
              name: 'project-settings-alerts',
              component: () => import('@/views/pages/member/Project/Settings/Alerts.vue'),
              meta: {
                title: 'Project Settings',
                activeMenuKeys: ['projects', 'project-settings', 'alerts'],
                allowedRole: [1, 5],
              },
            }, {
              path: 'could-scope',
              name: 'project-settings-cloud-scope',
              component: () => import('@/views/pages/member/Project/Settings/CloudScope.vue'),
              meta: {
                title: 'Project Settings',
                activeMenuKeys: ['projects', 'project-settings', 'cloud-scope'],
                allowedRole: [1, 5],
              },
            }, {
              path: 'feature-branch-mgmt',
              name: 'project-settings-feature-branch-mgmt',
              component: () => import('@/views/pages/member/Project/Settings/FeatureBranchMgmt.vue'),
              meta: {
                title: 'Project Settings',
                activeMenuKeys: ['projects', 'project-settings', 'feature-branch-mgmt'],
                allowedRole: [1, 5],
              },
            },
          ],
        },
      ],
    },
  ],
};
