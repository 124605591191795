import Api from '@/services/Api';
import { API_URL } from '@/config/';

export default {
  getBranchComparison(data) {
    const params = data.query;
    return Api().get(`${data.type}/${data.id}/${data.prefix}/charts/` +
      'branch_comparison', { params });
  },
  getBubbleChart(data) {
    const params = data.query;
    return Api().get(`${data.type}/${data.id}/${data.prefix}/charts/` +
      `bubble_${data.chartType}`, { params });
  },
  getOwaspTopTen(data) {
    const params = data.query;
    return Api().get(`${data.type}/${data.id}/${data.prefix}/charts/` +
      `${data.chartType}`, { params });
  },
  getRadarChart(params) {
    return Api().get(`${API_URL.PROJECT}/${params.id}/appsec/charts/asvs_radar`);
  },
  getKeyMetrics(data) {
    const params = data.query;
    return Api().get(`${data.type}/${data.id}/${data.prefix}/charts/key_metrics`, { params });
  },
  getSummary(data) {
    const params = data.query;
    return Api().get(`${data.type}/${data.id}/${data.prefix}/charts/` +
      'tool_summary', { params });
  },
  getSummaryTable(data) {
    const params = data.query;
    return Api().get(`${data.type}/${data.id}/${data.prefix}/charts` +
      '/summary_table', { params });
  },
  getProjectSuppressedVulns(data) {
    const params = data.query;
    return Api().get(`${data.type}/${data.id}/${data.prefix}/charts` +
      '/suppressed_vulns_chart', { params });
  },
  getToolComparison(data) {
    const params = data.query;
    return Api().get(`${data.type}/${data.id}/${data.prefix}/charts/` +
      'tools_comparison', { params });
  },
  getSevertiyTrend(data) {
    const params = data.query;
    return Api().get(`${data.type}/${data.id}/${data.prefix}/charts/severity_trend`, { params });
  },
  getRiskScoreTrend(data) {
    const params = data.query;
    return Api().get(`${data.type}/${data.id}/${data.prefix}/charts/risk_score_trend`, { params });
  },
  getProjectSecurityKPITrend(data) {
    const params = data.query;

    return Api().get(
      `${data.type}/${data.id}/${data.prefix}/charts/security_kpi_trend`,
      { params },
    );
  },
  getProjectBurndownTrend(data) {
    const params = data.query;
    return Api().get(`${data.type}/${data.id}/${data.prefix}/charts/` +
      'vulnerability_trend', { params });
  },
  getProjectIssueTrend(data) {
    const params = data.query;
    return Api().get(`${data.type}/${data.id}/${data.prefix}/charts/` +
      'issue_trend', { params });
  },
  getProjectWoeBySeverity(data) {
    const params = data.query;
    return Api().get(`${data.type}/${data.id}/${data.prefix}/charts/stacked_bar_chart`, { params });
  },
  getProjectWoeTopTen(data) {
    const params = data.query;
    return Api().get(`${data.type}/${data.id}/${data.prefix}/charts/top10_woe`, { params });
  },
  getProjectNewClosedTrend(data) {
    const params = data.query;
    return Api().get(`${data.type}/${data.id}/${data.prefix}/charts/new_closed_trend`, { params });
  },
  getProjectEnvironmentBreakdown(data) {
    const params = data.query;
    return Api().get(`${data.type}/${data.id}/${data.prefix}/charts/environments_bar_chart`, { params });
  },
};
