import { createRouter, createWebHistory } from 'vue-router';

import routes from './_Routes';
import beforeEach from './_BeforeEach';

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(beforeEach);

export default router;
