const dummyData = {
  project: {
    status: false,
    used: 0,
    max: 0,
  },
  user: {
    status: false,
    used: 0,
    max: 0,
  },
  expire_date: '#########',
  hid: '#########',
  status: '######',
};

export default dummyData;
