import { defineStore } from 'pinia';

import CloudAssetsService from '@/services/CloudAssets';

import ErrorHandling from '@/mixins/ErrorHandling';

import { CLOUDASSETS } from '@/config/';

import dummyData from './_dummyData';

const useCloudAssetsStore = defineStore({
  id: 'CloudAssets',
  state: () => ({
    // ## All cloud assets table. ---------------------------------------------------------------------------
    cloudAssetsTable: dummyData,
    cloudAssetsTablePerpage: CLOUDASSETS.PERPAGE,
    cloudAssetsTableTotal: 0,
    cloudAssetsTablePending: false,
    cloudAssetsTableDone: false,
    cloudAssetsTableFail: false,
    cloudAssetsTableFailMsg: null,
  }),
  actions: {
    // ## Get cloud assets table. -----------------------------------------------------------------------
    async getCloudAssetsTable(data) {
      this.cloudAssetsTablePending = true;
      this.cloudAssetsTable = dummyData;

      try {
        const params = {
          limit: this.cloudAssetsTablePerpage,
          start: (data.page - 1) * this.cloudAssetsTablePerpage,
          sort_by: data.sort,
          name: data.name,
          cloud_platform: data.platform,
          resource_type: data.resourceType,
          projects: data.projects,
          region: data.region,
        };
        const res = await CloudAssetsService.getCloudAssetsTable(params);
        this.cloudAssetsTableTotal = res.data.total;
        this.setGetAllCloudAssetsTable(res.data.inventories);
        this.cloudAssetsTableDone = !this.cloudAssetsTableDone;
        this.cloudAssetsTablePending = false;
      } catch (error) {
        this.cloudAssetsTable = dummyData;
        this.cloudAssetsTableTotal = 0;
        this.cloudAssetsTablePending = false;
        this.cloudAssetsTableFail = !this.cloudAssetsTableFail;
        this.cloudAssetsTableFailMsg = ErrorHandling(error);
      }
    },

    setGetAllCloudAssetsTable(payload) {
      this.cloudAssetsTable = [];
      if (!payload) {
        return;
      }
      this.cloudAssetsTable = payload.map((s) => ({
        key: s.id,
        id: s.id,
        name: s.name,
        platform: s.cloud_platform,
        resourceType: s.resource_type,
        nativeType: s.native_type,
        region: s.region,
        projects: s.projects,
        tags: s.tags,
      }));
    },
  },
});

export default useCloudAssetsStore;
