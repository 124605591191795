// ## Config Inc.
const dummyData = [...Array(15)].map((_, i) => ({
  id: i,
  cweId: '##',
  vulnerabilityName: '####################',
  status: 'open',
  severity: 1,
  requestedBy: '#####',
  requestedDate: '#####',
  requestedText: '####################',
  repliedBy: '#####',
  repliedDate: '#####',
  repliedText: '####################',
  requestType: 'mark',
}));

export default dummyData;
