import { defineStore } from 'pinia';

import ErrorHandling from '@/mixins/ErrorHandling';

import DashboardService from '@/services/Dashboard';

import { COLOR, LOCAL_STORAGE, DASHBOARD } from '@/config/';

import dayjs from 'dayjs';

import i18n from '@/i18n';

import {
  dummyDashboard,
  dummyProjectMetricsData,
  dummyKeyMetricsData,
  dummyEndpointCoverageData,
  summaryTableDummy,
  actionItemsDummy,
} from './_dummyData';

const teamsLocalStorageName = LOCAL_STORAGE.SEVERITY_CHART_VISIBLE_TEAMS;

const useDashboardStore = defineStore({
  id: 'Dashboard',
  state: () => ({
    // ## Dashboard charts. --------------------------------------------------------------------
    dashboardAllCharts: dummyDashboard,
    dashboardAllChartsPending: null,
    dashboardAllChartsDone: null,

    saveDashboardAllChartsPending: false,
    saveDashboardAllChartsDone: false,

    resetDashboardAllChartsPending: false,
    resetDashboardAllChartsDone: false,

    // ## Dashboard bubble chart. --------------------------------------------------------------------
    dashboardBubbleChart: [],
    dashboardBubbleChartPending: null,
    dashboardBubbleChartDone: null,

    // ## Owasp top ten. -----------------------------------------------------------------------------
    owaspTopTen: [],
    owaspTopTenPending: false,
    owaspTopTenDone: false,

    // ## Dashboard project metrics. -----------------------------------------------------------------
    dashboardProjectMetrics: dummyProjectMetricsData,
    dashboardProjectMetricsPending: null,
    dashboardProjectMetricsDone: null,

    // ## Dashboard key metrics. ---------------------------------------------------------------------
    dashboardKeyMetrics: dummyKeyMetricsData,
    dashboardKeyMetricsPending: null,
    dashboardKeyMetricsDone: null,

    // ## Dashboard maintainability metrics. ---------------------------------------------------------
    dashboardMaintainabilityMetrics: [],
    dashboardMaintainabilityMetricsPending: null,
    dashboardMaintainabilityMetricsDone: null,

    // ## Dashboard endpoint coverage Metrics. -------------------------------------------------------
    dashboardEndpointCoverageMetrics: dummyEndpointCoverageData,
    dashboardEndpointCoverageMetricsPending: null,
    dashboardEndpointCoverageMetricsDone: null,

    // ## Risk score project. ------------------------------------------------------------------------
    riskScoreProject: [],
    riskScoreProjectPending: false,
    riskScoreProjectDone: false,

    // ## Risk score project. ------------------------------------------------------------------------
    riskScoreTeam: [],
    riskScoreTeamPending: false,
    riskScoreTeamDone: false,

    // ## Risk score product. ------------------------------------------------------------------------
    riskScoreProduct: [],
    riskScoreProductPending: false,
    riskScoreProductDone: false,

    // ## Risk score trend. --------------------------------------------------------------------------
    riskScoreTrend: {
      data: [],
      dates: [],
    },
    riskScoreTrendPending: false,
    riskScoreTrendDone: false,

    // ## Severity trend. ----------------------------------------------------------------------------
    severityTrend: {
      data: [],
      dates: [],
    },
    severityTrendPending: false,
    severityTrendDone: false,

    // ## Woe by severity. ---------------------------------------------------------------------------
    woeBySeverity: {
      data: [],
      days: [],
    },
    woeBySeverityPending: false,
    woeBySeverityDone: false,

    // ## Woe top ten. -------------------------------------------------------------------------------
    woeTopTen: [],
    woeTopTenPending: false,
    woeTopTenDone: false,

    // ## Severity team. -----------------------------------------------------------------------------
    severityTeam: {},
    severityTeamPending: false,
    severityTeamDone: false,
    severityTeamAllTeams: [],
    severityTeamVisibleTeams:
      localStorage[teamsLocalStorageName] ? localStorage[teamsLocalStorageName].split(',') : null,
    severityTrendByTeamMaxVisibleTeams: DASHBOARD.MAX_SEVERITY_CHART_VISIBLE_TEAMS,

    // ## Security KPI trend. ------------------------------------------------------------------------
    securityKPITrend: {
      data: [],
      dates: [],
    },
    securityKPITrendPending: false,
    securityKPITrendDone: false,

    // ## Summary Table. -----------------------------------------------------------------------------
    summaryTable: summaryTableDummy,
    summaryTablePending: false,
    summaryTableDone: false,

    // ## Action items Table. ------------------------------------------------------------------------
    actionItemsTable: actionItemsDummy,
    actionItemsTablePending: false,
    actionItemsTableDone: false,
    actionItemsTableFail: false,

    // ## Suppressed vulns. --------------------------------------------------------------------------
    suppressedVulns: {
      data: [],
      types: [],
    },
    suppressedVulnsPending: false,
    suppressedVulnsDone: false,

    // ## Burndown trend chart. ----------------------------------------------------------------------
    burndownTrend: {
      data: [],
      dates: [],
    },
    burndownTrendPending: false,
    burndownTrendDone: false,

    // ## Issue trend. -----------------------------------------------------------------------
    issueTrend: {
      data: [],
      dates: [],
    },
    issueTrendPending: false,
    issueTrendDone: false,

    // ## Recurrent Vulnerability. -----------------------------------------------------------------------
    recurrentVulnerabilities: {
      data: [],
      axis: [],
    },
    recurrentVulnerabilitiesPending: null,
    recurrentVulnerabilitiesDone: null,

    // ## New Vulnerability. -----------------------------------------------------------------------
    newVulnerabilities: {
      data: [],
      axis: [],
    },
    newVulnerabilitiesPending: null,
    newVulnerabilitiesDone: null,

    // ## Closed Vulnerability. -----------------------------------------------------------------------
    closedVulnerabilities: {
      data: [],
      axis: [],
    },
    closedVulnerabilitiesPending: null,
    closedVulnerabilitiesDone: null,

    // ## Net new Vulnerability. -----------------------------------------------------------------------
    netNewVulnerabilities: {
      data: [],
      axis: [],
    },
    netNewVulnerabilitiesPending: false,
    netNewVulnerabilitiesDone: null,

    // ## Suppressed Vulnerability. -----------------------------------------------------------------------
    suppressedVulnerabilities: {
      data: [],
      axis: [],
    },
    suppressedVulnerabilitiesPending: null,
    suppressedVulnerabilitiesDone: null,

    // ## New closed trend. --------------------------------------------------------------------------------------------
    newClosedTrend: {
      data: [],
      weeks: [],
    },
    newClosedTrendPending: false,
    newClosedTrendDone: false,

    // ## EnvironmentBreakdown vulns. --------------------------------------------------------------------------
    environmentBreakdown: {
      data: [],
      types: [],
    },
    environmentBreakdownPending: false,
    environmentBreakdownDone: false,
  }),
  actions: {
    // ## Get dashboard all charts. ----------------------------------------------------------------
    async getDashboardAllCharts(data) {
      this.dashboardAllChartsPending = true;
      try {
        const res = await DashboardService.getDashboardAllCharts(data);
        this.setGetDashboardCharts(res.data.chart_names.general_dashboards);
        this.dashboardAllChartsDone = !this.dashboardAllChartsDone;
        this.dashboardAllChartsPending = false;
      } catch (error) {
        this.dashboardAllChartsPending = false;
        ErrorHandling(error);
      }
    },

    setGetDashboardCharts(payload) {
      this.dashboardAllCharts = [
        ...payload.left.map((s) => ({
          name: s,
          displayName: s.split('_').map((a) => a.charAt(0).toUpperCase() + a.slice(1)).join(''),
          type: 'left',
        })),
        ...payload.right.map((s) => ({
          name: s,
          displayName: s.split('_').map((a) => a.charAt(0).toUpperCase() + a.slice(1)).join(''),
          type: 'right',
        })),
        ...payload.unused.map((s) => ({
          name: s,
          displayName: s.split('_').map((a) => a.charAt(0).toUpperCase() + a.slice(1)).join(''),
          type: 'unused',
        })),
      ];
    },

    async saveDashboardAllCharts(data) {
      this.saveDashboardAllChartsPending = true;

      try {
        await DashboardService.saveDashboardAllCharts(data);
        this.saveDashboardAllChartsDone = !this.saveDashboardAllChartsDone;
        this.saveDashboardAllChartsPending = false;
      } catch (error) {
        this.saveDashboardAllChartsPending = false;
        ErrorHandling(error);
      }
    },

    async resetDashboardAllCharts(data) {
      this.resetDashboardAllChartsPending = true;

      try {
        await DashboardService.resetDashboardAllCharts(data);
        this.resetDashboardAllChartsDone = !this.resetDashboardAllChartsDone;
        this.resetDashboardAllChartsPending = false;
      } catch (error) {
        this.resetDashboardAllChartsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get dashboard bubble chart. ----------------------------------------------------------------
    async getDashboardBubbleChart(data) {
      this.dashboardBubbleChartPending = true;

      try {
        const res = await DashboardService.getBubbleChart(data);
        this.setGetDashboardBubbleChart(res.data.chart_data);
        this.dashboardBubbleChartDone = !this.dashboardBubbleChartDone;
        this.dashboardBubbleChartPending = false;
      } catch (error) {
        this.dashboardBubbleChartPending = false;
        ErrorHandling(error);
      }
    },

    setGetDashboardBubbleChart(payload) {
      this.dashboardBubbleChart = [];
      const type = payload.chart_type;
      let data = null;
      if (type === 'cwe') {
        data = payload.cwe_list;
      } else if (type === 'endpoint') {
        data = payload.endpoint_list;
      } else {
        data = payload.path_list;
      }
      if (!data) {
        return;
      }
      data.forEach((s, i) => {
        if (type === 'cwe') {
          this.dashboardBubbleChart.push({
            id: s.cwe.cwe_id,
            idTitle: 'CWE ID',
            type: 'cwe',
            name: (s.cwe.name !== 'unknown' ? s.cwe.name : 'Uncategorized'),
            value: s.count,
            countByTool: s.count_by_tool,
          });
        } else if (type === 'endpoint') {
          this.dashboardBubbleChart.push({
            id: i,
            type: 'endpoint',
            name: s.endpoint,
            value: s.count,
          });
        } else {
          this.dashboardBubbleChart.push({
            id: i,
            type: 'path',
            name: s.path,
            value: s.count,
          });
        }
      });
    },

    // ## Owasp top ten. -----------------------------------------------------------------------------
    async getOwaspTopTen(data) {
      this.owaspTopTenPending = true;

      try {
        const res = await DashboardService.getOwaspTopTen(data);
        this.setGetOwaspTopTen(res.data.chart_data);
        this.owaspTopTenDone = !this.owaspTopTenDone;
        this.owaspTopTenPending = false;
      } catch (error) {
        this.owaspTopTenPending = false;
        ErrorHandling(error);
      }
    },

    setGetOwaspTopTen(payload) {
      this.owaspTopTen = [];
      if (payload.categories.filter((s) => s.count > 0).length < 1) {
        return;
      }
      this.owaspTopTen = payload.categories.map((s) => ({
        name: s.name,
        category: s.category,
        value: s.count,
        display: s.display_name,
      }));
    },

    // ## Get dashboard project metrics. -------------------------------------------------------------
    async getDashboardProjectMetrics(data) {
      this.dashboardProjectMetricsPending = true;

      try {
        const res = await DashboardService.getProjectMetrics(data);
        this.dashboardProjectMetrics = res.data.chart_data;
        this.dashboardProjectMetricsDone = !this.dashboardProjectMetricsDone;
        this.dashboardProjectMetricsPending = false;
      } catch (error) {
        this.dashboardProjectMetricsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get dashboard key metrics. -----------------------------------------------------------------
    async getDashboardKeyMetrics(data) {
      this.dashboardKeyMetricsPending = true;

      try {
        const res = await DashboardService.getKeyMetrics(data);
        this.setGetDashboardKeyMetrics(res.data.chart_data);
        this.dashboardKeyMetricsDone = !this.dashboardKeyMetricsDone;
        this.dashboardKeyMetricsPending = false;
      } catch (error) {
        this.dashboardKeyMetricsPending = false;
        ErrorHandling(error);
      }
    },

    setGetDashboardKeyMetrics(payload) {
      const data = [];
      Object.keys(payload).forEach((s) => {
        if (s !== 'tool_type') {
          data.push({
            name: (s === 'vwi' ? 'issues' : s),
            critical: payload[s].findings.critical,
            high: payload[s].findings.high,
            low: payload[s].findings.low,
            medium: payload[s].findings.medium,
            total: (s === 'vwi' || s === 'sla' ? payload[s].total : payload[s].avg),
            sla: payload[s].limits,
          });
        }
      });
      const sortedData = [];
      data.forEach((s) => {
        if (s.name === 'issues') sortedData[0] = s;
        if (s.name === 'sla') sortedData[1] = s;
        if (s.name === 'woe') sortedData[2] = s;
        if (s.name === 'mtf') sortedData[3] = s;
      });
      this.dashboardKeyMetrics = sortedData;
    },

    // ## Get dashboard maintainability metrics. -----------------------------------------------------
    async getDashboardMaintainabilityMetrics(data) {
      this.dashboardMaintainabilityMetricsPending = true;

      try {
        const res = await DashboardService.getDashboardMaintainabilityMetrics(data);
        this.setGetDashboardMaintainabilityMetrics(res.data.chart_data);
        this.dashboardMaintainabilityMetricsDone = !this.dashboardMaintainabilityMetricsDone;
        this.dashboardMaintainabilityMetricsPending = false;
      } catch (error) {
        this.dashboardMaintainabilityMetricsPending = false;
        ErrorHandling(error);
      }
    },

    setGetDashboardMaintainabilityMetrics(payload) {
      this.dashboardMaintainabilityMetrics = [];
      if (Object.entries(payload).filter((s) => s[1] > 0).length < 1) {
        return;
      }
      Object.entries(payload).forEach((s) => {
        const type = s[0].split('_')[1].toUpperCase();
        const data = {
          type,
          value: s[1],
        };
        if (type === 'A') {
          data.color = '#bdbdbd';
        } else if (type === 'B') {
          data.color = '#b8d246';
        } else if (type === 'C') {
          data.color = '#e4be41';
        } else if (type === 'D') {
          data.color = '#df823a';
        } else if (type === 'E') {
          data.color = '#c34245';
        }
        this.dashboardMaintainabilityMetrics.push(data);
      });
    },

    // ## Get dashboard endpoint coverage Metrics. ---------------------------------------------------
    async getDashboardEndpointCoverageMetrics(data) {
      this.dashboardEndpointCoverageMetricsPending = true;

      try {
        const res = await DashboardService.getDashboardEndpointCoverageMetrics(data);
        this.dashboardEndpointCoverageMetrics = res.data.chart_data;
        this.dashboardEndpointCoverageMetricsDone = !this.dashboardEndpointCoverageMetricsDone;
        this.dashboardEndpointCoverageMetricsPending = false;
      } catch (error) {
        this.dashboardEndpointCoverageMetricsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Risk score project. ------------------------------------------------------------------------
    async getRiskScoreProject(data) {
      this.riskScoreProjectPending = true;

      try {
        const res = await DashboardService.getRiskScoreProject(data);
        this.setGetRiskScoreProject({
          ...data,
          response: res.data.chart_data,
        });
        this.riskScoreProjectDone = !this.riskScoreProjectDone;
        this.riskScoreProjectPending = false;
      } catch (error) {
        this.riskScoreProjectPending = false;
        ErrorHandling(error);
      }
    },

    setGetRiskScoreProject(payload) {
      this.riskScoreProject = [];
      if (payload.response.projects.length < 1) {
        return;
      }
      const averageFindings = payload.response.findings_count / payload.response.projects.length;
      const findingDataItem = payload.response.projects.map((s) => s.findings_count);
      const riskScoreDataItem = payload.response.projects.map((s) => s.risk_score);
      const cvssDataItem = payload.response.projects.map((s) => s.cvss);

      const maxScore = Math.max.apply(null, riskScoreDataItem);
      const maxFindings = Math.max.apply(null, findingDataItem);
      const maxCvss = Math.max.apply(null, cvssDataItem);

      const avgAnger = (payload.response.avg + maxScore) / 2;
      const findingsAnger = (averageFindings + maxFindings) / 2;
      const cvssAnger = (payload.response.cvss + maxCvss) / 2;

      const setStatus = (s) => {
        let status = null;
        if (s.risk_score <= payload.response.avg && s.findings_count <= averageFindings) {
          status = 'success';
        } else if (
          (s.risk_score < avgAnger && s.risk_score > payload.response.avg && s.findings_count < findingsAnger) ||
          (s.findings_count < findingsAnger && s.risk_score < payload.response.avg)
        ) {
          status = 'warning';
        } else {
          status = 'danger';
        }
        return status;
      };

      const setCvssStatus = (s) => {
        let status = null;
        if (s.cvss <= payload.response.cvss && s.findings_count <= averageFindings) {
          status = 'success';
        } else if (
          (s.cvss < cvssAnger && s.cvss > payload.response.cvss && s.findings_count < findingsAnger) ||
          (s.findings_count < findingsAnger && s.cvss < payload.response.cvss)
        ) {
          status = 'warning';
        } else {
          status = 'error';
        }
        return status;
      };
      if (payload.chartType === 'average') {
        payload.response.projects.forEach((s) => {
          this.riskScoreProject.push([s.findings_count, s.risk_score, s.name, setStatus(s), s.default_branch, s.id]);
        });
        this.riskScoreProject.push([
          Number(payload.response.findings_count / payload.response.projects.length).toFixed(2),
          payload.response.avg,
          'Average',
          'info',
        ]);
      } else {
        payload.response.projects.forEach((s) => {
          this.riskScoreProject.push([s.findings_count, s.cvss, s.name, setCvssStatus(s), s.default_branch, s.id]);
        });
        this.riskScoreProject.push([
          Number(payload.response.findings_count / payload.response.projects.length).toFixed(2),
          payload.response.cvss,
          'Average',
          'info',
        ]);
      }
    },

    // ## Risk score team. ---------------------------------------------------------------------------
    async getRiskScoreTeam(data) {
      this.riskScoreTeamPending = true;

      try {
        const res = await DashboardService.getRiskScoreTeam(data);
        this.setGetRiskScoreTeam({
          ...data,
          response: res.data.chart_data,
        });
        this.riskScoreTeamDone = !this.riskScoreTeamDone;
        this.riskScoreTeamPending = false;
      } catch (error) {
        this.riskScoreTeamPending = false;
        ErrorHandling(error);
      }
    },

    setGetRiskScoreTeam(payload) {
      this.riskScoreTeam = [];
      const data = payload.response;
      if (data.teams.length < 1) {
        return;
      }

      const averageFindings = payload.response.findings_count / payload.response.teams.length;
      const findingDataItem = data.teams.map((s) => s.findings_count);
      const riskScoreDataItem = data.teams.map((s) => s.risk_score);
      const cvssDataItem = payload.response.teams.map((s) => s.cvss);

      const max = Math.max.apply(null, riskScoreDataItem);
      const maxFindings = Math.max.apply(null, findingDataItem);
      const maxCvss = Math.max.apply(null, cvssDataItem);

      const avgAnger = (data.avg + max) / 2;
      const findingsAnger = (averageFindings + maxFindings) / 2;
      const cvssAnger = (payload.response.cvss + maxCvss) / 2;

      const setStatus = (s) => {
        let status = null;
        if (s.risk_score <= data.avg && s.findings_count <= averageFindings) {
          status = 'success';
        } else if (
          (s.risk_score < avgAnger && s.risk_score > data.avg && s.findings_count < findingsAnger) ||
          (s.findings_count < findingsAnger && s.risk_score < data.avg)
        ) {
          status = 'warning';
        } else {
          status = 'danger';
        }
        return status;
      };

      const setCvssStatus = (s) => {
        let status = null;
        if (s.cvss <= payload.response.cvss && s.findings_count <= averageFindings) {
          status = 'success';
        } else if (
          (s.cvss < cvssAnger && s.cvss > payload.response.cvss && s.findings_count < findingsAnger) ||
          (s.findings_count < findingsAnger && s.cvss < payload.response.cvss)
        ) {
          status = 'warning';
        } else {
          status = 'danger';
        }
        return status;
      };
      if (payload.chartType === 'average') {
        data.teams.forEach((s) => {
          this.riskScoreTeam.push([s.findings_count, s.risk_score, s.name, setStatus(s)]);
        });
        this.riskScoreTeam.push([
          Number(payload.response.findings_count / payload.response.teams.length).toFixed(2),
          payload.response.avg,
          'Average',
          'info',
        ]);
      } else {
        payload.response.teams.forEach((s) => {
          this.riskScoreTeam.push([s.findings_count, s.cvss, s.name, setCvssStatus(s)]);
        });
        this.riskScoreTeam.push([
          Number(payload.response.findings_count / payload.response.teams.length).toFixed(2),
          payload.response.cvss,
          'Average',
          'info',
        ]);
      }
    },

    // ## Risk score product. ------------------------------------------------------------------------
    async getRiskScoreProduct(data) {
      this.riskScoreProductPending = true;

      try {
        const res = await DashboardService.getRiskScoreProduct(data);
        this.setGetRiskScoreProduct({
          ...data,
          response: res.data.chart_data,
        });
        this.riskScoreProductDone = !this.riskScoreProductDone;
        this.riskScoreProductPending = false;
      } catch (error) {
        this.riskScoreProductPending = false;
        ErrorHandling(error);
      }
    },

    setGetRiskScoreProduct(payload) {
      this.riskScoreProduct = [];
      const data = payload.response;
      if (data.products.length < 1) {
        return;
      }
      const averageFindings = data.findings_count / data.products.length;
      const findingDataItem = data.products.map((s) => s.findings_count);
      const riskScoreDataItem = data.products.map((s) => s.risk_score);
      const cvssDataItem = data.products.map((s) => s.cvss);

      const max = Math.max.apply(null, riskScoreDataItem);
      const maxFindings = Math.max.apply(null, findingDataItem);
      const maxCvss = Math.max.apply(null, cvssDataItem);

      const avgThreshold = (data.avg + max) / 2;
      const findingsThreshold = (averageFindings + maxFindings) / 2;
      const cvssAnger = (data.cvss + maxCvss) / 2;

      const setStatus = (s) => {
        let status = null;
        if (s.risk_score <= data.avg && s.findings_count <= averageFindings) {
          status = 'success';
        } else if (
          (s.risk_score < avgThreshold && s.risk_score > data.avg && s.findings_count < findingsThreshold) ||
          (s.findings_count < findingsThreshold && s.risk_score < data.avg)
        ) {
          status = 'warning';
        } else {
          status = 'danger';
        }
        return status;
      };

      const setCvssStatus = (s) => {
        let status = null;
        if (s.cvss <= data.cvss && s.findings_count <= averageFindings) {
          status = 'success';
        } else if (
          (s.cvss < cvssAnger && s.cvss > data.cvss && s.findings_count < findingsThreshold) ||
          (s.findings_count < findingsThreshold && s.cvss < data.cvss)
        ) {
          status = 'warning';
        } else {
          status = 'danger';
        }
        return status;
      };
      if (payload.chartType === 'average') {
        data.products.forEach((s) => {
          this.riskScoreProduct.push([s.findings_count, s.risk_score, s.name, setStatus(s)]);
        });
        this.riskScoreProduct.push([
          Number(data.findings_count / data.products.length).toFixed(2),
          data.cvss,
          'Average',
          'info',
        ]);
      } else {
        data.products.forEach((s) => {
          this.riskScoreProduct.push([s.findings_count, s.cvss, s.name, setCvssStatus(s)]);
        });
        this.riskScoreProduct.push([
          Number(data.findings_count / data.products.length).toFixed(2),
          data.cvss,
          'Average',
          'info',
        ]);
      }
    },

    // ## Risk score trend request. ------------------------------------------------------------------
    async getRiskScoreTrend(data) {
      this.riskScoreTrendPending = true;

      try {
        const res = await DashboardService.getRiskScoreTrend(data);
        this.setGetRiskScoreTrend(res.data.chart_data);
        this.riskScoreTrendDone = !this.riskScoreTrendDone;
        this.riskScoreTrendPending = false;
      } catch (error) {
        this.riskScoreTrendPending = false;
        ErrorHandling(error);
      }
    },

    setGetRiskScoreTrend(payload) {
      this.riskScoreTrend = {
        data: [],
        dates: [],
      };
      if (payload.scan_trend.filter((s) => s.score > 0).length < 1) {
        return;
      }
      const formatDate = (date) => (
        dayjs(date).format('DD MMM YYYY')
      );
      this.riskScoreTrend.dates = payload.scan_trend.map((a) => formatDate(a.scan_date));
      const keys = ['avgRiskScore'];
      keys.forEach((s) => {
        this.riskScoreTrend.data.push({
          name: s,
          value: payload.scan_trend.map((a) => a.score),
          color: COLOR.DANGER,
        });
      });
    },

    // ## Severity trend request. --------------------------------------------------------------------
    async getSeverityTrend(data) {
      this.severityTrendPending = true;

      try {
        const res = await DashboardService.getSevertiyTrend(data);
        this.setGetSeverityTrend(res.data.chart_data);
        this.severityTrendDone = !this.severityTrendDone;
        this.severityTrendPending = false;
      } catch (error) {
        this.severityTrendPending = false;
        ErrorHandling(error);
      }
    },

    setGetSeverityTrend(payload) {
      this.severityTrend = {
        data: [],
        dates: [],
      };
      if (payload.scan_trend.filter((s) => s.score > 0).length < 1) {
        return;
      }
      const formatDate = (date) => (
        dayjs(date).format('DD MMM YYYY')
      );
      const setValue = (a, s) => {
        if (s === 'low') {
          return a.summary.low;
        }
        if (s === 'medium') {
          return a.summary.medium;
        }
        if (s === 'high') {
          return a.summary.high;
        }
        return a.summary.critical;
      };
      const setColor = (type) => {
        if (type === 'low') {
          return COLOR.LOW;
        }
        if (type === 'medium') {
          return COLOR.MEDIUM;
        }
        if (type === 'high') {
          return COLOR.HIGH;
        }
        return COLOR.CRITICAL;
      };
      this.severityTrend.dates = payload.scan_trend.map((a) => formatDate(a.scan_date));
      const keys = ['critical', 'high', 'medium', 'low'];
      keys.forEach((s) => {
        this.severityTrend.data.push({
          name: s,
          value: payload.scan_trend.map((a) => setValue(a, s)),
          color: setColor(s),
        });
      });
    },

    // ## Woe by severity. ---------------------------------------------------------------------------
    async getWoeBySeverity(data) {
      this.woeBySeverityPending = true;

      try {
        const res = await DashboardService.getWoeBySeverity(data);
        this.setGetWoeBySeverity(res.data.chart_data);
        this.woeBySeverityDone = !this.woeBySeverityDone;
        this.woeBySeverityPending = false;
      } catch (error) {
        this.woeBySeverityPending = false;
        ErrorHandling(error);
      }
    },

    setGetWoeBySeverity(payload) {
      this.woeBySeverity = {
        data: [],
        days: [],
      };
      if (payload.bars.filter((s) => s.count > 0).length < 1) {
        return;
      }
      const setColor = (type) => {
        if (type === 'low') {
          return COLOR.LOW;
        }
        if (type === 'medium') {
          return COLOR.MEDIUM;
        }
        if (type === 'high') {
          return COLOR.HIGH;
        }
        return COLOR.CRITICAL;
      };
      this.woeBySeverity.days = payload.bars.map((a) => `${a.stack_name} Days`);
      const keys = ['low', 'medium', 'high', 'critical'];
      keys.forEach((s) => {
        this.woeBySeverity.data.push({
          name: s,
          value: payload.bars.map((a) => a.findings[s]),
          color: setColor(s),
        });
      });
    },

    // ## Woe top ten. -------------------------------------------------------------------------------
    async getWoeTopTen(data) {
      this.woeTopTenPending = true;

      try {
        const res = await DashboardService.getWoeTopTen(data);
        this.setGetWoeTopTen(res.data.chart_data);
        this.woeTopTenDone = !this.woeTopTenDone;
        this.woeTopTenPending = false;
      } catch (error) {
        this.woeTopTenPending = false;
        ErrorHandling(error);
      }
    },

    setGetWoeTopTen(payload) {
      this.woeTopTen = [];
      payload.Vulns.forEach((s) => {
        this.woeTopTen.push({
          // eslint-disable-next-line no-underscore-dangle
          id: s._id,
          name: s.name,
          severity: s.severity,
          woe: s.woe,
        });
      });
    },

    // ## Get severity team. -------------------------------------------------------------------------
    async getSeverityTeam(data) {
      this.severityTeamPending = true;

      try {
        const res = await DashboardService.getSeverityTeam(data);
        this.setGetSeverityTeam(res.data.chart_data);
        this.severityTeamDone = !this.severityTeamDone;
        this.severityTeamPending = false;
      } catch (error) {
        this.severityTeamPending = false;
        ErrorHandling(error);
      }
    },

    setGetSeverityTeam(payload) {
      let teams = [];
      this.severityTeam = {
        teams: [],
        colors: [COLOR.LOW, COLOR.MEDIUM, COLOR.HIGH, COLOR.CRITICAL],
        data: [],
      };
      this.severityTeamAllTeams = [];
      if (!payload.teams) {
        return;
      }
      payload.teams.forEach((d) => {
        this.severityTeamAllTeams.push({
          key: d.name,
          title: d.name,
        });
      });
      if (!this.severityTeamVisibleTeams) {
        teams = payload.teams.map((d) => d.name).slice(0, this.severityTrendByTeamMaxVisibleTeams);
        localStorage[teamsLocalStorageName] = teams;
        this.severityTeamVisibleTeams = teams;
      } else {
        teams = this.severityTeamVisibleTeams;
      }
      this.severityTeam.teams = payload.teams.filter((a) => teams.includes(a.name)).map((s) => s.name);
      if (this.severityTeam.teams.filter((s) => this.severityTeamVisibleTeams.includes(s)).length < 1) {
        localStorage.removeItem('kondukto-severity-visible-teams');
      }
      const keys = ['low', 'medium', 'high', 'critical'];
      keys.forEach((s) => {
        this.severityTeam.data.push({
          name: s,
          value: payload.teams.filter((a) => teams.includes(a.name)).map((a) => a.findings[s]),
        });
      });
    },

    changeSeverityTeamVisibleTeams(data) {
      localStorage['kondukto-severity-visible-teams'] = data;
      this.severityTeamVisibleTeams = data;
    },

    resetSeverityTrendByTeamChartData() {
      this.severityTrendByTeamChartVisible = false;
      this.severityTrendByTeamChartData = [];
      this.chartDataProps = {
        tabName: null,
      };
      this.severityTrendByTeam = null;
      this.severityTrendByTeamPending = false;
      this.severityTrendByTeamDone = false;
    },

    // ## Security KPI trend request. ----------------------------------------------------------------
    async getSecurityKPITrend(data) {
      this.securityKPITrendPending = true;

      try {
        const res = await DashboardService.getSecurityKPITrend(data);
        this.setGetSecurityKPITrend(res.data.chart_data);
        this.securityKPITrendDone = !this.securityKPITrendDone;
        this.securityKPITrendPending = false;
      } catch (error) {
        this.securityKPITrendPending = false;
        ErrorHandling(error);
      }
    },

    setGetSecurityKPITrend(payload) {
      this.securityKPITrend = {
        data: [],
        dates: [],
      };
      if (
        payload.kpi_metric_trend.filter((s) => s.first_action > 0 ||
          s.first_response > 0 || s.go_live_delay > 0 ||
          s.remediation > 0 || s.resolution > 0).length < 1
      ) {
        return;
      }
      const formatDate = (date) => (
        dayjs(date).format('DD MMM YYYY')
      );
      const setType = (type) => {
        if (type === 'first_response') {
          return 'Time to first response (d)';
        }
        if (type === 'first_action') {
          return 'Time to first action (d)';
        }
        if (type === 'go_live_delay') {
          return 'Go-live delay (d)';
        }
        if (type === 'remediation') {
          return 'Time to remediate (d)';
        }
        if (type === 'date') {
          return 'date';
        }
        return 'Time to resolution (d)';
      };
      const setColor = (type) => {
        if (type === 'first_response') {
          return '#1790ff';
        }
        if (type === 'first_action') {
          return '#e05633';
        }
        if (type === 'go_live_delay') {
          return '#f8b82e';
        }
        if (type === 'remediation') {
          return '#418b4b';
        }
        if (type === 'date') {
          return 'date';
        }
        return '#f8e23b';
      };
      this.securityKPITrend.dates = payload.kpi_metric_trend.map((s) => formatDate(s.date));
      const keys = [];
      Object.entries(payload.kpi_metric_trend[0]).forEach((s) => {
        if (s[0] !== 'date') {
          keys.push(s[0]);
        }
      });
      keys.forEach((s) => {
        this.securityKPITrend.data.push({
          name: setType(s),
          value: payload.kpi_metric_trend.map((a) => Math.ceil(a[s])),
          color: setColor(s),
        });
      });
    },

    // ## Get summary table. -------------------------------------------------------------------------
    async getSummaryTable(data) {
      this.summaryTablePending = true;

      try {
        const res = await DashboardService.getSummary(data);
        this.setGetSummaryTable(res.data.chart_data);
        this.summaryTableDone = !this.summaryTableDone;
        this.summaryTablePending = false;
      } catch (error) {
        this.summaryTablePending = false;
        ErrorHandling(error);
      }
    },

    setGetSummaryTable(payload) {
      const data = [];
      Object.keys(payload.rows).forEach((s) => {
        data.push({
          name: s === 'ra' ? 'riskAccepted' : s,
          critical: payload.rows[s].findings.critical,
          high: payload.rows[s].findings.high,
          low: payload.rows[s].findings.low,
          medium: payload.rows[s].findings.medium,
          total: payload.rows[s].findings.critical +
            payload.rows[s].findings.high +
            payload.rows[s].findings.low +
            payload.rows[s].findings.medium,
        });
      });
      this.summaryTable = data;
    },

    // ## Get action items table. --------------------------------------------------------------------
    async getActionItemsTable(data) {
      this.actionItemsTablePending = true;

      try {
        const res = await DashboardService.getActionItemsTable(data);
        this.setGetActionItemsTable(res.data);
        this.actionItemsTableDone = !this.actionItemsTableDone;
        this.actionItemsTablePending = false;
      } catch (error) {
        this.actionItemsTable = actionItemsDummy;
        this.actionItemsTablePending = false;
        this.actionItemsTableFail = !this.actionItemsTableFail;
        ErrorHandling(error);
      }
    },

    setGetActionItemsTable(payload) {
      this.actionItemsTable = [];
      this.actionItemsTable = payload.map((s) => ({
        id: s.id,
        titles: s.title,
        state: s.state,
        assignedTo: (s.assigned_to !== '' ? s.assigned_to : 'Unassigned'),
      })).sort((a, b) => {
        const NumberA = Number(a.id);
        const NumberB = Number(b.id);
        if (NumberB < NumberA) return 1;
        if (NumberB > NumberA) return -1;
        return 0;
      });
    },

    // ## Get suppressed vulns chart. ----------------------------------------------------------------
    async getSuppressedVulns(data) {
      this.suppressedVulnsPending = true;

      try {
        const res = await DashboardService.getSuppressedVulns(data);
        this.setGetSuppressedVulns(res.data.chart_data);
        this.suppressedVulnsDone = !this.suppressedVulnsDone;
        this.suppressedVulnsPending = false;
      } catch (error) {
        this.suppressedVulnsPending = false;
        ErrorHandling(error);
      }
    },

    setGetSuppressedVulns(payload) {
      this.suppressedVulns = {
        data: [],
        types: [],
      };
      if (payload.bars.filter((s) => s.total > 0).length < 1) {
        return;
      }
      const setColor = (type) => {
        if (type === 'low') {
          return COLOR.LOW;
        }
        if (type === 'medium') {
          return COLOR.MEDIUM;
        }
        if (type === 'high') {
          return COLOR.HIGH;
        }
        return COLOR.CRITICAL;
      };
      const setTypes = (type) => {
        if (type === 'fp') {
          return 'falsePositive';
        }
        if (type === 'wf') {
          return 'wontfix';
        }
        return type;
      };
      this.suppressedVulns.types = payload.bars.map((a) => setTypes(a.stack_name));
      const keys = ['low', 'medium', 'high', 'critical'];
      keys.forEach((s) => {
        this.suppressedVulns.data.push({
          name: s,
          value: payload.bars.map((a) => a.findings[s]),
          color: setColor(s),
        });
      });
    },

    // ## Get burndown trend chart. ------------------------------------------------------------------
    async getBurndownTrend(data) {
      this.burndownTrendPending = true;

      try {
        const res = await DashboardService.getBurndownTrend(data);
        this.setGetBurndownTrend(res.data.chart_data);
        this.burndownTrendDone = !this.burndownTrendDone;
        this.burndownTrendPending = false;
      } catch (error) {
        this.burndownTrendPending = false;
        ErrorHandling(error);
      }
    },

    setGetBurndownTrend(payload) {
      this.burndownTrend = {
        data: [],
        dates: [],
      };
      if (payload.vulnerability_trend.filter((s) => s.total_count > 0).length < 1) {
        return;
      }
      const formatDate = (date) => (
        dayjs(date).format('DD MMM YYYY')
      );
      this.burndownTrend = {
        data: [],
        dates: payload.vulnerability_trend.map((a) => formatDate(a.scan_date)),
      };
      const setValue = (type) => {
        if (type === 'riskAccepted') {
          return payload.vulnerability_trend.map((a) => (type === 'riskAccepted' && a.total_ra_count));
        }
        if (type === 'closed') {
          return payload.vulnerability_trend.map((a) => (type === 'closed' && a.total_closed_count));
        }
        if (type === 'open') {
          return payload.vulnerability_trend.map((a) => (type === 'open' && a.total_count));
        }
        if (type === 'total') {
          return payload.vulnerability_trend.map((a) => (type === 'total' && a.total_ra_count + a.total_closed_count + a.total_count));
        }
      };
      const setColor = (type) => {
        if (type === 'riskAccepted') {
          return COLOR.LOW;
        }
        if (type === 'closed') {
          return COLOR.GREEN;
        }
        if (type === 'open') {
          return COLOR.CRITICAL;
        }
      };
      ['closed', 'riskAccepted', 'open', 'total'].forEach((s) => {
        this.burndownTrend.data.push({
          name: s,
          color: setColor(s),
          value: setValue(s),
        });
      });
    },

    // ## Issue trend request. ---------------------------------------------------------------
    async getIssueTrend(data) {
      this.issueTrendPending = true;

      try {
        const res = await DashboardService.getIssueTrend(data);
        this.setGetIssueTrend(res.data.chart_data);
        this.issueTrendDone = !this.issueTrendDone;
        this.issueTrendPending = false;
      } catch (error) {
        this.issueTrendPending = false;
        ErrorHandling(error);
      }
    },

    setGetIssueTrend(payload) {
      this.issueTrend = {
        data: [],
        dates: [],
      };
      if (
        payload
          .filter((s) => s.closed_count > 0 || s.in_progress_count > 0 || s.opened_count > 0)
          .length < 1
      ) {
        return;
      }
      const formatDate = (date) => (
        dayjs(date).format('DD MMM YYYY')
      );
      const setValue = (a, s) => {
        if (s === 'open') {
          return a.opened_count;
        }
        if (s === 'closed') {
          return a.closed_count;
        }
        return a.in_progress_count;
      };
      const setColor = (type) => {
        if (type === 'open') {
          return COLOR.DANGER;
        }
        if (type === 'closed') {
          return COLOR.GREEN;
        }
        return COLOR.MEDIUM;
      };
      this.issueTrend = {
        data: [],
        dates: payload.map((a) => formatDate(a.date)),
      };
      const keys = ['closed', 'inprogress', 'open'];
      keys.forEach((s) => {
        this.issueTrend.data.push({
          name: s,
          value: payload.map((a) => setValue(a, s)),
          color: setColor(s),
        });
      });
    },

    // ## Recurrent vulnerabilities. ---------------------------------------------------------------------------
    async getRecurrentVulnerabilities(data) {
      this.recurrentVulnerabilitiesPending = true;

      try {
        const res = await DashboardService.getRecurrentVulns(data);
        this.setGetRecurrentVulnerabilities(res.data.chart_data, data);
        this.recurrentVulnerabilitiesDone = !this.recurrentVulnerabilitiesDone;
        this.recurrentVulnerabilitiesPending = false;
      } catch (error) {
        this.recurrentVulnerabilitiesPending = false;
        ErrorHandling(error);
      }
    },

    setGetRecurrentVulnerabilities(payload, data) {
      this.recurrentVulnerabilities = {
        colors: [COLOR.LOW, COLOR.MEDIUM, COLOR.HIGH, COLOR.CRITICAL],
        axis: payload[data.query.category].map((s) => s.name),
        data: [],
      };
      if (!payload[data.query.category]) {
        return;
      }
      const keys = ['low', 'medium', 'high', 'critical'];
      keys.forEach((s) => {
        this.recurrentVulnerabilities.data.push({
          name: s,
          value: payload[data.query.category].map((a) => a.summary[s]),
        });
      });
    },

    // ## New vulnerabilities. ---------------------------------------------------------------------------
    async getNewVulnerabilities(data) {
      this.newVulnerabilitiesPending = true;

      try {
        const res = await DashboardService.getNewVulns(data);
        this.setGetNewVulnerabilities(res.data.chart_data, data);
        this.newVulnerabilitiesDone = !this.newVulnerabilitiesDone;
        this.newVulnerabilitiesPending = false;
      } catch (error) {
        this.newVulnerabilitiesPending = false;
        ErrorHandling(error);
      }
    },

    setGetNewVulnerabilities(payload, data) {
      this.newVulnerabilities = {
        colors: [COLOR.LOW, COLOR.MEDIUM, COLOR.HIGH, COLOR.CRITICAL],
        axis: payload[data.query.category].map((s) => s.name),
        data: [],
      };
      if (!payload[data.query.category]) {
        return;
      }
      const keys = ['low', 'medium', 'high', 'critical'];
      keys.forEach((s) => {
        this.newVulnerabilities.data.push({
          name: s,
          value: payload[data.query.category].map((a) => a.summary[s]),
        });
      });
    },

    // ## Closed vulnerabilities. ---------------------------------------------------------------------------
    async getClosedVulnerabilities(data) {
      this.closedVulnerabilitiesPending = true;

      try {
        const res = await DashboardService.getClosedVulns(data);
        this.setGetClosedVulnerabilities(res.data.chart_data, data);
        this.closedVulnerabilitiesDone = !this.closedVulnerabilitiesDone;
        this.closedVulnerabilitiesPending = false;
      } catch (error) {
        this.closedVulnerabilitiesPending = false;
        ErrorHandling(error);
      }
    },

    setGetClosedVulnerabilities(payload, data) {
      this.closedVulnerabilities = {
        colors: [COLOR.LOW, COLOR.MEDIUM, COLOR.HIGH, COLOR.CRITICAL],
        axis: payload[data.query.category].map((s) => s.name),
        data: [],
      };
      if (!payload[data.query.category]) {
        return;
      }
      const keys = ['low', 'medium', 'high', 'critical'];
      keys.forEach((s) => {
        this.closedVulnerabilities.data.push({
          name: s,
          value: payload[data.query.category].map((a) => a.summary[s]),
        });
      });
    },

    // ## Net new vulnerabilities. ---------------------------------------------------------------------------
    async getNetNewVulnerabilities(data) {
      this.netNewVulnerabilitiesPending = true;

      try {
        const res = await DashboardService.getNetNewVulns(data);
        this.setGetNetNewVulnerabilities(res.data.chart_data, data);
        this.netNewVulnerabilitiesDone = !this.netNewVulnerabilitiesDone;
        this.netNewVulnerabilitiesPending = false;
      } catch (error) {
        this.netNewVulnerabilitiesPending = false;
        ErrorHandling(error);
      }
    },

    setGetNetNewVulnerabilities(payload, data) {
      this.netNewVulnerabilities = {
        colors: [COLOR.LOW, COLOR.MEDIUM, COLOR.HIGH, COLOR.CRITICAL],
        axis: payload[data.query.category].map((s) => s.name),
        data: [],
      };
      if (!payload[data.query.category]) {
        return;
      }
      const keys = ['low', 'medium', 'high', 'critical'];
      keys.forEach((s) => {
        this.netNewVulnerabilities.data.push({
          name: s,
          value: payload[data.query.category].map((a) => a.summary[s]),
        });
      });
    },

    // ## Suppressed vulnerabilities. ---------------------------------------------------------------------------
    async getSuppressedVulnerabilities(data) {
      this.suppressedVulnerabilitiesPending = true;

      try {
        const res = await DashboardService.getSuppressedVulnerabilities(data);
        this.setGetSuppressedVulnerabilities(res.data.chart_data, data);
        this.suppressedVulnerabilitiesDone = !this.suppressedVulnerabilitiesDone;
        this.suppressedVulnerabilitiesPending = false;
      } catch (error) {
        this.suppressedVulnerabilitiesPending = false;
        ErrorHandling(error);
      }
    },

    setGetSuppressedVulnerabilities(payload, data) {
      this.suppressedVulnerabilities = {
        colors: [COLOR.LOW, COLOR.MEDIUM, COLOR.HIGH, COLOR.CRITICAL],
        axis: payload[data.query.category].map((s) => s.name),
        data: [],
      };
      if (!payload[data.query.category]) {
        return;
      }
      const keys = ['low', 'medium', 'high', 'critical'];
      keys.forEach((s) => {
        this.suppressedVulnerabilities.data.push({
          name: s,
          value: payload[data.query.category].map((a) => a.summary[s]),
        });
      });
    },

    // ## New closed trend chart. --------------------------------------------------------------------------------------
    async getNewClosedTrend(data) {
      this.newClosedTrendPending = true;

      try {
        const res = await DashboardService.getNewClosedTrend(data);
        this.setGetNewClosedTrend(res.data.chart_data.weeks);
        this.newClosedTrendDone = !this.newClosedTrendDone;
        this.newClosedTrendPending = false;
      } catch (error) {
        this.newClosedTrendPending = false;
        ErrorHandling(error);
      }
    },

    setGetNewClosedTrend(payload) {
      this.newClosedTrend = {
        data: [],
        weeks: [],
      };
      if (!payload || payload.filter((s) => s.total_open > 0 || s.total_closed > 0).length < 1) {
        return;
      }
      const setValue = (a, s) => {
        if (s === 'total_open') {
          return a.total_open;
        }
        return a.total_closed;
      };
      const setColor = (type) => {
        if (type === 'total_open') {
          return COLOR.DANGER;
        }
        return COLOR.GREEN;
      };
      this.newClosedTrend.weeks = payload.map((a) => `Week ${a.week}`);
      const keys = ['total_open', 'total_closed'];
      keys.forEach((s) => {
        this.newClosedTrend.data.push({
          name: s === 'total_open' ? 'totalNew' : 'totalClosed',
          value: payload.map((a) => setValue(a, s)),
          color: setColor(s),
        });
      });
    },

    // ## Get environment breakdown chart. ----------------------------------------------------------------
    async getEnvironmentBreakdown(data) {
      this.environmentBreakdownPending = true;

      try {
        const res = await DashboardService.getEnvironmentBreakdown(data);
        this.setGetEnvironmentBreakdown(res.data.chart_data);
        this.environmentBreakdownDone = !this.environmentBreakdownDone;
        this.environmentBreakdownPending = false;
      } catch (error) {
        this.environmentBreakdownPending = false;
        ErrorHandling(error);
      }
    },

    setGetEnvironmentBreakdown(payload) {
      this.environmentBreakdown = {
        data: [],
        types: [],
      };
      if (payload.bars.filter((s) => s.count > 0).length < 1) {
        return;
      }
      const setColor = (type) => {
        if (type === 'low') {
          return COLOR.LOW;
        }
        if (type === 'medium') {
          return COLOR.MEDIUM;
        }
        if (type === 'high') {
          return COLOR.HIGH;
        }
        return COLOR.CRITICAL;
      };
      this.environmentBreakdown.types = payload.bars.map((a) => i18n.global.t(a.stack_name === 'development' ? 'featuredevelopment' : a.stack_name));
      const keys = ['low', 'medium', 'high', 'critical'];
      keys.forEach((s) => {
        this.environmentBreakdown.data.push({
          name: s,
          value: payload.bars.map((a) => a.findings[s]),
          color: setColor(s),
        });
      });
    },
  },
});

export default useDashboardStore;
