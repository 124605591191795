export default {
  path: 'scans',
  name: 'scans',
  component: () => import('@/views/layouts/ScansLayout.vue'),
  redirect: {
    name: 'scans-list-queue',
    query: {
      page: 1,
    },
  },
  children: [
    {
      path: 'queue',
      name: 'scans-list-queue',
      component: () => import('@/views/pages/member/Scans/Queue.vue'),
      meta: {
        title: 'Scans Queue',
        activeMenuKeys: ['scans', 'queue'],
        allowedRole: [0, 1, 5, 10],
      },
      beforeEnter: (to) => {
        if (!to.query.page) {
          return {
            name: 'scans-list-queue',
            query: { page: 1 },
          };
        }
      },
    },
    {
      path: 'completed',
      name: 'scans-list-view',
      component: () => import('@/views/pages/member/Scans/ListView.vue'),
      meta: {
        title: 'Completed Scans',
        activeMenuKeys: ['scans', 'list'],
        allowedRole: [0, 1, 5],
      },
      beforeEnter: (to) => {
        if (!to.query.page) {
          return {
            name: 'scans-list-view',
            query: { page: 1 },
          };
        }
      },
    }, {
      path: 'failed',
      name: 'scans-list-failed',
      component: () => import('@/views/pages/member/Scans/Failed.vue'),
      meta: {
        title: 'Failed Scans',
        activeMenuKeys: ['scans', 'failed'],
        allowedRole: [0, 1, 5, 10],
      },
      beforeEnter: (to) => {
        if (!to.query.page) {
          return {
            name: 'scans-list-failed',
            query: { page: 1 },
          };
        }
      },
    }, {
      path: 'calendar-view',
      name: 'scans-calendar-view',
      component: () => import('@/views/pages/member/Scans/CalendarView.vue'),
      meta: {
        title: 'Scans Calendar',
        activeMenuKeys: ['scans', 'calendar'],
        allowedRole: [0, 1, 5],
      },
    }, {
      path: 'imports',
      name: 'scans-imports',
      component: () => import('@/views/pages/member/Scans/Imports.vue'),
      meta: {
        title: 'Imports',
        activeMenuKeys: ['scans', 'import'],
        allowedRole: [0, 1, 5, 10],
      },
      beforeEnter: (to) => {
        if (!to.query.page) {
          return {
            name: 'scans-imports',
            query: { page: 1 },
          };
        }
      },
    }, {
      path: 'comparison',
      name: 'scans-comparison',
      component: () => import('@/views/pages/member/Scans/Comparison.vue'),
      meta: {
        title: 'Scans Comparison',
        activeMenuKeys: ['scans', 'comparison'],
        allowedRole: [0, 1, 5, 10],
      },
    },
  ],
};
