const Error = (error) => {
  let message = null;
  if (error.name === 'AxiosError') {
    if (error.response) {
      if (error.response.data.title) {
        return error.response.data;
      }
      message = `${error.response.data.error}`;
    } else {
      message = `RequestError: ${error.code} ${error.message}`;
    }
  } else if (error.name === 'CanceledError') {
    message = 'Operation canceled by the user';
    console.warn(`Operation canceled by the user /${error.config.url}`);
  } else {
    message = error;
    console.error(error);
  }
  return message;
};

export default Error;
