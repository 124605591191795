// ## Config Inc.
import { LOCAL_STORAGE } from '@/config/';

import useAuthStore from '@/store/auth';
import useDashboardFilterStore from '@/store/dashboard/Filter';

export default ((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const authToken = localStorage[LOCAL_STORAGE.TOKEN_NAME];
  const store = useAuthStore();
  const dashboardFilterStore = useDashboardFilterStore();
  const { authRole, authDefault, isDemohub } = store;
  const matchedRole = to.meta.allowedRole && to.meta.allowedRole.indexOf(authRole) > -1;
  const isRedirect = to.fullPath.indexOf('redirect') > 1;

  const {
    type,
    scannerType,
    branch,
    projects,
    teams,
    labels,
    businessCriticality,
    businessUnits,
    infraGroups,
  } = dashboardFilterStore.globalDashboardFilter.global.filter;
  const dashboardQuery = {
    type,
    scannerType,
    ...(type === 'appsec' && { branch }),
    ...(businessCriticality && { businessCriticality }),
    ...(businessUnits && { businessUnits }),
    ...(scannerType === 'infra' && { infraGroups }),
    ...(labels && { labels }),
    ...(projects && { projects }),
    ...(teams && { teams }),
  };

  if (to.path === '/vulnerabilities/appsec') {
    next({
      name: 'all-appsec-vulns',
      query: to.query,
    });
  } else if (to.path === '/vulnerabilities/infra') {
    next({
      name: 'all-infra-vulns',
      query: to.query,
    });
  }
  if (to.query && (to.query.perPage > 100 || to.query.perPage < 0)) {
    to.query.perPage = 100;
  }

  if (from.name !== null) {
    if (to.name && to.name.indexOf('project-add') < 0) {
      localStorage.removeItem('projectId');
    }
  }

  if (!authToken && requiresAuth) {
    next({
      name: 'login',
      ...(to.fullPath !== '/' && {
        query: {
          redirect: to.fullPath,
        },
      }),
    });
  }

  if (authToken && !authDefault) {
    if (!matchedRole && !isRedirect) {
      if (authRole === 10) {
        next({
          name: 'project-list',
          query: {
            page: 1,
          },
        });
      } else {
        next({
          name: 'dashboard',
          query: {
            ...dashboardQuery,
          },
        });
      }
    }

    if (!isDemohub && to.name === 'demohub-scanners') {
      next({
        name: 'dashboard',
        query: {
          ...dashboardQuery,
        },
      });
    }
  }
  if (to.name === 'dashboard' && (!to.query.type)) {
    next({
      name: 'dashboard',
      query: {
        type: 'appsec',
        scannerType: 'consolidated',
        branch: 'all-branches',
        businessCriticality: 'all',
      },
    });
  }
  store.cancelPendingRequest();
  next();
});
