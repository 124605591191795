import { defineStore } from 'pinia';

import SettingsService from '@/services/Settings';

import ErrorHandling from '@/mixins/ErrorHandling';

import { PROJECT, GLOBAL, SBOM } from '@/config/';

import dayjs from 'dayjs';
import i18n from '@/i18n';

import { rrulestr } from 'rrule';

import reportDummy from './_reportDummy';
import reportHistoryDummyData from './_historyDummy';
import sourceProjectsDummy from './_sourceProjectsDummy';
import { Dummy, auditLogDummy } from './_dummy';
import labelDummy from './_labelDummy';

import { automationRulesDummy } from './_automationRulesDummy';
import slaCriteriaDummy from './_slaCriteriaDummy';
import { sbomDummy, sbomDetailDummy } from './_sbomDummy';

import { infraProfileDummy, infraGroupsDummy, toolProfilesDummy } from './_infraDummy';

import {
  teamLeadScannerIntegrationSettingsDummy,
  teamLeadIntegrationSettingsDummy,
} from './_teamLeadIntegrationSettingsDummy';

const setCompiledAutomationRules = (payload, type) => {
  const data = {
    id: payload.id,
    import: payload.import,
    type: payload.policy_type || payload.type,
    vulnType: payload.vulnType,
    count: payload.count,
    rule: [],
  };
  if (
    payload.release && payload.release.ok &&
    payload.release.min_repetition_count &&
    payload.release.min_repetition_count > 0
  ) {
    data.vulnType = 'count';
    data.count = payload.release.min_repetition_count;
  }
  const setRuleField = (field) => {
    if (field === 'cvss_vector') {
      return 'cvssVector';
    }
    if (field === 'cwe_no') {
      return 'cweId';
    }
    if (field === 'cwe_name') {
      return 'cweName';
    }
    if (field === 'default_branch') {
      return 'defaultBranch';
    }
    if (field === 'dns_name') {
      return 'dnsName';
    }
    if (field === 'docker_image') {
      return 'dockerImage';
    }
    if (field === 'epss_percentage') {
      return 'epssPercentage';
    }
    if (field === 'epss_percentile') {
      return 'epssPercentile';
    }
    if (field === 'fp') {
      return 'falsePositive';
    }
    if (field === 'tp') {
      return 'truePositive';
    }
    if (field === 'from') {
      return 'firstSeenFrom';
    }
    if (field === 'to') {
      return 'firstSeenTo';
    }
    if (field === 'historical_status') {
      return 'historicalStatus';
    }
    if (field === 'issue_closed_date') {
      return 'issueClosedDate';
    }
    if (field === 'issue_created_date') {
      return 'issueCreatedDate';
    }
    if (field === 'issue_status') {
      return 'issueStatus';
    }
    if (field === 'issue_wip_date') {
      return 'issueWIPDate';
    }
    if (field === 'known_exploit') {
      return 'knownExploit';
    }
    if (field === 'last_seen') {
      return 'lastSeen';
    }
    if (field === 'last_scan') {
      return 'lastScan';
    }
    if (field === 'meta_data') {
      return 'metaData';
    }
    if (field === 'mit') {
      return 'mitigated';
    }
    if (field === 'name_space') {
      return 'nameSpace';
    }
    if (field === 'net_bios_name') {
      return 'netBiosName';
    }
    if (field === 'issue_assignee') {
      return 'issueAssignee';
    }
    if (field === 'issue_id') {
      return 'issueId';
    }
    if (field === 'issue_url') {
      return 'issueUrl';
    }
    if (field === 'observed_in_the_wild') {
      return 'observedInTheWild';
    }
    if (field === 'owasp2017') {
      return 'owasp2017Top10';
    }
    if (field === 'owasp2021') {
      return 'owasp2021Top10';
    }
    if (field === 'purl') {
      return 'packageUrl';
    }
    if (field === 'plugin_id') {
      return 'pluginId';
    }
    if (field === 'path') {
      return 'filePath';
    }
    if (field === 'risk_rating') {
      return 'riskRating';
    }
    if (field === 'ra') {
      return 'riskAccepted';
    }
    if (field === 'source_type') {
      return 'sbomSource';
    }
    if (field === 'tool_type') {
      return 'scannerType';
    }
    if (field === 'tool_name') {
      return 'scanner';
    }
    if (field === 'tool_name') {
      return 'scanner';
    }
    if (field === 'text') {
      return 'textSearch';
    }
    if (field === 'wf') {
      return 'wontfix';
    }
    return field;
  };

  const setRuleValue = (field, value) => {
    if (['fixable', 'known_exploit', 'observed_in_the_wild', 'manual', 'overdue', 'fp', 'tp'].find((s) => s === field) && type !== 'internal') {
      return value === 'true' ? 'yes' : 'no';
    }
    if (field === 'metaData' && value === 'undefined') {
      return '';
    }
    return value;
  };

  const setRuleOperand = (operand) => {
    if (operand.split('=')[0] && operand.split('=')[1].split(':')[0] === 'eq' && operand.split('=')[1].split(':')[1] === '') {
      return 'bl';
    }
    if (operand.split('=')[0] && operand.split('=')[1].split(':')[0] === 'ne' && operand.split('=')[1].split(':')[1] === '') {
      return 'nbl';
    }
    return operand.split('=')[1].split(':')[0];
  };

  if (payload.scan && (payload.policy_type === 'scan' || payload.type === 'scan')) {
    if (payload.scan.frequency) {
      data.rule.push({
        id: 0,
        field: 'lastScan',
        operand: 'old',
        value: payload.scan.frequency,
      });
    }
    if (payload.scan.scanner_names && payload.scan.scanner_names.length > 0) {
      data.rule.push({
        id: 0,
        field: 'scanner',
        operand: 'in',
        value: payload.scan.scanner_names.toString(),
      });
    }
  } else {
    payload.rule.split('&').forEach((s, i) => {
      data.rule.push({
        id: i,
        field: setRuleField(s.split('=')[0]),
        operand: setRuleOperand(s),
        value: setRuleValue(s.split('=')[0], s.split('=')[1].split(':').filter((a, b) => b !== 0).join(':')),
      });
    });
  }
  return data;
};

const useSettingsStore = defineStore({
  id: 'Settings',
  state: () => ({
    // ## Table settings. ----------------------------------------------------------------------------
    allScannerSettings: [],
    allTISettings: [],
    allAlmSettings: [],
    allIssueSettings: [],
    allNotificationSettings: [],
    allSingleSignOnToolsSettings: [],
    allAgentsSettings: [],
    allTrainingSettings: [],
    allRecommendationSettings: [],

    // ## Create scanners projects. ------------------------------------------------------------------
    createScannersProjectsData: {},
    createScannersProjectsPending: false,
    createScannersProjectsDone: false,
    createScannersProjectsFail: false,
    createScannersProjectsFailMsg: null,

    // ## Scanners projects. -------------------------------------------------------------------------
    scannersProjects: [],
    scannersProjectsPending: false,
    scannersProjectsDone: false,
    scannersProjectsFail: false,
    scannersProjectsFailMsg: null,

    // ## Scanners enginess. -------------------------------------------------------------------------
    scannersEngines: [],
    scannersEnginesPending: false,
    scannersEnginesDone: false,
    scannersEnginesFail: false,
    scannersEnginesFailMsg: null,

    // ## Scanners projects detail. ------------------------------------------------------------------
    scannersProjectsDetail: null,
    scannersProjectsDetailPending: false,
    scannersProjectsDetailDone: false,

    // ## Scanners configurations. -------------------------------------------------------------------
    scannersConfigurations: [],
    scannersConfigurationsPending: false,
    scannersConfigurationsDone: false,

    // ## Upload scanners preset. --------------------------------------------------------------------
    uploadScannersPresetsData: null,
    uploadScannersPresetsPending: false,
    uploadScannersPresetsDone: false,
    uploadScannersPresetsFail: false,
    uploadScannersPresetsFailMsg: null,

    // ## Scanners macros. ---------------------------------------------------------------------------
    scannersMacrosData: null,
    scannersMacrosPending: false,
    scannersMacrosDone: false,

    // ## Upload scanners macro. ---------------------------------------------------------------------
    uploadScannersMacroData: null,
    uploadScannersMacroPending: false,
    uploadScannersMacroDone: false,

    // ## Scanners profiles. -------------------------------------------------------------------------
    scannersProfiles: null,
    scannersProfilesPending: false,
    scannersProfilesDone: false,
    scannersProfilesFail: false,
    scannersProfilesFailMsg: null,

    semgrepRules: [],

    // ## Repos projects. ----------------------------------------------------------------------------
    reposProjects: [],
    reposProjectsPending: false,
    reposProjectsDone: false,
    reposProjectsFail: false,

    // ## Repos collections. -------------------------------------------------------------------------
    reposCollections: null,
    reposCollectionsPending: false,
    reposCollectionsDone: false,
    reposCollectionsFail: false,

    // ## Repos apps. --------------------------------------------------------------------------------
    reposApps: null,
    reposAppsPending: false,
    reposAppsDone: false,

    // ## Repos branches. ----------------------------------------------------------------------------
    reposBranches: [],
    reposBranchesPending: false,
    reposBranchesDone: false,

    // ## Repos tags. ----------------------------------------------------------------------------
    reposTags: [],
    reposTagsPending: false,
    reposTagsDone: false,

    // ## Issues projects. ---------------------------------------------------------------------------
    issuesProjects: [],
    issuesProjectsPending: false,
    issuesProjectsDone: false,
    issuesProjectsFail: false,

    // ## Issues components. -------------------------------------------------------------------------
    issuesComponents: null,
    issuesComponentsPending: false,
    issuesComponentsDone: false,

    // ## Issues types. ------------------------------------------------------------------------------
    issuesTypes: [],
    issueCustomVariables: null,
    issueResolutionsStates: [],
    issuesTypesPending: false,
    issuesTypesDone: false,
    issuesTypesFail: false,

    // ## Issues statuses. ---------------------------------------------------------------------------
    issuesStatuses: [],
    issuesStatusesPending: false,
    issuesStatusesDone: false,

    // ## Issues work items. ---------------------------------------------------------------------------
    issuesWorkItems: [],
    issuesWorkItemsPending: false,
    issuesWorkItemsDone: false,

    // ## Update Issues statuses. --------------------------------------------------------------------
    updateIssuesStatusesPending: false,
    updateIssuesStatusesDone: false,

    // ## Issues collections. ------------------------------------------------------------------------
    issuesCollections: [],
    issuesCollectionsPending: false,
    issuesCollectionsDone: false,

    // ## Issues override fields. ---------------------------------------------------------------------
    issuesOverrideFields: [],
    issuesOverrideFieldsPending: false,
    issuesOverrideFieldsDone: false,

    // ## Issue fields. ---------------------------------------------------------------------
    issueFields: [],
    issueFieldsPending: false,
    issueFieldsDone: false,

    // ## Ivanti Owners. ---------------------------------------------------------------------
    ivantiOwners: [],
    ivantiOwnersPending: false,
    ivantiOwnersDone: false,

    // ## Ivanti Owner teams. ---------------------------------------------------------------------
    ivantiOwnerTeams: [],
    ivantiOwnerTeamsPending: false,
    ivantiOwnerTeamsDone: false,

    // ## Team lead integration settings. ------------------------------------------------------------------------------
    teamLeadIntegrationSettings: teamLeadScannerIntegrationSettingsDummy,
    teamLeadIntegrationSettingsPending: false,
    teamLeadIntegrationSettingsDone: false,

    // ## Team lead integration details. ------------------------------------------------------------------------------
    teamLeadIntegrationDetails: {},
    teamLeadIntegrationDetailsPending: false,
    teamLeadIntegrationDetailsDone: false,

    // ## Team lead integration test connection. -----------------------------------------------------------------------
    teamLeadIntegrationTestConnectionData: null,
    teamLeadIntegrationTestConnectionPending: false,
    teamLeadIntegrationTestConnectionDone: false,
    teamLeadIntegrationTestConnectionDoneMsg: null,
    teamLeadIntegrationTestConnectionFail: false,
    teamLeadIntegrationTestConnectionFailMsg: null,

    // ## Retest team lead integration connection. ---------------------------------------------------------------------
    retestTeamLeadIntegrationConnectionPending: false,
    retestTeamLeadIntegrationConnectionDone: false,
    retestTeamLeadIntegrationConnectionFail: false,

    // ## Add team lead instance. --------------------------------------------------------------
    addTeamLeadIntegrationInstancePending: false,
    addTeamLeadIntegrationInstanceDone: false,

    // ## Update team lead instance. --------------------------------------------------------------
    updateTeamLeadIntegrationInstancePending: false,
    updateTeamLeadIntegrationInstanceDone: false,

    // ## Delete team lead instance. --------------------------------------------------------------
    deleteTeamLeadIntegrationInstancePending: false,
    deleteTeamLeadIntegrationInstanceDone: false,

    // ## Update tool access settings. --------------------------------------------------------------
    updateToolAccessSettingsPending: false,
    updateToolAccessSettingsDone: false,
    updateToolAccessSettingsFail: false,

    // ## All settings. ------------------------------------------------------------------------------
    allSettings: [],
    allSettingsPending: false,
    allSettingsDone: false,

    // ## Activate settings. -------------------------------------------------------------------------
    activateSettingsPending: false,
    activateSettingsDone: false,
    activateSettingsFail: false,
    activateSettingsFailMsg: null,

    // ## Update settings. ---------------------------------------------------------------------------
    updateSettingsPending: false,
    updateSettingsDone: false,
    updateSettingsFail: false,
    updateSettingsFailMsg: null,

    // ## Deactivete settings. -----------------------------------------------------------------------
    deactivateSettingsPending: false,
    deactivateSettingsDone: false,

    // ## Detial setting. ----------------------------------------------------------------------------
    settingsDetail: {},
    settingsDetailPending: false,
    settingsDetailDone: false,

    // ## Test connection. ---------------------------------------------------------------------------
    testConnectionData: null,
    testConnectionPending: false,
    testConnectionDone: false,
    testConnectionDoneMsg: null,
    testConnectionFail: false,
    testConnectionFailMsg: null,

    // ## Retest connection. ---------------------------------------------------------------------------
    retestConnectionPending: false,
    retestConnectionDone: false,
    retestConnectionFail: false,

    // ## Add instance setting. --------------------------------------------------------------
    addInstancePending: false,
    addInstanceDone: false,

    // ## Update instance setting. -----------------------------------------------------------
    updateInstancePending: false,
    updateInstanceDone: false,

    // ## Delete instance. -------------------------------------------------------------------
    deleteInstancePending: false,
    deleteInstanceDone: false,

    // ## All slack channels. ------------------------------------------------------------------------
    allSlackChannels: [],
    allSlackChannelsPending: false,
    allSlackChannelsDone: false,

    // ## All mattermost channels. -------------------------------------------------------------------
    allMattermostChannels: [],
    allMattermostChannelsPending: false,
    allMattermostChannelsDone: false,

    // ## Personal access token. ---------------------------------------------------------------------
    personalAccessToken: Dummy,
    newPersonalAccessTokenKey: null,
    personalAccessTokenPending: false,
    personalAccessTokenDone: false,
    personalAccessTokenTotal: 0,

    // ## Create Personal access token. --------------------------------------------------------------
    createPersonalAccessTokenPending: false,
    createPersonalAccessTokenDone: false,

    // ## Delete Personal access token. --------------------------------------------------------------
    deletePersonalAccessTokenPending: false,
    deletePersonalAccessTokenDone: false,

    // ## Update Personal access token. --------------------------------------------------------------
    updatePersonalAccessTokenPending: false,
    updatePersonalAccessTokenDone: false,

    // ## Report Profiles. ---------------------------------------------------------------------------
    reportProfiles: reportDummy,
    reportProfilesTotal: 0,
    reportProfilesPending: false,
    reportProfilesDone: false,

    // ## Report Profiles List -----------------------------------------------------------------------
    reportProfilesList: [],
    reportProfilesListPending: false,
    reportProfilesListDone: false,

    // ## Report profile detail. ---------------------------------------------------------------------
    reportProfileDetail: {},
    reportProfileDetailPending: false,
    reportProfileDetailDone: false,

    // ## Report profile add. ------------------------------------------------------------------------
    addReportProfilePending: false,
    addReportProfileDone: false,
    addReportProfileFail: false,
    addReportProfileFailMsg: null,

    // ## Report profile delete. ---------------------------------------------------------------------
    deleteReportProfilePending: false,
    deleteReportProfileDone: false,

    // ## Report profile update. ---------------------------------------------------------------------
    updateReportProfilePending: false,
    updateReportProfileDone: false,
    updateReportProfileFail: false,
    updateReportProfileFailMsg: null,

    // ## Report profile activate. -------------------------------------------------------------------
    activateReportProfilePending: false,
    activateReportProfileDone: false,

    // ## Report profile deactivate. -----------------------------------------------------------------
    deactivateReportProfilePending: false,
    deactivateReportProfileDone: false,

    // ## Report profile download. -------------------------------------------------------------------
    downloadReportHistoryData: null,
    downloadReportHistoryPending: false,
    downloadReportHistoryDone: false,
    downloadReportHistoryFail: false,
    downloadReportHistoryFailMsg: null,

    // ## Report History Data. --------------------------------------------------------------------
    reportHistory: reportHistoryDummyData,
    reportHistoryTotal: 0,
    reportHistoryPending: false,
    reportHistoryDone: false,

    // ## Report History Delete. ---------------------------------------------------------------------
    deleteReportHistoryPending: false,
    deleteReportHistoryDone: false,

    // ## Sourse sync status. ------------------------------------------------------------------------
    sourceSyncStatus: null,
    sourceSyncStatusPending: false,
    sourceSyncStatusDone: false,

    // ## Start alm sourse sync. -------------------------------------------------------------------------
    startAlmSourceSyncData: null,
    startAlmSourceSyncDone: false,
    startAlmSourceSyncPending: false,

    // ## Alm sourse sync status. ------------------------------------------------------------------------
    almSourceSyncStatus: [],
    almSourceSyncStatusPending: false,
    almSourceSyncStatusDone: false,

    // ## Docker tags. -------------------------------------------------------------------------------
    dockerTags: [],
    availableTags: null,
    dockerTagsPending: false,
    dockerTagsDone: false,

    // ## Pull docker tags. --------------------------------------------------------------------------
    pullDockerTagsPending: false,
    pullDockerTagsDone: false,

    // ## Start sourse sync. -------------------------------------------------------------------------
    startSourceSyncData: null,
    startSourceSyncDone: false,
    startSourceSyncPending: false,

    // ## Get source projects. -----------------------------------------------------------------------
    sourceProjectsTable: sourceProjectsDummy,
    sourceProjectsTablePerPage: 10,
    sourceProjectsTableTotal: null,
    sourceProjectsTableIsIssue: false,
    sourceProjectsPending: false,
    sourceProjectsDone: false,

    // ## Create source project. ---------------------------------------------------------------------
    createSourceProjectData: null,
    createSourceProjectPending: false,
    createSourceProjectDone: false,

    // ## logsData. ----------------------------------------------------------------------------------
    logsData: Dummy,
    logsDataPending: false,
    logsDataDone: false,
    logsDataFail: false,
    logsDataFailMsg: null,

    // ## Audit log. ----------------------------------------------------------------------------------
    auditLogTable: auditLogDummy,
    auditLogTablePending: false,
    auditLogTableTotal: 0,
    auditLogTableDone: false,
    auditLogTableFail: false,
    auditLogTableFailMsg: null,

    // ## All scanner. -------------------------------------------------------------------------------
    allScanner: Dummy,
    allScannerPending: false,
    allScannerDone: false,

    // ## Issues user group types. -------------------------------------------------------------------
    issuesUserGroupTypes: [],
    issuesUserGroupTypesPending: false,
    issuesUserGroupTypesDone: false,

    // ## Issues user groups. ------------------------------------------------------------------------
    issuesUserGroups: [],
    issuesUserGroupsPending: false,
    issuesUserGroupsDone: false,

    // ## Issue categories. --------------------------------------------------------------------------
    issueCategories: [],
    issueCategoriesPending: false,
    issueCategoriesDone: false,

    // ## Issue sub categories. ----------------------------------------------------------------------
    issueSubCategories: [],
    issueSubCategoriesPending: false,
    issueSubCategoriesDone: false,

    // ## Issue services. --------------------------------------------------------------------------
    issueServices: [],
    issueServicesPending: false,
    issueServicesDone: false,

    // ## Issue service instances. --------------------------------------------------------------------------
    issueServiceInstances: [],
    issueServiceInstancesPending: false,
    issueServiceInstancesDone: false,

    // ## Issue teamplates. --------------------------------------------------------------------------
    issueTemplates: [],
    issueTemplatesPending: false,
    issueTemplatesDone: false,

    // ## Issue teams. --------------------------------------------------------------------------
    issueTeams: [],
    issueTeamsPending: false,
    issueTeamsDone: false,

    // ## Issue members. --------------------------------------------------------------------------
    issueMembers: [],
    issueMembersPending: false,
    issueMembersDone: false,

    // ## Issue labels. ------------------------------------------------------------------------------
    issueLabels: [],
    issueLabelsPending: false,
    issueLabelsDone: false,

    // ## Labels Data. -------------------------------------------------------------------------------
    labelsTable: labelDummy,
    labelsTablePending: false,
    labelsTablePerpage: 20,
    labelsTableTotal: 0,
    labelsTableDone: false,

    // ## Labels list. -------------------------------------------------------------------------------
    labelsList: [],
    labelsListPending: false,
    labelsListDone: false,

    // ## Add Label. ---------------------------------------------------------------------------------
    addLabelData: null,
    addLabelPending: false,
    addLabelDone: false,

    // ## Delete Label. ------------------------------------------------------------------------------
    deleteLabelPending: false,
    deleteLabelDone: false,

    // ## Update Label. ------------------------------------------------------------------------------
    updateLabelPending: false,
    updateLabelDone: false,

    // ## Validate label name. -----------------------------------------------------------------------
    validateLabelNameData: true,
    validateLabelNamePending: false,
    validateLabelNameDone: false,

    // ## Business unist table. ----------------------------------------------------------------------
    businessUnitsTable: labelDummy,
    businessUnitsTablePending: false,
    businessUnitsTablePerpage: 20,
    businessUnitsTableTotal: 0,
    businessUnitsTableDone: false,

    // ## Business units list. -----------------------------------------------------------------------
    businessUnitsList: [],
    businessUnitsListPending: false,
    businessUnitsListDone: false,

    // ## Add business units. ------------------------------------------------------------------------
    addBusinessUnitsData: null,
    addBusinessUnitsPending: false,
    addBusinessUnitsDone: false,

    // ## Update business units. ---------------------------------------------------------------------
    updateBusinessUnitsPending: false,
    updateBusinessUnitsDone: false,

    // ## Delete business units. ---------------------------------------------------------------------
    deleteBusinessUnitsPending: false,
    deleteBusinessUnitsDone: false,

    // ## Validate business units name. --------------------------------------------------------------
    validateBusinessUnitsNameData: true,
    validateBusinessUnitsNamePending: false,
    validateBusinessUnitsNameDone: false,

    // ## Authman people. ----------------------------------------------------------------------------
    authmanPeopleTable: Dummy,
    authmanPeopleTablePerPage: 30,
    authmanPeopleTableTotal: 0,
    authmanPeopleTablePending: false,
    authmanPeopleTableDone: false,
    authmanPeopleTableFail: false,
    authmanPeopleTableFailMsg: null,

    // ## Create authman people. ---------------------------------------------------------------------
    createAuthmanPeopleData: {
      created: [],
      failed: [],
    },
    createAuthmanPeoplePending: false,
    createAuthmanPeopleDone: false,
    createAuthmanPeopleFail: false,
    createAuthmanPeopleFailMsg: null,

    // ## SLA Rules. ---------------------------------------------------------------------------------
    slaRulesTable: slaCriteriaDummy,
    slaRulesDefault: false,
    slaRulesTableTotal: 0,
    slaRulesTablePending: false,
    slaRulesTableDone: false,

    // ## Add SLA Rules. -----------------------------------------------------------------------------
    addSLARulesPending: false,
    addSLARulesDone: false,

    // ## SLA Rules details. -------------------------------------------------------------------------
    slaRulesDetails: {},
    slaRulesDetailsPending: false,
    slaRulesDetailsDone: false,

    // ## Update SLA Rules. --------------------------------------------------------------------------
    updateSlaRulesPending: false,
    updateSlaRulesDone: false,

    // ## Delete SLA Rule. --------------------------------------------------------------------------
    deleteSlaRulePending: false,
    deleteSlaRuleDone: false,

    // ## All branches. ------------------------------------------------------------------------------
    allBranches: [],
    allBranchesPending: false,
    allBranchesDone: false,

    // ## Validation criteria name. ------------------------------------------------------------------
    validationPolicyNameData: true,
    validationPolicyNamePending: false,
    validationPolicyNameDone: false,

    // ## Validation agent label. --------------------------------------------------------------------
    validationAgentLabelData: true,
    validationAgentLabelPending: false,
    validationAgentLabelDone: false,

    // ## Training tool invite user. -----------------------------------------------------------------
    trainingToolInviteUserPending: false,
    trainingToolInviteUserDone: false,
    trainingToolInviteUserFail: false,

    // ## Training user info. ------------------------------------------------------------------------
    trainingUserInfo: {
      user: {},
    },
    trainingUserInfoPending: false,
    trainingUserInfoDone: false,
    trainingUserInfoFail: false,
    trainingUserInfoFailMsg: null,

    // ## Training courses. --------------------------------------------------------------------------
    trainingCourses: null,
    trainingCoursesPending: false,
    trainingCoursesDone: false,

    // ## Training lessons. --------------------------------------------------------------------------
    trainingLessons: null,
    trainingLessonsPending: false,
    trainingLessonsDone: false,

    // ## Assign training to user. -------------------------------------------------------------------
    assignTrainingToUserPending: false,
    assignTrainingToUserDone: false,
    assignTrainingToUserFail: false,
    assignTrainingToUserFailMsg: null,

    // ## Addable scanners. --------------------------------------------------------------------------
    addableScanners: [],
    addableScannersPending: false,
    addableScannersDone: false,

    // ## Agent language. ----------------------------------------------------------------------------
    agentLanguage: [],
    agentLanguagePending: false,
    agentLanguageDone: false,

    // ## Organizations. ----------------------------------------------------------------------------
    organizationsData: [],
    organizationsPending: false,
    organizationsDone: false,
    organizationsFail: false,
    organizationsFailMsg: null,

    // ## All sbom table. -------------------------------------------------------------------------
    allSbomTable: sbomDummy,
    allSbomTablePerpage: SBOM.PERPAGE,
    allSbomTableTotal: 0,
    allSbomTablePending: false,
    allSbomTableDone: false,
    allSbomTableFail: false,
    allSbomTableFailMsg: null,

    // ## All sbom table. -------------------------------------------------------------------------
    allSbomComponentTable: sbomDetailDummy,
    allSbomComponentTablePerpage: SBOM.PERPAGE,
    allSbomComponentTableTotal: 0,
    sbomComponentLastUpdatedDate: null,
    sbomComponentNextScheduleDate: null,
    allSbomComponentTablePending: false,
    allSbomComponentTableDone: false,
    allSbomComponentTableFail: false,
    allSbomComponentTableFailMsg: null,

    // ## Severity score. ----------------------------------------------------------------------------
    severityScore: [],
    severityScorePending: false,
    severityScoreDone: false,

    // ## Update severity score. ---------------------------------------------------------------------
    updateSeverityScorePending: false,
    updateSeverityScoreDone: false,
    updateSeverityScoreFail: false,

    // ## Restore severity score. --------------------------------------------------------------------
    restoreSeverityScorePending: false,
    restoreSeverityScoreDone: false,

    // ## Sandboxes. ---------------------------------------------------------------------------------
    sandboxes: [],
    sandboxesPending: false,
    sandboxesDone: false,

    // ## Global settings. ---------------------------------------------------------------------------
    globalSettings: {},
    globalSettingsPending: false,
    globalSettingsDone: false,

    // ## Update global settings. --------------------------------------------------------------------
    updateGlobalSettingsPending: false,
    updateGlobalSettingsDone: false,
    updateGlobalSettingsFail: false,

    // ## Update instance settings. ------------------------------------------------------------------
    updateInstanceSettingsPending: false,
    updateInstanceSettingsDone: false,
    updateInstanceSettingsFail: false,

    // ## Global configurations. ---------------------------------------------------------------------
    globalConfigurations: {
      organization: {
        name: '',
      },
      scan_section: {
        concurrent_imports_count: {
          current: 0,
          maximum: 1,
        },
        concurrent_scans_count: {
          current: 0,
          maximum: 1,
        },
      },
      ssh_section: {
        private_key_file_path: null,
        public_key_file_path: null,
      },
      tls_section: {
        cert_file_path: null,
        key_file_path: null,
      },
      audit_section: {
        time_to_live: 1,
      },
    },
    globalConfigurationsPending: false,
    globalConfigurationsDone: false,

    // ## Save global configurations. ----------------------------------------------------------------
    saveGlobalConfigurationsPending: false,
    saveGlobalConfigurationsDone: false,
    saveGlobalConfigurationsFail: false,

    // ## Reset global configurations. ---------------------------------------------------------------
    resetGlobalConfigurationsPending: false,
    resetGlobalConfigurationsDone: false,

    // ## Upload global configurations files. --------------------------------------------------------
    uploadGlobalConfigurationsFilesPending: false,
    uploadGlobalConfigurationsFilesDone: false,

    // ## Delete global configurations files. --------------------------------------------------------
    deleteGlobalConfigurationsFilesPending: false,
    deleteGlobalConfigurationsFilesDone: false,

    // ## Risk Profile. ------------------------------------------------------------------------------
    riskProfile: [],
    riskProfileId: null,
    riskProfilePending: false,
    riskProfileDone: false,

    // ## Update Risk Profile. -----------------------------------------------------------------------
    updateRiskProfilePending: false,
    updateRiskProfileDone: false,
    updateRiskProfileFail: false,

    // Infra profiles. -------------------------------------------------------------------------------------------------
    infraProfiles: infraProfileDummy,
    infraProfilesPending: false,
    infraProfilesDone: false,
    infraProfilesTotal: 0,
    infraProfilesPerPage: 15,
    infraProfilesFail: false,

    // Delete Infra profiles. ------------------------------------------------------------------------------------------
    deleteInfraProfilesDone: false,
    deleteInfraProfilesPending: false,

    // ## Tool profiles. ----------------------------------------------------------------------------------------------
    toolProfiles: toolProfilesDummy,
    toolProfilesPerPage: 30,
    toolProfilesTotal: 0,
    toolProfilesPending: false,
    toolProfilesDone: false,
    toolProfilesFail: false,
    toolProfilesFailMsg: null,

    toolProjectsCount: 0,
    toolProjectsCountDone: false,
    toolProjectsCountPending: false,

    // ## Create tool projects. -------------------------------------------------------------------------
    createToolProjectsData: {},
    createToolProjectsDone: false,
    createToolProjectsPending: false,

    // ## Create infra tool projects. -------------------------------------------------------------------------
    createInfraToolProfilesData: {},
    createInfraToolProfilesDone: false,
    createInfraToolProfilesPending: false,

    // Infra groups. ---------------------------------------------------------------------------------------------------
    infraGroups: infraGroupsDummy,
    infraGroupsPending: false,
    infraGroupsDone: false,
    infraGroupsTotal: 0,
    infraGroupsPerPage: 15,
    infraGroupsFail: false,
    infraGroupsFailMsg: null,

    // ## Validate infra group name. -----------------------------------------------------------------------
    validateInfraGroupNameData: true,
    validateInfraGroupNamePending: false,
    validateInfraGroupNameDone: false,

    // Create groups. --------------------------------------------------------------------------------------------------
    createInfraGroupsPending: false,
    createInfraGroupsDone: false,

    // Delete groups. --------------------------------------------------------------------------------------------------
    deleteInfraGroupsPending: false,
    deleteInfraGroupsDone: false,

    // Update groups. --------------------------------------------------------------------------------------------------
    updateInfraGroupsPending: false,
    updateInfraGroupsDone: false,

    // ## Update Scanner Status. -----------------------------------------------------------------------
    updateScannerStatusPending: false,
    updateScannerStatusDone: false,

    // ## Get scanner Status. -----------------------------------------------------------------------
    scannerStatus: {},
    scannerStatusPending: false,
    scannerStatusDone: false,

    // ## Get scanner scope count. -----------------------------------------------------------------------
    scannerScopeCount: 0,
    scannerScopeCountPending: false,
    scannerScopeCountDone: false,

    // ## Automation Rules. --------------------------------------------------------------------------------------------
    automationRules: automationRulesDummy,
    automationRulesDefault: {
      issue: false,
      notification: false,
      release: false,
      suppression: false,
    },
    automationRulesPerPage: 30,
    automationRulesTotal: 1,
    automationRulesPending: false,
    automationRulesDone: false,

    projectAutomationRules: [],
    projectAutomationRulesDefault: {
      issue: null,
      notification: null,
      release: null,
    },
    projectAutomationRulesPending: false,
    projectAutomationRulesDone: false,

    automationRulesDetails: {},
    automationRulesDetailsPending: false,
    automationRulesDetailsDone: false,

    compiledAutomationRules: [],

    createAutomationRulesDone: false,
    createAutomationRulesPending: false,

    deleteAutomationRulesTableDone: false,
    deleteAutomationRulesTablePending: false,

    updateAutomationRulesPending: false,
    updateAutomationRulesDone: false,

    // ## Highlighted Vulns. -------------------------------------------------------------------------------------------
    highlightedVulns: automationRulesDummy,
    highlightedVulnsPerPage: 30,
    highlightedVulnsTotal: 1,
    highlightedVulnsPending: false,
    highlightedVulnsDone: false,

    createHighlightedVulnsDone: false,
    createHighlightedVulnsPending: false,

    updateHighlightedVulnsDone: false,
    updateHighlightedVulnsPending: false,

    deleteHighlightedVulnsDone: false,
    deleteHighlightedVulnsPending: false,

    highlightedVulnsDetails: {},
    highlightedVulnsDetailsPending: false,
    highlightedVulnsDetailsDone: false,

    sbomGeneratorTools: [],
    sbomGeneratorToolsPending: false,
    sbomGeneratorToolsDone: false,

    featureEnvironmentSettings: [],
    featureEnvironmentSettingsPending: false,
    featureEnvironmentSettingsDone: false,

    customToolFieldsTable: [],
    customToolFieldsPending: false,
    customToolFieldsDone: false,
    customToolFieldsFail: false,
    customToolFieldsFailMsg: null,

    uploadCustomToolFieldsData: [],
    uploadCustomToolFieldsList: {},
    uploadCustomToolFieldsPending: false,
    uploadCustomToolFieldsDone: false,
    uploadCustomToolFieldsFail: false,
    uploadCustomToolFieldsFailMsg: null,

    uploadedCustomToolFieldsPending: false,
    uploadedCustomToolFieldsDone: false,
    uploadedCustomToolFieldsFail: false,
    uploadedCustomToolFieldsFailMsg: null,

    customIntegrationLogos: [],
    customIntegrationLogosPending: false,
    customIntegrationLogosDone: false,
    customIntegrationLogosFail: false,
    customIntegrationLogosFailMsg: null,

    // ## Validation custom tool name. ------------------------------------------------------------------
    validationCustomToolNameData: true,
    validationCustomToolNamePending: false,
    validationCustomToolNameDone: false,

    pageSettings: null,
  }),
  getters: {
    getSettingsByType: (state) => (type) => (
      state.allSettings.filter((setting) => setting.type === type)
    ),
  },
  actions: {
    // ## Create scanners projects. ------------------------------------------------------------------
    async createScannersProjects(params) {
      this.createScannersProjectsPending = true;

      try {
        const res = await SettingsService.createScannersProjects(params);
        this.createScannersProjectsData = res.data;
        this.createScannersProjectsDone = !this.createScannersProjectsDone;
        this.createScannersProjectsPending = false;
      } catch (error) {
        this.createScannersProjectsPending = false;
        this.createScannersProjectsFail = !this.createScannersProjectsFail;
        this.createScannersProjectsFailMsg = ErrorHandling(error);
      }
    },

    // ## Get scanners projects. ---------------------------------------------------------------------
    async getScannersProjects(data) {
      this.scannersProjectsPending = true;

      try {
        const res = await SettingsService.getScannersProjects(data);
        this.scannersProjects = res.data.data;
        this.scannersProjectsDone = !this.scannersProjectsDone;
        this.scannersProjectsPending = false;
      } catch (error) {
        this.scannersProjectsPending = false;
        this.scannersProjectsFail = !this.scannersProjectsFail;
        this.scannersProjectsFailMsg = ErrorHandling(error);
      }
    },

    resetScannersProjects() {
      this.scannersProjects = [];
    },

    // ## Get scanners engines. ---------------------------------------------------------------------
    async getScannersEngines(data) {
      this.scannersEnginesPending = true;

      try {
        const res = await SettingsService.getScannersEngines(data);
        this.scannersEngines = res.data.data;
        this.scannersEnginesDone = !this.scannersEnginesDone;
        this.scannersEnginesPending = false;
      } catch (error) {
        this.scannersEnginesPending = false;
        this.scannersEnginesFail = !this.scannersEnginesFail;
        this.scannersEnginesFailMsg = ErrorHandling(error);
      }
    },

    resetScannersEngines() {
      this.scannersEngines = [];
    },

    // ## Get scanners projects detail. --------------------------------------------------------------
    async getScannersProjectsDetail(data) {
      this.scannersProjectsDetailPending = true;

      try {
        const res = await SettingsService.getScannersProjectsDetail(data);
        this.scannersProjectsDetail = res.data.data;
        this.scannersProjectsDetailDone = !this.scannersProjectsDetailDone;
        this.scannersProjectsDetailPending = false;
      } catch (error) {
        this.scannersProjectsDetailPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get scanners configurations. ---------------------------------------------------------------
    async getScannersConfigurations(data) {
      this.scannersConfigurationsPending = true;

      try {
        const res = await SettingsService.getScannersConfigurations(data);
        this.scannersConfigurations = res.data.data;
        this.scannersConfigurationsDone = !this.scannersConfigurationsDone;
        this.scannersConfigurationsPending = false;
      } catch (error) {
        this.scannersConfigurationsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Upload scanners presets. -------------------------------------------------------------------
    async uploadScannersPresets(data) {
      this.uploadScannersPresetsPending = true;

      try {
        const res = await SettingsService.uploadScannersPresets(data);
        this.uploadScannersPresetsData = res.data.data;
        this.uploadScannersPresetsDone = !this.uploadScannersPresetsDone;
        this.uploadScannersPresetsPending = false;
      } catch (error) {
        this.uploadScannersPresetsPending = false;
        this.uploadScannersPresetsFail = !this.uploadScannersPresetsFail;
        this.uploadScannersPresetsFailMsg = ErrorHandling(error);
      }
    },

    // ## Get scanners macros. -----------------------------------------------------------------------
    async getScannersMacros(data) {
      this.scannersMacrosPending = true;

      try {
        const res = await SettingsService.getScannersMacros(data);
        this.setGetScannersMacros(res.data.data);
        this.scannersMacrosDone = !this.scannersMacrosDone;
        this.scannersMacrosPending = false;
      } catch (error) {
        this.scannersMacrosPending = false;
        ErrorHandling(error);
      }
    },

    setGetScannersMacros(payload) {
      const data = [];
      payload.forEach((s) => {
        if (data.filter((a) => a.type === s.type).length < 1) {
          data.push({
            type: s.type,
            data: payload.filter((a) => a.type === s.type),
          });
        }
      });
      this.scannersMacrosData = data;
    },

    // ## Upload scanners macro. ---------------------------------------------------------------------
    async uploadScannersMacro(data) {
      this.uploadScannersMacroPending = true;

      try {
        const res = await SettingsService.uploadScannersMacro(data);
        this.uploadScannersMacroData = res.data.data;
        this.uploadScannersMacroDone = !this.uploadScannersMacroDone;
        this.uploadScannersMacroPending = false;
      } catch (error) {
        this.uploadScannersMacroPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get scanners profiles. ---------------------------------------------------------------------
    async getScannersProfiles(data) {
      this.scannersProfilesPending = true;

      try {
        const res = await SettingsService.getScannersProfiles(data);
        this.scannersProfiles = res.data.data;
        this.scannersProfilesDone = !this.scannersProfilesDone;
        this.scannersProfilesPending = false;
      } catch (error) {
        this.scannersProfilesPending = false;
        this.scannersProfilesFail = !this.scannersProfilesFail;
        this.scannersProfilesFailMsg = ErrorHandling(error);
      }
    },

    addSemgrepRules(data) {
      this.semgrepRules.push(data);
    },

    editSemgrepRules(data) {
      this.semgrepRules.forEach((s) => {
        if (data.id === s.id) {
          s.type = data.type;
          s.value = data.value;
        }
      });
    },

    deleteSemgrepRules(data) {
      this.semgrepRules = this.semgrepRules.filter((s) => s.id !== data.id);
    },

    resetSemgrepRules() {
      this.semgrepRules = [];
    },

    // ## Get repository projects. -------------------------------------------------------------------
    async getReposProjects(data) {
      this.reposProjectsPending = true;

      try {
        const res = await SettingsService.getReposProjects(data);
        this.reposProjects = res.data.data;
        this.reposProjectsDone = !this.reposProjectsDone;
        this.reposProjectsPending = false;
      } catch (error) {
        this.reposProjectsPending = false;
        this.reposProjectsFail = !this.reposProjectsFail;
        ErrorHandling(error);
      }
    },

    // ## Get repository collections. ----------------------------------------------------------------
    async getReposCollections(data) {
      this.reposCollectionsPending = true;

      try {
        const res = await SettingsService.getReposCollections(data);
        this.reposCollections = res.data;
        this.reposCollectionsDone = !this.reposCollectionsDone;
        this.reposCollectionsPending = false;
      } catch (error) {
        this.reposCollectionsPending = false;
        this.reposCollectionsFail = !this.reposCollectionsFail;
        ErrorHandling(error);
      }
    },

    // ## Get repository apps. ----------------------------------------------------------------------
    async getReposApps(data) {
      this.reposAppsPending = true;

      try {
        const res = await SettingsService.getReposApps(data);
        this.reposApps = res.data;
        this.reposAppsDone = !this.reposAppsDone;
        this.reposAppsPending = false;
      } catch (error) {
        this.reposAppsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get repository branches. -------------------------------------------------------------------
    async getReposBranches(data) {
      this.reposBranchesPending = true;

      try {
        const res = await SettingsService.getReposBranches(data);
        this.reposBranches = res.data;
        this.reposBranchesDone = !this.reposBranchesDone;
        this.reposBranchesPending = false;
      } catch (error) {
        this.reposBranchesPending = false;
        ErrorHandling(error);
      }
    },

    resetReposBranches() {
      this.reposBranches = [];
    },

    // ## Get repository branches. -------------------------------------------------------------------
    async getReposTags(data) {
      this.reposTagsPending = true;

      try {
        const res = await SettingsService.getReposTags(data);
        this.reposTags = res.data;
        this.reposTagsDone = !this.reposTagsDone;
        this.reposTagsPending = false;
      } catch (error) {
        this.reposTagsPending = false;
        ErrorHandling(error);
      }
    },

    resetReposTags() {
      this.reposTags = null;
    },

    // ## Get issues projects. -----------------------------------------------------------------------
    async getIssuesProjects(data) {
      this.issuesProjectsPending = true;

      try {
        const res = await SettingsService.getIssuesProjects(data);
        this.issuesProjects = res.data;
        this.issuesProjectsDone = !this.issuesProjectsDone;
        this.issuesProjectsPending = false;
      } catch (error) {
        this.issuesProjectsPending = false;
        this.issuesProjectsFail = !this.issuesProjectsFail;
        ErrorHandling(error);
      }
    },

    // ## Get issues components. ---------------------------------------------------------------------
    async getIssuesComponents(data) {
      this.issuesComponentsPending = true;

      try {
        const res = await SettingsService.getIssuesComponents(data);
        this.issuesComponents = res.data;
        this.issuesComponentsDone = !this.issuesComponentsDone;
        this.issuesComponentsPending = false;
      } catch (error) {
        this.issuesComponentsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get issues types. --------------------------------------------------------------------------
    async getIssuesTypes(data) {
      this.issuesTypesPending = true;

      try {
        const res = await SettingsService.getIssuesTypes(data);
        this.setGetIssuesTypes(res.data.data);
        this.issuesTypesDone = !this.issuesTypesDone;
        this.issuesTypesPending = false;
      } catch (error) {
        this.issuesTypesPending = false;
        this.issuesTypesFail = !this.issuesTypesFail;
        ErrorHandling(error);
      }
    },

    setGetIssuesTypes(payload) {
      this.issuesTypes = payload.issue_types ? payload.issue_types : payload;
      this.issueResolutionsStates = payload.resolutions;
      this.issueCustomVariables = payload.custom_variables;
    },

    // ## Get issues statuses. -----------------------------------------------------------------------
    async getIssuesStatuses(data) {
      this.issuesStatusesPending = true;

      try {
        const res = await SettingsService.getIssuesStatuses(data);
        this.issuesStatuses = res.data;
        this.issuesStatusesDone = !this.issuesStatusesDone;
        this.issuesStatusesPending = false;
      } catch (error) {
        this.issuesStatusesPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get issues work items. -----------------------------------------------------------------------
    async getIssuesWorkItems(data) {
      this.issuesWorkItemsPending = true;

      try {
        const res = await SettingsService.getIssuesWorkItems(data);
        this.issuesWorkItems = res.data.data;
        this.issuesWorkItemsDone = !this.issuesWorkItemsDone;
        this.issuesWorkItemsPending = false;
      } catch (error) {
        this.issuesWorkItemsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Update issues statuses. --------------------------------------------------------------------
    async updateIssuesStatuses(data) {
      this.updateIssuesStatusesPending = true;

      try {
        await SettingsService.updateIssuesStatuses(data);
        this.updateIssuesStatusesDone = !this.updateIssuesStatusesDone;
        this.updateIssuesStatusesPending = false;
      } catch (error) {
        this.updateIssuesStatusesPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get issues collections. --------------------------------------------------------------------
    async getIssuesCollections(data) {
      this.issuesCollectionsPending = true;

      try {
        const res = await SettingsService.getIssuesCollections(data);
        this.issuesCollections = res.data;
        this.issuesCollectionsDone = !this.issuesCollectionsDone;
        this.issuesCollectionsPending = false;
      } catch (error) {
        this.issuesCollectionsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get issues override field. --------------------------------------------------------------------------
    async getIssuesOverrideFields(data) {
      this.issuesOverrideFieldsPending = true;

      try {
        const res = await SettingsService.getIssuesOverrideFields(data);
        this.issuesOverrideFields = res.data.data;
        this.issuesOverrideFieldsDone = !this.issuesOverrideFieldsDone;
        this.issuesOverrideFieldsPending = false;
      } catch (error) {
        this.issuesOverrideFieldsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get issue fields. --------------------------------------------------------------------------
    async getIssueFields(data) {
      this.issueFieldsPending = true;

      try {
        const res = await SettingsService.getIssueFields(data);
        this.setGetIssueFields(res.data);
        this.issueFieldsDone = !this.issueFieldsDone;
        this.issueFieldsPending = false;
      } catch (error) {
        this.issueFieldsPending = false;
        ErrorHandling(error);
      }
    },

    setGetIssueFields(payload) {
      this.issueFields = [];
      if (!payload) {
        return;
      }
      Object.keys(payload).sort((a, b) => {
        if (a < b) return 1;
        if (a > b) return -1;
        return 0;
      }).forEach((s) => {
        this.issueFields.push(payload[s]);
      });
    },

    resetIssueFields() {
      this.issueFields = [];
    },

    // ## Get ivanti owner teams. --------------------------------------------------------------------------
    async getIvantiOwnerTeams(data) {
      this.ivantiOwnerTeamsPending = true;

      try {
        const res = await SettingsService.getIvantiOwnerTeams(data);
        this.ivantiOwnerTeams = res.data.items;
        this.ivantiOwnerTeamsDone = !this.ivantiOwnerTeamsDone;
        this.ivantiOwnerTeamsPending = false;
      } catch (error) {
        this.ivantiOwnerTeamsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get ivanti owners. --------------------------------------------------------------------------
    async getIvantiOwners(data) {
      this.ivantiOwnersPending = true;

      try {
        const res = await SettingsService.getIvantiOwners(data);
        this.ivantiOwners = res.data.items;
        this.ivantiOwnersDone = !this.ivantiOwnersDone;
        this.ivantiOwnersPending = false;
      } catch (error) {
        this.ivantiOwnersPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get team lead settings. --------------------------------------------------------------------------
    async getTeamLeadIntegrationSettings(data) {
      this.teamLeadIntegrationSettings = [];
      this.teamLeadIntegrationSettingsPending = true;
      if (data === 'scanners') {
        this.teamLeadIntegrationSettings = teamLeadScannerIntegrationSettingsDummy;
      } else {
        this.teamLeadIntegrationSettings = teamLeadIntegrationSettingsDummy;
      }

      try {
        const res = await SettingsService.getTeamLeadIntegrationSettings(data);
        this.setGetTeamLeadIntegrationSettings(res.data.data, data);
        this.teamLeadIntegrationSettingsDone = !this.teamLeadIntegrationSettingsDone;
        this.teamLeadIntegrationSettingsPending = false;
      } catch (error) {
        this.teamLeadIntegrationSettingsPending = false;
        ErrorHandling(error);
      }
    },

    setGetTeamLeadIntegrationSettings(payload, data) {
      if (payload.length < 1) {
        this.teamLeadIntegrationSettings = [];
        return;
      }
      if (data === 'scanners') {
        this.teamLeadIntegrationSettings = [
          {
            name: 'cspm',
            data: payload.filter((s) => s.type === 'cspm'),
          },
        ];
      } else if (data === 'repo' || data === 'issue') {
        this.teamLeadIntegrationSettings = payload;
      }
    },

    // ## Get team lead details. --------------------------------------------------------------------------
    async getTeamLeadIntegrationDetails(data) {
      this.teamLeadIntegrationDetailsPending = true;

      try {
        const res = await SettingsService.getTeamLeadIntegrationDetails(data);
        this.teamLeadIntegrationDetails = res.data.data;
        this.teamLeadIntegrationDetailsDone = !this.teamLeadIntegrationDetailsDone;
        this.teamLeadIntegrationDetailsPending = false;
      } catch (error) {
        this.teamLeadIntegrationDetailsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Team lead integration test connection. -----------------------------------------------------------------------
    async teamLeadIntegrationTestConnection(data) {
      this.teamLeadIntegrationTestConnectionPending = true;

      try {
        const res = await SettingsService.teamLeadIntegrationTestConnection(data);
        this.teamLeadIntegrationTestConnectionData = res.data;
        this.teamLeadIntegrationTestConnectionDone = !this.teamLeadIntegrationTestConnectionDone;
        this.teamLeadIntegrationTestConnectionDoneMsg = res.data.message;
        this.teamLeadIntegrationTestConnectionPending = false;
      } catch (error) {
        this.teamLeadIntegrationTestConnectionPending = false;
        this.teamLeadIntegrationTestConnectionFail = !this.teamLeadIntegrationTestConnectionFail;
        this.teamLeadIntegrationTestConnectionFailMsg = ErrorHandling(error);
      }
    },

    async retestTeamLeadIntegrationConnection(data) {
      this.retestTeamLeadIntegrationConnectionPending = true;

      try {
        await SettingsService.retestTeamLeadIntegrationConnection(data);
        this.retestTeamLeadIntegrationConnectionDone = !this.retestTeamLeadIntegrationConnectionDone;
        this.retestTeamLeadIntegrationConnectionPending = false;
      } catch (error) {
        this.retestTeamLeadIntegrationConnectionPending = false;
        this.retestTeamLeadIntegrationConnectionFail = !this.retestTeamLeadIntegrationConnectionFail;
        ErrorHandling(error);
      }
    },

    // ## Add team lead instance settings. -------------------------------------------------------------
    async addTeamLeadIntegrationInstance(data) {
      this.addTeamLeadIntegrationInstancePending = true;

      try {
        await SettingsService.addTeamLeadIntegrationInstance(data);
        this.addTeamLeadIntegrationInstanceDone = !this.addTeamLeadIntegrationInstanceDone;
        this.addTeamLeadIntegrationInstancePending = false;
      } catch (error) {
        this.addTeamLeadIntegrationInstancePending = false;
        ErrorHandling(error);
      }
    },

    // ## Update team lead instance settings. -------------------------------------------------------------
    async updateTeamLeadIntegrationInstance(data) {
      this.updateTeamLeadIntegrationInstancePending = true;

      try {
        await SettingsService.updateTeamLeadIntegrationInstance(data);
        this.updateTeamLeadIntegrationInstanceDone = !this.updateTeamLeadIntegrationInstanceDone;
        this.updateTeamLeadIntegrationInstancePending = false;
      } catch (error) {
        this.updateTeamLeadIntegrationInstancePending = false;
        ErrorHandling(error);
      }
    },

    // ## Delete team lead instance settings. -------------------------------------------------------------
    async deleteTeamLeadIntegrationInstance(data) {
      this.deleteTeamLeadIntegrationInstancePending = true;

      try {
        await SettingsService.deleteTeamLeadIntegrationInstance(data);
        this.deleteTeamLeadIntegrationInstanceDone = !this.deleteTeamLeadIntegrationInstanceDone;
        this.deleteTeamLeadIntegrationInstancePending = false;
      } catch (error) {
        this.deleteTeamLeadIntegrationInstancePending = false;
        ErrorHandling(error);
      }
    },

    // ## Update tool access settings. -----------------------------------------------------------------
    async updateToolAccessSettings(data) {
      this.updateToolAccessSettingsPending = true;

      try {
        await SettingsService.updateToolAccessSettings(data);
        this.updateToolAccessSettingsDone = !this.updateToolAccessSettingsDone;
        this.updateToolAccessSettingsPending = false;
      } catch (error) {
        this.updateToolAccessSettingsPending = false;
        this.updateToolAccessSettingsFail = !this.updateToolAccessSettingsFail;
        ErrorHandling(error);
      }
    },

    // ## Get all settings. --------------------------------------------------------------------------
    async getAllSettings(data) {
      this.allSettingsPending = true;

      try {
        const res = await SettingsService.get(data);
        this.allSettings = res.data.data;
        this.allSettingsDone = !this.allSettingsDone;
        this.allSettingsPending = false;
      } catch (error) {
        this.allSettingsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get all scanner settings. ------------------------------------------------------------------
    getAllScannerSettings() {
      this.allScannerSettings = [];
      const data = [];
      PROJECT.SCANNER.forEach((s) => {
        const id = (this.allSettings.find((a) => a.name === s.name) ?
          this.allSettings.find((a) => a.name === s.name).id : null);
        if (this.allSettings.find((a) => a.name === s.name)) {
          s.active = true;
          s.id = id;
          s.disabled = (this.allSettings.find((a) => a.name === s.name) ?
            !this.allSettings.find((a) => a.name === s.name).disabled : null);
          s.labels = this.allSettings.find((a) => a.name === s.name).labels ?
            this.allSettings
              .find((a) => a.name === s.name)
              .labels.filter((a) => a === 'kdt' || a === 'agent' || a === 'import' || a === 'ui-import')
              .sort((a, b) => {
                const NumberA = a.charAt(0);
                const NumberB = b.charAt(0);
                if (NumberB < NumberA) return 1;
                if (NumberB > NumberA) return -1;
                return 0;
              })
            : null;
          s.instances = this.allSettings.find((a) => a.name === s.name).instances || null;
        } else {
          s.active = false;
          s.disabled = false;
          delete s.id;
          delete s.labels;
          delete s.instances;
        }
        data.push(s);
      });
      const setName = (name, type) => {
        if (type === 'sca') {
          return name.slice(9);
        }
        if (type === 'cs') {
          return name.slice(8);
        }
        if (type === 'iac') {
          return name.slice(9);
        }
        return name.slice(10);
      };
      const setCustomTool = (type) => this.allSettings
        .filter((s) => s.type === type && s.name.indexOf(`${type}custom`) > -1)
        .map((s) => ({
          name: setName(s.name, type),
          disabled: !s.disabled,
          customType: s.custom.type,
          id: s.id,
          instances: s.instances,
          imageIndex: setName(s.name, type),
          type: s.type,
          prefix: 'custom',
          active: true,
        }));
      this.allScannerSettings = [
        {
          name: 'sast',
          data: [...data.filter((s) => s.type === 'sast'), ...setCustomTool('sast')],
        }, {
          name: 'mast',
          data: data.filter((s) => s.type === 'mast'),
        }, {
          name: 'dast',
          data: data.filter((s) => s.type === 'dast'),
        }, {
          name: 'iast',
          data: data.filter((s) => s.type === 'iast'),
        }, {
          name: 'sca',
          data: [...data.filter((s) => s.type === 'sca'), ...setCustomTool('sca')],
        }, {
          name: 'cspm',
          data: data.filter((s) => s.type === 'cspm'),
        }, /* {
          name: 'cnapp',
          data: data.filter((s) => s.type === 'cnapp'),
        }, */ {
          name: 'cs',
          data: [...data.filter((s) => s.type === 'cs'), ...setCustomTool('cs')],
        }, {
          name: 'iac',
          data: [...data.filter((s) => s.type === 'iac'), ...setCustomTool('iac')],
        }, {
          name: 'infra',
          data: data.filter((s) => s.type === 'infra'),
        }, {
          name: 'bugbounty',
          data: data.filter((s) => s.type === 'bugbounty'),
        },
      ];
    },

    // ## Get all ti settings. ----------------------------------------------------------------------
    getAllTISettings() {
      this.allTISettings = [];
      PROJECT.TI.forEach((s) => {
        const id = (this.allSettings.find((a) => a.name === s.name) ?
          this.allSettings.find((a) => a.name === s.name).id : null);
        if (this.allSettings.find((a) => a.name === s.name)) {
          s.active = true;
          s.id = id;
        } else {
          s.active = false;
          delete s.id;
        }
        this.allTISettings.push(s);
      });
    },

    // ## Get all alm settings. ----------------------------------------------------------------------
    getAllAlmSettings() {
      this.allAlmSettings = [];
      PROJECT.ALM.forEach((s) => {
        const id = (this.allSettings.find((a) => a.name === s.name) ?
          this.allSettings.find((a) => a.name === s.name).id : null);
        if (this.allSettings.find((a) => a.name === s.name)) {
          s.active = true;
          s.id = id;
          s.instances = this.allSettings.find((a) => a.name === s.name).instances || null;
        } else {
          s.active = false;
          delete s.id;
          delete s.instances;
        }
        this.allAlmSettings.push(s);
      });
    },

    // ## Get all issue settings. --------------------------------------------------------------------
    getAllIssueSettings() {
      this.allIssueSettings = [];
      PROJECT.ISSUE_MANAGER.forEach((s) => {
        const id = (this.allSettings.find((a) => a.name === s.name) ?
          this.allSettings.find((a) => a.name === s.name).id : null);
        if (this.allSettings.find((a) => a.name === s.name)) {
          s.active = true;
          s.id = id;
          s.instances = this.allSettings.find((a) => a.name === s.name).instances || null;
        } else {
          s.active = false;
          delete s.id;
          delete s.instances;
        }
        this.allIssueSettings.push(s);
      });
    },

    // ## Get all notification settings. -------------------------------------------------------------
    getAllNotificationSettings() {
      this.allNotificationSettings = [];
      PROJECT.ALERTS.forEach((s) => {
        const id = (this.allSettings.find((a) => a.name === s.name) ?
          this.allSettings.find((a) => a.name === s.name).id : null);
        if (this.allSettings.find((a) => a.name === s.name)) {
          s.active = true;
          s.id = id;
        } else {
          s.active = false;
          delete s.id;
        }
        this.allNotificationSettings.push(s);
      });
    },

    // ## Get all training settings. -----------------------------------------------------------------
    getAllTrainingSettings() {
      this.allTrainingSettings = [];
      PROJECT.TRAINING.forEach((s) => {
        const id = (this.allSettings.find((a) => a.name === s.name) ?
          this.allSettings.find((a) => a.name === s.name).id : null);
        if (this.allSettings.find((a) => a.name === s.name)) {
          s.active = true;
          s.id = id;
        } else {
          s.active = false;
          delete s.id;
        }
        this.allTrainingSettings.push(s);
      });
    },

    // ## Get all recommendation settings. -----------------------------------------------------------
    getAllRecommendationSettings() {
      this.allRecommendationSettings = [];
      PROJECT.RECOMMENDATION.forEach((s) => {
        const id = (this.allSettings.find((a) => a.name === s.name) ?
          this.allSettings.find((a) => a.name === s.name).id : null);
        if (this.allSettings.find((a) => a.name === s.name)) {
          s.active = true;
          s.id = id;
        } else {
          s.active = false;
          delete s.id;
        }
        this.allRecommendationSettings.push(s);
      });
    },

    // ## Get all single sign on tool settings. ------------------------------------------------------
    getAllSingleSignOnToolSettings() {
      this.allSingleSignOnToolsSettings = [];
      PROJECT.AUTHMAN.forEach((s) => {
        const id = (this.allSettings.find((a) => a.name === s.name) ?
          this.allSettings.find((a) => a.name === s.name).id : null);
        if (this.allSettings.find((a) => a.name === s.name)) {
          s.active = true;
          s.id = id;
        } else {
          s.active = false;
          delete s.id;
        }
        this.allSingleSignOnToolsSettings.push(s);
      });
    },

    // ## Get all agent settings. --------------------------------------------------------------------
    getAllAgentSettings() {
      this.allAgentsSettings = [];
      this.allAgentsSettings = this.allSettings.map((s) => ({
        id: s.id,
        name: s.label,
        isActive: s.isActive,
      }));
    },

    // ## Activate settings. -------------------------------------------------------------------------
    async activateSettings(data) {
      this.activateSettingsPending = true;

      try {
        await SettingsService.post(data);
        this.activateSettingsDone = !this.activateSettingsDone;
        this.activateSettingsPending = false;
      } catch (error) {
        this.activateSettingsPending = false;
        this.activateSettingsFail = !this.activateSettingsFail;
        this.activateSettingsFailMsg = ErrorHandling(error);
      }
    },

    // ## Update settings. ---------------------------------------------------------------------------
    async updateSettings(data) {
      this.updateSettingsPending = true;

      try {
        await SettingsService.update(data);
        this.updateSettingsDone = !this.updateSettingsDone;
        this.updateSettingsPending = false;
      } catch (error) {
        this.updateSettingsPending = false;
        this.updateSettingsFail = !this.updateSettingsFail;
        this.updateSettingsFailMsg = ErrorHandling(error);
      }
    },

    // ## Deactivate settings. -----------------------------------------------------------------------
    async deactivateSettings(data) {
      this.deactivateSettingsPending = true;

      try {
        await SettingsService.delete(data);
        this.deactivateSettingsDone = !this.deactivateSettingsDone;
        this.deactivateSettingsPending = false;
      } catch (error) {
        this.deactivateSettingsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get setting detail. ------------------------------------------------------------------------
    async getSettingDetail(data) {
      this.settingsDetailPending = true;

      try {
        const res = await SettingsService.getDetail(data);
        this.settingsDetail = res.data.data;
        this.settingsDetailDone = !this.settingsDetailDone;
        this.settingsDetailPending = false;
      } catch (error) {
        this.settingsDetailPending = false;
        ErrorHandling(error);
      }
    },

    resetSettingDetail() {
      this.settingsDetail = {};
    },

    // ## Test setting. ------------------------------------------------------------------------------
    async testConnection(data) {
      this.testConnectionPending = true;

      try {
        const res = await SettingsService.test(data);
        this.testConnectionData = res.data;
        this.testConnectionDone = !this.testConnectionDone;
        this.testConnectionDoneMsg = res.data.message;
        this.testConnectionPending = false;
      } catch (error) {
        this.testConnectionPending = false;
        this.testConnectionFail = !this.testConnectionFail;
        this.testConnectionFailMsg = ErrorHandling(error);
      }
    },

    async retestConnection(data) {
      this.retestConnectionPending = true;

      try {
        await SettingsService.retest(data);
        this.retestConnectionDone = !this.retestConnectionDone;
        this.retestConnectionPending = false;
      } catch (error) {
        this.retestConnectionPending = false;
        this.retestConnectionFail = !this.retestConnectionFail;
        ErrorHandling(error);
      }
    },

    // ## Add Instance settings. -------------------------------------------------------------
    async addInstance(data) {
      this.addInstancePending = true;

      try {
        await SettingsService.addInstance(data);
        this.addInstanceDone = !this.addInstanceDone;
        this.addInstancePending = false;
      } catch (error) {
        this.addInstancePending = false;
        ErrorHandling(error);
      }
    },

    // ## Updated Instance settings. ---------------------------------------------------------
    async updateInstance(data) {
      this.updateInstancePending = true;

      try {
        await SettingsService.updateInstance(data);
        this.updateInstanceDone = !this.updateInstanceDone;
        this.updateInstancePending = false;
      } catch (error) {
        this.updateInstancePending = false;
        ErrorHandling(error);
      }
    },

    // ## Delete Instance. -------------------------------------------------------------------
    async deleteInstance(data) {
      this.deleteInstancePending = true;

      try {
        await SettingsService.deleteInstance(data);
        this.deleteInstanceDone = !this.deleteInstanceDone;
        this.deleteInstancePending = false;
      } catch (error) {
        this.deleteInstancePending = false;
        ErrorHandling(error);
      }
    },

    // ## Get all slack channels. --------------------------------------------------------------------
    async getAllSlackChannels() {
      this.allSlackChannelsPending = true;

      try {
        const res = await SettingsService.getSlackChannels();
        this.allSlackChannels = res.data.data;
        this.allSlackChannelsDone = !this.allSlackChannelsDone;
        this.allSlackChannelsPending = false;
      } catch (error) {
        this.allSlackChannelsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get all mattermost channels. --------------------------------------------------------------
    async getAllMattermostChannels() {
      this.allMattermostChannelsPending = true;

      try {
        const res = await SettingsService.getMattermostChannels();
        this.allMattermostChannels = res.data.data;
        this.allMattermostChannelsDone = !this.allMattermostChannelsDone;
        this.allMattermostChannelsPending = false;
      } catch (error) {
        this.allMattermostChannelsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get personel access token. -----------------------------------------------------------------
    async getPersonalAccessToken(data) {
      this.personalAccessTokenPending = true;

      try {
        const params = {
          limit: 30,
          start: (data.page - 1) * 30,
        };
        const res = await SettingsService.getTokens(params);
        this.personalAccessTokenTotal = res.data.total;
        this.setGetPersonalAccessToken(res.data.data);
        this.personalAccessTokenDone = !this.personalAccessTokenDone;
        this.personalAccessTokenPending = false;
      } catch (error) {
        this.personalAccessTokenPending = false;
        ErrorHandling(error);
      }
    },

    setGetPersonalAccessToken(payload) {
      this.personalAccessToken = payload.map((s) => ({
        id: s.id,
        label: s.label,
        user: {
          id: s.user.id,
          name: s.user.username,
          email: s.user.email,
        },
        expired_at: dayjs(s.expired_at).format('DD MMM YYYY'),
        expired: s.expired,
        m2m: s.label.indexOf('M2M') > -1,
      }));
    },

    // ## Create personel access token. --------------------------------------------------------------
    async createPersonalAccessToken(data) {
      this.createPersonalAccessTokenPending = true;

      try {
        const res = await SettingsService.createToken(data);
        this.setCreatePersonalAccessToken(res.data.data);
        this.createPersonalAccessTokenDone = !this.createPersonalAccessTokenDone;
        this.createPersonalAccessTokenPending = false;
      } catch (error) {
        this.createPersonalAccessTokenPending = false;
        ErrorHandling(error);
      }
    },

    setCreatePersonalAccessToken(payload) {
      this.newPersonalAccessTokenKey = payload.key;
      this.personalAccessToken.unshift({
        id: payload.id,
        label: payload.label,
        user: {
          id: payload.user.id,
          name: payload.user.username,
          email: payload.user.email,
        },
        expired_at: dayjs(payload.expired_at).format('YYYY-MM-DD'),
      });
    },

    // ## Delete personel access token. --------------------------------------------------------------
    async deletePersonalAccessToken(data) {
      this.deletePersonalAccessTokenPending = true;

      try {
        await SettingsService.deleteToken(data);
        this.deletePersonalAccessTokenDone = !this.deletePersonalAccessTokenDone;
        this.deletePersonalAccessTokenPending = false;
      } catch (error) {
        this.deletePersonalAccessTokenPending = false;
        ErrorHandling(error);
      }
    },

    // ## Update personel access token. --------------------------------------------------------------
    async updatePersonalAccessToken(data) {
      this.updatePersonalAccessTokenPending = true;

      try {
        const res = await SettingsService.updateToken(data);
        this.setUpdatePersonalAccessToken(res.data.data);
        this.updatePersonalAccessTokenDone = !this.updatePersonalAccessTokenDone;
        this.updatePersonalAccessTokenPending = false;
      } catch (error) {
        this.updatePersonalAccessTokenPending = false;
        ErrorHandling(error);
      }
    },

    setUpdatePersonalAccessToken(payload) {
      const selectedToken =
        this.personalAccessToken.find((s) => s.id === payload.id);
      const selectedTokenIndex = this.personalAccessToken.indexOf(selectedToken);
      this.personalAccessToken[selectedTokenIndex] = {
        id: payload.id,
        label: payload.label,
        user: {
          id: payload.user.id,
          name: payload.user.username,
          email: payload.user.email,
        },
        expired_at: dayjs(payload.expired_at).format('YYYY-MM-DD'),
      };
    },

    // ## Get report profiles. -----------------------------------------------------------------------
    async getReportProfiles(data) {
      this.reportProfilesPending = true;

      try {
        const params = {
          limit: 16,
          start: (data.page - 1) * 16,
        };
        const res = await SettingsService.getReportProfiles(params);
        this.reportProfilesTotal = res.data.total;
        this.setGetReportProfiles(res.data.data);
        this.reportProfilesDone = !this.reportProfilesDone;
        this.reportProfilesPending = false;
      } catch (error) {
        this.reportProfilesPending = false;
        ErrorHandling(error);
      }
    },

    setGetReportProfiles(payload) {
      this.reportProfilesDummy = true;
      if (!payload) {
        this.reportProfiles = [];
        return;
      }
      const data = [];
      payload.forEach((s) => {
        const parsedRrule = rrulestr(s.r_rule);

        let rRuleString = rrulestr(s.r_rule).toText();
        rRuleString = rRuleString.charAt(0).toUpperCase() + rRuleString.slice(1);
        data.push({
          id: s.id,
          title: s.title,
          type: s.type,
          name: s.name,
          recurrence: rRuleString,
          startDate: dayjs(parsedRrule.options.dtstart).format('DD MMM YYYY HH:mm'),
          lastGenerateDate:
            (s.last_generated ? dayjs(s.last_generated).format('DD MMM YYYY HH:mm') : null),
          scheduled: s.scheduled,
          projects: s.projects,
          teams: s.teams,
          sendVia: s.send_via,
        });
      });
      this.reportProfiles = data;
    },

    // ## Get report profiles. -----------------------------------------------------------------------
    async getReportProfilesList(data) {
      this.reportProfilesListPending = true;

      try {
        const params = {
          q: data.q,
        };
        const res = await SettingsService.getReportProfilesList(params);
        this.setGetReportProfilesList(res.data.data);
        this.reportProfilesListDone = !this.reportProfilesListDone;
        this.reportProfilesListPending = false;
      } catch (error) {
        this.reportProfilesListPending = false;
        ErrorHandling(error);
      }
    },

    setGetReportProfilesList(payload) {
      this.reportProfilesList = [];
      this.reportProfilesList = payload.map((s) => ({
        name: s.title,
        id: s.id,
      }));
    },

    // ## Get report details. ------------------------------------------------------------------------
    async getReportProfileDetails(data) {
      this.reportProfileDetailPending = true;

      try {
        const res = await SettingsService.getReportProfileDetails(data);
        this.reportProfileDetail = res.data.data;
        this.reportProfileDetailDone = !this.reportProfileDetailDone;
        this.reportProfileDetailPending = false;
      } catch (error) {
        this.reportProfileDetailPending = false;
        ErrorHandling(error);
      }
    },

    // ## Add report. --------------------------------------------------------------------------------
    async addReportProfile(data) {
      this.addReportProfilePending = true;

      try {
        await SettingsService.addReportProfile(data);
        this.addReportProfileDone = !this.addReportProfileDone;
        this.addReportProfilePending = false;
      } catch (error) {
        this.addReportProfilePending = false;
        this.addReportProfileFail = !this.addReportProfileFail;
        this.addReportProfileFailMsg = ErrorHandling(error);
      }
    },

    // ## Delete report. -----------------------------------------------------------------------------
    async deleteReportProfile(data) {
      this.deleteReportProfilePending = true;

      try {
        await SettingsService.deleteReportProfile(data);
        this.deleteReportProfileDone = !this.deleteReportProfileDone;
        this.deleteReportProfilePending = false;
      } catch (error) {
        this.deleteReportProfilePending = false;
        ErrorHandling(error);
      }
    },

    // ## Delete report. -----------------------------------------------------------------------------
    async updateReportProfile(data) {
      this.updateReportProfilePending = true;

      try {
        await SettingsService.updateReportProfile(data);
        this.updateReportProfileDone = !this.updateReportProfileDone;
        this.updateReportProfilePending = false;
      } catch (error) {
        this.updateReportProfilePending = false;
        ErrorHandling(error);
      }
    },

    // ## Activate report. ---------------------------------------------------------------------------
    async activateReportProfile(data) {
      this.activateReportProfilePending = true;

      try {
        await SettingsService.activateReportProfile(data);
        this.activateReportProfileDone = !this.activateReportProfileDone;
        this.activateReportProfilePending = false;
      } catch (error) {
        this.activateReportProfilePending = false;
        ErrorHandling(error);
      }
    },

    // ## Activate report. ---------------------------------------------------------------------------
    async deactivateReportProfile(data) {
      this.deactivateReportProfilePending = true;

      try {
        await SettingsService.deactivateReportProfile(data);
        this.deactivateReportProfileDone = !this.deactivateReportProfileDone;
        this.deactivateReportProfilePending = false;
      } catch (error) {
        this.deactivateReportProfilePending = false;
        ErrorHandling(error);
      }
    },

    // ## Download report. ---------------------------------------------------------------------------
    async downloadReportHistory(data) {
      this.downloadReportHistoryPending = true;

      try {
        const res = await SettingsService.downloadReportHistory(data);
        this.downloadReportHistoryData = res.data;
        this.downloadReportHistoryDone = !this.downloadReportHistoryDone;
        this.downloadReportHistoryPending = false;
      } catch (error) {
        this.downloadReportHistoryPending = false;
        this.downloadReportHistoryFail = !this.downloadReportHistoryFail;
        this.downloadReportHistoryFailMsg = ErrorHandling(error);
      }
    },

    // ## Report events. -----------------------------------------------------------------------
    async getReportHistory(data) {
      this.reportHistoryPending = true;

      try {
        const params = {
          limit: 18,
          start: (data.page - 1) * 18,
          profile_id: data.profileId,
          name: data.name,
          status: data.status,
        };
        const res = await SettingsService.getReportHistory(params);
        this.reportHistoryTotal = res.data.total;
        this.setGetReportHistory(res.data.data);
        this.reportHistoryDone = !this.reportHistoryDone;
        this.reportHistoryPending = false;
      } catch (error) {
        this.reportHistoryPending = false;
        ErrorHandling(error);
      }
    },

    setGetReportHistory(payload) {
      this.reportHistory = payload.map((s) => ({
        id: s.id,
        profileId: s.report_profile.id,
        profile: s.report_profile.title,
        type: s.report_profile.type,
        createdAt: dayjs(s.created_at).format('DD MMM YYYY HH:mm'),
        status: s.status,
      }));
    },

    // ## Delete History report. -------------------------------------------------------------------
    async deleteReportHistory(data) {
      this.deleteReportHistoryPending = true;

      try {
        await SettingsService.deleteReportHistory(data);
        this.deleteReportHistoryDone = !this.deleteReportHistoryDone;
        this.deleteReportHistoryPending = false;
      } catch (error) {
        this.deleteReportHistoryPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get source sync status. --------------------------------------------------------------------
    async getSourceSyncStatus(data) {
      this.sourceSyncStatusPending = true;

      try {
        const res = await SettingsService.getSourceStatus(data);
        this.setGetSourceSyncStatus(res.data.data);
        this.sourceSyncStatusDone = !this.sourceSyncStatusDone;
        this.sourceSyncStatusPending = false;
      } catch (error) {
        this.sourceSyncStatusPending = false;
        ErrorHandling(error);
      }
    },

    setGetSourceSyncStatus(payload) {
      if (!payload) {
        this.sourceSyncStatus = null;
        return;
      }
      this.sourceSyncStatus = {
        new: payload.new,
        added: payload.added,
        fetched: payload.fetched,
        status: payload.status,
        updateAt: dayjs(payload.updated_at).format('DD-MMM-YYYY'),
      };
    },

    // ## Start alm source sync. -------------------------------------------------------------------------
    async startAlmSourceSync(data) {
      this.startAlmSourceSyncPending = true;

      try {
        const res = await SettingsService.startAlmSourceSync(data);
        this.startAlmSourceSyncData = res.data;
        this.startAlmSourceSyncDone = !this.startAlmSourceSyncDone;
        this.startAlmSourceSyncPending = false;
      } catch (error) {
        this.startAlmSourceSyncPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get alm source sync status. --------------------------------------------------------------------
    async getAlmSourceSyncStatus(data) {
      this.almSourceSyncStatusPending = true;

      try {
        const res = await SettingsService.getAlmSourceSyncStatus(data);
        this.setGetAlmSourceSyncStatus(res.data.data);
        this.almSourceSyncStatusDone = !this.almSourceSyncStatusDone;
        this.almSourceSyncStatusPending = false;
      } catch (error) {
        this.almSourceSyncStatusPending = false;
        ErrorHandling(error);
      }
    },

    setGetAlmSourceSyncStatus(payload) {
      if (!payload) {
        this.almSourceSyncStatus = [];
        return;
      }
      this.almSourceSyncStatus = payload.map((s) => ({
        new: s.new,
        added: s.added,
        fetched: s.fetched,
        status: s.status,
        tool: s.tool,
        updateAt: dayjs(s.updated_at).format('DD-MMM-YYYY'),
      }));
    },

    // ## Get docker tags. ---------------------------------------------------------------------------
    async getDockerTags(data) {
      this.dockerTagsPending = true;

      try {
        const res = await SettingsService.getDockerTags(data);
        this.setGetDockerTags(res.data);
        this.dockerTagsDone = !this.dockerTagsDone;
        this.dockerTagsPending = false;
      } catch (error) {
        this.dockerTagsPending = false;
        ErrorHandling(error);
      }
    },

    setGetDockerTags(payload) {
      this.dockerTags = payload.tags.map((t) => ({
        label: t.tag,
        value: t.tag,
        disabled: t.status === 'ready',
        status: t.status,
      }));
      this.availableTags = payload.tags.filter((t) => t.status === 'ready');
    },

    // ## Pull docker tags. --------------------------------------------------------------------------
    async pullDockerTags(data) {
      this.pullDockerTagsPending = true;

      try {
        await SettingsService.pullDockerTags(data);
        this.pullDockerTagsDone = !this.pullDockerTagsDone;
        this.pullDockerTagsPending = false;
      } catch (error) {
        this.pullDockerTagsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Start source sync. -------------------------------------------------------------------------
    async startSourceSync(data) {
      this.startSourceSyncPending = true;

      try {
        const res = await SettingsService.startSourceSync(data);
        this.startSourceSyncData = res.data;
        this.startSourceSyncDone = !this.startSourceSyncDone;
        this.startSourceSyncPending = false;
      } catch (error) {
        this.startSourceSyncPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get source projects. -----------------------------------------------------------------------
    async getSourceProjects(data) {
      this.sourceProjectsPending = true;

      try {
        const params = {
          tool: data.tool,
          query: {
            limit: this.sourceProjectsTablePerPage,
            start: (data.options.page - 1) * this.sourceProjectsTablePerPage,
            sort_by: data.options.sort,
            forked: data.forked,
            q: data.options.q,
            added: data.options.added,
          },
        };
        const res = await SettingsService.getSourceProjects(params);
        this.setGetSourceProjects(res.data);
        this.sourceProjectsDone = !this.sourceProjectsDone;
        this.sourceProjectsPending = false;
      } catch (error) {
        this.sourceProjectsPending = false;
        ErrorHandling(error);
      }
    },

    setGetSourceProjects(payload) {
      this.sourceProjectsTable = [];
      this.sourceProjectsTableTotal = payload.total;
      this.sourceProjectsData = payload.data;
      this.sourceProjectsTableIsIssue = payload.add_as_issue;
      payload.data.forEach((s) => {
        this.sourceProjectsTable.push({
          id: s.id,
          name: s.name,
          owner: s.owner,
          slug: s.slug,
          tool: s.tool,
          url: s.url,
          added: s.added,
          fork: s.fork,
          exists: s.exists,
          languages: s.languages,
          project: s.project,
        });
      });
    },

    // ## Create source project. ---------------------------------------------------------------------
    async createSourceProject(data) {
      this.createSourceProjectPending = true;

      try {
        const res = await SettingsService.createSourceProject(data);
        this.createSourceProjectData = res.data.data;
        this.createSourceProjectDone = !this.createSourceProjectDone;
        this.createSourceProjectPending = false;
      } catch (error) {
        this.createSourceProjectPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get logs data. -----------------------------------------------------------------------------
    async getLogsData(data) {
      this.logsDataPending = true;

      try {
        const res = await SettingsService.getLogs(data);
        this.setGetLogsData(res.data.data);
        this.logsDataDone = !this.logsDataDone;
        this.logsDataPending = false;
      } catch (error) {
        this.logsDataPending = false;
        this.logsDataFail = !this.logsDataFail;
        this.logsDataFailMsg = ErrorHandling(error);
      }
    },

    setGetLogsData(payload) {
      this.logsData = payload.map((s) => ({
        level: s.Level,
        caller: s.Caller,
        message: s.Message,
        date: s.Timestamp,
      }));
      this.logsData.sort((a, b) => {
        if (a.date > b.date) {
          return -1;
        }
        if (b.date > a.date) {
          return 1;
        }
        return 0;
      });
    },

    // ## Get audit log. -----------------------------------------------------------------------------
    async getAuditLog(data) {
      this.auditLogTablePending = true;
      this.auditLogTable = auditLogDummy;

      try {
        const params = {
          limit: data.perPage,
          start: (data.page - 1) * data.perPage,
          sort: data.sort,
          event: data.eventName,
          event_category: data.eventCategory,
          username: data.username,
          start_date: data.startDate,
          end_date: data.endDate,
        };
        const res = await SettingsService.getAuditLog(params);
        this.auditLogTableTotal = res.data.total;
        this.setGetAuditLog(res.data.data);
        this.auditLogTableDone = !this.auditLogTableDone;
        this.auditLogTablePending = false;
      } catch (error) {
        this.auditLogTable = auditLogDummy;
        this.auditLogTablePending = false;
        this.auditLogTableFail = !this.auditLogTableFail;
        this.auditLogTableFailMsg = ErrorHandling(error);
      }
    },

    setGetAuditLog(payload) {
      this.auditLogTable = [];
      if (!payload) {
        return;
      }
      this.auditLogTable = payload.map((s) => ({
        date: dayjs(s.date).format('DD MMM YYYY HH:mm'),
        username: s.actor.username,
        userAgent: s.actor.user_agent,
        ip: s.actor.ip,
        eventName: s.event.name,
        eventCategory: s.event.event_category,
        description: s.description,
      }));
    },

    // ## Get logs data. -----------------------------------------------------------------------------
    async getAllScanner(data) {
      this.allScannerPending = true;

      try {
        const res = await SettingsService.getAllScanner(data);
        this.allScanner = res.data.data;
        this.allScannerDone = !this.allScannerDone;
        this.allScannerPending = false;
      } catch (error) {
        this.allScannerPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get issues user group types. ---------------------------------------------------------------
    async getIssuesUserGroupTypes(data) {
      this.issuesUserGroupTypesPending = true;

      try {
        const params = {
          type: data.type,
          query: {
            q: data.q,
          },
        };
        const res = await SettingsService.getIssuesUserGroupTypes(params);
        this.issuesUserGroupTypes = res.data;
        this.issuesUserGroupTypesDone = !this.issuesUserGroupTypesDone;
        this.issuesUserGroupTypesPending = false;
      } catch (error) {
        this.issuesUserGroupTypesPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get issues user groups. --------------------------------------------------------------------
    async getIssuesUserGroups(data) {
      this.issuesUserGroupsPending = true;

      try {
        const params = {
          type: data.type,
          query: {
            group_type: data.key,
            q: data.q,
          },
        };
        const res = await SettingsService.getIssuesUserGroups(params);
        this.issuesUserGroups = res.data;
        this.issuesUserGroupsDone = !this.issuesUserGroupsDone;
        this.issuesUserGroupsPending = false;
      } catch (error) {
        this.issuesUserGroupsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get issues categories. ---------------------------------------------------------------------
    async getIssueCategories(data) {
      this.issueCategoriesPending = true;

      try {
        const params = {
          type: data.type,
          query: {
            q: data.q,
          },
        };
        const res = await SettingsService.getIssueCategories(params);
        this.setGetIssueCategories(res.data, data);
        this.issueCategoriesDone = !this.issueCategoriesDone;
        this.issueCategoriesPending = false;
      } catch (error) {
        this.issueCategoriesPending = false;
        ErrorHandling(error);
      }
    },

    setGetIssueCategories(payload, data) {
      this.issueCategories = [];
      if (data.type === 'fourme') {
        this.issueCategories = payload.service_categories;
      } else {
        this.issueCategories = payload.filter((v, i, a) => a.findIndex((t) => (t.label === v.label)) === i);
      }
    },

    // ## Get issues sub categories. -----------------------------------------------------------------
    async getIssueSubCategories(data) {
      this.issueSubCategoriesPending = true;

      try {
        const params = {
          type: data.type,
          query: {
            category: data.category,
            q: data.q,
          },
        };
        const res = await SettingsService.getIssueSubCategories(params);
        this.issueSubCategories = res.data;
        this.issueSubCategoriesDone = !this.issueSubCategoriesDone;
        this.issueSubCategoriesPending = false;
      } catch (error) {
        this.issueSubCategoriesPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get issues services. ---------------------------------------------------------------------
    async getIssueServices(data) {
      this.issueServicesPending = true;

      try {
        const res = await SettingsService.getIssueServices(data);
        this.issueServices = res.data;
        this.issueServicesDone = !this.issueServicesDone;
        this.issueServicesPending = false;
      } catch (error) {
        this.issueServicesPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get issues service instances. ---------------------------------------------------------------------
    async getIssueServiceInstances(data) {
      this.issueServiceInstancesPending = true;

      try {
        const res = await SettingsService.getIssueServiceInstances(data);
        this.issueServiceInstances = res.data;
        this.issueServiceInstancesDone = !this.issueServiceInstancesDone;
        this.issueServiceInstancesPending = false;
      } catch (error) {
        this.issueServiceInstancesPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get issues templates. ---------------------------------------------------------------------
    async getIssueTemplates(data) {
      this.issueTemplatesPending = true;

      try {
        const res = await SettingsService.getIssueTemplates(data);
        this.issueTemplates = res.data;
        this.issueTemplatesDone = !this.issueTemplatesDone;
        this.issueTemplatesPending = false;
      } catch (error) {
        this.issueTemplatesPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get issues teams. -------------------------------------------------------------------------
    async getIssueTeams(data) {
      this.issueTeamsPending = true;

      try {
        const res = await SettingsService.getIssueTeams(data);
        this.issueTeams = res.data;
        this.issueTeamsDone = !this.issueTeamsDone;
        this.issueTeamsPending = false;
      } catch (error) {
        this.issueTeamsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get issues members. -------------------------------------------------------------------------
    async getIssueMembers(data) {
      this.issueMembersPending = true;

      try {
        const res = await SettingsService.getIssueMembers(data);
        this.issueMembers = res.data;
        this.issueMembersDone = !this.issueMembersDone;
        this.issueMembersPending = false;
      } catch (error) {
        this.issueMembersPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get issues sub categories. -----------------------------------------------------------------
    async getIssueLabels(data) {
      this.issueLabelsPending = true;

      try {
        const res = await SettingsService.getIssueLabels(data);
        this.issueLabels = res.data.labels;
        this.issueLabelsDone = !this.issueLabelsDone;
        this.issueLabelsPending = false;
      } catch (error) {
        this.issueLabelsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get labels data. ---------------------------------------------------------------------------
    async getLabelsData(data) {
      this.labelsTablePending = true;

      try {
        const params = {
          query: {
            limit: this.labelsTablePerpage,
            start: (data.page - 1) * this.labelsTablePerpage,
          },
        };
        const res = await SettingsService.getLabels(params);
        this.labelsTableTotal = res.data.total;
        this.setGetLabelsData(res.data.data);
        this.labelsTableDone = !this.labelsTableDone;
        this.labelsTablePending = false;
      } catch (error) {
        this.labelsTablePending = false;
        ErrorHandling(error);
      }
    },

    setGetLabelsData(payload) {
      this.labelsTable = payload.map((s) => ({
        id: s.id,
        name: s.name,
        color: `#${s.color}`,
        internal: s.internal,
        isUsedSC: s.is_used_sc,
        isUsedIC: s.is_used_ic,
        riskValue: s.risk_value,
        type: s.type,
        isDeactivatedFromUser: s.is_deactivated_from_user,
      }));
    },

    // ## Get labels list. ---------------------------------------------------------------------------
    async getLabelsList(data) {
      this.labelsListPending = true;

      try {
        const res = await SettingsService.getLabelsList(data);
        this.setGetLabelsList(res.data.data);
        this.labelsListDone = !this.labelsListDone;
        this.labelsListPending = false;
      } catch (error) {
        this.labelsListPending = false;
        ErrorHandling(error);
      }
    },

    setGetLabelsList(payload) {
      this.labelsList = payload.map((s) => ({
        id: s.id,
        name: s.name,
        color: `#${s.color}`,
        isUsedSC: s.is_used_sc,
        isUsedIC: s.is_used_ic,
        isUsedSLA: s.is_used_sla,
        isUsedHV: s.is_used_hv,
      }));
    },

    // ## Create label. ------------------------------------------------------------------------------
    async addLabel(data) {
      this.addLabelPending = true;

      try {
        const res = await SettingsService.addLabel(data);
        this.addLabelData = res.data;
        this.addLabelDone = !this.addLabelDone;
        this.addLabelPending = false;
      } catch (error) {
        this.addLabelPending = false;
        ErrorHandling(error);
      }
    },

    // ## Create label. ------------------------------------------------------------------------------
    async deleteLabel(data) {
      this.deleteLabelPending = true;

      try {
        await SettingsService.deleteLabel(data);
        this.deleteLabelDone = !this.deleteLabelDone;
        this.deleteLabelPending = false;
      } catch (error) {
        this.deleteLabelPending = false;
        ErrorHandling(error);
      }
    },

    // ## Update label. ------------------------------------------------------------------------------
    async updateLabel(data) {
      this.updateLabelPending = true;

      try {
        await SettingsService.updateLabel(data);
        this.updateLabelDone = !this.updateLabelDone;
        this.updateLabelPending = false;
      } catch (error) {
        this.updateLabelPending = false;
        ErrorHandling(error);
      }
    },

    // ## Validate label name. -----------------------------------------------------------------------
    async validateLabelName(data) {
      this.validateLabelNamePending = true;

      try {
        const res = await SettingsService.validateLabelName(data);
        this.validateLabelNameData = res.data.result;
        this.validateLabelNameDone = !this.validateLabelNameDone;
        this.validateLabelNamePending = false;
      } catch (error) {
        this.validateLabelNamePending = false;
        ErrorHandling(error);
      }
    },

    // ## Get business units table. ------------------------------------------------------------------
    async getBusinessUnitsTable(data) {
      this.businessUnitsTablePending = true;

      try {
        const params = {
          limit: this.labelsTablePerpage,
          start: (data.page - 1) * this.labelsTablePerpage,
        };
        const res = await SettingsService.getBusinessUnitsTable(params);
        this.businessUnitsTableTotal = res.data.total;
        this.setGetBusinessUnitsTable(res.data.data);
        this.businessUnitsTableDone = !this.businessUnitsTableDone;
        this.businessUnitsTablePending = false;
      } catch (error) {
        this.businessUnitsTablePending = false;
        ErrorHandling(error);
      }
    },

    setGetBusinessUnitsTable(payload) {
      this.businessUnitsTable = payload.map((s) => ({
        id: s.id,
        name: s.name,
        color: `#${s.color}`,
        internal: s.internal,
        isUsedSC: s.is_used_sc,
        isUsedIC: s.is_used_ic,
        userIds: s.user_ids,
      }));
    },

    // ## Get business units list. -------------------------------------------------------------------
    async getBusinessUnitsList(data) {
      this.businessUnitsListPending = true;

      try {
        const res = await SettingsService.getBusinessUnitsList(data);
        this.setGetBusinessUnitsList(res.data.data);
        this.businessUnitsListDone = !this.businessUnitsListDone;
        this.businessUnitsListPending = false;
        return res.data;
      } catch (error) {
        this.businessUnitsListPending = false;
        ErrorHandling(error);
      }
    },

    setGetBusinessUnitsList(payload) {
      this.businessUnitsList = payload.map((s) => ({
        id: s.id,
        name: s.name,
        color: `#${s.color}`,
        isUsedSC: s.is_used_sc,
        isUsedIC: s.is_used_ic,
        isUsedSLA: s.is_used_sla,
        required: s.required,
        userIds: s.user_ids,
      }));
    },

    // ## Add Business units. ------------------------------------------------------------------------
    async addBusinessUnits(data) {
      this.addBusinessUnitsPending = true;

      try {
        const res = await SettingsService.addBusinessUnits(data);
        this.addBusinessUnitsData = res.data;
        this.addBusinessUnitsDone = !this.addBusinessUnitsDone;
        this.addBusinessUnitsPending = false;
      } catch (error) {
        this.addBusinessUnitsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Update business units. ---------------------------------------------------------------------
    async updateBusinessUnits(data) {
      this.updateBusinessUnitsPending = true;

      try {
        await SettingsService.updateBusinessUnits(data);
        this.updateBusinessUnitsDone = !this.updateBusinessUnitsDone;
        this.updateBusinessUnitsPending = false;
      } catch (error) {
        this.updateBusinessUnitsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Delete business units. ---------------------------------------------------------------------
    async deleteBusinessUnits(data) {
      this.deleteBusinessUnitsPending = true;

      try {
        await SettingsService.deleteBusinessUnits(data);
        this.deleteBusinessUnitsDone = !this.deleteBusinessUnitsDone;
        this.deleteBusinessUnitsPending = false;
      } catch (error) {
        this.deleteBusinessUnitsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Validate business units name. --------------------------------------------------------------
    async validateBusinessUnitsName(data) {
      this.validateBusinessUnitsNamePending = true;

      try {
        const res = await SettingsService.validateBusinessUnitsName(data);
        this.validateBusinessUnitsNameData = res.data.result;
        this.validateBusinessUnitsNameDone = !this.validateBusinessUnitsNameDone;
        this.validateBusinessUnitsNamePending = false;
      } catch (error) {
        this.validateBusinessUnitsNamePending = false;
        ErrorHandling(error);
      }
    },

    // ## Get authman people. ------------------------------------------------------------------------
    async getAuthmanPeople(data) {
      this.authmanPeopleTablePending = true;

      try {
        const params = {
          type: data.type,
          query: {
            limit: this.authmanPeopleTablePerPage,
            start: (data.page - 1) * this.authmanPeopleTablePerPage,
            mail: data.mail,
          },
        };
        const res = await SettingsService.getAuthmanPeople(params);
        this.authmanPeopleTableTotal = res.data.total;
        this.setGetAuthmanPeople(res.data.data);
        this.authmanPeopleTableDone = !this.authmanPeopleTableDone;
        this.authmanPeopleTablePending = false;
      } catch (error) {
        this.authmanPeopleTablePending = false;
        this.authmanPeopleTableFail = !this.authmanPeopleTableFail;
        this.authmanPeopleTableFailMsg = ErrorHandling(error);
      }
    },

    setGetAuthmanPeople(payload) {
      this.authmanPeopleTable = [];
      this.authmanPeopleTable = payload.map((s) => ({
        id: s.id,
        commonName: s.common_name,
        fullName: s.full_name,
        mail: s.mail,
        uniqueName: s.unique_name,
        username: s.username,
        role: 0,
        imported: s.imported,
      }));
    },

    // ## Create authman people. ---------------------------------------------------------------------
    async createAuthmanPeople(data) {
      this.createAuthmanPeoplePending = true;

      try {
        const res = await SettingsService.createAuthmanPeople(data);
        this.createAuthmanPeopleData = res.data;
        this.createAuthmanPeopleDone = !this.createAuthmanPeopleDone;
        this.createAuthmanPeoplePending = false;
      } catch (error) {
        this.createAuthmanPeoplePending = false;
        this.createAuthmanPeopleFail = !this.createAuthmanPeopleFail;
        this.createAuthmanPeopleFailMsg = ErrorHandling(error);
      }
    },

    // ## Get SLA rules table. -----------------------------------------------------------------------
    async getSLARulesTable(data) {
      this.slaRulesTablePending = true;

      try {
        const params = {
          limit: 15,
          start: (data.page - 1) * 15,
          sla_type: data.type,
        };
        const res = await SettingsService.getSLARulesTable(params);
        this.slaRulesTableTotal = res.data.total;
        this.setGetSlaRulesTable(res.data.data);
        this.slaRulesTableDone = !this.slaRulesTableDone;
        this.slaRulesTablePending = false;
      } catch (error) {
        this.slaRulesTablePending = false;
        ErrorHandling(error);
      }
    },

    setGetSlaRulesTable(payload) {
      this.slaRulesTable = [];
      this.slaRulesDefault = payload.default.exists;
      if (!payload.criteria) {
        return;
      }
      this.slaRulesTable = payload.criteria.map((s) => ({
        id: s.id,
        name: s.name,
        label: s.label ? s.label : null,
        rules: s.rules,
        default: s.default,
        scanType: s.scan_type,
      }));
    },

    // ## Add SLA rules. ----------------------------------------------------------------------------
    async addSLARules(data) {
      this.addSLARulesPending = true;

      try {
        await SettingsService.addSLARules(data);
        this.addSLARulesDone = !this.addSLARulesDone;
        this.addSLARulesPending = false;
      } catch (error) {
        this.addSLARulesPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get SLA rules details. ---------------------------------------------------------------------
    async getSLARuleDetails(data) {
      this.slaRulesDetailsPending = true;

      try {
        const res = await SettingsService.getSLARuleDetails(data);
        this.slaRulesDetails = res.data.data;
        this.slaRulesDetailsDone = !this.slaRulesDetailsDone;
        this.slaRulesDetailsPending = false;
      } catch (error) {
        this.slaRulesDetailsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Update SLA rules. ------------------------------------------------------------------------
    async updateSLARules(data) {
      this.updateSlaRulesPending = true;

      try {
        await SettingsService.updateSLARules(data);
        this.updateSlaRulesDone = !this.updateSlaRulesDone;
        this.updateSlaRulesPending = false;
      } catch (error) {
        this.updateSlaRulesPending = false;
        ErrorHandling(error);
      }
    },

    // ## Delete SLA rule. ---------------------------------------------------------------------------
    async deleteSLARule(data) {
      this.deleteSlaRulePending = true;

      try {
        await SettingsService.deleteSLARule(data);
        this.deleteSlaRuleDone = !this.deleteSlaRuleDone;
        this.deleteSlaRulePending = false;
      } catch (error) {
        this.deleteSlaRulePending = false;
        ErrorHandling(error);
      }
    },

    // ## Get all branches. --------------------------------------------------------------------------
    async getAllBranches(data) {
      this.allBranchesPending = true;

      try {
        const res = await SettingsService.getAllBranches(data);
        this.allBranches = res.data.data;
        this.allBranchesDone = !this.allBranchesDone;
        this.allBranchesPending = false;
      } catch (error) {
        this.allBranchesPending = false;
        ErrorHandling(error);
      }
    },

    // ## Validation policy name. ------------------------------------------------------------------
    async validationPolicyName(data) {
      this.validationPolicyNamePending = true;

      try {
        const res = await SettingsService.validationPolicyName(data);
        this.validationPolicyNameData = res.data.result;
        this.validationPolicyNameDone = !this.validationPolicyNameDone;
        this.validationPolicyNamePending = false;
      } catch (error) {
        this.validationPolicyNamePending = false;
        ErrorHandling(error);
      }
    },

    // ## Validation agent label. --------------------------------------------------------------------
    async validationAgentLabel(data) {
      this.validationAgentLabelPending = true;

      try {
        const res = await SettingsService.validationAgentLabel(data);
        this.validationAgentLabelData = res.data.result;
        this.validationAgentLabelDone = !this.validationAgentLabelDone;
        this.validationAgentLabelPending = false;
      } catch (error) {
        this.validationAgentLabelPending = true;
        ErrorHandling(error);
      }
    },

    // ## Invite user to training tool. --------------------------------------------------------------
    async trainingToolInviteUser(data) {
      this.trainingToolInviteUserPending = true;

      try {
        await SettingsService.trainingToolInviteUser(data);
        this.trainingToolInviteUserDone = !this.trainingToolInviteUserDone;
        this.trainingToolInviteUserPending = false;
      } catch (error) {
        this.trainingToolInviteUserPending = false;
        this.trainingToolInviteUserFail = !this.trainingToolInviteUserFail;
        ErrorHandling(error);
      }
    },

    // ## Get training user info. --------------------------------------------------------------------
    async getTrainingUserInfo(data) {
      this.trainingUserInfoPending = true;

      try {
        const res = await SettingsService.getTrainingUserInfo(data);
        this.trainingUserInfo = res.data;
        this.trainingUserInfoDone = !this.trainingUserInfoDone;
        this.trainingUserInfoPending = false;
      } catch (error) {
        this.trainingUserInfoPending = false;
        this.trainingUserInfoFail = !this.trainingUserInfoFail;
        this.trainingUserInfoFailMsg = ErrorHandling(error);
      }
    },

    // ## Get training corse. ------------------------------------------------------------------------
    async getTrainingCourses(data) {
      this.trainingCoursesPending = true;

      try {
        const res = await SettingsService.getTrainingCourses(data);
        this.trainingCourses = res.data;
        this.trainingCoursesDone = !this.trainingCoursesDone;
        this.trainingCoursesPending = false;
      } catch (error) {
        this.trainingCoursesPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get training corse. ------------------------------------------------------------------------
    async getTrainingLessons(data) {
      this.trainingLessonsPending = true;

      try {
        const res = await SettingsService.getTrainingLessons(data);
        this.trainingLessons = res.data.lessons;
        this.trainingLessonsDone = !this.trainingLessonsDone;
        this.trainingLessonsPending = false;
      } catch (error) {
        this.trainingLessonsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Assign training. ---------------------------------------------------------------------------
    async assignTrainingToUser(data) {
      this.assignTrainingToUserPending = true;

      try {
        await SettingsService.assignTrainingToUser(data);
        this.assignTrainingToUserDone = !this.assignTrainingToUserDone;
        this.assignTrainingToUserPending = false;
      } catch (error) {
        this.assignTrainingToUserPending = false;
        this.assignTrainingToUserFail = !this.assignTrainingToUserFail;
        this.assignTrainingToUserFailMsg = ErrorHandling(error);
      }
    },

    // ## Get addable scanners. ----------------------------------------------------------------------
    async getAddableScanners(data) {
      this.addableScannersPending = true;

      try {
        const res = await SettingsService.getAddableScanners(data);
        this.addableScanners = res.data.data.filter((s) => s.name !== 'sbomradar');
        this.addableScannersDone = !this.addableScannersDone;
        this.addableScannersPending = false;
      } catch (error) {
        this.addableScannersPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get agent language. ------------------------------------------------------------------------
    async getAgentLanguage(data) {
      this.agentLanguagePending = true;

      try {
        const res = await SettingsService.getAgentLanguage(data);
        this.agentLanguage = res.data;
        this.agentLanguageDone = !this.agentLanguageDone;
        this.agentLanguagePending = false;
      } catch (error) {
        this.agentLanguagePending = false;
        ErrorHandling(error);
      }
    },

    // ## Get organizations. ------------------------------------------------------------------------
    async getOrganizations(data) {
      this.organizationsPending = true;

      try {
        const res = await SettingsService.getOrganizations(data);
        this.organizationsData = res.data.data;
        this.organizationsDone = !this.organizationsDone;
        this.organizationsPending = false;
      } catch (error) {
        this.organizationsPending = false;
        this.organizationsFail = !this.organizationsFail;
        this.organizationsFailMsg = ErrorHandling(error);
      }
    },

    // ## Get all sbom table. ------------------------------------------------------------------------
    async getAllSbomTable(data) {
      this.allSbomTablePending = true;
      this.allSbomTable = sbomDummy;
      try {
        const params = {
          limit: this.allSbomTablePerpage,
          start: (data.page - 1) * this.allSbomTablePerpage,
          sort_by: data.sort,
          branch: data.branch,
          license: data.license,
          project_id: data.projects,
          purl: data.packageUrl,
          scan_type: data.sbomSource,
          vulnerable: data.vulnerable ? `${data.vulnerable.split(':')[0]}:${data.vulnerable.split(':')[1] === 'yes'}` : null,
        };
        const res = await SettingsService.getAllSbomTable(params);
        this.allSbomTableTotal = res.data.total;
        this.setGetAllSbomTable(res.data.data);
        this.allSbomTableDone = !this.allSbomTableDone;
        this.allSbomTablePending = false;
      } catch (error) {
        this.allSbomTable = sbomDummy;
        this.allSbomTablePending = false;
        this.allSbomTableFail = !this.allSbomTableFail;
        this.allSbomTableFailMsg = ErrorHandling(error);
      }
    },

    setGetAllSbomTable(payload) {
      this.allSbomTable = [];
      if (!payload) {
        return;
      }
      this.allSbomTable = payload.map((s, i) => ({
        key: i,
        id: i,
        lastUpdatedDate: dayjs(s.updated_at).format('DD MMM YYYY HH:mm'),
        sbomFormat: s.sbom_format,
        branch: s.project.branch,
        project: s.project.name,
        scanType: s.scan_type,
        inspectorTool: s.inspector,
        vulnerableComponents: s.vulnerable_component_count,
        totalComponents: s.component_count,
        projectId: s.project.id,
      }));
    },

    // ## Get all sbom table. ------------------------------------------------------------------------
    async getAllSbomComponentTable(data) {
      this.allSbomComponentTablePending = true;
      this.allSbomComponentTable = sbomDetailDummy;
      try {
        const params = {
          limit: this.allSbomComponentTablePerpage,
          start: (data.page - 1) * this.allSbomComponentTablePerpage,
          sort_by: data.sort,
          branch: data.branch,
          license: data.license,
          project_id: data.projects,
          purl: data.packageUrl,
          risk_factor: data.licenseRisk,
          severity: data.severity,
          name: data.name,
          vulnerable: data.vulnerable ? `${data.vulnerable.split(':')[0]}:${data.vulnerable.split(':')[1] === 'yes'}` : null,
        };
        const res = await SettingsService.getAllSbomComponentTable(params);
        this.allSbomComponentTableTotal = res.data.total;
        this.sbomComponentLastUpdatedDate = dayjs(res.data.last_updated_date).format('DD MMM YYYY HH:mm');
        this.sbomComponentNextScheduleDate = dayjs(res.data.next_schedule_date).format('DD MMM YYYY HH:mm');
        this.setGetAllSbomComponentTable(res.data.data);
        this.allSbomComponentTableDone = !this.allSbomComponentTableDone;
        this.allSbomComponentTablePending = false;
      } catch (error) {
        this.allSbomComponentTable = sbomDetailDummy;
        this.allSbomComponentTablePending = false;
        this.allSbomComponentTableFail = !this.allSbomComponentTableFail;
        this.allSbomComponentTableFailMsg = ErrorHandling(error);
      }
    },

    setGetAllSbomComponentTable(payload) {
      this.allSbomComponentTable = [];
      if (!payload) {
        return;
      }
      this.allSbomComponentTable = payload.map((s, i) => ({
        key: i,
        id: i,
        componentName: s.component_name,
        componentVersion: s.component_version,
        componentType: s.component_type,
        projects: s.projects ? s.projects : [],
        branch: s.branches ? s.branches : [],
        licenses: s.licenses ? s.licenses.map((a) => (a.id === '' ? a.name : a.id)) : [],
        packageUrl: s.purl,
        vulnerabilities: s.vulnerabilities,
        licenseRisk: s.highest_license_risk_factor.severity,
        critical: s.summary.critical ? s.summary.critical : 0,
        high: s.summary.high ? s.summary.high : 0,
        medium: s.summary.medium ? s.summary.medium : 0,
        low: s.summary.low ? s.summary.low : 0,
        info: s.summary.info ? s.summary.info : 0,
      }));
    },

    // ## Get severity score. ------------------------------------------------------------------------
    async getSeverityScore() {
      this.severityScorePending = true;

      try {
        const res = await SettingsService.getSeverityScore();
        this.setGetSeverityScore(res.data.data);
        this.severityScoreDone = !this.severityScoreDone;
        this.severityScorePending = false;
      } catch (error) {
        this.severityScorePending = false;
        ErrorHandling(error);
      }
    },

    setGetSeverityScore(payload) {
      const data = [];
      if (!payload) {
        return;
      }
      Object.entries(payload).forEach((s, i) => {
        const [label, value] = s;
        if (label === 'critical_multiplier' || label === 'high_multiplier' || label === 'medium_multiplier' || label === 'low_multiplier') {
          data.push({
            key: i,
            severity: label.split('_')[0],
            value,
          });
        }
      });
      this.severityScore = data;
    },

    // ## Update severity score. ---------------------------------------------------------------------
    async updateSeverityScore(data) {
      this.updateSeverityScorePending = true;

      try {
        await SettingsService.updateSeverityScore(data);
        this.updateSeverityScoreDone = !this.updateSeverityScoreDone;
        this.updateSeverityScorePending = false;
      } catch (error) {
        this.updateSeverityScorePending = false;
        this.updateSeverityScoreFail = !this.updateSeverityScoreFail;
        ErrorHandling(error);
      }
    },

    // ## Restore severity score. --------------------------------------------------------------------
    async restoreSeverityScore(data) {
      this.restoreSeverityScorePending = true;

      try {
        await SettingsService.restoreSeverityScore(data);
        this.restoreSeverityScoreDone = !this.restoreSeverityScoreDone;
        this.restoreSeverityScorePending = false;
      } catch (error) {
        this.restoreSeverityScorePending = false;
        ErrorHandling(error);
      }
    },

    // ## Get sandboxes. -----------------------------------------------------------------------------
    async getSandboxes(data) {
      this.sandboxesPending = true;

      try {
        const res = await SettingsService.getSandboxes(data);
        this.sandboxes = res.data.data;
        this.sandboxesDone = !this.sandboxesDone;
        this.sandboxesPending = false;
      } catch (error) {
        this.sandboxesPending = false;
        ErrorHandling(error);
      }
    },

    // ## Reset sandboxes. ---------------------------------------------------------------------------
    resetSandboxes() {
      this.sandboxes = [];
    },

    // ## Get global settings. -----------------------------------------------------------------
    async getGlobalSettings(data) {
      this.globalSettingsPending = true;

      try {
        const res = await SettingsService.getGlobalSettings(data);
        this.globalSettings = res.data;
        this.globalSettingsDone = !this.globalSettingsDone;
        this.globalSettingsPending = false;
      } catch (error) {
        this.globalSettingsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Update global settings. --------------------------------------------------------------------
    async updateGlobalSettings(data) {
      this.updateGlobalSettingsPending = true;

      try {
        await SettingsService.updateGlobalSettings(data);
        this.updateGlobalSettingsDone = !this.updateGlobalSettingsDone;
        this.updateGlobalSettingsPending = false;
      } catch (error) {
        this.updateGlobalSettingsPending = false;
        this.updateGlobalSettingsFail = !this.updateGlobalSettingsFail;
        ErrorHandling(error);
      }
    },

    // ## Update instance settings. ------------------------------------------------------------------
    async updateInstanceSettings(data) {
      this.updateInstanceSettingsPending = true;

      try {
        await SettingsService.updateInstanceSettings(data);
        this.updateInstanceSettingsDone = !this.updateInstanceSettingsDone;
        this.updateInstanceSettingsPending = false;
      } catch (error) {
        this.updateInstanceSettingsPending = false;
        this.updateInstanceSettingsFail = !this.updateInstanceSettingsFail;
        ErrorHandling(error);
      }
    },

    // ## Get global configurations . ----------------------------------------------------------------
    async getGlobalConfigurations(data) {
      this.globalConfigurationsPending = true;

      try {
        const res = await SettingsService.getGlobalConfigurations(data);
        this.globalConfigurations = res.data.result;
        this.globalConfigurationsDone = !this.globalConfigurationsDone;
        this.globalConfigurationsPending = false;
      } catch (error) {
        this.globalConfigurationsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Save global configurations . ---------------------------------------------------------------
    async saveGlobalConfigurations(data) {
      this.saveGlobalConfigurationsPending = true;

      try {
        await SettingsService.saveGlobalConfigurations(data);
        this.saveGlobalConfigurationsDone = !this.saveGlobalConfigurationsDone;
        this.saveGlobalConfigurationsPending = false;
      } catch (error) {
        this.saveGlobalConfigurationsPending = false;
        this.saveGlobalConfigurationsFail = !this.saveGlobalConfigurationsFail;
        ErrorHandling(error);
      }
    },

    // ## Reset global configurations . --------------------------------------------------------------
    async resetGlobalConfigurations(data) {
      this.resetGlobalConfigurationsPending = true;

      try {
        await SettingsService.resetGlobalConfigurations(data);
        this.resetGlobalConfigurationsDone = !this.resetGlobalConfigurationsDone;
        this.resetGlobalConfigurationsPending = false;
      } catch (error) {
        this.resetGlobalConfigurationsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Upload global configurations files. --------------------------------------------------------
    async uploadGlobalConfigurationsFiles(data) {
      this.uploadGlobalConfigurationsFilesPending = true;

      try {
        await SettingsService.uploadGlobalConfigurationsFiles(data);
        this.uploadGlobalConfigurationsFilesDone = !this.uploadGlobalConfigurationsFilesDone;
        this.uploadGlobalConfigurationsFilesPending = false;
      } catch (error) {
        this.uploadGlobalConfigurationsFilesPending = false;
        ErrorHandling(error);
      }
    },

    // ## Delete global configurations files. --------------------------------------------------------
    async deleteGlobalConfigurationsFiles(data) {
      this.deleteGlobalConfigurationsFilesPending = true;

      try {
        await SettingsService.deleteGlobalConfigurationsFiles(data);
        this.deleteGlobalConfigurationsFilesDone = !this.deleteGlobalConfigurationsFilesDone;
        this.deleteGlobalConfigurationsFilesPending = false;
      } catch (error) {
        this.deleteGlobalConfigurationsFilesPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get risk profile. --------------------------------------------------------------------------
    async getRiskProfile(data) {
      this.riskProfilePending = true;

      try {
        const res = await SettingsService.getRiskProfile(data);
        this.setGetRiskProfile(res.data.data);
        this.riskProfileDone = !this.riskProfileDone;
        this.riskProfilePending = false;
      } catch (error) {
        this.riskProfilePending = false;
        ErrorHandling(error);
      }
    },

    setGetRiskProfile(payload) {
      this.riskProfile = [];
      this.riskProfileId = payload.id;
      this.riskProfile = payload.profiles.map((s, i) => ({
        key: i,
        level: s.level,
        name: s.name,
        value: s.value,
        min: s.value,
        max: s.value,
      }));
    },

    // ## Update risk profile. -----------------------------------------------------------------------
    async updateRiskProfile(data) {
      this.updateRiskProfilePending = true;

      try {
        await SettingsService.updateRiskProfile(data);
        this.updateRiskProfileDone = !this.updateRiskProfileDone;
        this.updateRiskProfilePending = false;
      } catch (error) {
        this.updateRiskProfilePending = false;
        ErrorHandling(error);
      }
    },

    // ## Get infra profiles. -----------------------------------------------------------------------
    async getInfraProfiles(data) {
      this.infraProfilesPending = true;
      this.infraProfiles = infraProfileDummy;

      try {
        const params = {
          limit: this.infraProfilesPerPage,
          start: (data.page - 1) * this.infraProfilesPerPage,
          sort_by: data.sort,
          tool_name: data.scanner,
          bind_to: data.bindTo,
          profile_name: data.profileName,
          meta_data: data.metaData,
        };
        const res = await SettingsService.getInfraProfiles(params);
        this.infraProfilesTotal = res.data.total;
        this.setGetInfraProfiles(res.data.data);
        this.infraProfilesDone = !this.infraProfilesDone;
        this.infraProfilesPending = false;
      } catch (error) {
        this.infraProfiles = infraProfileDummy;
        this.infraProfilesPending = false;
        this.infraProfilesFail = !this.infraProfilesFail;
        ErrorHandling(error);
      }
    },

    setGetInfraProfiles(payload) {
      this.infraProfiles = payload.map((s) => ({
        id: s.id,
        tool: s.tool,
        metaData: s.meta_data,
        bindTo: s.custom && s.custom.params && s.custom.params.bind_name ? s.custom.params.bind_name : null,
        profileName: s.profile_name,
        manual: s.manual,
        incrementSeverity: s.increment_severity,
        decrementSeverity: s.decrement_severity,
      }));
    },

    // ## Delete infra profiles. -----------------------------------------------------------------------
    async deleteInfraProfiles(data) {
      this.deleteInfraProfilesPending = true;

      try {
        await SettingsService.deleteInfraProfiles(data);
        this.deleteInfraProfilesDone = !this.deleteInfraProfilesDone;
        this.deleteInfraProfilesPending = false;
      } catch (error) {
        this.deleteInfraProfilesPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get infra tool profiles. -----------------------------------------------------------------------
    async getToolProfiles(data) {
      this.toolProfilesPending = true;
      this.toolProfiles = toolProfilesDummy;

      try {
        const params = {
          tool: data.tool,
          type: data.type,
          query: {
            limit: this.toolProfilesPerPage,
            start: (data.query.page - 1) * this.toolProfilesPerPage,
            q: data.query.q,
            instance_id: data.query.instance,
            bind_type: data.query.bindType,
            forceSync: data.query.forceSync,
          },
        };
        const res = await SettingsService.getToolProfiles(params);
        this.toolProfilesTotal = res.data.total;
        this.setGetToolProfiles(res.data.data);
        this.toolProfilesDone = !this.toolProfilesDone;
        this.toolProfilesPending = false;
      } catch (error) {
        this.toolProfiles = toolProfilesDummy;
        this.toolProfilesPending = false;
        this.toolProfilesFail = !this.toolProfilesFail;
        this.toolProfilesFailMsg = ErrorHandling(error);
      }
    },

    setGetToolProfiles(payload) {
      this.toolProfiles = payload.map((s) => ({
        id: s.id,
        profileId: s.profile_id,
        name: s.name || s.profile_name,
        onBoarded: s.on_boarded || s.onboarded,
        orgUrl: s.org_url,
        url: s.repo_path,
        branch: s.branch,
        status: s.status,
        project: s.kondukto_project_name,
        projectId: s.kondukto_project_id,
      }));
    },

    // ## Create tool projects. -----------------------------------------------------------------------
    async createToolProjects(data) {
      this.createToolProjectsPending = true;

      try {
        const res = await SettingsService.createToolProjects(data);
        this.createToolProjectsData = res.data.data;
        this.createToolProjectsDone = !this.createToolProjectsDone;
        this.createToolProjectsPending = false;
      } catch (error) {
        this.createToolProfilesPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get tool projects count. -----------------------------------------------------------------------
    async getToolProjectsCount(data) {
      this.toolProjectsCountPending = true;

      try {
        const res = await SettingsService.getToolProjectsCount(data);
        this.toolProjectsCount = res.data.data;
        this.toolProjectsCountDone = !this.toolProjectsCountDone;
        this.toolProjectsCountPending = false;
      } catch (error) {
        this.toolProjectsCountPending = false;
        ErrorHandling(error);
      }
    },

    resetCreateToolProjects() {
      this.createToolProjectsData = {};
    },

    // ## Create infra tool profiles. -----------------------------------------------------------------------
    async createInfraToolProfiles(data) {
      this.createInfraToolProfilesPending = true;

      try {
        const res = await SettingsService.createInfraToolProfiles(data);
        this.createInfraToolProfilesData = res.data.data;
        this.createInfraToolProfilesDone = !this.createInfraToolProfilesDone;
        this.createInfraToolProfilesPending = false;
      } catch (error) {
        this.createInfraToolProfilesPending = false;
        ErrorHandling(error);
      }
    },

    resetCreateInfraToolProfiles() {
      this.createInfraToolProfilesData = {};
    },

    // ## Get infra groups. -----------------------------------------------------------------------
    async getInfraGroups(data) {
      this.infraGroupsPending = true;
      // this.infraGroups = infraGroupsDummy;

      try {
        const params = {
          limit: data.perPage,
          start: (data.page - 1) * data.perPage,
          sort_by: data.sort,
          tag_name: data.tagName,
          tag_id: data.tagId,
          rules: data.rules,
        };
        const res = await SettingsService.getInfraGroups(params);
        this.infraGroupsTotal = res.data.total;
        this.setGetInfraGroups(res.data.data);
        this.infraGroupsDone = !this.infraGroupsDone;
        this.infraGroupsPending = false;
      } catch (error) {
        this.infraGroups = infraGroupsDummy;
        this.infraGroupsPending = false;
        this.infraGroupsFail = !this.infraGroupsFail;
        this.infraGroupsFailMsg = ErrorHandling(error);
      }
    },

    setGetInfraGroups(payload) {
      const setValue = (option) => {
        const name = option.split('=')[0];
        const value = option.split('=')[1].slice(option.split('=')[1].split(':')[0].length + 1);
        if (name === 'fixable' || name === 'overdue' || name === 'manual') {
          return value ? 'yes' : 'no';
        }
        return value;
      };
      const setName = (name) => {
        if (name === 'cwe_name') {
          return 'cweName';
        }
        if (name === 'meta_data') {
          return 'metaData';
        }
        if (name === 'ip_cidr') {
          return 'ipCidr';
        }
        if (name === 'name') {
          return 'vulnerabilityName';
        }
        if (name === 'net_bios_name') {
          return 'netBiosName';
        }
        if (name === 'dns_name') {
          return 'dnsName';
        }
        if (name === 'plugin_name') {
          return 'pluginName';
        }
        return name;
      };

      this.infraGroups = payload.map((s) => ({
        id: s.id,
        product: s.product_ids,
        groupName: s.tag_name,
        tagName: s.tag_name,
        tagId: s.tag_name,
        rules: s.rules !== '' ? s.rules.split('&').map((a) => ({
          name: setName(a.split('=')[0]),
          operand: a.split('=')[1].split(':')[0],
          value: setValue(a),
        })) : [],
      }));
    },

    // ## Validate infra group name. -----------------------------------------------------------------------
    async validateInfraGroupName(data) {
      this.validateInfraGroupNamePending = true;

      try {
        const res = await SettingsService.validateInfraGroupName(data);
        this.validateInfraGroupNameData = res.data.result;
        this.validateInfraGroupNameDone = !this.validateInfraGroupNameDone;
        this.validateInfraGroupNamePending = false;
      } catch (error) {
        this.validateInfraGroupNamePending = false;
        ErrorHandling(error);
      }
    },

    // ## Create infra groups. -----------------------------------------------------------------------
    async createInfraGroups(data) {
      this.createInfraGroupsPending = true;

      try {
        await SettingsService.createInfraGroups(data);
        this.createInfraGroupsDone = !this.createInfraGroupsDone;
        this.createInfraGroupsPending = false;
      } catch (error) {
        this.createInfraGroupsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Delete infra groups. -----------------------------------------------------------------------
    async deleteInfraGroups(data) {
      this.deleteInfraGroupsPending = true;

      try {
        await SettingsService.deleteInfraGroups(data);
        this.deleteInfraGroupsDone = !this.deleteInfraGroupsDone;
        this.deleteInfraGroupsPending = false;
      } catch (error) {
        this.deleteInfraGroupsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Update infra groups. -----------------------------------------------------------------------------------------
    async updateInfraGroups(data) {
      this.updateInfraGroupsPending = true;

      try {
        await SettingsService.updateInfraGroups(data);
        this.updateInfraGroupsDone = !this.updateInfraGroupsDone;
        this.updateInfraGroupsPending = false;
      } catch (error) {
        this.updateInfraGroupsPending = false;
      }
    },

    // ## Update scanner status. -----------------------------------------------------------------------
    async updateScannerStatus(data) {
      this.updateScannerStatusPending = true;

      try {
        await SettingsService.updateScannerStatus(data);
        this.updateScannerStatusDone = !this.updateScannerStatusDone;
        this.updateScannerStatusPending = false;
      } catch (error) {
        this.updateScannerStatusPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get scanner status. ---------------------------------------------------------------
    async getScannerStatus(data) {
      this.scannerStatusPending = true;

      try {
        const res = await SettingsService.getScannerStatus(data);
        this.scannerStatus = res.data;
        this.scannerStatusDone = !this.scannerStatusDone;
        this.scannerStatusPending = false;
      } catch (error) {
        this.scannerStatusPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get scanner scope count. ---------------------------------------------------------------
    async getScannerScopeCount(data) {
      this.scannerScopeCountPending = true;

      try {
        const res = await SettingsService.getScannerScopeCount(data);
        this.scannerScopeCount = res.data.total;
        this.scannerScopeCountDone = !this.scannerScopeCountDone;
        this.scannerScopeCountPending = false;
      } catch (error) {
        this.scannerScopeCountPending = false;
        ErrorHandling(error);
      }
    },

    // ## Automation Rules. --------------------------------------------------------------------------------------------
    async getAutomationRules(data) {
      this.automationRulesPending = true;
      this.automationRules = automationRulesDummy;

      try {
        const params = {
          query: {
            limit: this.automationRulesPerPage,
            start: (data.page - 1) * this.automationRulesPerPage,
            sort: data.sort,
            policy_action_type: data.actionType,
          },
        };
        const res = await SettingsService.getAutomationRules(params);
        this.automationRulesTotal = res.data.total;
        this.setGetAutomationRules(res.data.result);
        this.automationRulesDone = !this.automationRulesDone;
        this.automationRulesPending = false;
      } catch (error) {
        this.automationRulesPending = false;
        ErrorHandling(error);
      }
    },

    setGetAutomationRules(payload) {
      this.automationRules = [];

      if (!payload.policies) {
        return;
      }
      this.automationRules = payload.policies.map((s) => ({
        id: s.id,
        name: s.name,
        default: s.default,
        issue: s.issue,
        notification: s.notification,
        release: s.release,
        suppression: s.suppression,
        rule: s.rule,
        type: s.policy_type,
        scan: s.scan,
        sbom: s.sbom,
        labels: s.labels,
        teams: s.teams,
      }));
      this.automationRulesDefault = {
        issue: payload.action_usages.issue.default,
        notification: payload.action_usages.notification.default,
        release: payload.action_usages.release.default,
        suppression: payload.action_usages.suppression.default,
      };
    },

    async getProjectAutomationRules(data) {
      this.projectAutomationRulesPending = true;

      try {
        const params = {
          id: data.id,
          query: {
            policy_action_type: data.actionType,
          },
        };
        const res = await SettingsService.getProjectAutomationRules(params);
        this.setGetProjectAutomationRules(res.data.result);
        this.projectAutomationRulesDone = !this.projectAutomationRulesDone;
        this.projectAutomationRulesPending = false;
      } catch (error) {
        this.projectAutomationRulesPending = false;
        ErrorHandling(error);
      }
    },

    setGetProjectAutomationRules(payload) {
      this.projectAutomationRules = payload.policies;
      this.compiledAutomationRules = payload.policies.map((s) => setCompiledAutomationRules(s));
      this.projectAutomationRulesDefault = {
        issue: {
          default: payload.action_usages.issue.default,
          teams: payload.action_usages.issue.via_teams.policy_name,
          labels: payload.action_usages.issue.via_labels.labels,
        },
        notification: {
          default: payload.action_usages.notification.default,
          teams: payload.action_usages.notification.via_teams.policy_name,
          labels: payload.action_usages.notification.via_labels.labels,
        },
        release: {
          default: payload.action_usages.release.default,
          teams: payload.action_usages.release.via_teams.policy_name,
          labels: payload.action_usages.release.via_labels.labels,
        },
      };
    },

    async createAutomationRules(params) {
      this.createAutomationRulesPending = true;

      try {
        await SettingsService.createAutomationRules(params);
        this.createAutomationRulesDone = !this.createAutomationRulesDone;
        this.createAutomationRulesPending = false;
      } catch (error) {
        this.createAutomationRulesPending = false;
        ErrorHandling(error);
      }
    },

    async deleteAutomationRulesTable(params) {
      this.deleteAutomationRulesTablePending = true;

      try {
        await SettingsService.deleteAutomationRulesTable(params);
        this.deleteAutomationRulesTableDone = !this.deleteAutomationRulesTableDone;
        this.deleteAutomationRulesTablePending = false;
      } catch (error) {
        this.deleteAutomationRulesTablePending = false;
        ErrorHandling(error);
      }
    },

    async updateAutomationRules(params) {
      this.updateAutomationRulesPending = true;

      try {
        await SettingsService.updateAutomationRules(params);
        this.updateAutomationRulesDone = !this.updateAutomationRulesDone;
        this.updateAutomationRulesPending = false;
      } catch (error) {
        this.updateAutomationRulesPending = false;
        ErrorHandling(error);
      }
    },

    async getAutomationRulesDetails(params) {
      this.automationRulesDetailsPending = true;

      try {
        const res = await SettingsService.getAutomationRulesDetails(params);
        this.setGetAutomationRulesDetails(res.data);
        this.automationRulesDetailsDone = !this.automationRulesDetailsDone;
        this.automationRulesDetailsPending = false;
      } catch (error) {
        this.automationRulesDetailsPending = false;
        ErrorHandling(error);
      }
    },

    setGetAutomationRulesDetails(payload) {
      this.automationRulesDetails = {};
      this.compiledAutomationRules = [];

      if (!payload.data) {
        return;
      }
      this.automationRulesDetails = {
        id: payload.data.id,
        name: payload.data.name,
        default: payload.data.default,
        issue: payload.data.issue,
        notification: payload.data.notification,
        release: payload.data.release,
        suppression: payload.data.suppression,
        teams: payload.data.team_ids,
        labels: payload.data.label_ids,
        scan: payload.data.scan,
        sbom: payload.data.sbom,
      };
      this.automationRulesDefault = {
        issue: payload.action_usages.issue.default,
        notification: payload.action_usages.notification.default,
        release: payload.action_usages.release.default,
        suppression: payload.action_usages.suppression.default,
      };
      this.compiledAutomationRules.push(setCompiledAutomationRules(payload.data));
    },

    addAutomationRules(data) {
      this.compiledAutomationRules.push(setCompiledAutomationRules(data, 'internal'));
    },

    editAutomationRules(data) {
      const selected = this.compiledAutomationRules.find((s) => s.id === data.id);
      const selectedIndex = this.compiledAutomationRules.indexOf(selected);
      this.compiledAutomationRules[selectedIndex] = setCompiledAutomationRules(data, 'internal');
    },

    deleteAutomationRules(data) {
      const selected = this.compiledAutomationRules.find((s) => s.id === data);
      const selectedIndex = this.compiledAutomationRules.indexOf(selected);
      this.compiledAutomationRules.splice(selectedIndex, 1);
    },

    resetAutomationRules() {
      this.compiledAutomationRules = [];
    },

    getAutomationRuleFields(field) {
      if (field === 'cvssVector') {
        return 'cvss_vector';
      }
      if (field === 'cweId') {
        return 'cwe_no';
      }
      if (field === 'cweName') {
        return 'cwe_name';
      }
      if (field === 'defaultBranch') {
        return 'default_branch';
      }
      if (field === 'dnsName') {
        return 'dns_name';
      }
      if (field === 'dockerImage') {
        return 'docker_image';
      }
      if (field === 'epssPercentage') {
        return 'epss_percentage';
      }
      if (field === 'epssPercentile') {
        return 'epss_percentile';
      }
      if (field === 'falsePositive') {
        return 'fp';
      }
      if (field === 'truePositive') {
        return 'tp';
      }
      if (field === 'firstSeenFrom') {
        return 'from';
      }
      if (field === 'filePath') {
        return 'path';
      }
      if (field === 'firstSeenTo') {
        return 'to';
      }
      if (field === 'historicalStatus') {
        return 'historical_status';
      }
      if (field === 'issueClosedDate') {
        return 'issue_closed_date';
      }
      if (field === 'issueCreatedDate') {
        return 'issue_created_date';
      }
      if (field === 'issueStatus') {
        return 'issue_status';
      }
      if (field === 'issueWIPDate') {
        return 'issue_wip_date';
      }
      if (field === 'knownExploit') {
        return 'known_exploit';
      }
      if (field === 'lastSeen') {
        return 'last_seen';
      }
      if (field === 'lastScan') {
        return 'last_scan';
      }
      if (field === 'metaData') {
        return 'meta_data';
      }
      if (field === 'mitigated') {
        return 'mit';
      }
      if (field === 'nameSpace') {
        return 'name_space';
      }
      if (field === 'netBiosName') {
        return 'net_bios_name';
      }
      if (field === 'issueAssignee') {
        return 'issue_assignee';
      }
      if (field === 'issueId') {
        return 'issue_id';
      }
      if (field === 'issueUrl') {
        return 'issue_url';
      }
      if (field === 'observedInTheWild') {
        return 'observed_in_the_wild';
      }
      if (field === 'owasp2017Top10') {
        return 'owasp2017';
      }
      if (field === 'owasp2021Top10') {
        return 'owasp2021';
      }
      if (field === 'pluginId') {
        return 'plugin_id';
      }
      if (field === 'packageUrl') {
        return 'purl';
      }
      if (field === 'riskRating') {
        return 'risk_rating';
      }
      if (field === 'riskAccepted') {
        return 'ra';
      }
      if (field === 'sbomSource') {
        return 'source_type';
      }
      if (field === 'textSearch') {
        return 'text';
      }
      if (field === 'scannerType') {
        return 'tool_type';
      }
      if (field === 'scanner') {
        return 'tool_name';
      }
      if (field === 'scanner') {
        return 'tool_name';
      }
      if (field === 'wontfix') {
        return 'wf';
      }
      return field;
    },

    getAutomationRuleTableValue(option) {
      if (
        ['knownExploit', 'manual', 'overdue', 'fixable',
          'vulnerable', 'falsePositive', 'mitigated', 'wontfix', 'truePositive'].find((s) => s === option.field)
      ) {
        return i18n.global.t(option.value === true || option.value === 'yes' ? 'yes' : 'no');
      }
      if (['defaultBranch'].find((s) => s === option.field)) {
        return i18n.global.t(option.value);
      }
      if (
        option.field === 'lastSeen' || option.field === 'firstSeenTo' || option.field === 'firstSeenFrom' ||
        option.field === 'issueClosedDate' || option.field === 'issueCreatedDate' || option.field === 'issueWIPDate'
      ) {
        return dayjs(option.value).format('DD MMM YYYY');
      }
      if (option.field === 'issueStatus' || option.field === 'riskAccepted') {
        const status = [];
        option.value.split(',').forEach((s) => {
          status.push(i18n.global.t(s));
        });
        return status.join(',').replaceAll(',', ', ');
      }
      if (option.field === 'scannerType') {
        const types = [];
        option.value.split(',').forEach((s) => {
          types.push(i18n.global.t(s));
        });
        return types.join(',').replaceAll(',', ', ');
      }
      if (option.field === 'severity') {
        const severity = [];
        option.value.split(',').forEach((s) => {
          if (Number(s) === 1) {
            severity.push(i18n.global.t('low'));
          } else if (Number(s) === 2) {
            severity.push(i18n.global.t('medium'));
          } else if (Number(s) === 3) {
            severity.push(i18n.global.t('high'));
          } else if (Number(s) === 4) {
            severity.push(i18n.global.t('critical'));
          }
        });
        return severity.join(',').replaceAll(',', ', ');
      }
      if (option.field === 'status') {
        const status = [];
        option.value.split(',').forEach((s) => {
          status.push(i18n.global.t(s));
        });
        return status.join(',').replaceAll(',', ', ');
      }
      return option.value;
    },

    getAutomationRuleTableOperand(rule) {
      return GLOBAL.AUTOMATION_RULES.find((s) => s.key === rule.field)
        .operands.find((s) => s.value === rule.operand).label;
    },

    // ## Get highlighted vulns. -----------------------------------------------------------------------------
    async getHighlightedVulns(data) {
      this.highlightedVulnsPending = true;
      this.highlightedVulns = automationRulesDummy;

      try {
        const params = {
          query: {
            limit: this.highlightedVulnsPerPage,
            start: (data.page - 1) * this.highlightedVulnsPerPage,
            sort: data.sort,
            policy_action_type: data.actionType,
          },
        };
        const res = await SettingsService.getHighlightedVulns(params);
        this.highlightedVulnsTotal = res.data.total;
        this.setGetHighlightedVulns(res.data.data);
        this.highlightedVulnsDone = !this.highlightedVulnsDone;
        this.highlightedVulnsPending = false;
      } catch (error) {
        this.highlightedVulnsPending = false;
        ErrorHandling(error);
      }
    },

    setGetHighlightedVulns(payload) {
      this.highlightedVulns = [];

      if (!payload) {
        return;
      }
      this.highlightedVulns = payload.map((s) => ({
        id: s.id,
        name: s.name,
        default: s.default,
        rule: s.rule,
        label: s.label,
      }));
    },

    async createHighlightedVulns(params) {
      this.createHighlightedVulnsPending = true;

      try {
        await SettingsService.createHighlightedVulns(params);
        this.createHighlightedVulnsDone = !this.createHighlightedVulnsDone;
        this.createHighlightedVulnsPending = false;
      } catch (error) {
        this.createHighlightedVulnsPending = false;
        ErrorHandling(error);
      }
    },

    async updateHighlightedVulns(params) {
      this.updateHighlightedVulnsPending = true;

      try {
        await SettingsService.updateHighlightedVulns(params);
        this.updateHighlightedVulnsDone = !this.updateHighlightedVulnsDone;
        this.updateHighlightedVulnsPending = false;
      } catch (error) {
        this.updateHighlightedVulnsPending = false;
        ErrorHandling(error);
      }
    },

    async deleteHighlightedVulns(params) {
      this.deleteHighlightedVulnsPending = true;

      try {
        await SettingsService.deleteHighlightedVulns(params);
        this.deleteHighlightedVulnsDone = !this.deleteHighlightedVulnsDone;
        this.deleteHighlightedVulnsPending = false;
      } catch (error) {
        this.deleteHighlightedVulnsPending = false;
        ErrorHandling(error);
      }
    },

    async getHighlightedVulnsDetails(params) {
      this.highlightedVulnsDetailsPending = true;

      try {
        const res = await SettingsService.getHighlightedVulnsDetails(params);
        this.setGetHighlightedVulnsDetails(res.data);
        this.highlightedVulnsDetailsDone = !this.highlightedVulnsDetailsDone;
        this.highlightedVulnsDetailsPending = false;
      } catch (error) {
        this.highlightedVulnsDetailsPending = false;
        ErrorHandling(error);
      }
    },

    setGetHighlightedVulnsDetails(payload) {
      this.highlightedVulnsDetails = {};
      this.compiledAutomationRules = [];
      if (!payload) {
        return;
      }
      this.highlightedVulnsDetails = {
        id: payload.id,
        name: payload.name,
        default: payload.default,
        label: payload.label,
      };
      this.compiledAutomationRules.push(setCompiledAutomationRules(payload));
    },

    // ## Get sbom generator tools. ---------------------------------------------------------------
    async getSbomGeneratorTools(data) {
      this.sbomGeneratorToolsPending = true;

      try {
        const res = await SettingsService.getSbomGeneratorTools(data);
        this.sbomGeneratorTools = res.data.data;
        this.sbomGeneratorToolsDone = !this.sbomGeneratorToolsDone;
        this.sbomGeneratorToolsPending = false;
      } catch (error) {
        this.sbomGeneratorToolsPending = false;
        ErrorHandling(error);
      }
    },

    getPageSettingsFromLocalStorage() {
      const storage = localStorage['kondukto-page-settings'];
      const savedItems = (storage ? JSON.parse(storage) : null);
      this.pageSettings = savedItems;
    },

    // ## Get feature enviroment settings. ---------------------------------------------------------------
    async getFeatureEnvironmentSettings(data) {
      this.featureEnvironmentSettingsPending = true;

      try {
        const res = await SettingsService.getFeatureEnvironmentSettings(data);
        this.featureEnvironmentSettings = res.data.data;
        this.featureEnvironmentSettingsDone = !this.featureEnvironmentSettingsDone;
        this.featureEnvironmentSettingsPending = false;
      } catch (error) {
        this.featureEnvironmentSettingsPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get custom tool tepmlate. -------------------------------------------------------------------------
    async getCustomToolFields(data) {
      this.customToolFieldsPending = true;

      try {
        const res = await SettingsService.getCustomToolFields(data);
        this.setGetCustomToolFields(res.data.data);
        this.customToolFieldsDone = !this.customToolFieldsDone;
        this.customToolFieldsPending = false;
      } catch (error) {
        this.customToolFieldsPending = false;
        this.customToolFieldsFail = !this.customToolFieldsFail;
        this.customToolFieldsFailMsg = ErrorHandling(error);
      }
    },

    setGetCustomToolFields(payload) {
      this.customToolFieldsTable = [];
      Object.entries(payload).forEach((s) => {
        const [label, value] = s;
        if (label === 'logo_data' || label === 'v3_vector' || label === 'path_list') {
          return;
        }
        this.customToolFieldsTable.push({
          key: label,
          name: this.getVulnerabilityFields(label),
          required: value.required,
          type: value.type,
          path: this.getVulnerabilityFields(label) === 'uniqueId' ? [] : undefined,
          status: null,
          help: null,
        });
      });
      this.customToolFieldsTable.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }).sort((a, b) => {
        if (a.required < b.required) return 1;
        if (a.required > b.required) return -1;
        return 0;
      });
    },

    resetCustomToolFields() {
      this.customToolFieldsTable = [];
    },

    // ## Upload custom tool fields. -------------------------------------------------------------------------
    async uploadCustomToolFields(data) {
      this.uploadCustomToolFieldsPending = true;

      try {
        const res = await SettingsService.uploadCustomToolFields(data);
        this.setUploadCustomToolFields(res.data.data.file);
        this.uploadCustomToolFieldsList = res.data.data.paths;
        this.uploadCustomToolFieldsDone = !this.uploadCustomToolFieldsDone;
        this.uploadCustomToolFieldsPending = false;
      } catch (error) {
        this.uploadCustomToolFieldsPending = false;
        this.uploadCustomToolFieldsFail = !this.uploadCustomToolFieldsFail;
        this.uploadCustomToolFieldsFailMsg = ErrorHandling(error);
      }
    },

    setUploadCustomToolFields(payload) {
      this.uploadCustomToolFieldsData = [];
      const recursivalySetSubLevels = (pay) => {
        const output = [];
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in pay) {
          if (typeof pay[key] === 'string') {
            output.push({
              id: Date.now() + Math.random().toString(36).slice(2, 9),
              name: key,
              type: 'string',
              value: pay[key],
              selectType: 1,
              select: false,
              selected: false,
            });
          } else if (typeof pay[key] === 'number') {
            output.push({
              id: Date.now() + Math.random().toString(36).slice(2, 9),
              name: key,
              type: 'number',
              value: pay[key],
              selectType: 1,
              select: false,
              selected: false,
            });
          } else if (pay[key].length === 0) {
            output.push({
              id: Date.now() + Math.random().toString(36).slice(2, 9),
              name: key,
              type: 'array',
              children: pay[key].map((s) => ({
                name: s,
              })),
              selectType: 1,
              select: false,
              selected: false,
            });
          } else if (pay[key].length > 0) {
            if (typeof pay[key][0] === 'string') {
              output.push({
                id: Date.now() + Math.random().toString(36).slice(2, 9),
                name: key,
                type: 'array',
                children: pay[key].map((s) => ({
                  name: s,
                })),
                selectType: 1,
                select: false,
                selected: false,
              });
            } else {
              output.push({
                id: Date.now() + Math.random().toString(36).slice(2, 9),
                name: key,
                type: 'array',
                children: recursivalySetSubLevels(pay[key][0]),
                selectType: 1,
                select: false,
                selected: false,
              });
            }
          } else if (typeof pay[key] === 'object' && !pay[key].length) {
            output.push({
              id: Date.now() + Math.random().toString(36).slice(2, 9),
              name: key,
              type: 'object',
              children: recursivalySetSubLevels(pay[key]),
              selectType: 1,
              select: false,
              selected: false,
            });
          }
        }
        return output;
      };
      this.uploadCustomToolFieldsData = recursivalySetSubLevels(payload);
    },

    // ## Get upload custom tool fields. -------------------------------------------------------------------------
    async getUploadedCustomToolFields(data) {
      this.uploadedCustomToolFieldsPending = true;

      try {
        const res = await SettingsService.getUploadedCustomToolFields(data);
        this.setUploadCustomToolFields(res.data.data.file);
        this.setGetUploadedCustomToolFields();
        this.uploadCustomToolFieldsList = res.data.data.paths;
        this.uploadedCustomToolFieldsDone = !this.uploadedCustomToolFieldsDone;
        this.uploadedCustomToolFieldsPending = false;
      } catch (error) {
        this.uploadedCustomToolFieldsPending = false;
        this.uploadedCustomToolFieldsFail = !this.uploadedCustomToolFieldsFail;
        this.uploadedCustomToolFieldsFailMsg = ErrorHandling(error);
      }
    },

    setGetUploadedCustomToolFields() {
      setTimeout(() => {
        this.uploadCustomToolFieldsData.forEach((s) => {
          if (
            this.customToolFieldsTable.filter((a) => a.path && a.path.indexOf('[*]') > -1)
              .find((a) => a.path.split('.[*]')[0] === s.name)
          ) {
            s.selected = true;
          }
        });
      }, 100);
    },

    // ## Validation custom tool name. ------------------------------------------------------------------
    async validationCustomToolName(data) {
      this.validationCustomToolNamePending = true;

      try {
        const res = await SettingsService.validationCustomToolName(data);
        this.validationCustomToolNameData = res.data.result;
        this.validationCustomToolNameDone = !this.validationCustomToolNameDone;
        this.validationCustomToolNamePending = false;
      } catch (error) {
        this.validationCustomToolNamePending = false;
        ErrorHandling(error);
      }
    },

    // ## Get custom integration logos. -------------------------------------------------------------------------
    async getCustomIntegrationLogos(data) {
      this.customIntegrationLogosPending = true;

      try {
        const res = await SettingsService.getCustomIntegrationLogos(data);
        this.setGetCustomIntegrationLogos(res.data);
        this.customIntegrationLogos = res.data.data;
        this.customIntegrationLogosDone = !this.customIntegrationLogosDone;
        this.customIntegrationLogosPending = false;
      } catch (error) {
        this.customIntegrationLogosPending = false;
        this.customIntegrationLogosFail = !this.customIntegrationLogosFail;
        this.customIntegrationLogosFailMsg = ErrorHandling(error);
      }
    },

    setGetCustomIntegrationLogos(payload) {
      localStorage.setItem('kondukto-custom-int-logos', JSON.stringify(payload));
    },

    getVulnerabilityFields(field) {
      if (field === 'cvss_v3') {
        return 'cvssV3';
      }
      if (field === 'file_name') {
        return 'fileName';
      }
      if (field === 'fp_mark_date') {
        return 'fpMarkDate';
      }
      if (field === 'is_fp') {
        return 'fp';
      }
      if (field === 'is_mitigated') {
        return 'mitigated';
      }
      if (field === 'is_wf') {
        return 'wontfix';
      }
      if (field === 'line_number') {
        return 'lineNumber';
      }
      if (field === 'ra_mark_date') {
        return 'raMarkDate';
      }
      if (field === 'unique_id') {
        return 'uniqueId';
      }
      if (field === 'package_version') {
        return 'packageVersion';
      }
      if (field === 'packages') {
        return 'packageS';
      }
      if (field === 'package_fixed_version') {
        return 'packageFixedVersion';
      }
      if (field === 'package_name') {
        return 'packageName';
      }
      return field;
    },
  },
});

export default useSettingsStore;
