import Api from '@/services/Api';
import { API_URL } from '@/config/';

export default {
  get(params) {
    return Api().get(API_URL.PRODUCTS, { params });
  },
  getProductsList(params) {
    return Api().get(`${API_URL.PRODUCTS}`, { params });
  },
  view(params) {
    return Api().get(`${API_URL.PRODUCTS}/${params.id}`, params);
  },
  validate(params) {
    return Api().get(`${API_URL.PRODUCTS_VALIDATE}?name=${params.name}`);
  },
  post(params) {
    return Api().post(API_URL.PRODUCTS, params);
  },
  update(params) {
    return Api().patch(`${API_URL.PRODUCTS}/${params.id}`, params);
  },
  remove(params) {
    return Api().delete(`${API_URL.PRODUCTS}/${params.id}`);
  },
  getVulns(data) {
    const params = data.query;
    if (data.prefix) {
      return Api().get(`${API_URL.PRODUCTS}/${data.id}/${API_URL.VULNS}/${data.type}/${data.prefix}`, { params });
    }
    return Api().get(`${API_URL.PRODUCTS}/${data.id}/${API_URL.VULNS}/${data.type}`, { params });
  },
  getVulnsTotal(data) {
    const params = data.query;
    return Api().get(`${API_URL.PRODUCTS}/${data.id}/` +
      `${API_URL.VULNS}/${data.type}/count`, { params });
  },
  getProductVulnsPath(data) {
    const params = data.query;
    return Api().get(`${API_URL.PRODUCTS}/${data.id}/${API_URL.VULNS}` +
      `/${data.type}/file_path`, { params });
  },
  getProductVulnsCweId(data) {
    const params = data.query;
    return Api().get(`${API_URL.PRODUCTS}/${data.id}/${API_URL.VULNS}` +
      `/${data.type}/cwe`, { params });
  },
  getScans(data) {
    const params = data.query;
    return Api().get(`${API_URL.PRODUCTS}/${data.id}/${API_URL.SCANS}`, { params });
  },
  getEndpointsList(data) {
    const params = data.query;
    return Api().get(`${API_URL.PRODUCTS}/${data.id}/endpoints/findings`, { params });
  },
  getProductEndpointsDetail(data) {
    const params = data.query;
    return Api().get(`${API_URL.PRODUCTS}/${data.id}/endpoints/${data.endpoint}`, { params });
  },
  getProductFiles(data) {
    const params = data.query;
    return Api().get(`${API_URL.PRODUCTS}/${data.id}/files`, { params });
  },
  getProductSbomTable(data) {
    const params = data.query;
    return Api().get(`${API_URL.PRODUCTS}/${data.id}/${API_URL.SBOM}/scans`, { params });
  },
  getProductSbomComponentTable(data) {
    const params = data.query;
    return Api().get(`${API_URL.PRODUCTS}/${data.id}/${API_URL.SBOM}/components`, { params });
  },
  exportProductSbom(data) {
    const params = data.query;
    return Api().get(`${API_URL.PRODUCTS}/${data.id}/${API_URL.SBOM}/export`, { params });
  },
  getSummary(data) {
    const params = data.query;
    return Api().get(`${data.type}/${data.id}/${data.prefix}/charts/` +
      'overall_risk_score', { params });
  },
  getProductEndpointCoverageMetrics(params) {
    return Api().get(`${params.type}/${params.id}/${params.prefix}/charts/endpoint_coverage`);
  },
  getProductUsers(data) {
    const params = data.query;
    return Api().get(`products/${data.id}/users`, { params });
  },
};
