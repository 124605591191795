export default {
  path: 'integration',
  name: 'integration',
  component: () => import('@/views/layouts/IntegrationsLayout.vue'),
  redirect: {
    name: 'integration-scanners',
  },
  children: [
    {
      path: 'scanners',
      name: 'integration-scanners',
      component: () => import('@/views/pages/member/Integrations/Scanners.vue'),
      meta: {
        title: 'Integrations: Scanners',
        activeMenuKeys: ['integration', 'scanners'],
        allowedRole: [5],
      },
    }, {
      path: 'threat-intelligence',
      name: 'integration-threat-intelligence',
      component: () => import('@/views/pages/member/Integrations/ThreatIntelligence.vue'),
      meta: {
        title: 'Integrations: Threat Intelligence',
        activeMenuKeys: ['integration', 'threat-intelligence'],
        allowedRole: [5],
      },
    }, {
      path: 'application-lifecycle-management',
      name: 'integration-application-lifecycle-management',
      component:
        () => import('@/views/pages/member/Integrations/ApplicationLifecycleManagement.vue'),
      meta: {
        title: 'Integrations: Application Lifecycle Management',
        activeMenuKeys: ['integration', 'alm'],
        allowedRole: [5],
      },
    }, {
      path: 'issue-managers',
      name: 'integration-issue-managers',
      component: () => import('@/views/pages/member/Integrations/IssueManagers.vue'),
      meta: {
        title: 'Integrations: Issue Managers',
        activeMenuKeys: ['integration', 'im'],
        allowedRole: [5],
      },
    }, {
      path: 'notification-tools',
      name: 'integration-noitification-tools',
      component: () => import('@/views/pages/member/Integrations/NotificationTools.vue'),
      meta: {
        title: 'Integrations: Notification Tools',
        activeMenuKeys: ['integration', 'nt'],
        allowedRole: [5],
      },
    }, {
      path: 'single-sign-on-tools',
      name: 'integration-single-sign-on-tools',
      component: () => import('@/views/pages/member/Integrations/SingleSignOnTools.vue'),
      meta: {
        title: 'Integrations: Single Sign-On Tools',
        activeMenuKeys: ['integration', 'sso'],
        allowedRole: [5],
      },
    }, {
      path: 'training',
      name: 'integration-training',
      component: () => import('@/views/pages/member/Integrations/Training.vue'),
      meta: {
        title: 'Integrations: Training',
        activeMenuKeys: ['integration', 'training'],
        allowedRole: [5],
      },
    }, {
      path: 'recommendation',
      name: 'integration-recommendation',
      component: () => import('@/views/pages/member/Integrations/Recommendation.vue'),
      meta: {
        title: 'Integrations: AI Recommendation',
        activeMenuKeys: ['integration', 'recommendation'],
        allowedRole: [5],
      },
    }, {
      path: 'agents',
      name: 'integration-agents',
      component: () => import('@/views/pages/member/Integrations/Agents.vue'),
      meta: {
        title: 'Integrations: Agents',
        activeMenuKeys: ['integration', 'agent'],
        allowedRole: [5],
      },
    }, {
      path: 'personal-access-tokens',
      name: 'integration-personal-access-tokens',
      component: () => import('@/views/pages/member/Integrations/PersonalAccessTokens.vue'),
      meta: {
        title: 'Integrations: Personal Access Tokens',
        activeMenuKeys: ['integration', 'pat'],
        allowedRole: [5],
      },
      beforeEnter: (to) => {
        if (!to.query.page) {
          return {
            name: 'integration-personal-access-tokens',
            query: {
              page: 1,
            },
          };
        }
      },
    },
  ],
};
