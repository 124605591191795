// ## Config Inc.
import { USER } from '@/config/';

const dummyData = [...Array(USER.PERPAGE)].map((_, i) => ({
  key: i,
  username: '#########',
  userId: i,
  role: 'developer',
  shadowUser: '#########',
  status: {},
  type: 'internal',
  teamIds: [],
}));

const dummyTeamsData = [...Array(USER.PERPAGE)].map((_, i) => ({
  id: i,
  projectName: '#########',
  issueResponsible: '#########',
  action: i,
}));

const dummyProjectsData = [...Array(USER.PERPAGE)].map((_, i) => ({
  id: i,
  projectName: '#########',
  accessType: 'team',
  action: i,
}));

const dummyProductData = [...Array(USER.PERPAGE)].map((_, i) => ({
  id: i,
  productName: '#########',
  accessType: 'team',
  action: i,
}));

export {
  dummyData,
  dummyTeamsData,
  dummyProjectsData,
  dummyProductData,
};
