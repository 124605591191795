export default {
  path: 'team-lead-integrations',
  name: 'team-lead',
  component: () => import('@/views/layouts/TeamLeadIntegrationsLayout.vue'),
  redirect: {
    name: 'scanners',
  },
  children: [
    {
      path: 'scanners',
      name: 'team-lead-integration-scanners',
      component: () => import('@/views/pages/member/TeamLeadIntegration/Scanners.vue'),
      meta: {
        title: 'Team Lead Integrations',
        activeMenuKeys: ['team-lead-integrations', 'scanners'],
        allowedRole: [1],
      },
    }, {
      path: 'application-lifecycle-management',
      name: 'team-lead-integration-alm',
      component: () => import('@/views/pages/member/TeamLeadIntegration/ApplicationLifecycleManagement.vue'),
      meta: {
        title: 'Team Lead Integrations',
        activeMenuKeys: ['team-lead-integrations', 'alm'],
        allowedRole: [1],
      },
    }, {
      path: 'issue-managers',
      name: 'team-lead-integration-issue-managers',
      component: () => import('@/views/pages/member/TeamLeadIntegration/IssueManagers.vue'),
      meta: {
        title: 'Team Lead Integrations',
        activeMenuKeys: ['team-lead-integrations', 'im'],
        allowedRole: [1],
      },
    },
  ],
};
