// ## Config Inc.
import { TEAM } from '@/config/';

const dummyData = [...Array(TEAM.PERPAGE)].map((_, i) => ({
  id: i,
  name: '#########',
  members: ['#', '#', '#', '#', '#'],
  issueResponsible: {
    name: '#',
    email: '#',
  },
}));

export default dummyData;
