const dummyDashboard = [
  {
    displayName: 'DummyProjectMetrics',
    name: 'DummyProjectMetrics',
    type: 'left',
  }, {
    displayName: 'DummyCharts',
    name: 'dummy_charts',
    type: 'left',
  }, {
    displayName: 'DummyCharts',
    name: 'dummy_charts',
    type: 'left',
  }, {
    displayName: 'DummyCharts',
    name: 'dummy_charts',
    type: 'left',
  }, {
    displayName: 'DummyCharts',
    name: 'dummy_charts',
    type: 'left',
  }, {
    displayName: 'DummyCharts',
    name: 'dummy_charts',
    type: 'left',
  }, {
    displayName: 'DummyCharts',
    name: 'dummy_charts',
    type: 'left',
  }, {
    displayName: 'DummyKeyMetrics',
    name: 'DummyKeyMetrics',
    type: 'right',
  }, {
    displayName: 'DummyCharts',
    name: 'dummy_charts',
    type: 'right',
  }, {
    displayName: 'DummyCharts',
    name: 'dummy_charts',
    type: 'right',
  }, {
    displayName: 'DummyCharts',
    name: 'dummy_charts',
    type: 'right',
  }, {
    displayName: 'DummyCharts',
    name: 'dummy_charts',
    type: 'right',
  }, {
    displayName: 'DummyCharts',
    name: 'dummy_charts',
    type: 'right',
  }, {
    displayName: 'DummyCharts',
    name: 'dummy_charts',
    type: 'right',
  },
];

const dummyProjectMetricsData = {
  projects_avg: {
    above: 0,
    avg: 0,
    below: 0,
  },
  projects_count: {
    fail_sc: 0,
    total: 0,
    asvs: {
      count: 0,
      names: [],
    },
  },
  cvss: 0,
  total_findings: {
    critical: 10,
    high: 10,
    medium: 10,
    low: 10,
  },
  ti_observed_in_the_wild_metric: {
    total: 0,
  },
  ti_known_exploit_metrics: {
    total: 0,
  },
};

const dummyKeyMetricsData = [
  {
    name: 'issues',
    critical: 0,
    high: 0,
    low: 0,
    medium: 0,
    total: 0,
  }, {
    name: 'sla',
    critical: 0,
    high: 0,
    low: 0,
    medium: 0,
    total: 0,
  }, {
    name: 'woe',
    critical: 0,
    high: 0,
    low: 0,
    medium: 0,
    total: 0,
  }, {
    name: 'mtf',
    critical: 0,
    high: 0,
    low: 0,
    medium: 0,
    total: 0,
  },
];

const dummyEndpointCoverageData = [
  {
    percentage: 0,
    critical: 0,
    high: 0,
    medium: 0,
    low: 0,
  },
];

const dummyRiskScore = [
  {
    id: 1,
    finding: 100,
    name: 'dummy',
    riskScore: 100,
    status: 'dummy',
  }, {
    id: 2,
    finding: 50,
    name: 'dummy',
    riskScore: 30,
    status: 'dummy',
  }, {
    id: 3,
    finding: 30,
    name: 'dummy',
    riskScore: 30,
    status: 'dummy',
  },
];

const dummyRiskScore2 = [
  {
    id: 1,
    finding: 100,
    name: 'dummy',
    riskScore: 100,
    status: 'danger',
  }, {
    id: 1,
    finding: 100,
    name: 'dummy',
    riskScore: 100,
    status: 'danger',
  }, {
    id: 1,
    finding: 100,
    name: 'dummy',
    riskScore: 100,
    status: 'danger',
  }, {
    id: 1,
    finding: 100,
    name: 'dummy',
    riskScore: 100,
    status: 'danger',
  },
];

const summaryTableDummy = [
  {
    name: 'new',
    critical: 0,
    high: 0,
    low: 0,
    medium: 0,
    total: 0,
  }, {
    name: 'recurrent',
    critical: 0,
    high: 0,
    low: 0,
    medium: 0,
    total: 0,
  }, {
    name: 'closed',
    critical: 0,
    high: 0,
    low: 0,
    medium: 0,
    total: 0,
  }, {
    name: 'total',
    critical: 0,
    high: 0,
    low: 0,
    medium: 0,
    total: 0,
  }, {
    name: 'wontfix',
    critical: 0,
    high: 0,
    low: 0,
    medium: 0,
    total: 0,
  }, {
    name: 'fp',
    critical: 0,
    high: 0,
    low: 0,
    medium: 0,
    total: 0,
  },
];

const actionItemsDummy = [
  {
    id: 0,
    titles: '#######',
    assignedTo: '#######',
    state: '#######',
  }, {
    id: 1,
    titles: '#######',
    assignedTo: '#######',
    state: '#######',
  }, {
    id: 2,
    titles: '#######',
    assignedTo: '#######',
    state: '#######',
  }, {
    id: 3,
    titles: '#######',
    assignedTo: '#######',
    state: '#######',
  }, {
    id: 4,
    titles: '#######',
    assignedTo: '#######',
    state: '#######',
  }, {
    id: 5,
    titles: '#######',
    assignedTo: '#######',
    state: '#######',
  },
];

export {
  dummyDashboard,
  dummyProjectMetricsData,
  dummyKeyMetricsData,
  dummyEndpointCoverageData,
  dummyRiskScore,
  dummyRiskScore2,
  summaryTableDummy,
  actionItemsDummy,
};
