/* eslint-disable import/no-cycle */
import Api from '@/services/Api';
import { API_URL } from '@/config/';

export default {
  getDashboardAllCharts(params) {
    return Api().get(`${API_URL.DASHBOARD}/${params}/charts/user_dashboard_items`);
  },
  saveDashboardAllCharts(data) {
    const params = data.query;
    return Api().post(`${API_URL.DASHBOARD}/${data.type}/charts/user_dashboard_items`, params);
  },
  resetDashboardAllCharts(params) {
    return Api().get(`${API_URL.DASHBOARD}/${params}/charts/user_dashboard_items?restore_default=true`);
  },
  getBubbleChart(data) {
    const params = data.query;
    return Api().get(`${API_URL.DASHBOARD}/${data.prefix}/charts/` +
      `bubble_${data.chartType}`, { params });
  },
  getOwaspTopTen(data) {
    const params = data.query;
    return Api().get(`${API_URL.DASHBOARD}/${data.prefix}/charts/` +
      `${data.chartType}`, { params });
  },
  getProjectMetrics(data) {
    const params = data.query;
    return Api().get(`${API_URL.DASHBOARD}/${data.prefix}/charts/project_metrics`, { params });
  },
  getKeyMetrics(data) {
    const params = data.query;
    return Api().get(`${API_URL.DASHBOARD}/${data.prefix}/charts/key_metrics`, { params });
  },
  getDashboardMaintainabilityMetrics(data) {
    const params = data.query;
    return Api().get(`${API_URL.DASHBOARD}/${data.prefix}/charts` +
      '/maintainability_metrics', { params });
  },
  getDashboardEndpointCoverageMetrics(data) {
    const params = data.query;
    return Api().get(`${API_URL.DASHBOARD}/${data.prefix}/charts/` +
      'endpoint_coverage_metrics', { params });
  },
  getRiskScoreProject(data) {
    const params = data.query;
    if (data.type === 'product') {
      return Api().get(`${data.type}/${data.id}/` +
        `${data.prefix}/charts/risk_score_snapshot`, { params });
    }
    return Api().get(`${data.type}/${data.prefix}/charts/risk_score_snapshot`, { params });
  },
  getRiskScoreTeam(data) {
    const params = data.query;
    if (data.type === 'product') {
      return Api().get(`${data.type}/${data.id}/` +
        `${data.prefix}/charts/risk_score_snapshot_by_team`, { params });
    }
    return Api().get(`${data.type}/${data.prefix}/charts/` +
      'risk_score_snapshot_by_team', { params });
  },
  getRiskScoreProduct(data) {
    const params = data.query;
    return Api().get(`${data.type}/${data.prefix}/charts/` +
      'risk_score_snapshot_by_product', { params });
  },
  getRiskScoreTrend(data) {
    const params = data.query;
    return Api().get(`${API_URL.DASHBOARD}/${data.prefix}/charts/risk_score_trend`, { params });
  },
  getSevertiyTrend(data) {
    const params = data.query;
    return Api().get(`${API_URL.DASHBOARD}/${data.prefix}/charts/severity_trend`, { params });
  },
  getWoeBySeverity(data) {
    const params = data.query;
    return Api().get(`${API_URL.DASHBOARD}/${data.prefix}/charts/stacked_bar_chart`, { params });
  },
  getWoeTopTen(data) {
    const params = data.query;
    return Api().get(`${API_URL.DASHBOARD}/${data.prefix}/charts/top10_woe`, { params });
  },
  getSeverityTeam(data) {
    const params = data.query;
    if (data.type === 'product') {
      return Api().get(`${data.type}/${data.id}/` +
        `${data.prefix}/charts/severity_snapshot_by_team`, { params });
    }
    return Api().get(`${data.type}/${data.prefix}/charts/severity_snapshot_by_team`, { params });
  },
  getSecurityKPITrend(data) {
    const params = data.query;
    if (data.type === 'product') {
      return Api().get(`${data.type}/${data.id}/` +
        `${data.prefix}/charts/security_kpi_trend`, { params });
    }
    return Api().get(`${API_URL.DASHBOARD}/${data.prefix}/charts/security_kpi_trend`, { params });
  },
  getSummary(data) {
    const params = data.query;
    return Api().get(`${API_URL.DASHBOARD}/${data.prefix}/charts/summary_table`, { params });
  },
  getActionItemsTable(data) {
    const params = data.query;
    return Api().get(`${API_URL.DASHBOARD}/${data.prefix}/charts` +
      `/${data.trackingTool}/action_items`, { params });
  },
  getSuppressedVulns(data) {
    const params = data.query;
    return Api().get(`${API_URL.DASHBOARD}/${data.prefix}/charts` +
      '/suppressed_vulns_chart', { params });
  },
  getBurndownTrend(data) {
    const params = data.query;
    return Api().get(`${API_URL.DASHBOARD}/${data.prefix}/charts/vulnerability_trend`, { params });
  },
  getIssueTrend(data) {
    const params = data.query;
    return Api().get(`${API_URL.DASHBOARD}/${data.prefix}/charts/issue_trend`, { params });
  },
  getNewClosedTrend(data) {
    const params = data.query;
    return Api().get(`${API_URL.DASHBOARD}/${data.prefix}/charts/new_closed_trend`, { params });
  },
  getProductsFilter(params) {
    return Api().get(`${API_URL.DASHBOARD}/filters/products`, { params });
  },
  getTeamsFilter(params) {
    return Api().get(`${API_URL.DASHBOARD}/filters/teams`, { params });
  },
  getLabelsFilter(params) {
    return Api().get(`${API_URL.DASHBOARD}/filters/labels`, { params });
  },
  getRecurrentVulns(data) {
    const params = data.query;
    return Api().get(`${API_URL.DASHBOARD}/${data.prefix}/charts/recurrent_vulnerabilities`, { params });
  },
  getNewVulns(data) {
    const params = data.query;
    return Api().get(`${API_URL.DASHBOARD}/${data.prefix}/charts/new_vulnerabilities`, { params });
  },
  getClosedVulns(data) {
    const params = data.query;
    return Api().get(`${API_URL.DASHBOARD}/${data.prefix}/charts/closed_vulnerabilities`, { params });
  },
  getSuppressedVulnerabilities(data) {
    const params = data.query;
    return Api().get(`${API_URL.DASHBOARD}/${data.prefix}/charts/suppressed_vulnerabilities`, { params });
  },
  getNetNewVulns(data) {
    const params = data.query;
    return Api().get(`${API_URL.DASHBOARD}/${data.prefix}/charts/net_vulns_chart`, { params });
  },
  getEnvironmentBreakdown(data) {
    const params = data.query;
    return Api().get(`${API_URL.DASHBOARD}/${data.prefix}/charts/environments_bar_chart`, { params });
  },
};
