const PreComponent = '<router-view/>';

export default {
  path: 'sbom',
  name: 'sbom',
  component: { template: PreComponent },
  redirect: {
    name: 'sbom-scans',
    query: {
      page: 1,
    },
  },
  children: [
    {
      path: 'scans',
      name: 'sbom-scans',
      component: () => import('@/views/pages/member/Sbom/Scans.vue'),
      meta: {
        title: 'SBOM Scans',
        activeMenuKeys: ['sbom', 'sbom-scans'],
        allowedRole: [0, 1, 2, 3, 5],
      },
      beforeEnter: (to) => {
        if (!to.query.page) {
          return {
            name: 'sbom-scans',
            query: { page: 1 },
          };
        }
      },
    }, {
      path: 'components',
      name: 'sbom-components',
      component: () => import('@/views/pages/member/Sbom/Components.vue'),
      meta: {
        title: 'SBOM Components',
        activeMenuKeys: ['sbom', 'sbom-components'],
        allowedRole: [0, 1, 2, 3, 5],
      },
      beforeEnter: (to) => {
        if (!to.query.page) {
          return {
            name: 'sbom-scans',
            query: { page: 1 },
          };
        }
      },
    },
  ],
};
