const sourceProjectDummy = [...Array(10)].map((s, i) => ({
  id: i,
  name: '###',
  owner: '###',
  slug: '###############',
  tool: 'github',
  url: '###############',
  languages: [{ name: 'default', percentage: '####' }],
}));

export default sourceProjectDummy;
