import Api from '@/services/Api';
import { API_URL } from '@/config/';

export default {
  getEndpointsList(data) {
    const params = data.query;
    return Api().get(`${API_URL.ENDPOINTS}/findings`, { params });
  },
  vulnAssignEndpoints(params) {
    if (params.mode === 'assign') {
      return Api().post(`${API_URL.ENDPOINTS}/assign`, params.query);
    }
    return Api().post(`${API_URL.ENDPOINTS}/ignore`, params.query);
  },
  getVulnEndpointsList(params) {
    return Api().get(`${API_URL.VULNS}/${params}/endpoints`);
  },
  getExport(params) {
    return Api().get(`${API_URL.ENDPOINTS}/export`, { params });
  },
  syncEndpoints() {
    return Api().get(`${API_URL.ENDPOINTS}/sync`);
  },
};
