const PreComponent = '<router-view/>';

export default {
  path: 'endpoints',
  name: 'endpoints',
  component: { template: PreComponent },
  redirect: {
    name: 'endpoints-list',
  },
  children: [
    {
      path: 'list',
      name: 'endpoints-list',
      component: () => import('@/views/pages/member/Endpoint/List.vue'),
      meta: {
        title: 'Endpoints',
        activeMenuKeys: ['endpoints'],
        allowedRole: [0, 1, 5],
      },
    },
  ],
};
