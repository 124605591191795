// ## Config Inc.
const dummyData = [...Array(2)].map((_, i) => ({
  id: i,
  name: '#####',
  category: '#####',
  image: '#####',
  desciption: '#####',
}));

export default dummyData;
