import { defineStore } from 'pinia';

import EndpointService from '@/services/Endpoint';

import ErrorHandling from '@/mixins/ErrorHandling';

import { ENDPOINTS } from '@/config/';

import dummyData from './_dummyData';

const useEndpointStore = defineStore({
  id: 'Endpoint',
  state: () => ({
    // ## Endpoints table. ---------------------------------------------------------------------------
    endpointsList: dummyData,
    endpointsListPerpage: ENDPOINTS.PERPAGE,
    endpointsListTotal: 0,
    endpointsListPending: false,
    endpointsListDone: false,
    endpointsListFail: false,
    endpointsListFailMsg: null,

    // ## Assign remove endpoint. --------------------------------------------------------------------
    vulnAssignEndpointsPending: false,
    vulnAssignEndpointsDone: false,
    vulnAssignEndpointsFail: false,
    vulnAssignEndpointsModalData: {},
    vulnAssignEndpointsModalVisible: false,

    // ## Vuln endpoints -----------------------------------------------------------------------------
    vulnEndpointsList: [],
    vulnEndpointsListPending: false,
    vulnEndpointsListDone: false,

    // ## Sync endpoints -----------------------------------------------------------------------------
    syncEndpointsData: null,
    syncEndpointsPending: false,
    syncEndpointsDone: false,
  }),
  actions: {
    // ## Get endpoint list. -------------------------------------------------------------------------
    async getEndpointsList(data) {
      this.endpointsListPending = true;

      try {
        const params = {
          query: {
            limit: this.endpointsListPerpage,
            start: (data.page - 1) * this.endpointsListPerpage,
            sort: data.sort,
            endpoint: data.endpoint,
            severity: data.severity,
            project_ids: data.projectIds,
            team_ids: data.teamIds,
            label_ids: data.labelIds,
          },
        };
        const res = await EndpointService.getEndpointsList(params);
        this.endpointsListTotal = res.data.total;
        this.setGetEndpointsList(res.data);
        this.endpointsListDone = !this.endpointsListDone;
        this.endpointsListPending = false;
      } catch (error) {
        this.endpointsList = dummyData;
        this.endpointsListTotal = 0;
        this.endpointsListPending = false;
        this.endpointsListFail = !this.endpointsListFail;
        this.endpointsListFailMsg = ErrorHandling(error);
      }
    },

    setGetEndpointsList(payload) {
      this.endpointsList = [];
      if (!payload) {
        return;
      }
      this.endpointsList = payload.data.map((s) => ({
        id: s.id,
        name: s.endpoint,
        summary: {
          critical: s.sast.findings.critical + s.dast.findings.critical + s.sca.findings.critical,
          high: s.sast.findings.high + s.dast.findings.high + s.sca.findings.high,
          medium: s.sast.findings.medium + s.dast.findings.medium + s.sca.findings.medium,
          low: s.sast.findings.low + s.dast.findings.low + s.sca.findings.low,
          total: s.sast.total + s.dast.total + s.sca.total,
        },
        projects: [
          {
            key: 1,
            sast: s.sast.projects,
            dast: s.dast.projects,
            sca: s.sca.projects,
          },
        ],
        children: [
          {
            name: 'sast',
            summary: {
              critical: s.sast.findings.critical,
              high: s.sast.findings.high,
              low: s.sast.findings.low,
              medium: s.sast.findings.medium,
              total: s.sast.total,
            },
          }, {
            name: 'dast',
            summary: {
              critical: s.dast.findings.critical,
              high: s.dast.findings.high,
              medium: s.dast.findings.medium,
              low: s.dast.findings.low,
              total: s.dast.total,
            },
          }, {
            name: 'sca',
            summary: {
              critical: s.sca.findings.critical,
              high: s.sca.findings.high,
              medium: s.sca.findings.medium,
              low: s.sca.findings.low,
              total: s.sca.total,
            },
          },
        ],
      }));
    },

    // ## Assign remove endpoint. --------------------------------------------------------------------
    async vulnAssignEndpoints(data) {
      this.vulnAssignEndpointsPending = true;

      try {
        await EndpointService.vulnAssignEndpoints(data);
        this.vulnAssignEndpointsDone = !this.vulnAssignEndpointsDone;
        this.vulnAssignEndpointsPending = false;
      } catch (error) {
        this.vulnAssignEndpointsFail = !this.vulnAssignEndpointsFail;
        this.vulnAssignEndpointsPending = false;
        ErrorHandling(error);
      }
    },

    showVulnAssignEndpointsModal(data) {
      this.vulnAssignEndpointsModalData = data;
      this.vulnAssignEndpointsModalVisible = true;
    },

    closeVulnAssignEndpointsModal() {
      this.vulnAssignEndpointsModalData = {};
      this.vulnAssignEndpointsModalVisible = false;
    },

    // ## Get vuln endpoints list. -------------------------------------------------------------------
    async getVulnEndpointsList(data) {
      this.vulnEndpointsListPending = true;

      try {
        const res = await EndpointService.getVulnEndpointsList(data);
        this.vulnEndpointsList = res.data.data;
        this.vulnEndpointsListDone = !this.vulnEndpointsListDone;
        this.vulnEndpointsListPending = false;
      } catch (error) {
        this.vulnEndpointsListPending = false;
        ErrorHandling(error);
      }
    },

    // ## Sync endpoints. ----------------------------------------------------------------------------
    async syncEndpoints() {
      this.syncEndpointsPending = true;

      try {
        const res = await EndpointService.syncEndpoints();
        this.syncEndpointsData = res.data.data;
        this.syncEndpointsDone = !this.syncEndpointsDone;
        this.syncEndpointsPending = false;
      } catch (error) {
        this.syncEndpointsPending = false;
        ErrorHandling(error);
      }
    },
  },
});

export default useEndpointStore;
