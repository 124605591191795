import dayjs from 'dayjs';

const Dummy = [
  {
    level: 'dummy',
    caller: '###',
    message: '#####',
    data: '###',
    user: {
      id: 0,
      name: '###',
      email: '###',
    },
  }, {
    level: 'dummy',
    caller: '###',
    message: '#####',
    data: '###',
    user: {
      id: 0,
      name: '###',
      email: '###',
    },
  }, {
    level: 'dummy',
    caller: '###',
    message: '#####',
    data: '###',
    user: {
      id: 0,
      name: '###',
      email: '###',
    },
  }, {
    level: 'dummy',
    caller: '###',
    message: '#####',
    data: '###',
    user: {
      id: 0,
      name: '###',
      email: '###',
    },
  }, {
    level: 'dummy',
    caller: '###',
    message: '#####',
    data: '###',
    user: {
      id: 0,
      name: '###',
      email: '###',
    },
  }, {
    level: 'dummy',
    caller: '###',
    message: '#####',
    data: '###',
    user: {
      id: 0,
      name: '###',
      email: '###',
    },
  }, {
    level: 'dummy',
    caller: '###',
    message: '#####',
    data: '###',
    user: {
      id: 0,
      name: '###',
      email: '###',
    },
  }, {
    level: 'dummy',
    caller: '###',
    message: '#####',
    data: '###',
    user: {
      id: 0,
      name: '###',
      email: '###',
    },
  }, {
    level: 'dummy',
    caller: '###',
    message: '#####',
    data: '###',
    user: {
      id: 0,
      name: '###',
      email: '###',
    },
  }, {
    level: 'dummy',
    caller: '###',
    message: '#####',
    data: '###',
    user: {
      id: 0,
      name: '###',
      email: '###',
    },
  }, {
    level: 'dummy',
    caller: '###',
    message: '#####',
    data: '###',
    user: {
      id: 0,
      name: '###',
      email: '###',
    },
  }, {
    level: 'dummy',
    caller: '###',
    message: '#####',
    data: '###',
    user: {
      id: 0,
      name: '###',
      email: '###',
    },
  }, {
    level: 'dummy',
    caller: '###',
    message: '#####',
    data: '###',
    user: {
      id: 0,
      name: '###',
      email: '###',
    },
  },
];

const auditLogDummy = [...Array(15)].map(() => ({
  date: dayjs(),
  username: '###############',
  userAgent: '###############',
  ip: '###############',
  eventName: '###############',
  eventCategory: 'user',
  description: '###############',
}));

export {
  Dummy,
  auditLogDummy,
};
