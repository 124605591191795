import Api from '@/services/Api';
import { API_URL } from '@/config/';

export default {
  getProjectFilterList(params) {
    return Api().get(`${API_URL.PROJECTS}`, { params });
  },
  getLabelFilterList(params) {
    return Api().get(`${API_URL.SETTINGS_LABELS}`, { params });
  },
  getCommitterFilterList(params) {
    return Api().get(`${API_URL.COMMITTERS}`, { params });
  },
  getTeamFilterList(params) {
    return Api().get(`${API_URL.TEAMS}`, { params });
  },
  getProductFilterList(params) {
    return Api().get(`${API_URL.PRODUCTS}`, { params });
  },
  getScannerFilterList(params) {
    return Api().get(`${API_URL.FILTERS_SCANNER}`, { params });
  },
  cweFilter(params) {
    return Api().get(`${API_URL.CWE}`, { params });
  },
  getCweSearch(params) {
    return Api().get(`${API_URL.CWE}/search?limit=30&sort=cwe_id&q=${params}`);
  },
  getEndpointSearch(params) {
    return Api().get(`${API_URL.PROJECTS}/${params.id}/endpoints?` +
      `limit=20&endpoint:rgx=${params.value}`);
  },
  getCriteriaSearch(params) {
    if (params.teamId) {
      if (params.name) {
        return Api().get(`${API_URL.SETTINGS}/global/${params.type}?q=rgx:${params.name}` +
        `&team_id=eq:${params.teamId}`);
      }
      return Api().get(`${API_URL.SETTINGS}/global/${params.type}?` +
      `&team_id=eq:${params.teamId}`);
    }
    if (params.name) {
      return Api().get(`${API_URL.SETTINGS}/global/${params.type}?q=rgx:${params.name}`);
    }
    return Api().get(`${API_URL.SETTINGS}/global/${params.type}`);
  },
  cloudAssetsFilter(data) {
    const params = data.query;
    if (data.projectId) {
      return Api().get(`${API_URL.CLOUD_ASSETS}/filters/${data.prefix}` +
        `?project_id=${data.projectId}&resource=${data.type}`, { params });
    }
    return Api().get(`${API_URL.CLOUD_ASSETS}/filters/inventory?resource=${data.type}`, { params });
  },
  getAuditLogFilter(data) {
    const params = data.query;
    return Api().get(`${API_URL.SETTINGS}/audit-logs/filters/${data.type}`, { params });
  },
};
