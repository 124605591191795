// ## Config Inc.
import { REPORTS } from '@/config/';

const reportDummy = [...Array(REPORTS.PERPAGE)].map(() => ({
  id: '###',
  title: '###',
  type: '###',
  name: '###',
  recurrence: '###############',
  dates: {
    start: '######### #####',
    generateAt: '######### #####',
    lastGenerated: '######### #####',
    lastSchedule: '######### #####',
  },
}));

export default reportDummy;
