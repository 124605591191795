import { defineStore } from 'pinia';

import WebhookService from '@/services/Webhook';

import ErrorHandling from '@/mixins/ErrorHandling';

const useWebhookStore = defineStore({
  id: 'Webhook',
  state: () => ({
    // ## Get webhook. -------------------------------------------------------------------------------
    webHook: null,
    getWebhookDone: false,
    getWebhookPending: false,

    // ## Get webhook key. ---------------------------------------------------------------------------
    webhookKey: null,
    getWebhookKeyPending: false,
    getWebhookKeyDone: false,

    webHookId: null,
    addWebhookPending: false,
    addWebhookDone: false,

    // ## Update webhook. ----------------------------------------------------------------------------
    updateWebhookPending: false,
    updateWebhookDone: false,

    // ## Remove webhook. ----------------------------------------------------------------------------
    removeWebhookPending: false,
    removeWebhookDone: false,
  }),
  actions: {
    // ## Get webhook. -------------------------------------------------------------------------------
    async getWebhook(data) {
      this.getWebhookPending = true;

      try {
        const res = await WebhookService.get(data);
        this.webHook = res.data;
        this.getWebhookDone = !this.getWebhookDone;
        this.getWebhookPending = false;
      } catch (error) {
        this.getWebhookPending = false;
        ErrorHandling(error);
      }
    },

    // ## Get webhook ley. ---------------------------------------------------------------------------
    async getWebhookKey(data) {
      this.getWebhookKeyPending = true;

      try {
        const res = await WebhookService.getKey(data);
        this.webhookKey = res.data.secretKey;
        this.getWebhookKeyDone = !this.getWebhookKeyDone;
        this.getWebhookKeyPending = false;
      } catch (error) {
        this.getWebhookKeyPending = false;
        ErrorHandling(error);
      }
    },

    // ## Add webhook. -------------------------------------------------------------------------------
    async addWebhook(data) {
      this.addWebhookPending = true;

      try {
        const res = await WebhookService.post(data);
        this.webHookId = res.data.data;
        this.addWebhookDone = !this.addWebhookDone;
        this.addWebhookPending = false;
      } catch (error) {
        this.addWebhookPending = false;
        ErrorHandling(error);
      }
    },

    // ## Update webhook. ----------------------------------------------------------------------------
    async updateWebhook(data) {
      this.updateWebhookPending = true;

      try {
        await WebhookService.update(data);
        this.updateWebhookDone = !this.updateWebhookDone;
        this.updateWebhookPending = false;
      } catch (error) {
        this.updateWebhookPending = false;
        ErrorHandling(error);
      }
    },

    // ## Remove webhook. ----------------------------------------------------------------------------
    async removeWebhook(data) {
      this.removeWebhookPending = false;

      try {
        await WebhookService.remove(data);
        this.removeWebhookDone = !this.removeWebhookDone;
        this.removeWebhookPending = false;
      } catch (error) {
        this.removeWebhookPending = false;
        ErrorHandling(error);
      }
    },
  },
});

export default useWebhookStore;
